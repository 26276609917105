<template>
  <b-modal id="bv-modal-info" size="lg" title="Mais Informações" scrollable centered>

        <div class="row">
			<div class="col-md-12" v-if="this.getlogInfo.protocol">
				<label for="protocolo"><strong>Protocolo</strong></label>
				<input class="form-control" v-model="this.getlogInfo.protocol" type="text" id="protocolo" name="protocolo" disabled>
			</div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12" v-if="this.tempoAtendimento">
				<label for="tempoAtendimento"><strong>Tempo de Atendimento</strong></label>
				<input class="form-control" v-model="this.tempoAtendimento" type="text" id="tempoAtendimento" name="tempoAtendimento" disabled>
			</div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12" v-if="this.tempoEspera">
				<label for="tempoEspera"><strong>Tempo de Espera</strong></label>
				<input class="form-control" v-model="this.tempoEspera" type="text" id="tempoEspera" name="tempoEspera" disabled>
			</div>
		</div>
 
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="danger"
            class="float-right"
            @click="downloadPdf()"
        >
          Baixar PDF
        </b-button>
      </div>
    </template>

    <VueHtml2pdf
        class="d-none"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :filename="mensagens"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        ref="html2Pdf"
    >
      <section slot="pdf-content">

        <div style="margin: 20px;">
        
        <div class="row">
			<div class="col-md-12" v-if="this.getlogInfo.protocol">
				<label for="protocolo"><strong>Protocolo</strong></label>
				<input class="form-control" v-model="this.getlogInfo.protocol" type="text" id="protocolo" name="protocolo" disabled>
			</div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12" v-if="this.tempoAtendimento">
				<label for="tempoAtendimento"><strong>Tempo de Atendimento</strong></label>
				<input class="form-control" v-model="this.tempoAtendimento" type="text" id="tempoAtendimento" name="tempoAtendimento" disabled>
			</div>
        </div>

        <div class="row mt-4">
            <div class="col-md-12" v-if="this.tempoEspera">
				<label for="tempoEspera"><strong>Tempo de Espera</strong></label>
				<input class="form-control" v-model="this.tempoEspera" type="text" id="tempoEspera" name="tempoEspera" disabled>
			</div>
		</div>

       </div>
      </section>
    </VueHtml2pdf>
  </b-modal>
</template>
<script>
import {mapGetters} from 'vuex';
import itemMessage from '../mensagem/ItemMensagen';
import VueHtml2pdf from 'vue-html2pdf';

export default {
  components:{
    itemMessage,
    VueHtml2pdf
  },

  data:() => ({
    attendance_at: moment().format("YYYY-MM-DD HH:mm:ss"),
    mensagens: 'mensagens_' + window.moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
    tempoEspera: 0,
    tempoAtendimento: 0
  }),

  computed:{
    ...mapGetters(['getlogInfo'])
  },

  methods:{
    async openModal() {
        this.$bvModal.show('bv-modal-info');
        this.attendance_at = await this.pAtenOperador(this.getlogInfo.msgs);
        this.tempoEspera = await this.calcTempo(this.getlogInfo.created_at, this.attendance_at);
        this.tempoAtendimento = await this.calcTempo(this.getlogInfo.created_at, this.getlogInfo.updated_at);
    },

    async pAtenOperador (msgs) {
        return new Promise(async resolve => {

            let return_attendance = moment().format("YYYY-MM-DD HH:mm:ss");
            Object.keys(msgs).forEach(async i => {
                if(msgs[i].type == "operator"){
                    let attendance_1 = moment(msgs[i].data, "DD/MM/YYYY HH:mm");
                    let attendance_at = attendance_1.format("YYYY-MM-DD HH:mm:ss");

                    resolve(attendance_at);
                }

                if(msgs[i].type == "sistema"){
                    let attendance_1 = moment(msgs[i].data, "DD/MM/YYYY HH:mm");
                    return_attendance = attendance_1.format("YYYY-MM-DD HH:mm:ss");
                }
            });

            resolve(return_attendance);
        });
    },

    async calcTempo (espStart, espEnd) {
        let espStartTime = moment(espStart, "YYYY-MM-DD HH:mm:ss").unix();
        let espEndTime = moment(espEnd, "YYYY-MM-DD HH:mm:ss").unix();

        let totalE = espEndTime - espStartTime;
        let totalEspera = totalE < 0 ? 0 : totalE;

        return totalEspera >= 86400 ? moment.duration(totalEspera, "seconds").format("h:mm:ss").replace(',', '.') : moment.utc(totalEspera * 1000).format('HH:mm:ss');
    },

    downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    }
  }
}
</script>

<style scoped>
    .modal-log{
        position: relative;
        margin: 10% auto;
        max-height: 50em;
        padding: 30px;
        max-width: 60em;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
        background-color: #FAFAFA;
        overflow-y: auto;
    }

    .avoid-page-break {
      page-break-inside: avoid;
    }
</style>