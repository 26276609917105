import axios from 'axios'
import { isEmpty } from 'lodash'
import operator from './operator'
import message from './message'

const state = {
    clientes: [],
    cnpj: '',
    phone: '',
    client: [],
    empty: '',
    preSelect: [],
    history: [],
}
const getters = {
    allClients: state => state.clientes,
    oneClient: state => state.client,
    getCnpj: state => state.cnpj,
    getPhone: state => state.phone,
    preSelectShow: state => state.preSelect,
    getHistory: state => state.history,
}
const actions = {
    async fetchClients({ commit }, cnpj) {
        let response = await axios(`/share-client-cnpj?share=${cnpj}`);

        commit('setClients', response.data);
    },
    async fetchClientsOP({ commit }, cnpj) {
        let response = await axios(`/share-client-cnpj?share=${cnpj}`);

        if(response.data[0] == undefined){
            cnpj = cnpj.slice(0, 3) + "9" + cnpj.slice(3);
            response = await axios(`/share-client-cnpj?share=${cnpj}`);
        }   

        commit('setClient', response.data[0]);
    },
    async preselectClient({ commit}, data){
        commit('setPreSelectClient', data);
    },
    async chooseClient({ commit }, data) {
        commit('setClient', data);
    },
    async chooseClientCnpj({ commit }, cpf_cnpj) {
        commit('setCnpj', cpf_cnpj);
    },
    async clearAllClients({ commit }) {
        commit('clearState');
    },
    async clearClient({ commit }) {
        commit('clearOneClient');
    },
    async saveClient({ commit }, data) {
        axios.post(`/save-attendance`, {
            params: data
        }).then(function() {

        });
    },
    selectClient(state){

        state.state.client = state.state.preSelect;
        state.state.preSelect = [];

        if(operator.state.myChannel == ''){
            message.state.curConversaCNPJ = state.state.preSelect.cpf_cnpj;
        }        
    },
    chooseClientPhone({ commit }, phone) {
        commit('setPhone', phone);
    },
    async fetchHistoryClient({ commit, getters }) {
        const num = getters.getPhone;
        const response = await axios(`/getHistoryClient?number=${num}`);

        commit('setHistory', response.data);
    }
}
const mutations = {
    setPreSelectClient: (state, client) => state.preSelect = client,
    setClients: (state, clientes) => (state.clientes = clientes),
    setCnpj: (state, cnpj) => (state.cnpj = cnpj),
    setClient: (state, client) => {
        state.client = client;
    },
    clearState: (state, empty, clientes) => (state.clientes = empty),
    clearOneClient: (state, empty) => (state.client = empty),
    setPhone: (state, phone) => (state.phone = phone),
    setHistory: (state, history) => (state.history = history),
}
export default {
    state,
    getters,
    actions,
    mutations
}