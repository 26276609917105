<template>
  <div class="card my-3 px-3 py-3" style="width: 46%">
    <h3 style="color: #000; text-align: center">
      <i class="fas fa-phone-slash"></i> Ligações Abandonadas
    </h3>
    <hr />
    <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  props: ["filter"],
  data() {
    return {
      chartOptions: {
        chart: {
          id: "pizzaChartAbandon",
          type: "donut",
        },
        dataLabels: {
          offsetX: 50,
          offsetY: 2,
          style: {
            fontSize: "1.5em",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  fontSize: "1.8em",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total",
                  fontSize: "1.8em",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: "#373d3f",
                },
              },
            },
          },
        },
        labels: ["Até 1 min de espera", "Maior 1 min de espera"],
        colors: ["#00E396", "#FF4560"],
      },
      series: [],
    };
  },
  methods: {
    getCalls() {
      axios
        .get(`${window.location.href}/abandonedCallsPizzaChart?queue=${this.filter}`)
        .then(({ data }) => {
          this.series = [data.Menor1minEspera, data.Maior1minEspera];
        });
    },
  },
  created() {
    this.getCalls();
    setInterval(() => {
      this.getCalls();
    }, 30000);
  },
  mounted() {
    this.getCalls();
  },
  watch: {
    filter() {
      this.getCalls();
    }
  }
};
</script>