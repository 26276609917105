<template>
    <section>
        <div class="row mt-4 container-table">
            <div class="col-md-12">
                <table class="tables w-100 mb-4">
                    <tr>
                        <th class="text-center">Fila de atendimento</th>
                        <th class="text-center">Em espera</th>
                        <th class="text-center">Ativos</th>
                        <th class="text-center">Em pausa</th>
                    </tr>

                    <tr v-for="q in userQueues" :key="q.id">
                        <td class="text-center">{{q.name}}</td>
                        <td class="text-center">{{q.count}}</td>
                        <td class="text-center">{{q.active.length}}</td>
                        <td class="text-center">{{q.paused.length}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    props: ['connection'],
    data(){
        return{
            obj: [],
            currentSort:'name',
            currentSortDir:'asc',
            pageSize:10,
            currentPage:1,
            userQueues: []
        }
    },
    computed:{
	},
    methods:{
        totalCalls: function() {
            return 0;
        },
        queueEntry: async function() {
            this.connection.on('QueueEntry', (data) => {
                let newClient ={
                    telefone: data.CallerIDNum,
                    fila: data.Queue,
                    tempo: data.Wait
                }
                const index = this.obj.findIndex(el => el.telefone == data.CallerIDNum);

                if(index != -1) return

                this.obj.push(newClient);

                this.userQueues.forEach(el => {
                    if(el.name == data.Queue){
                        el.count++;
                    }
                });
            });
        },
        queueCallerJoin: async function() {
            this.connection.on('QueueCallerJoin', data => {
                const index = this.obj.findIndex(el => el.telefone == data.CallerIDNum);

                if(index != -1) return

                this.obj.push({
                    telefone: data.CallerIDNum,
                    fila: data.Queue,
                    tempo: '0'
                })

                this.userQueues.forEach(el => {
                    if(el.name == data.Queue){
                        el.count++;
                    }
                })
            });

        },
        queueCallerLeave: async function() {
            this.connection.on('QueueCallerLeave', data => {
                const index = this.obj.findIndex(el => el.telefone == data.CallerIDNum);

                if(index == -1) return

                this.obj.splice(index, 1);

                this.userQueues.forEach(el => {
                    if(el.name == data.Queue && el.count > 0){
                        el.count--;
                    }
                })
            });

        },
        queueMember: async function(extension) {
            this.connection.emit('QueueStatus', {
                ramal: extension
            });

            this.connection.on(`QueueMember-${extension}`, (data) => {
                const extensionReplace = data.Name.replace('SIP/', '');
                this.userQueues.forEach(el => {
                    if(el.name == data.Queue) {
                        if(data.Paused == '1') {
                            if (!el.paused.includes(extensionReplace)) {
                                el.paused.push(extensionReplace);
                            }
                        } else {
                            if (!el.active.includes(extensionReplace)) {
                                el.active.push(extensionReplace);
                            }
                        }
                    }
                });
            });
        },
        queueMemberAdded: async function(extension) {
            this.connection.on(`QueueMemberAdded-${extension}`, data => {
                const extensionReplace = data.MemberName.replace('SIP/', '');
                this.userQueues.forEach(el => {
                    if(el.name == data.Queue) {
                        if (!el.active.includes(extensionReplace)) {
                            el.active.push(extensionReplace);
                        }
                    }
                });
            });
        },
        queueMemberRemoved: async function(extension) {
            this.connection.on(`QueueMemberRemoved-${extension}`, data => {
                const extensionReplace = data.MemberName.replace('SIP/', '');
                this.userQueues.forEach(el => {
                    if(el.name == data.Queue) {
                        if(el.active.length > 0) {
                            el.active = el.active.filter(l => l != extensionReplace);
                        };
                    }
                });
            });
        },
        queueMemberPause: async function(extension) {
            this.connection.on(`QueueMemberPause-${extension}`, data => {
                const extensionReplace = data.MemberName.replace('SIP/', '');
                this.userQueues.forEach(el => {
                    if(el.name == data.Queue) {
                        if(data.Paused == '1') {
                            if (!el.paused.includes(extensionReplace)) {
                                el.paused.push(extensionReplace);
                            }

                            if(el.active.length > 0) {
                                el.active = el.active.filter(l => l != extensionReplace);
                            };
                        } else {
                            if (!el.active.includes(extensionReplace)) {
                                el.active.push(extensionReplace);
                            }
                            
                            if(el.paused.length > 0) {
                                el.paused = el.paused.filter(l => l != extensionReplace);
                            };
                        }
                    }
                });
            });
        },
    },
    watch:{
		obj(){
            // this.obj
		}
    },
    created(){
        this.queueEntry();
        this.queueCallerJoin();
        this.queueCallerLeave();
        axios.get('/realtime/listUsersPhone').then(({ data }) => {
            data.forEach(({ extension_line }) => {
                this.queueMember(extension_line);
                this.queueMemberAdded(extension_line);
                this.queueMemberRemoved(extension_line);
                this.queueMemberPause(extension_line);
            });
        });

        axios.get('/user-queues').then(({ data }) => {
            if(data.length > 0) {
                this.userQueues = data;
            }
        });
    }

}
</script>

<style scoped>
	.container-table {
		margin-bottom: 20px;
	}

    .tables th {
        border: none;
    }

    .button-page {
        float:right;
        margin-right: 0.5em;
    }

    .no-border {
        border: hidden;
        background: none;
    }

    th {
        padding: 1em;
    }

    td {
        padding:1em;
    }
</style>
