import axios from 'axios'

const state ={
    occurrences:[],
    occurrencesByDateAndStatus:[],
    response:[],
    id: 0
}
const getters ={
    getAllOcorrence: state => state.occurrences,
    getOccurrencesByDateAndStatus:state => state.occurrencesByDateAndStatus,
}
const actions={
    async fetchOcurrences({commit},data){
        commit('setOccurrence', data)
    },
    async fetchOcurrencesByDate({commit},x){
        commit('setOccurrence',response.data)
    },
    async createOccurrence({commit},x){
        axios({
            method: 'post',
            url: '/create-occurrences',
            data: x
        });
    },
    async fetchOcurrencesByDateAndStatus({commit},x){
        const response = await axios.get(`/list-occurrences-date-status?cnpj=${x.cnpj}&startDate=${x.de}&endDate=${x.ate}&status=${x.status}`);
        commit('setOccurrencesByDateAndStatus',response.data)
    },
    async pushActions(state){
        const size = 10;
        axios.get('dialer/search_product').then(data => {
            state.state.occurrences = data.data.slice(0, size);
        });
        // state.state.occurrences.push({id: state.state.id++, code: 'rod', description:'rodrigo'})
    }
}
const mutations={
    setOccurrence:(state,occurrences)=>{
        
        if(typeof occurrences == 'string'){ 
            return;
        }

        state.occurrences = occurrences
    },
    setOccurrencesByDateAndStatus:(state,occurrencesByDateAndStatus)=>(state.occurrencesByDateAndStatus = occurrencesByDateAndStatus)
}
export default {
    state,
    getters,
    actions,
    mutations
}

