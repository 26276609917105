<template>
  <div>
    <b-modal 
      id="bv-modal-transfer" 
      title="Para qual Departamento/Operador deseja transferir?" 
      centered 
      hide-footer
      @show="resetModal"
      @hidden="resetModal">
      <b-row class="justify-content-md-center">
        <b-col cols="12" md="7">
          <v-select
            label="text"
            :options="queues"
            :searchable="false"
            placeholder="🔽 Selecione um departamento"
            v-model="selectedFila">
            <template #header>
              <div style="opacity: 1">Departamento</div>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-row v-if="exten.length > 0" class="justify-content-md-center mt-3">
        <b-col cols="12" md="7">
          <v-select
            label="name"
            :options="exten"
            placeholder="🔽 Selecione um operador"
            v-model="selectedRamal">
            <template #header>
              <div style="opacity: 1">Operador</div>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <!-- <b-row v-else-if="exten.length == 0 && selectedFila" class="justify-content-md-center mt-3">
        <b-col cols="12" md="7">
          <v-select
            label="name"
            :searchable = "false"
            placeholder="❌ Sem operadores disponíveis"
            :disabled = "true">
            <template #header>
              <div style="opacity: 1">Operador</div>
            </template>
          </v-select>
        </b-col>
      </b-row> -->
      <b-row class="mt-3 pad">
        <b-col></b-col>
        <b-col cols="12" md="auto">
          <b-button @click="$bvModal.hide('bv-modal-transfer')">CANCELAR</b-button>
          <b-button variant="primary" @click="sendTransfer">TRANSFERIR</b-button>
        </b-col>
      </b-row>
      <b-toast id="my-toast" variant="warning" solid>
        <template #toast-title>
          <div class="d-flex flex-grow-1 align-items-baseline">
            <strong><b-icon icon="exclamation-triangle" class="mr-2" style="color: #91560a; width: 18px; height: 18px;"></b-icon></strong>
            <strong class="mr-auto mt-2" style="font-size: 16px">Atenção</strong>
          </div>
        </template>
        Você precisa selecionar pelo menos o Departamento
      </b-toast>
    </b-modal>
  </div>
</template>

<script>
import Swal from "sweetalert";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "ModalTransferencia",
  data: () => ({
    queues: [],
    operators: [],
    selectedFila: null,
    selectedRamal: null,
    options: [],
    exten: [],
  }),
  watch: {
    gettransfData() {
      this.queues = this.gettransfData.queues;
    },
    async selectedFila() {
      if (!this.selectedFila || this.selectedFila.value == null) return;

      try {
        const response = await axios.get(
          `ramaisFila/${this.selectedFila.text}`
        );
        if (response.data) {
          const operadores = response.data;
          this.exten = [];

          this.gettransfData.operators.forEach((el) => {
            const value = el.replace(/\D/g, "");
            if (
              Object.keys(operadores).includes(value) &&
              value != user.getId()
            ) {
              this.exten.push({
                id: operadores[value].id,
                name: operadores[value].name,
              });
            }
          });
        }
      } catch (e) {
        console.error("Erro ao buscar operadores");
      }
    },
  },
  computed: {
    ...mapGetters(["gettransfData"]),
  },
  methods: {
    ...mapMutations(["transfProcess", "transfCurrentConversa"]),
    openModal() {
      this.$bvModal.show("bv-modal-transfer");
      this.transfProcess();
    },
    resetModal() {
      this.selectedFila = null;
      this.selectedRamal = null;
      this.exten = [];
    },
    async sendTransfer() {
      if (this.selectedFila.value == null) {
        Swal.fire({
          title: "Você não selecionou nenhuma fila",
          text: "Selecione a fila para qual deseja transferir",
          type: "info",
          confirmButtonText: "Ok",
        });
        return;
      }

      //DESCOMENTAR PRA HABILITAR TRANSFERÊNCIA SOMENTE PARA OPERADOR
      // if(!this.selectedRamal){
      //   Swal.fire({
      //     title: "Você não selecionou o operador",
      //     text: "Selecione para qual operador deseja transferir",
      //     type: 'info',
      //     confirmButtonText: "Ok"
      //   });
      //   return;
      // }

      if (this.selectedFila.value != null && this.selectedRamal) {
        return this.transfCurrentConversa({
          destination: {
            extension_line: this.selectedRamal.id,
            name: this.selectedRamal.name,
            queue: this.selectedFila.text
          },
          origin: {
            extension_line: user.getId(),
            name: user.getName(),
          },
          type: "operator",
        });
      }

      this.transfCurrentConversa({
        destination: {
          id: this.selectedFila.value,
          name: this.selectedFila.text,
        },
        origin: {
          extension_line: user.getId(),
          name: user.getName(),
        },
        type: "queue",
      });

      this.selectedFila = { value: null, text: "Selecione" };
      this.selectedRamal = null;
      return;
    },
  },
};
</script>

<style scoped>
.pad {
  padding: 1em;
}
</style>