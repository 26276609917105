<template>
  <div
    v-if="getShowBarRight"
    v-on:click="buttonPush"
    class="button-push esquerdo"
  >
    <i class="image-button fas fa-circle-arrow-right"></i>
  </div>

  <div v-else v-on:click="buttonPush" class="button-push esquerdo">
    <i class="image-button fas fa-circle-arrow-left"></i>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getShowBarRight"]),
  },
  methods: {
    ...mapActions(["setShowBarRight"]),
    buttonPush(event) {
      this.setShowBarRight();
    },
  },
};
</script>

<style>
.button-push {
  position: absolute;
  top: 49vh;
  left: -26px;
}

.button-push:hover,
.button-pop-up:hover {
  cursor: pointer;
}

.image-button {
  color: black;
  font-size: 24px;
}
</style>
