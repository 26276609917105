<template>
    <div class="pause modal-2" v-if='getModalPause'>
            <div class="modal-content-my">
                <form class='form-modal'>
					<div class="modal-head">
						<h3 class="title-text">Pausas</h3>
						<span @click='btnClose' class='closeBtn'>&times;</span>
					</div>
                    <div class="opcoes" v-for='pause in getPauses' @click='clickPause(pause.id, pause.name)' :key='pause.id'>
                        <p class='opcao'>{{pause.name}}</p>
                    </div>
                </form>

            </div>
        </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
const connection = io.connect(`${location.protocol}//${location.hostname}:3006/`);

export default {
    computed: {
        ...mapGetters(['getModalPause','getPauses','getId','getExtension','getPauseTime'])
    },
    methods: {
        ...mapActions(['setModalPause','selectPause','sendPauseApi','setExtension','setPauseTime']),
        btnClose() {
            this.setModalPause();
        },
        getTime() {
            var d = new Date();
            var h = d.getHours();
            var m = d.getMinutes();
            var s = d.getSeconds();
            var time = d.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
            return time;

        },
        clickPause(id, pause) {
            connection.emit('QueuePause', {
                reason: pause,
                extension: user.getExtension()
            });

            this.setModalPause();
        }
    }
}
</script>
