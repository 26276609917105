<template>
  <div class="card my-3 px-3 py-3">
    <h5 style="color: #000; align-self: center">{{ title }}</h5>
    <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  props: ['title', 'values', 'categories'],
  data: function() {
    return {
      chartOptions: {
        chart: {
          id: "barchart",
            toolbar: {
              show: false,
            },
            type: 'bar',
            stacked: true,
            stackType: '100%'
        },
        colors: ['#f26143', '#ffa07a', '#f3e89e', '#90ee90', '#27ae60'],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: 50,
            columnsWidth: 50,
          },
        },
        theme: {
          palette: "palette7",
        },
        fill: {
        colors: ['#f26143', '#ffa07a', '#f3e89e', '#90ee90', '#27ae60']
        },
        dataLabels: {
          style: {
          colors: ['#8b0000', '#8b0000', '#b8860b', '#006400', '#006400']
          }
        },
        legend:{
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: this.categories,
          labels: {
            show: false
          }
        },
        tooltip: {
          theme: 'dark',
        },
        yaxis: {
          show: true,
          labels: {
            fontWeight: '600'
          }
        },
      },

      series: this.values
    };
  }
};
</script>