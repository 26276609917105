<template>
  <b-container fluid>
    <b-row class="mt-2 mb-2">
      <b-col>
        <b-button variant="primary" @click="showModal({id: null, type: 'Filtro', name: null})">Novo Filtro</b-button>
        <b-button variant="primary" @click="showModal({id: null, type: 'Tipo', name: null})">Novo Tipo</b-button>
      </b-col>
      <b-col md="4">
        <b-form-group
            label="Pesquisar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="md"
            class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Apagar</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
        primary-key="id_"
        :busy="isBusy"
        striped
        bordered
        :hover="true"
        fixed
        :items="items"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        show-empty
        stacked="md"
        @filtered="onFiltered"
        :current-page="currentPage"
        :per-page="perPage"
        class="tableFilter"
        sort-icon-left
        id="tableFilter"
        ref="tableFilter"
    >
      <template #cell(acoes)="{ item }">
        <span>
          <b-btn @click.prevent="edit(item)" size="sm" variant="primary" :disabled="item.id === 1">
            <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
          </b-btn>
          <b-btn @click.prevent="delet(item)" size="sm" variant="danger" :disabled="item.id === 1">
            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
          </b-btn>
        </span>
      </template>
      <template #empty="scope">
        <h6 style="text-align: center">Nenhum Resultado Encontrado</h6>
      </template>
      <template #emptyfiltered="scope">
        <h6 style="text-align: center">Nenhum Resultado Encontrado</h6>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
    </b-table>

    <b-row align-h="end">
      <b-col md="7" id="rows">
        Mostrando de 1 até {{ totalRows > 5 ? perPage : totalRows }} de {{ totalRows }} registros
      </b-col>
      <b-col md="5">
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <CreateModal ref="modalCreate" @handleCreate="create" @handleUpdate="update"/>
    <AlertToast
        id_="save-error-toast"
        variant="danger"
        icon="x-circle"
        color="#910a0a"
        title="Erro"
        mensagem="Ocorreu um erro ao tentar salvar!"
        ref="save-error-toast"/>
    <AlertToast
        id_="save-success-toast"
        variant="success"
        icon="check-square"
        color="#05940c"
        title="Sucesso"
        mensagem="Cadastrado com sucesso!"
        ref="save-success-toast"/>
    <AlertToast
        id_="update-success-toast"
        variant="success"
        icon="check-square"
        color="#05940c"
        title="Sucesso"
        mensagem="Item atualizado com sucesso!"
        ref="update-success-toast"/>
    <AlertToast
        id_="success-toast"
        variant="success"
        icon="check-square"
        color="#05940c"
        title="Sucesso"
        mensagem="Excluído com sucesso"
        ref="success-toast"/>
    <AlertToast
        id_="error-toast"
        variant="danger"
        icon="x-circle"
        color="#910a0a"
        title="Erro"
        mensagem="Ocorreu um erro ao tentar excluir!"
        ref="error-toast"/>
  </b-container>
</template>

<script>
import CreateModal from "./CreateModal";
import AlertToast from "./AlertToast";
export default {
  name: "ContatoList",

  components: {CreateModal,AlertToast},

  data:() => ({
    fields: [
      {key: 'id', label: 'ID', sortable: true},
      {key: 'name', label: 'Descrição', sortable: true},
      {key: 'type', label: 'Tipo', sortable: true},
      {key: 'acoes', label: 'Ações', class: 'text-center acao', sortable: false},
    ],
    obj: [],
    filter: null,
    filterOn: [],
    totalRows: 0,
    currentPage: 1,
    perPage: 5,
    isBusy: false,
    pageOptions: [5, 10, 20, { value: 100, text: "Mostrar mais" }],
  }),

  computed: {
    items() {
      return this.obj;
    },
  },

  created() {
    this.showList();
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    showModal(obj) {
      this.$refs.modalCreate.showModal(obj);
    },

    toggleIsBusy() {
      this.isBusy = !this.isBusy;
    },

    showList() {
      this.toggleIsBusy();
      axios.get('/settings/contato/listFilterTipo').then(({data}) => {
        this.obj = data;
        this.totalRows = data.length;
        this.toggleIsBusy();
      }).catch(error => {
        console.error(error)
      });
    },

    create(obj) {
      axios.post('/settings/contato/saveFilterTipo', obj).then((data) => {
        if (data.status == '200') {
          this.$refs["save-success-toast"].showAlertToast();
          this.$refs.modalCreate.hideModal();
          this.showList();
          return;
        }
        this.$refs["save-error-toast"].showAlertToast();
      }).catch((error)=> console.error(error.message));
    },

    edit(obj) {
      this.$refs.modalCreate.showModal(obj);
    },

    update(obj) {
      axios.post('/settings/contato/updateFilterTipo', obj).then((data) => {
        if (data.status == '200') {
          this.$refs["update-success-toast"].showAlertToast();
          this.$refs.modalCreate.hideModal();
          this.showList();
          return;
        }
        this.$refs["save-error-toast"].showAlertToast();
      }).catch((error)=> console.error(error.message));
    },

    delet(data) {
      Swal.fire({
        title: 'Deseja realmente excluir este item?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.value) {
          axios.delete('/settings/contato/deleteFilterTipo', {data}).then(response => {
            this.$refs["success-toast"].showAlertToast();
            this.showList();
          }, (response) => {
            this.$refs["error-toast"].showAlertToast();
            console.error(response);
          });
        }
      })
    }
  }
}
</script>

<style scoped>
  #rows{
    color: black;
  }
</style>

<style>
.tableFilter>thead>tr{
  background: #1C869A;
  color: white;
}
</style>