<template>
    <b-container fluid id="history-container">
        <b-row id="filters">
            <b-col md="6" id="filter-select-container">
                <label for="" class="filter-select-label">Buscar histórico por: </label>
                <div class="row justify-content-center">
                    <div class="filter-select-group col-md-3">
                        <input type="radio" data-type="horizontal" class="form-control"  name="filter-select" id="date-filter" value="date" v-model="selectedFilter">
                        <label for="date-filter">Data</label>
                    </div>
                    <div class="filter-select-group col-md-3">
                        <input type="radio" data-type="horizontal" class="form-control"  name="filter-select" id="operator-filter" value="operator" v-model="selectedFilter">
                        <label for="operator-filter">Operador</label>
                    </div>
                    <div class="filter-select-group col-md-3">
                        <input type="radio" data-type="horizontal" class="form-control"  name="filter-select" id="queue-filter" value="queue" v-model="selectedFilter">
                        <label for="queue-filter">Departamento</label>
                    </div>
                    <div class="filter-select-group col-md-3">
                        <input type="radio" data-type="horizontal" class="form-control"  name="filter-select" id="protocol-filter" value="protocol" v-model="selectedFilter">
                        <label for="protocol-filter">Protocolo</label>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="filter-select-group col-md-3">
                        <input type="radio" data-type="horizontal" class="form-control"  name="filter-select" id="client-filter" value="client" v-model="selectedFilter">
                        <label for="client-filter">Cliente</label>
                    </div>
                    <div class="filter-select-group col-md-3">
                        <input type="radio" data-type="horizontal" class="form-control"  name="filter-select" id="phone-filter" value="phone" v-model="selectedFilter">
                        <label for="phone-filter">Telefone</label>
                    </div>
                    <div class="filter-select-group col-md-3">
                        <input type="radio" data-type="horizontal" class="form-control"  name="filter-select" id="key-filter" value="unique_key" v-model="selectedFilter">
                        <label for="key-filter">CPF / CNPJ / ID</label>
                    </div>
                </div>
            </b-col>
            <b-col md="6" id="filter-container">
                <div v-if="selectedFilter == 'date'" class="filter-group">
                    <div>
                        <label for="" class="filter-select-label">Buscar por data</label>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="col">
                            <label for="">De</label>
                            <input type="date" class="form-control" v-model="filters.startDate">
                        </div>
                        <div class="col">
                            <label for="">Até</label>
                            <input type="date" class="form-control" v-model="filters.endDate">
                        </div>
                        <div class="col d-flex align-items-end">
                            <button type="button" class="btn btn-primary mb-0" @click="search">Buscar</button>
                        </div>
                    </div>
                </div>
                <div v-if="selectedFilter == 'client'" class="filter-group">
                    <div>
                        <label for="" class="filter-select-label">Buscar por cliente</label>
                    </div>
                    <div class="custom-input-group">
                        <input type="text" class="form-control" placeholder="🔎 Digite o nome do cliente..." v-model="filters.client">
                        <button type="button" class="btn btn-primary" @click="search">Buscar</button>
                    </div>
                </div>
                <div v-if="selectedFilter == 'operator'" class="filter-group">
                    <div>
                        <label for="" class="filter-select-label">Buscar por operador</label>
                    </div>
                    <div class="custom-input-group">
                        <select class="form-control" name="operator-select" id="operator-select" v-model="filters.operator">
                            <option value="" selected disabled hidden>🔽 Selecione o operador...</option>
                            <option v-for="key in operatorsList" :key="key" v-bind:value="key">{{key}}</option>
                        </select>
                        <button type="button" class="btn btn-primary" @click="search">Buscar</button>
                    </div>
                </div>
                <div v-if="selectedFilter == 'queue'" class="filter-group">
                    <div>
                        <label for="" class="filter-select-label">Buscar por departamento</label>
                    </div>
                    <div class="custom-input-group">
                        <select class="form-control" name="queues-chat" id="queue-select" v-model="filters.queue">
                            <option value="" selected disabled hidden>🔽 Selecione o departamento...</option>
                            <option v-for="key in userChatQueues" :key="key" v-bind:value="key">{{key}}</option>
                            <option value="entrante">Atendimentos Ativos</option>
                            <option value="generica">Atendimentos na URA</option>
                        </select>
                        <button type="button" class="btn btn-primary" @click="search">Buscar</button>
                    </div>
                </div>
                <div v-if="selectedFilter == 'protocol'" class="filter-group">
                    <div>
                        <label for="" class="filter-select-label">Buscar por protocolo</label>
                    </div>
                    <div class="custom-input-group">
                        <input type="text" class="form-control" placeholder="🔎 Digite o protocolo do atendimento..." v-model="filters.protocol">
                        <button type="button" class="btn btn-primary" @click="search">Buscar</button>
                    </div>
                </div>
                <div v-if="selectedFilter == 'phone'" class="filter-group">
                    <div>
                        <label for="" class="filter-select-label">Buscar por telefone</label>
                    </div>
                    <div class="custom-input-group">
                        <input type="tel" class="form-control" placeholder="🔎 Digite o telefone do cliente..." v-mask="['(##) ####-####', '(##) #####-####']" v-model="filters.phone">
                        <button type="button" class="btn btn-primary" @click="search">Buscar</button>
                    </div>
                </div>
                <div v-if="selectedFilter == 'unique_key'" class="filter-group">
                    <div>
                        <label for="" class="filter-select-label">Buscar por CPF / CNPJ / ID</label>
                    </div>
                    <div class="custom-input-group">
                        <input type="text" class="form-control" placeholder="🔎 Digite o CPF / CNPJ / ID..." v-model="filters.unique_key">
                        <button type="button" class="btn btn-primary" @click="search">Buscar</button>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row class="justify-content-center mt-4 mb-2">
            <h2 class="text-center">Histórico de Conversas</h2>
        </b-row>
        <b-row class="justify-content-end">
            <b-col md="4">
                <b-form-group class="mb-1">
                    <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Digite para filtrar os resultados..."
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">        
                <b-table 
                    striped
                    hover
                    bordered
                    sort-icon-left
                    show-empty
                    :items="attData" 
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :current-page="currentPage"
                    :per-page="perPage"
        	        thead-class="font-weight-bold"
                    :sort-compare="sortDate"

                >

                    <template v-if="loading" #empty>
                        <div class="text-center">
                            <b-spinner variant="dark" small></b-spinner> Processando, por favor, aguarde...
                        </div>
                    </template>
                    <template v-else #empty>
                        <h6 style="text-align: center">Nenhum resultado encontrado.</h6>
                    </template>

                    <!-- Formatar campo telefone -->
                    <template #cell(phone)="data">
                        {{ formTelefone(data.item.phone) }}
                    </template>

                    <!-- Formatar CNPJ -->
                    <template #cell(unique_key)="data">
                        {{ formata_cpf_cnpj(data.item.unique_key) }}
                    </template>

                    <template v-slot:cell(view-button)="{ item }">
                        <span>
                            <b-btn id="view-button" @click.prevent="getMsgLog(item.uniqueid)">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                            </b-btn>
                            <!-- <b-btn id="view-button" @click.prevent="getMsgInfo(item.uniqueid)">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </b-btn> -->
                        </span>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row class="justify-content-between">
            <b-col md="4">
                <b-form-group
                  label="Registros por página"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0 align-self-center"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                  ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="7" md="6" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  size="md"
                  class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <logModal ref="logModal"/>
        <infoModal ref="infoModal"/>
    </b-container>
</template>

<script>
import moment from 'moment';
import { formata_cpf_cnpj } from '../../validaCPFeCNPJ'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import { BSpinner } from 'bootstrap-vue'
Vue.component('b-spinner', BSpinner)

import { mapMutations} from 'vuex'

export default {
    data(){
        return {
            moment: moment,
            formata_cpf_cnpj: formata_cpf_cnpj,
            loading: false,
            fields: [
                {
                    key: 'created_at',
                    label: 'Data',
                    sortable: true,
                    sortByFormatted: true,
                    class: 'text-center',
                    thStyle: {backgroundColor: '#1c869a', color: '#ffffff'}
                },
                {
                    key: 'name',
                    label: 'Cliente',
                    sortable: true,
                    class: 'text-center',
                    thStyle: {backgroundColor: '#1c869a', color: '#ffffff'}
                },
                {
                    key: 'phone',
                    label:'Telefone',
                    sortable: true,
                    class: 'text-center',
                    thStyle: {backgroundColor: '#1c869a', color: '#ffffff'}            
                },
                {
                    key: 'unique_key',
                    label:'CPF / CNPJ / ID',
                    sortable: true,
                    class: 'text-center',
                    thStyle: {backgroundColor: '#1c869a', color: '#ffffff'}            
                },
                {
                    key: 'operator',
                    label: 'Operador',
                    sortable: true,
                    class: 'text-center',
                    thStyle: {backgroundColor: '#1c869a', color: '#ffffff'}            
                },
                {
                    key: 'queue',
                    label: 'Departamento',
                    sortable: true,
                    class: 'text-center',
                    thStyle: {backgroundColor: '#1c869a', color: '#ffffff'}            
                },
                {
                    key: 'view-button',
                    label: 'Visualizar',
                    sortable: true,
                    class: 'text-center',
                    thStyle: {backgroundColor: '#1c869a', color: '#ffffff', width: '100px'}            
                },
            ],
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver mais" }],
            sortBy: 'created_at',
            sortDirection: 'desc',
            filter: null,
            filterOn: [],
            selectedFilter: 'date',
            attData: [],
            filters: {
                startDate: '',
                endDate: '',
                client: '',
                operator: '',
                queue: '',
                phone: '',
                unique_key: '',
                protocol: '',
            },
            userChatQueues: [],
            operatorsList: [],
            meuAvatar: '/imgs/icons/person.svg'
        }
    },
    methods: {
    ...mapMutations(['setLogChat','setLogInfo']),

        sortDate(a, b, key) {
            if (key !== 'created_at') {
                return null;
            }
            let aDate = moment(a[key], 'DD/MM/YYYY')
            let bDate = moment(b[key], 'DD/MM/YYYY')
            if (aDate.isValid && bDate.isValid) {
              if (aDate < bDate) {
                return -1
              }
              else if (aDate > bDate) {
                return 1
              }
              else {
                return 0
              }
            }
            return null
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        search() {
            switch(this.selectedFilter){
                case 'date': {
                    this.loading = true;
                    this.attData = [];
                    axios.get('/history/attendance-history/', {params: {start: this.filters.startDate, end: this.filters.endDate}}).then((data) => {
                        if(data.data.length > 0){
                            this.attData = data.data;
                        }
                        this.loading = false;
                    })
                    break;
                }
                case 'client': {
                    this.loading = true;
                    this.attData = [];
                    axios.get('/history/attendance-history/', {params: {client: this.filters.client}}).then((data) => {
                        if(data.data.length > 0){
                            this.attData = data.data;
                        }
                        this.loading = false;
                    })
                    break;
                }
                case 'operator': {
                    this.loading = true;
                    this.attData = [];
                    axios.get('/history/attendance-history/', {params: {operator: this.filters.operator}}).then((data) => {
                        if(data.data.length > 0){
                            this.attData = data.data;
                        }
                        this.loading = false;
                    })
                    break;
                }
                case 'queue': {
                    this.loading = true;
                    this.attData = [];
                    axios.get('/history/attendance-history/', {params: {queue: this.filters.queue}}).then((data) => {
                        if(data.data.length > 0){
                            this.attData = data.data;
                        }
                        this.loading = false;
                    })
                    break;
                }
                case 'unique_key': {
                    this.loading = true;
                    this.attData = [];
                    axios.get('/history/attendance-history/', {params: {unique_key: this.filters.unique_key}}).then((data) => {
                        if(data.data.length > 0){
                            this.attData = data.data;
                        }
                        this.loading = false;
                    })
                    break;
                }
                case 'phone': {
                    this.loading = true;
                    this.attData = [];
                    axios.get('/history/attendance-history/', {params: {phone: this.filters.phone}}).then((data) => {
                        if(data.data.length > 0){
                            this.attData = data.data;
                        }
                        this.loading = false;
                    })
                    break;
                }
                case 'protocol': {
                    this.loading = true;
                    this.attData = [];
                    axios.get('/history/attendance-history/', {params: {protocol: this.filters.protocol}}).then((data) => {
                        if(data.data.length > 0){
                            this.attData = data.data;
                        }
                        this.loading = false;
                    })
                    break;
                }
            }
        },

        resetFilters(){
            this.filters = {
                startDate: '',
                endDate: '',
                client: '',
                operator: '',
                queue: '',
                phone: '',
                unique_key: ''
            }
        },

        getChatQueues(){
            axios.get('/user-chat-queues').then((data) => {
                this.userChatQueues = data.data;
            })
        },

        getOperators(){
            axios.get('/operators-by-queue').then((data) => {
                this.operatorsList = data.data;
            })
        },

        async getMsgInfo(uniqueid){     
            this.msgInfo = []
            await axios.get('/get-msg-log', {params: {uniqueid: uniqueid}}).then((data) => {
                this.msgInfo = data.data;
            }); 
            const msg = {
                created_at: this.msgInfo[0].created_at,
                updated_at: this.msgInfo[0].updated_at,
                protocol: this.msgInfo[0].protocol,
                msgs: this.msgInfo[0].msgs
            }
            this.setLogInfo(msg);

            this.$refs.infoModal.openModal();
        },

        async getMsgLog(uniqueid){
            await axios.get('/get-msg-log', {params: {uniqueid: uniqueid}}).then((data) => {
                const objReturn = [];
                const dataCheck = [];
                data.data[0].msgs.forEach(function(msg){

                    let dt = msg.data.trim().split(" ");
                    let sepData = {
                    'data': dt[0],
                    'hora': dt[1]
                    }

                    if(!moment(sepData.data, 'DD/MM/YYYY', true).isValid()){
                        sepData.data = moment(sepData.data).format('DD/MM/YYYY');
                    }
                    
                    let exibirData = '';
                    if(!dataCheck.includes(sepData.data)){
                    dataCheck.push(sepData.data);
                    exibirData = sepData.data;
                    }

                    const obj = {
                        type: msg.type,
                        exibirData: exibirData,
                        data: sepData.data,
                        hora: sepData.hora,
                        mensagem: msg.mensagem,
                        avatar: msg.isProprio ? this.meuAvatar : data.data[0].avatar,
                    }
                    objReturn.push(obj);
                });

                const msg = {
                    meuAvatar: data.data[0].avatar,
                    msgs: objReturn
                }
                this.setLogChat(msg);
            })

            this.$refs.logModal.openModal();
        },

        formTelefone(tel) {
			if( (tel.length == 10) || (tel.length == 11) ) {
				const v1 = tel.replace(/\D/g,"");
				const v2 = v1.replace(/^(\d{2})(\d)/g,"($1) $2");
				const v3 = v2.replace(/(\d)(\d{4})$/,"$1-$2");
				
				return v3;
			}
			tel=tel.replace(/\D/g,"");
			tel=tel.replace(/^(\d)/,"+$1");
			tel=tel.replace(/(.{3})(\d)/,"$1($2");
			tel=tel.replace(/(.{6})(\d)/,"$1)$2");
			if(tel.length == 12) {
				tel=tel.replace(/(.{1})$/,"-$1");
			}
			else if (tel.length == 13) {
				tel=tel.replace(/(.{2})$/,"-$1");
			}
			else if (tel.length == 14) {
				tel=tel.replace(/(.{3})$/,"-$1");
			}
			else if (tel.length == 15) {
				tel=tel.replace(/(.{4})$/,"-$1");
			}
			else if (tel.length > 15) {
				tel=tel.replace(/(.{4})$/,"-$1");
			}
			tel=tel.replace("("," (");
			tel=tel.replace(")",") ");

			return tel;
		}
    },
    mounted() {
        this.totalRows = this.attData.length;
        this.getChatQueues();
        this.getOperators();
    },

    watch: {
        selectedFilter(){
            this.resetFilters();
        },
        attData(){
            this.totalRows = this.attData.length;
        }
    },
}
</script>

<style scoped>
    .custom-input-group {
        display: flex;
        height: 5em;
        justify-content: center;
        align-items: center;
    }

    .filter-select-group {
        display: flex;
        flex-direction: row;
        padding: 10px;
    }

    .filter-select-group input {
        height: 1.5em;
        width: 2em;
        margin: 0px;
        padding: 10px;
    }

    .filter-select-group input:focus {
        box-shadow: none;
    }

    .filter-select-group label {
        margin: 0px;
        font-size: 12px;
        align-self: center;
    }

    .filter-group {
        display: flex;
        flex-direction: column;
        margin: 0px;
    }

    .filter-group input {
        width: 70%;
        margin: 5px;
    }

    .filter-group input[type='date'] {
        width: 100%;
        margin: 0;
    }

    .filter-group button {
        margin: 5px;
        height: 2.5em;
        border-radius: 5px !important;
    }

    .filter-select-label {
        font-weight: bold;
        font-size: 18px;
        align-self: center
    }

    #filter-select-container {
        border-right: 2px solid lightgray;
    }

    #history-container {
        margin: 10px;
    }

    #view-button {
        background: none;
        border: none;
        padding: 0;
        box-shadow: none;
        outline: none;
    }

    #view-button i {
        color: #1c869a;
        font-size: 22px;
    }

    #view-button i:hover {
        color: #31bdda;
        transition: 0.5s;
    }

</style>