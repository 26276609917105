<template>
  <b-container fluid>
    <b-row class="mt-4" align-h="end">
      <b-col md="5">
        <b-form-group
          label="Pesquisar"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Apagar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      striped
      hover
      bordered
      sort-icon-left
      show-empty
      :hover="true"
      :items="items"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      stacked="md"
      @filtered="onFiltered"
      :current-page="currentPage"
      :per-page="perPage"
      class="tableHistory"
      id="tableHistory"
      ref="tableHistory"
      :sort-compare="sortDate"
    >

      <template #empty="totalRows">
        <h6 style="text-align: center">Nenhum resultado encontrado.</h6>
      </template>

      <template #cell(date)="{ item }">
        {{ formatDate(item.date) }}
      </template>

      <template #cell(phone)="data">
        {{ formTelefone(data.item.phone) }}
      </template>

      <template #cell(type)="data">
        {{ `${data.item.type}` }}
      </template>

      <template #cell(unique_key)="data">
        {{ formata_cpf_cnpj(data.item.unique_key) }}
      </template>

      <template v-slot:cell(view-button)="{ item }">
        <span>
          <b-btn id="view-button" @click.prevent="getMsgLog(item.uniqueid)">
            <i class="fa fa-eye" aria-hidden="true"></i>
          </b-btn>
          <!-- <b-btn id="view-button" @click.prevent="getMsgInfo(item.uniqueid)">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </b-btn> -->
        </span>
      </template>
    </b-table>

    <b-row align-h="end">
      <b-col md="7" id="rows">
        Mostrando de 1 até {{ totalRows > 5 ? perPage : totalRows }} de
        {{ totalRows }} registros
      </b-col>
      <b-col md="5">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <logModal ref="logModal" />
    <infoModal ref="infoModal" />
  </b-container>
</template>

<script>
import { formata_cpf_cnpj } from "../../../validaCPFeCNPJ";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "tableHistory",
  data: () => ({
    formata_cpf_cnpj: formata_cpf_cnpj,
    fields: [
      {
        key: "date",
        label: "Data",
        sortable: true,
        class: "text-center",
        thStyle: { backgroundColor: "#1c869a", color: "#ffffff" },
      },
      {
        key: "name",
        label: "Cliente",
        sortable: true,
        class: "text-center",
        thStyle: { backgroundColor: "#1c869a", color: "#ffffff" },
      },
      {
        key: "phone",
        label: "Telefone",
        sortable: true,
        class: "text-center",
        thStyle: { backgroundColor: "#1c869a", color: "#ffffff" },
      },
      {
        key: "unique_key",
        label: "CPF/CNPJ",
        sortable: true,
        class: "text-center",
        thStyle: { backgroundColor: "#1c869a", color: "#ffffff" },
      },
      {
        key: "operator",
        label: "Operador",
        sortable: true,
        class: "text-center",
        thStyle: { backgroundColor: "#1c869a", color: "#ffffff" },
      },
      {
        key: "queue",
        label: "Fila",
        sortable: true,
        class: "text-center",
        thStyle: {
          backgroundColor: "#1c869a",
          color: "#ffffff",
          width: "100px",
        },
      },
      {
        key: 'protocol',
        label: 'Protocolo',
        sortable: true,
        class: 'text-center',
        thStyle: {backgroundColor: '#1c869a', color: '#ffffff'}            
      },
      {
        key: "view-button",
        label: "Visualizar",
        sortable: true,
        class: "text-center",
        thStyle: {
          backgroundColor: "#1c869a",
          color: "#ffffff",
          width: "100px",
        },
      },
    ],
    obj: [],
    filter: null,
    filterOn: [],
    totalRows: 0,
    currentPage: 1,
    perPage: 5,
    sortBy: "created_at",
    sortDirection: "desc",
    msgLog: [],
  }),
  computed: {
    ...mapGetters(["getHistory", "getPhone"]),
    items() {
      return this.obj != "" ? this.obj : [];
    },
  },
  methods: {
    ...mapActions(["fetchHistoryClient"]),
    ...mapMutations(["setLogChat", "setLogInfo"]),
    sortDate(a, b, key) {
      if (key !== "created_at") {
        return null;
      }
      let aDate = moment(a[key], "DD/MM/YYYY");
      let bDate = moment(b[key], "DD/MM/YYYY");
      if (aDate.isValid && bDate.isValid) {
        if (aDate < bDate) {
          return -1;
        } else if (aDate > bDate) {
          return 1;
        } else {
          return 0;
        }
      }
      return null;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMsgInfo(uniqueid) {
      this.msgInfo = [];
      await axios
        .get("/get-msg-log", { params: { uniqueid: uniqueid } })
        .then((data) => {
          this.msgInfo = data.data;
        });
      const msg = {
        created_at: this.msgInfo[0].created_at,
        updated_at: this.msgInfo[0].updated_at,
        ticket: this.msgInfo[0].ticket,
        msgs: this.msgInfo[0].msgs,
      };

      this.setLogInfo(msg);
      this.$refs.infoModal.openModal();
    },
    async getMsgLog(uniqueid) {
      await axios
        .get("/get-msg-log", { params: { uniqueid: uniqueid } })
        .then((data) => {
          const objReturn = [];
          const dataCheck = [];
          data.data[0].msgs.forEach(function (msg) {
            let dt = msg.data.trim().split(" ");
            let sepData = {
              data: dt[0],
              hora: dt[1],
            };

            if (!moment(sepData.data, "DD/MM/YYYY", true).isValid()) {
              sepData.data = moment(sepData.data).format("DD/MM/YYYY");
            }

            let exibirData = "";
            if (!dataCheck.includes(sepData.data)) {
              dataCheck.push(sepData.data);
              exibirData = sepData.data;
            }

            const obj = {
              type: msg.type,
              exibirData: exibirData,
              data: sepData.data,
              hora: sepData.hora,
              mensagem: msg.mensagem,
              avatar: msg.isProprio ? this.meuAvatar : data.data[0].avatar,
            };

            objReturn.push(obj);
          });

          const msg = {
            meuAvatar: data.data[0].avatar,
            msgs: objReturn,
          };
          this.setLogChat(msg);
        });

      this.$refs.logModal.openModal();
    },
    formTelefone(tel) {
      if (tel.substr(0, 2) != "55") {
        return "+" + tel;
      }
      if (tel.length == 10 || tel.length == 11) {
        const v1 = tel.replace(/\D/g, "");
        const v2 = v1.replace(/^(\d{2})(\d)/g, "($1) $2");
        const v3 = v2.replace(/(\d)(\d{4})$/, "$1-$2");

        return v3;
      }
      tel = tel.replace(/\D/g, "");
      tel = tel.replace(/^(\d)/, "+$1");
      tel = tel.replace(/(.{3})(\d)/, "$1($2");
      tel = tel.replace(/(.{6})(\d)/, "$1)$2");
      if (tel.length == 12) {
        tel = tel.replace(/(.{1})$/, "-$1");
      } else if (tel.length == 13) {
        tel = tel.replace(/(.{2})$/, "-$1");
      } else if (tel.length == 14) {
        tel = tel.replace(/(.{3})$/, "-$1");
      } else if (tel.length == 15) {
        tel = tel.replace(/(.{4})$/, "-$1");
      } else if (tel.length > 15) {
        tel = tel.replace(/(.{4})$/, "-$1");
      }
      tel = tel.replace("(", " (");
      tel = tel.replace(")", ") ");

      return tel;
    },
    formatDate(data) {
      return moment(data).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  watch: {
    getHistory(val) {
      this.obj = val;
      this.totalRows = val.length;
    },
    getPhone() {
      this.fetchHistoryClient();
    },
  },
};
</script>

<style scoped>
#rows {
  color: black;
}

#view-button {
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
  outline: none;
}

#view-button i {
  color: #1c869a;
  font-size: 22px;
}

#view-button i:hover {
  color: #31bdda;
  transition: 0.5s;
}
</style>

<style>
.tableHistory > thead > tr {
  background: #1c869a;
  color: white;
}
</style>