<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog">
    <div :class="`modal-dialog ${modalType}`" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"> 
              <!-- modal title -->
              <slot name="title"></slot>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fa fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
            <!-- modal content -->
          <slot></slot>
        </div>
        <div class="modal-footer">
            <!-- modal footer -->
            <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['id', 'modalType']
};
</script>