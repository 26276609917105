<template>
    <b-card border-variant="dark">
      <div class="height-queue">
        <span class="font-weight-bold font-15">Detalhes da Fila</span>
        <b-table id="queue" small striped bordered fixed :per-page="perPage"
                 :fields="fields" :items="items" responsive="sm" class="mt-2"
                 :current-page="currentPage" show-empty>
          <template #empty="scope">
            <h6 class="text-center">Nenhum cliente na fila</h6>
          </template>

          <template #cell(queue)="data">
            <span :class="data.item.queue == 'Atendimento Ativo' ? 'text-danger font-weight-bold' : ''">{{ data.item.queue }}</span>
          </template>
        </b-table>
        <b-pagination v-model="currentPage" pills :total-rows="rows" :per-page="perPage" aria-controls="queue" size="sm" align="right"></b-pagination>
        </div>
    </b-card>
  </template>
  
  <script>
  const socket = require('./../../../modules/realtime/service/service');
  
  export default {
    name: "TbFilaDetail",
  
    data:() => ({
      fields: [
        {key: 'queue', label: 'Fila', class: 'text-center align-middle', thStyle: {'font-weight': 'bold', padding: '3px'}},
        {key: 'atendimento', label: 'Atendimento', class: 'text-center align-middle', thStyle: {'font-weight': 'bold', padding: '3px'}},
        {key: 'aguardando', label: 'Aguardando', class: 'text-center align-middle', thStyle: {'font-weight': 'bold', padding: '3px'}},
      ],
      currentPage: 1,
      perPage: 10,
      queues: [],
      userChatQueues: [],
    }),
  
    created() {
      axios.get('/user-chat-queues').then(({data}) => {
        this.userChatQueues = data;
        this.userChatQueues.push('entrante');
      });
  
      socket.connection.on('queueDetail', (obj) => {
        this.queues = obj.queues;
      });
    },
  
    computed: {
      items() {
        return this.mountTable();
      },
      rows() {
        return this.items.length;
      }
    },
  
    methods: {
      mountTable() {
        const queue = [];
        this.queues.map(q => {
            if (this.userChatQueues.includes(q.queue) && q.queue != 'Ativos' ) {
              queue.push({
                queue: (q.queue == 'entrante') ? 'Atendimento Ativo' : q.queue,
                atendimento: q.atendimento,
                aguardando: q.aguardando
              })
            }
        });

        queue.sort(function (x, y) {
            let a = x.queue.toUpperCase(),
                b = y.queue.toUpperCase();
            return a == b ? 0 : a > b ? 1 : -1;
        });

        return queue;
      }
    },
  
    watch: {
      queues() {
        this.mountTable();
      }
    }
  }
  </script>
  
  <style scoped>
    .card {
      color: black !important;
      border: 1px solid rgba(0, 0, 0, 0.125) !important;
      border-radius: 0.25rem !important;
    }
    .font-15 {
      font-size: 15px;
    }
    .height-queue {
      /* height: 205px; */
    }
  </style>