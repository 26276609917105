<template>
    <b-container fluid>
        <b-row class="mt-4" align-h="end">
            <b-col md="5">
                <b-form-group label="Pesquisar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="md" >
                    
                    <b-input-group size="md">
                        <b-form-input id="filter-input" v-model="filter" type="search" ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Apagar</b-button>
                        </b-input-group-append>
                    </b-input-group>

                </b-form-group>
            </b-col>
        </b-row>

        <b-table
        primary-key="client_id"
        striped
        bordered
        :hover="true"
        fixed
        :items="items"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        show-empty
        stacked="md"
        @filtered="onFiltered"
        :current-page="currentPage"
        :per-page="perPage"
        class="tableHistory"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        id="tableHistory"
        ref="tableHistory">

            <template #cell(date)="{item}">
                {{ formatDate(item.date) }}
            </template>

            <template #cell(acoes)="{item}">
                <b-button-group>
                    <b-button size="sm" @click="openMessages(item.msgs)" variant="primary">
                        <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template>
                <h6 style="text-align: center">Nenhum Resultado Encontrado</h6>
            </template>

            <template>
                <h6 style="text-align: center">Nenhum Resultado Encontrado</h6>
            </template>

        </b-table>

        <b-row align-h="end">
            <b-col md="7" id="rows">
                Mostrando de 1 até {{ totalRows > 5 ? perPage : totalRows }} de {{ totalRows }} registros
            </b-col>
            <b-col md="5">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>

export default {
    name: "tableHistory",
    data:() => ({
        fields: [
            {key: 'date', label: 'Data de Abertura', sortable: true},
            {key: 'operador', label: 'Operador', sortable: true},
            {key: 'departamento', label: 'Departamento', sortable: true},
            {key: 'acoes', label: 'Visualizar', class: 'text-center acao', sortable: false},
        ],
        obj: [],
        filter: null,
        filterOn: [],
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        sortBy: 'date',
        sortDesc: true,
    }),
    computed: {
        items() {
            return this.obj;
        },
    },
    methods:{

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        openMessages(msgs) {
            const msg = {
                meuAvatar: '/imgs/icons/person.svg',
                msgs
            }
        },

        formatDate(data) {
            return window.moment(data).format('DD/MM/YYYY HH:mm:ss');
        },
    },
    watch:{
    },
}
</script>

<style scoped>
    #rows{
        color: black;
    }
    .tableHistory>thead>tr{
        background: #1C869A;
        color: white;
    }
</style>

