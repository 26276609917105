<template>
  <div class="row">
    <div class="col-12">
      <label :for="`input${name}`">{{title}}</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i :class="icon + ' fa-fw'"></i>
          </span>
        </div>
        <select
          class="custom-select"
          :id="`${id}_input`"
          :name="name + '[]'"
          multiple
          style="height: 184px"
          ref="select"
          :required="required"
          :readonly="readonly"
          :disabled="disabled"
        >
        <option :key="x.id" v-for="x in listData" v-bind:value="x.id">{{x.name}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
	  "title": String,
	  "name": String,
	  "id": String,
	  "data-type": String,
	  "icon": String,
	  "required": {
		type: Boolean,
		default: true
	},
  "readonly": {
    type: Boolean,
    default: true
  },
  "disabled": {
    type: Boolean,
    default: true
  }
  },
  data: function(){
    return {
      'listData': []
    }
  },
  mounted() {
    this.popData()
  },
  methods: {
    popData: function(){
      var self = this;
      switch(this.dataType) {
        case 'usersList':
          axios.get('release/userList')
          .then(function(response){
            self.listData = response.data;
          })
          .catch(function(error){
            console.error(error);
          })
		break;

        case 'listQueue':
			axios.get('user/listQueue')
          	.then(function(response) {
        	self.listData = response.data;
		  })
		  .catch(function(error) {
			  console.error(error);
          })
		break;

		case 'listQueueChat':
			axios.get('user/listQueueChat')
			.then(function(response) {
            self.listData = response.data;
          })
          .catch(function(error) {
            console.error(error);
          })
		break;
      }
    },
  }
};
</script>
