<template>
  <div>
      
        <div class="col-md-12">
            <h3>Relatório Analítico campanha</h3>
            <table id="relLog" ref='tabela' class="table display table-hover table-striped"  style="width: 100%">
                <thead></thead>
                <tbody></tbody>
                <tfoot></tfoot>
            </table>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        filter: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            dt: null,
            val: this.filter
        }
    },
    watch:{
        filter: {
            handler: function(val){
                this.createTable()
            },
            deep: true
        }
    },
    methods:{
        createTable(){

            if (this.dt){
                $('#relLog').DataTable().destroy();
            }

            this.dt = $('#relLog').DataTable({
                ajax:{
                    url: '/marketing/log',
                    data: { param: this.filter },
                    dataSrc: ''
                },
                columns: [{
                    data: 'campanha',
                    class: 'tamanho',
                    title: 'Campanha'
                },
                {
                    data: 'telefone',
                    class: 'tamanho',
                    title: 'Telefone'
                },
                {
                    data: 'name',

                    class: 'tamanho',
                    title: 'Cliente'
                },
                {
                    data: 'status',

                    class: 'tamanho',
                    title: 'status'
                }]
            })


            this.dt.buttons('.dtUpdateButton').remove();
        }
    },
    mounted(){
        this.createTable();

    }

}
</script>

<style scoped>
.tamanho{
    width: 300px;
}
</style>