<template>
  <div id="menu" class="overflowAuto" hidden>
    <div class="container">
      <div class="row no-gutters">
        <div class="col-6 col-sm-6 col-lg-4 col-md-4 col-xl-3 menu-item menu-dialer-top">
          <a href="/dialer">
            <div
              class="menu-background menu-dialer"
              data-popover="true"
              data-placement="bottom"
              data-trigger="hover"
              title="Tela do Operador"
              data-content="Acesse a central operacional do sistema, efetue discagens automaticamente conforme programado por Campanhas, Agendamentos ou atue na área Receptiva."
            >
              <i class="fas fa-phone-square icon"></i>
              <br />Tela do Operador
            </div>
          </a>
        </div>

        <!--<div class="col-6 col-sm-6 col-lg-4 col-md-4 col-xl-3 menu-item menu-dashboard-top">
          <a href="/dashboard">
            <div
              class="menu-background menu-dashboard"
              data-popover="true"
              data-placement="bottom"
              data-trigger="hover"
              title="Dashboard"
              data-content="Acompanhe em tempo real suas principais informações"
            >
              <i class="fas fa-chart-pie icon"></i>
              <br />Dashboard
            </div>
          </a>
        </div>-->

        <div class="col-6 col-sm-6 col-lg-4 col-md-4 col-xl-3 menu-item menu-realtime-top">
          <a href="/realtime">
            <div
              class="menu-background menu-realtime"
              data-popover="true"
              data-placement="bottom"
              data-trigger="hover"
              title="Realtime"
              data-content="Monitore e gerencie em tempo real sua equipe de telemarketing."
            >
              <i class="fas fa-clock icon"></i>
              <br />Realtime
            </div>
          </a>
        </div>
        
        <div class="col-6 col-sm-6 col-lg-4 col-md-4 col-xl-3 menu-item menu-customer_base-top">
          <a href="/customer_base">
            <div
              class="menu-background menu-customer_base"
              data-popover="true"
              data-placement="bottom"
              data-trigger="hover"
              title="Carteira de Clientes"
              data-content="Confira sua carteira de clientes filtrando por operador e cliente."
            >
              <i class="fas fa-list-ol icon"></i>
              <br />Contatos
            </div>
          </a>
        </div>

        <div class="col-6 col-sm-6 col-lg-4 col-md-4 col-xl-3 menu-item menu-records-top">
          <a href="/records">
            <div
              class="menu-background menu-records"
              data-popover="true"
              data-placement="bottom"
              data-trigger="hover"
              title="Gravações"
              data-content="Ouça todas as chamadas de entrada e saída, contando com filtros para uma busca específica."
            >
              <i class="fas fa-voicemail icon"></i>
              <br />Gravações
            </div>
          </a>
        </div>

        <div class="col-6 col-sm-6 col-lg-4 col-md-4 col-xl-3 menu-item menu-reports-top">
          <a href="/reports">
            <div
              class="menu-background menu-reports"
              data-popover="true"
              data-placement="bottom"
              data-trigger="hover"
              title="Relatórios"
              data-content="Otimize suas decisões com o auxílio de relatórios de faturamento, operadores e chamadas."
            >
              <i class="fas fa-signal icon"></i>
              <br />Relatórios
            </div>
          </a>
        </div>

        <div class="col-6 col-sm-6 col-lg-4 col-md-4 col-xl-3 menu-item menu-callback-top">
          <a href="/callback">
            <div
              class="menu-background menu-callback"
              data-popover="true"
              data-placement="bottom"
              data-trigger="hover"
              title="Callback"
              data-content="Confira ligações abandonadas, não atendidas ou deixadas na caixa postal e agende um retorno."
            >
              <i class="fas fa-reply icon"></i>
              <br />Callback
            </div>
          </a>
        </div>
        
        <div class="col-6 col-sm-6 col-lg-4 col-md-4 col-xl-3 menu-item menu-user-top">
          <a href="/user">
            <div
              class="menu-background menu-user"
              data-popover="true"
              data-placement="bottom"
              data-trigger="hover"
              title="Usuário"
              data-content="Crie e gerencie os usuários do sistema."
            >
              <i class="fas fa-users icon"></i>
              <br />Usuário
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.permissions();
    var url = window.location.href;
    url = url.split('/');
    if((url[3] == '') || (url[4] == '')){
      $("#menu").attr("hidden", false);
      $("#menuButton").attr("hidden", true);
    }

  },
  methods:{
           permissions: function () {
                axios.get('/permission')
                    .then(response => {
                      $(response.data.allowed).each(function(index, item){
                          $(`.menu-${item}`).css('background', `linear-gradient(rgba(4, 85, 142, 0.3), rgba(4, 85, 142, 0.5)), url('/imgs/menu/${item}-bkg.png')`);
                        })
              $(response.data.denied).each(function(index, item){
                //document.querySelector(`.menu-${item}-top`).style.display = 'none';
                //$(`.menu-${item}`).css('display', 'none');
                  $(`.menu-${item}`).css('background', `linear-gradient(rgba(13, 14, 15, 0.9), rgba(13, 14, 15, 0.9)), url('/imgs/menu/${item}-bkg.png')`);
                  $(`.menu-${item} .icon`).removeClass().addClass('fas fa-lock icon');
              })

                    }).catch(error => {
                    console.error(error)
                })
            }
  }
};
</script>
