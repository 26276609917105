<template>
    <b-modal id="bv-modal-visualizar-ura" size="lg" title="Visualizar" scrollable centered hide-footer class="p-1">
      <div class="modal-log">
  
        <section class="custom-chat" style="background: #efe7dd url('imgs/bg_wtt.jpg') repeat;" v-if="getUserRealtimeData">
            <div class="mensagens" ref="msgContainer">
              <item-mensagen
                v-for="(msgItem, index) in getUserRealtimeData"
                :key="'item-mensagen-' + index"
                :modal="'modal-' + index"
                :avatar="msgItem.avatar"
                :is-proprio="msgItem.isProprio"
                :exibirData="msgItem.exibirData"
                :hora="msgItem.hora"
                :data="msgItem.data"
                :mensagem="msgItem.mensagem"
              ></item-mensagen>
            </div>
          </section>
  
      </div>
    </b-modal>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import ItemMensagen from "../../../components/newScreen/mensagem/ItemMensagen";
  
  export default {
    data:() => ({
        dataModal: [],
        scrollHeight: 5000000000000
    }),
    computed: {
      ...mapGetters(["getUserRealtimeData"])
    },
    components: {
        ItemMensagen
    },
    methods:{
      scrollMsg() {
        if(this.$refs.msgContainer){
          if(this.$refs.msgContainer.scrollHeight != this.scrollHeight){
              this.scrollHeight = this.$refs.msgContainer.scrollHeight;

              this.$refs.msgContainer.scrollIntoView(false);
          }
        }
      },
      openModal() {                
          this.$bvModal.show('bv-modal-visualizar-ura');

          setInterval(this.scrollMsg, 5000);
      }
    }
  }
  </script>
  
  <style scoped>
      .modal-log{
          overflow-y: auto;
          overflow-x: hidden;
      }
  
      .modal-log .espcForm{
          margin: 5px 0px 0px 0px;
      }
  
      .modal-log label, input, textarea{
          font-size: 12px;
      }
      .made-up{
          font-family: arial;
          margin: 10px;
          border-radius: 10px;
          background: #fff;
          border: solid 1px #9797A6;
          border-radius: 2px;
          padding: 10px;
      }
  
      .made-up fieldset{
          margin: 5px 30px;
      }
  
      .made-up legend{
          background: #1C869A; 
          color: #FEFEFE;
          margin: 0px;
          padding: 5px 20px;
          width: auto;
          font-size: 14px;
          border-radius: 2px;
      }
  
      .avoid-page-break {
        page-break-inside: avoid;
      }

      body.modal-open {
        overflow: hidden;
      }
  </style>