<template>
    <div id="tabs">
        <ul>
            <li>
                <a href="#tabs-upload">Upload</a>
            </li>
            <li>
                <a href="#tabs-arquivos">Clientes</a>
            </li>
        </ul>

        <div class="row">
            <div class="col-md-12" id="tabs-upload">
                <costumers-upload  v-on:updateList="inc" />
            </div>

            <div class="col-md-12" id="tabs-arquivos">
                <dt-costumers :upList="upList" />
            </div>
        </div>
    </div>
</template>

<script>
import costumersUpload from './CostumersUpload.vue';
import dtCostumers from './DtCostumers.vue';

export default {
    components: {
        costumersUpload,
        dtCostumers
    },
    data() {
        return {
            upList: 0
        }
    },
    methods: {
        inc() {
            this.upList++;
        }
    }
}
</script>