<template>
  <div class="main">
      <ul class="messanger-list">
      <div class='background-img'></div>
        <div v-for="index in getArr()" :key="index">
            <li v-if="image[index -1] == null" class="common-message is-other" style="overflow-wrap: break-word;"> {{ text[index -1] }} </li>
            <li v-else class="common-message is-other"> 
                <img :src="image[index -1]" alt="" width="150" > 
                <div style="white-space: pre;">
                    {{ text[index -1] }}       
                </div>
            </li>
        </div>

      </ul>
  </div>
</template>

<script>
export default {
    props:{
        text:{
            type: Array,
            default: []
        },
        image:{
            type: Array,
            default: [null]
        }
    },
    methods:{
        getArr(){
            return (this.text.length > this.image.length) ? this.text.length : this.image.length;
        }
    }

}
</script>

<style scoped>

.background-img{

    background: url('/images/background-wpp.png');
    opacity:0.06;
    position: absolute;
    top: 0;
    width: 95%;
    height: 16em;
    left:0;
}
.main{

    overflow-y: auto;
    flex: 1;
    height: 38vh;
    width:100%;
    background-color: #e6ded5;
    
}


ul {
    list-style: none;
}

.common-message {
    position: relative;
    background-color: var(--white);
    width: -moz-fit-content;
    width: fit-content;
    max-width: 315px;
    overflow-wrap: break-word;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 1px 0.5px rgb(0 0 0 / 13%);
    margin-bottom: 10px;
}

.messanger-list {
    display: flex;
    flex-direction: column;
    padding: 20px 7%;
}
 .common-message.is-other + .is-other::before {
	 display: none;
}

.common-message::before {
    position: absolute;
    left: -10px;
    top: 0;
    content: "";
    display: block;
    border: solid;
    border-width: 0px 16px 16px 0px;
    border-color: transparent;
    border-right-color: var(--white);
}

</style>