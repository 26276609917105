<template>
    <div style="margin: 2px">
        <b-form-textarea
        class="inputs"
        id="input-msg"
        mid="`input-message`"
        size="lg"
        placeholder="Digite a mensagem aqui."
        rows="2"
        v-model="mensagem"
        @keyup="keyup"
        ></b-form-textarea>

        <b-form-file
        class="c-file inputs"
        v-model="file"
        accept=".jpg, .jfif, .jpeg, .png, .gif, .pdf"
        :state="Boolean(file)"
        placeholder="Escolha a Imagem ou PDF..."
        drop-placeholder="Arraste para aqui..."
        browse-text="Procurar"
        ></b-form-file>
        
        <div class="mt-1">{{ file ? 'Arquivo selecionado: ' + file.name : '' }}</div>
    </div>
</template>

<script>
export default {
    props: {
        idText:{
            type: String,
            default: '1'
        }
    },
    data(){
        return{
            file: null,
            mensagem: '',
            srcImage: null,
            auster: process.env.MIX_Auster
        }
    },
    methods:{
        keyup(e){
            this.$emit('updateText',this.idText ,e.target.value);
        },
    },
    watch:{
        file () {
            if(!!this.file && this.file.type == 'application/pdf' || this.file.type == 'image/jpeg' || this.file.type == 'image/png' || this.file.type == 'image/gif') {
                let reader = new FileReader()
                reader.readAsDataURL(this.file)
                reader.onloadend = () => {
                    this.srcImage = reader.result
                    this.$emit('addImage',this.idText, this.srcImage, this.file);
                }
            } else {
                this.file = null
                this.srcImage = null
                Swal.fire({
                    type: 'error',
                    text: 'O arquivo selecionado não é válido. Escolha uma Imagem (jpeg, jpg, png e gif) ou PDF.'
                })
            }
        }
    } 
}
</script>

<style>
    .inputs {
        width: 20em;
    }

</style>