<template>
    <div id="chart" class="wrapper-chart">
        <h3 v-if="type == 'waitingTime'">Tempo Médio de Espera por Fila (Telefonia)</h3>
        <h3 v-else>Tempo Médio de Atendimento por Fila (Telefonia)</h3>
        <apexchart type="bar" height="450" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import momentDuration from "moment-duration-format";
momentDuration(moment);

export default {
    props: ["type", "filter"],
    data: function() {
        return {
            startDateAvg: moment().format('YYYY-MM-DD 00:00:00'),
			endDateAvg: moment().format('YYYY-MM-DD 23:59:59'),
            arrayColors: [
                ,
                
            ],
            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                        position: 'bottom'
                        },
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: (value) => this.formatDuration(value),
                    },
                },
                tooltip: {
                    y: {
                        formatter: (value) => this.formatDuration(value),
                        title: {
                            formatter: () => 'Tempo Médio: '
                        }
                    },
                },
                noData: {
                    text: 'Sem dados para exibir no momento...'
                },
				colors: this.type == "waitingTime" ? ['#008FFB', '#FEB019', '#00E396', '#FF4560', '#ffd60a', '#775DD0', '#7f4f24', '#00f5d4', '#4d194d', '#333533'] : ['#00E396', '#FF4560', '#008FFB', '#FEB019', '#775DD0', '#ffd60a', '#4d194d', '#7f4f24', '#00f5d4', '#333533']
            },
        }
    },
    methods: {
        formatDuration(date) {
			return date >= 86400 ?
			 moment.duration(date, "seconds").format('hh:mm:ss').replace(',', '.') :
			 moment.utc(date * 1000).format('HH:mm:ss')
		},
        getCallsAverage(event) {
            event && event.preventDefault();
            const url = this.type == "waitingTime" ? "averageWaitingTime" : "averageServiceTime";
            axios.get(`${window.location.href}/${url}?queue=${this.filter}`)
            .then(({ data }) => {
                this.chartOptions.xaxis.categories.length = 0;
                this.series[0].data.length = 0;
                data.forEach(element => {
                    this.chartOptions.xaxis.categories.push(element.queuename.toUpperCase());
                    this.series[0].data.push(element.average)
                });
            })
        },
    },
    beforeMount() {
        this.getCallsAverage();
        setInterval(() => {
            this.getCallsAverage();
        }, 30000);
    },
    watch: {
		filter() {
			this.getCallsAverage();
		}
	}
}
</script>

<style>
#btnSubmitAvg {
	margin-top: 33px;
}

.wrapper-chart {
    width: 100%;
    margin-top: 50px;
}
</style>
