<template>
  <section v-if="getMessages">
    <!--todas redes sociais  barra superior hidden-->
    <div class="barra-superior direita">
      <div class="total-chat">
        <span><i class="fas fa-comments"></i> Total de Atendimentos</span
        ><span id="chat-count">{{ getTotalChatQueue }}</span>
      </div>

      <div class="align-self-center">

        <div class="createNewContact">
          <span
            v-if="getCurrentConversaIndex || getCurrentConversaIndex === 0"
            @click="checando"
            class="botao-novo-contato"
            title="Salvar Contato"
          >
            <i class="fas fa-user-plus"></i> Salvar Contato
          </span>
          <template v-else>&nbsp;</template>
        </div>

        <div class="createNewContact">
					<span v-if="getCurrentConversaIndex || getCurrentConversaIndex === 0" @click.prevent="showMensagensProntas" class="botao-novo-contato" title="Mensagens Prontas">
						<i class="fas fa-message"></i> Mensagens Prontas
					</span>
					<template v-else>&nbsp;</template>
				</div>

      </div>
    </div>
    <div class="body">
      <TodasMenssagensChat class="todasMensagens"></TodasMenssagensChat>
      <chat-mensagem
        class="chatMensagem"
        @addContact="saveContact($event)"
      ></chat-mensagem>
    </div>

    <div v-show="addNewContact" id='bg-contatos'>
      <ContatosRapido
        v-show="addNewContact"
        v-on:closeContacts="fecharContatos"
        :addWppContact="newContact"
      ></ContatosRapido>
    </div>

    <MensagenPronta ref="showModalMsg" />
    <!--fim barra superior-->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ChatMensagem from "../mensagem/ChatMensagem";
import TodasMenssagensChat from "../mensagem/TodasMenssagensChat";
import ContatosRapido from "../contacts/ContatosRapido.vue";
import MensagenPronta from "../mensagem/MensagenPronta.vue";

export default {
  components: {
    ChatMensagem,
    TodasMenssagensChat,
    ContatosRapido,
    MensagenPronta
  },
  data() {
    return {
      contatosRapido: "",
      addNewContact: false,
      newContact: {},
    };
  },
  computed: {
    ...mapGetters([
      "getMessages",
      "getTotalChatQueue",
      "getTotalNaoLidoWhatsapp",
      "getTotalNaoLidoFacebook",
      "getTotalNaoLidoInstagram",
      "getCurrentConversaIndex",
      "getConversasChat",
    ]),
  },
  methods: {
    checando() {
      if (localStorage.getItem("clienteAtual")) {
        const clienteAtual = JSON.parse(localStorage.getItem("clienteAtual"));
        if (Object.keys(clienteAtual).length) {
          if (
            (clienteAtual.phone || clienteAtual.name) &&
            (clienteAtual.type == "wpp" || clienteAtual.type == "chat")
          ) {
            const phoneArr = clienteAtual.phone.split("@");
            const cod = phoneArr[0].slice(0, 2);
            const phoneEdited =
              cod == "55" ? phoneArr[0].slice(2) : phoneArr[0];
            this.newContact = {
              name: clienteAtual.name,
              phone: phoneEdited,
            };
          }
        }
      }

      this.addNewContact = true;
    },
    saveContact(data) {
      this.newContact = data;
      this.addNewContact = true;
    },
    fecharContatos() {
      this.newContact = {};
      this.addNewContact = false;
    },
    showMensagensProntas() {
      this.$refs.showModalMsg.show = true;
    },
  },
};
</script>

<style>
#bg-contatos {
  background-color: #181717cf;
  width: 94.7vw;
  height: 93vh;
  top: 0.1em;
  position: absolute;
  z-index: 3;
}

.alert-message-out {
  border-bottom: solid 1px #1c869a;
  border-left: solid 1px #1c869a;
  padding: 0 0 3px 3px;
}
.alert-message {
  border: solid 1px #1c869a;
  padding: 0 5px 0 5px;
  font-size: 13px;
}
.alert-message-in {
  border-bottom: solid 1px gray;
  border-left: solid 1px gray;
  padding: 0 0 3px 3px;
}
.alert-message-fine {
  border: solid 1px gray;
  padding: 0 5px 0 5px;
  font-size: 13px;
}
.icon-size {
  width: 18px;
  height: 18px;
}
</style>

<style scoped>
.body {
  /* height: calc(100vh - 10.5em);  */
  display: flex;
  align-items: stretch;
}
.todasMensagens {
  width: 18em;
  overflow-y: auto;
  border-radius: 0% 2% 2% 0%;
  max-height: 80vh;
  height: 80vh;
  box-shadow: 20px 19px 31px -22px rgba(177, 182, 199, 1);
}
.chatMensagem {
  flex: 1;
  max-height: 81vh;
  min-height: 81vh;
}

.total-chat {
  background-color: lightgray;
  width: 20em;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border-radius: 0px 20px 20px 0px;
}

#chat-count {
  background-color: #1c869a;
  color: white;
  border-radius: 10px;
  margin: 10px;
  padding: 2px 5px;
}

@media screen and (max-width: 1489px) {
  .todasMensagens {
    width: 290px;
  }
  .body {
    height: calc(100vh - 14.3em);
  }
}
@media screen and (max-width: 941px) {
  .body {
    height: calc(100vh - 19.1em);
  }
}
.barra-superior,
.contatos-rapido {
  display: flex;
  justify-content: space-between;
}

.createNewContact {
  margin-top: 0.25em;
  margin-right: 0.4em;
  float: right;
  margin-bottom: 0.25em;
}

.botao-novo-contato {
  background: white;
  border: solid 2px #1c869a;
  color: #1c869a;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  cursor: pointer;
  padding: 0px 5px;
  transition: 0.25s;
}

.botao-novo-contato:hover {
  color: white;
  background-color: #1c869a;
}
</style>
