<template>
	<div>
		<div v-if="isReconnect" class="reconnect-feedback"> Reconectando... </div>
		<section>
			<div id="spearhead"> 
				<div v-if="protocol || personContact.name || personContact.phone || queue" class="d-flex">
					<div class="spearhead-element">
						<span><i class="fas fa-clipboard"></i><b>Protocolo</b></span>
						<p v-if="protocol">{{protocol}}</p>
						<p v-else>----------</p>
					</div>
					<div class="spearhead-element">
						<span><i class="fas fa-user"></i><b>Cliente</b></span>
						<p v-if="personContact.name">{{personContact.name}}</p>
						<p v-else>----------</p>
					</div>
					<div class="spearhead-element">
						<span><i class="fas fa-phone"></i><b>Telefone</b></span>
						<p v-if="personContact.phone">{{personContact.phone}}</p>
						<p v-else>----------</p>
					</div>
					<div class="spearhead-element">
						<span><i class="fas fa-headset"></i><b>Fila de Atendimento</b></span>
						<p v-if="queue">{{queue}}</p>
						<p v-else>----------</p>
					</div>						
					<div class="spearhead-element">
						<span><i class="fas fa-key"></i><b>CPF/CNPJ</b></span>
						<p v-if="chave">{{chave}}</p>
						<p v-else>----------</p>
					</div>						
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      interval: "",
      time: "",
      timeFormat: "",
      protocol: "",
      personContact: {
        name: "",
        phone: "",
      },
      queue: "",
      chave: "",
    };
  },
  computed: {
    ...mapGetters(["isReconnect", "getDataTransduson"]),
  },
  methods: {
    async setClientData(client) {
      if (client.phone && client.type == "phone") {
        let clientName = "";
        this.protocol = client.protocol;
        this.queue = client.queue;
        if (client.cpf) this.chave = client.cpf;
        clientName = await axios(`/share-client-cnpj?share=${client.phone}`)
        .then(({ data }) => {
          if (data.length > 0) {
            this.chave = client.cpf ? client.cpf : data[0]?.unique_key;
            return data[0].name
          } else {
            return client.phone;
          }
        });

        this.personContact = {
          name: clientName,
          phone: client.phone,
        };
      }
    },
  },
  watch: {
    getDataTransduson(val) {
      this.setClientData(val);
      localStorage.setItem("clienteAtual", JSON.stringify(val));
      this.hasData = !!Object.keys(val).length;

      if (Object.keys(val).length === 0) {
        this.personContact = {
          name: "",
          phone: "",
        };
        this.protocol = "";
        this.queue = "";
      }
    },
  },
  mounted() {
    if (!localStorage.getItem("clienteAtual")) {
      this.hasData = false;
      return;
    }

    const clienteAtual = JSON.parse(localStorage.getItem("clienteAtual"));
    if (Object.keys(clienteAtual).length) {
      this.hasData = true;
      this.setClientData(clienteAtual);
    }
  },
};
</script>

<style scoped>
#spearhead {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  border-radius: 0px 0px 14px 14px;
  font-size: 11px;
  background-color: white;
  margin: 2px;
  box-shadow: -1px 5px 7px 0px #9f9999;
  transition: 0.7s;
  transition-timing-function: ease;
  height: max-content;
}
.spearhead-element {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  padding: 0px 10px;
}
.spearhead-element span {
  font-size: 12px;
  text-align: center;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reconnect-feedback {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: gray;
  color: white;
}

.parte-cima {
  box-shadow: 5px 3px 6px -6px black;
  padding: 1px 5px 2px 20px;
  background-color: #ecf0f1;
  max-height: 10em;
  overflow-x: auto;
}
.row {
  margin-right: 0 !important;
}
::-webkit-scrollbar {
  width: 0.18rem;
}
::-webkit-scrollbar-track {
  background: #f38630;
}
::-webkit-scrollbar-thumb {
  background: #1c869a;
}
.espaco-lado-lado {
  max-width: 270px;
  width: 100%;
  margin: 0.1em 0 0.1em 0;
}
.img {
  height: 1.7em;
}
b {
  font-size: 1.1em;
  margin-left: 0.5em;
}
p {
  margin: 0 5px;
}
span {
  font-size: 20px;
}
</style>
