<template>
  <div>
      
        <div class="col">
            <h3>Campanha de Covid</h3>
            <table id="relAuster" ref='tabela' class="size-tabela table display table-hover table-striped">
                <thead></thead>
                <tbody></tbody>
                <tfoot></tfoot>
            </table>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        filter: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            dt: null,
            val: this.filter
        }
    },
    watch:{
        filter: {
            handler: function(val){
                this.createTable()
            },
            deep: true
        }
    },
    methods:{
        createTable(){

            if (this.dt){
                $('#relAuster').DataTable().destroy();
            }

            console.log('filter', this.filter);

            this.dt = $('#relAuster').DataTable({
                ajax:{
                    url: '/marketing/covidAuster',
                    dataSrc: ''
                },
                columns: [{
                    data: 'cliente',
                    title: 'Cliente',
                    class: 'text-center',
                },
                {
                    data: 'covid',
                    title: 'Teve Covid?',
                    class: 'text-center',
                    render: data => (data == 1) ? 'Sim' : 'Não'
                },
                {
                    data: 'sintomasFortes',
                    class: 'text-center',
                    title: 'Teve sintomas fortes?',
                    render: data => (data == 1) ? 'Sim' : 'Não'
                },
                {
                    data: 'primeiraVez',
                    class: 'text-center',
                    title: 'É a primeira vez que teve?',
                    render: data => (data == 1) ? 'Sim' : 'Não'
                },  
                {
                    data: "quandoTeve",
                    class: 'text-center',
                    title: 'Quando teve?',
                    render: (data) => {
                        const translate = {
                            '1': '1 semana atrás',
                            '2': '1 mês atrás',
                            '3': '3 mêses atrás',
                            '4': '3 a 6 mêses atrás',
                            '5': 'Mais de 1 anos atrás',
                        }
                        if(data == null) return '-';
                        return translate[data]
                    }
                }]
            })
        }
    },
    mounted(){
        this.createTable();

    }

}
</script>

<style scoped>
.size-tabela{
    width: 700px;
}

</style>