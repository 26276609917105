<template>
  <div>
    <b-card border-variant="dark">
      <div class="height-user">
        <h5>Operadores</h5>

        <div class="select-queue">
          <select
            class="form-control form-control-sm queue-select"
            v-model="changeQueue"
          >
            <option value="*" selected>Todas as Filas</option>
            <option
              v-for="(queue, index) in userChatQueues"
              :key="index"
              :value="queue"
            >
              {{ queue }}
            </option>
          </select>
        </div>

        <b-table
          class="customTable"
          small
          striped
          bordered
          fixed
          sticky-header="782px"
          :fields="fields"
          :items="items"
          responsive="sm"
        >
          <!-- A custom formatted column -->
          <template #cell(status)="data">
            <div>
              <b-badge :variant="getStatus(data.item.status).variant">{{
                getStatus(data.item.status).txt
              }}</b-badge>
            </div>
          </template>

          <!-- opcoes -->
          <template #cell(opcoes)="data">
            <i
              title="Visualizar conversa"
              id="action-button-view"
              class="image-button fa fa-eye"
              @click.prevent="abrirModalUsers(data.item.opcoes)"
            ></i>
          </template>
        </b-table>
        <h6 class="mb-2">Total de {{ items.length }} registros</h6>
      </div>
    </b-card>

    <dtModalUsers ref="dtModalUsers" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
const socket = require("./../../../modules/realtime/service/service");

export default {
  name: "TbUsers",
  data: () => ({
    fields: [
      {
        key: "user",
        label: "Operador",
        class: "text-center align-middle",
        thStyle: { "font-weight": "bold" },
      },
      {
        key: "login",
        label: "Login",
        class: "w-log text-center allign-middle",
        thStyle: { "font-weight": "bold" },
      },
      {
        key: "status",
        label: "Status",
        class: "middle w-stt text-center allign-middle",
        thStyle: { "font-weight": "bold" },
      },
      {
        key: "opcoes",
        label: "Ações",
        class: "middle w-stt text-center allign-middle",
        thStyle: { "font-weight": "bold" },
      },
    ],
    users: [],
    operatorAvailable: [],
    operatorInAttendance: [],
    userChatQueues: [],
    changeQueue: "*",
    queueSelect: "*",
    items: [],
  }),
  created() {
    axios.get("/realtime/listUsers", {}).then(({ data }) => {
      this.users = data;
    });

    axios.get("/user-chat-queues").then(({ data }) => {
      this.userChatQueues = data;
    });

    socket.connection.on("attendanceChat", (obj) => {
      this.operatorInAttendance = obj.atendimento.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.operator === thing.operator)
      );

      this.items = this.getOperators(this.queueSelect);
    });

    socket.connection.on("availableChat", (obj) => {
      this.operatorAvailable = obj;
      this.items = this.getOperators(this.queueSelect);
    });
  },
  methods: {
    ...mapMutations(["setLogChat", "setLogInfo"]),
    getStatus(stt) {
      if (stt === "c_deslogado") {
        return { txt: "Deslogado", variant: "danger" };
      }
      if (stt === "a_em_atendimento") {
        return { txt: "Em Atendimento", variant: "warning" };
      }
      if (stt === "b_ativo") {
        return { txt: "Disponível", variant: "primary" };
      }
    },
    abrirModalUsers(idUsers) {
      this.setLogInfo(idUsers);

      this.$refs.dtModalUsers.openModal();
    },
    getOperators(queueSelect) {
      let doubles = [];
      this.users.map((us) => {
        if (
          (queueSelect == "*") ||
          ((queueSelect != "*") &&
            us.queueChat.map((el) => el).includes(queueSelect))
        ) {
          const obj = {
            user: us.nome,
            fila: us.queueChat,
            rede_social: "WhatsApp",
            ramal: us.id.toString(),
            login: "00:00:00",
            status: us.queueChat.map(() => "c_deslogado"),
          };

          doubles.push(obj);
        }
      });

      return doubles.map((us) => {
        if (
          (Object.keys(this.operatorAvailable).length > 0) &&
          (this.operatorAvailable.disponivel[us.ramal]) &&
          (this.operatorInAttendance.length > 0) &&
          (this.operatorInAttendance
            .map((val) => us.fila.map((el) => el).includes(val.queue))
            .includes(true)) &&
          (this.operatorInAttendance.map((el) => el.operator).includes(us.ramal))
        ) {
          let op = [];
          this.operatorInAttendance.map((el) => {
            if (el.operator === us.ramal) {
              op = el;
            }
          });

          let notSortFila = [];
          let statusFila = us.fila.map((el) => {
            if (op.operator === us.ramal && el === op.queue) {
              notSortFila.push("a_em_atendimento");
              return "a_em_atendimento";
            } else {
              notSortFila.push("b_ativo");
              return "b_ativo";
            }
          });

          statusFila.sort();

          return {
            user: us.user,
            rede_social: "WhatsApp",
            login: moment(
              this.operatorAvailable.disponivel[us.ramal].login
            ).format("HH:mm:ss"),
            status: statusFila[0],
            opcoes: {
              ramal: us.ramal,
              fila: notSortFila,
            },
            queueChat: us.fila,
          };
        }

        if (
          (Object.keys(this.operatorAvailable).length > 0) &&
          this.operatorAvailable.disponivel[us.ramal]
        ) {
          return {
            user: us.user,
            rede_social: "WhatsApp",
            login: moment(
              this.operatorAvailable.disponivel[us.ramal].login
            ).format("HH:mm:ss"),
            status: "b_ativo",
            opcoes: {
              ramal: us.ramal,
              fila: us.status.map(() => "b_ativo"),
            },
            queueChat: us.fila,
          };
        } else {
          return {
            user: us.user,
            rede_social: "WhatsApp",
            login: "00:00:00",
            status: "c_deslogado",
            opcoes: {
              ramal: us.ramal,
              fila: us.status.map(() => "c_deslogado"),
            },
            queueChat: us.fila,
          };
        }
      });
    },
  },
  watch: {
    operatorAvailable() {
      let available = this.getOperators(this.queueSelect);

      this.setLogChat(available);
    },
    operatorInAttendance() {
      let inAttendance = this.getOperators(this.queueSelect);

      this.setLogChat(inAttendance);
    },
    changeQueue(queue) {
      this.items = this.getOperators(queue);
      this.queueSelect = queue;
    },
  },
};
</script>

<style>
.height-user {
  max-height: 890px;
}
.card {
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem !important;
}
td.fila,
td.middle {
  padding: 0;
}
.customTable > table > tbody > tr > td:nth-child(4) > div,
.customTable > table > tbody > tr > td:nth-child(5) > div {
  /*display: flex;*/
  padding: 0.25rem 0 0.25rem 0.3rem;
}
.customTable > table > tbody > tr > td:nth-child(4) > div:not(:last-child),
.customTable > table > tbody > tr > td:nth-child(5) > div:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}
.customTable > table > tbody > tr > td:nth-child(5) > div {
  height: 32px;
}
th.w-log {
  width: 90px;
}
th.w-social {
  width: 106px;
}
th.w-stt {
  width: 110px;
}

.select-queue {
  display: inline-block;
  width: 100%;
}
.select-queue select {
  width: 30%;
  float: right;
}
#action-button-view {
  cursor: pointer;
  font-size: 16px;
  color: #1c869a;
  transition: 0.25s;
  margin: 5px;
}

#action-button-view:hover {
  color: #177081;
}
</style>