<template>
	<section id="chat-item">
		<div id="chat-item-body">

			<div id="slot-avatar" class="avatar"><img :src="avatar_url" alt=""></div>

			<div id="slot-msgs">
				<div id="msg-name"><span class="text-truncate">{{ nome }}</span></div>
				<div id="msg-preview"><span class="text-truncate" v-html="prev_text"></span></div>
			</div>
			<div :class="[notify > 0 ? 'slot-notify' : 'no-notify']">
				<div id="notify-number"> <span>{{ notify }}</span></div>
			</div>
		</div>
		<div id="chat-item-footer">
			<span class="footer-time">{{ time }}</span>
			<span :class="tipo == 'ativo' ? 'footer-type ativo' : 'footer-type receptivo'">{{ fila }}</span>
		</div>
	</section>
</template>

<script>
export default {
	name: "ItemTodasMensagens",

	props: [
		'avatar_url',
		'nome',
		'prev_text',
		'time',
		'notify',
		'fila',
		'tipo'
	],
}
</script>

<style scoped>
.avatar img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
#slot-avatar {
 	flex: 1;
 	padding: 5px;
	text-align: center;
}
#chat-item{
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
}
#chat-item-body {
	display: flex;
	flex-direction: row;
}
#slot-msgs {
	padding-top: 8px;
	flex: 3;
	display: flex;
	flex-direction: column;
	width: 150px;
	overflow: hidden;
}
#msg-name {
	font-weight: bold;
 	padding-left: 8px;
}
#msg-preview {
	font-size: small;
	color: rgb(161, 160, 160);
	grid-area: previaText;
	padding-left: 8px;
}	
#chat-item-footer {
	display: flex;
	justify-content: space-between;
}	
#chat-item-footer .footer-type {
	margin-top: 10px;
	padding: 4px 8px 4px 8px;
	border-radius: 7px;
	color: white;
	font-weight: bold;
	font-size: 8px;
	text-transform: uppercase;
}	
#chat-item-footer .footer-time {
	margin: 10px 0px 0px 15px;
	color: rgb(66, 64, 64);
	font-weight: bold;
	font-size: 12px;
	text-transform: uppercase;
}
.slot-notify #notify-number {
	text-align: center;
 	align-self: center;
	background: #1aac1a;
	border-radius: 5px;
	padding: 0px 4px;
}
.slot-notify span {
	color: white;
	font-size: smaller;
	font-weight: bold;
}
.no-notify {
	display: none;
}
.item {
	display: grid;
	grid-template-areas:
		"avatar name time"
		"avatar previaText notificacao";
	grid-template-columns: 60px 1fr 40px;
	grid-template-rows: 30px 30px;
}
.name {
	grid-area: name;
	padding: 3px;
}
.name span {
	font-size: 13px;
	font-weight: bold;
}
.time {
	grid-area: time;
	text-align: center;
	padding: 5px;
}
.time span {
	font-size: 11px;
	color: #b3b1b1;
}
.previaText {
	grid-area: previaText;
	padding: 5px;
}
.notificacao {
	grid-area: notificacao;
	padding: 5px;
	display: grid;
	place-items: center;
}
.notificacao .count {
	display: none;
	border-radius: 2px;
	height: 18px;
	width: 18px;
	place-items: center;
}
.notificacao.temNotify .count {
	background: #1aac1a;
	display: grid;
}
.notificacao .count span {
	font-size: 11px;
	color: white;
}

.fila span{
	margin-top: 5px;
	padding: 4px;
	border-radius: 7px;
	background-color: #313131;
	color: white;
	font-weight: bold;
	font-size: 8px;
	text-transform: uppercase;
}

.chatItem {
	display: flex;
	flex-direction: row;
}

.ativo {
	background-color: red;
}

.receptivo {
	background-color: green;
}
</style>