<template>
  <div class="row">
    <div class="col-12">
      <label :for="`input${name}`">{{title}}</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" :id="`${name}`">
            <i :class="`${icon} fa-fw`"></i>
          </span>
        </div>
        <input
          :type="`${type}`"
          :id="`${id}_input`"
          :name="name"
          class="form-control"
          :aria-label="name"
          :aria-describedby="name"
          :placeholder="placeholder"
          :value="value"
          :required="required"
          :disabled="disabled"
          :readonly="readonly"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    "id": String,
    "name": String,
    "icon": String,
    "title": String,
    "type": {
		type: String,
		default: 'text'
	},
    "placeholder": String,
    "disabled": {
      type: Boolean,
      default: false
    },
    "required": {
      type: Boolean,
      default: false
    },
    "readonly": {
      type: Boolean,
      default: false
    },
    "value": {
      type: String,
      default: ''
    }
  }
};
</script>