<template>
  <div class="barra-lateral-esquerdo">
    <figure @click="deslogar" class="figure-image">
      <div v-if="getDeslog" class="backGroundOrangeRed">
        <img src="/imgs/icons/power-white.svg" class="img" />
        <figcaption>Deslogado</figcaption>
      </div>
      <div v-else class="backgroundGreen">
        <img src="/imgs/icons/power-white.svg" class="img" />
        <figcaption>Logado</figcaption>
      </div>
    </figure>

    <!-- <figure @click="messages" class="figure-image messagesChange">
      <div :class="{ 'bg-adv': getMessages }">
        <div class="remove-padding">
          <img
            src="/imgs/icons/chat-square-text-white.svg"
            class="img"
            alt=""
          />
          <div
            class="remove-padding"
            v-if="getTotalInQueue > 0 && !getMessages"
          >
            <span class="num">{{ getTotalInQueue }}</span>
          </div>
        </div>
        <figcaption>Atendimento Online</figcaption>
      </div>
    </figure> -->

    <figure @click="contacts()" class="figure-image">
      <div :class="{ 'bg-adv': getContacts }">
        <img src="/imgs/icons/address-book.svg" class="img" />
        <figcaption>Contatos</figcaption>
      </div>
    </figure>

    <!-- <figure @click="historicol()" class="figure-image">
      <div :class="{ 'bg-adv': getShowHistory }">
        <img src="/imgs/icons/arrow-counterclockwise-white.svg" class="img" />
        <figcaption>Histórico</figcaption>
      </div>
    </figure> -->

    <figure v-if="telefone" class="figure-image">
      <div
        v-if="getChannel() != ''"
        @click="hangup"
        class="backGroundOrangeRed"
      >
        <img src="/imgs/icons/telephone-forward-white.svg" class="img" alt="" />
        <figcaption>Desligar</figcaption>
      </div>
      <div v-else @click="discar">
        <img src="/imgs/icons/telephone-forward-white.svg" class="img" alt="" />
        <figcaption>Discar</figcaption>
      </div>
    </figure>

    <figure v-if="telefone" @click="pause" class="figure-image">
      <div v-if="getPause" class="backGroundOrange">
        <img src="/imgs/icons/pause-white.svg" class="img" />
        <figcaption>
          {{ time_pause_format }} <br />
          Pausado
        </figcaption>
      </div>
      <div v-else>
        <img src="/imgs/icons/pause-white.svg" class="img" />
        <figcaption>Pausar</figcaption>
      </div>
    </figure>

    <figure v-if="telefone" @click="mute" class="figure-image">
      <div v-if="getMute" class="backGroundOrange">
        <img src="/imgs/icons/mute-white.svg" class="img" alt="" />
        <figcaption>Mudo</figcaption>
      </div>
      <div v-else>
        <img src="/imgs/icons/mute-white.svg" class="img" alt="" />
        <figcaption>Mute</figcaption>
      </div>
    </figure>

    <figure @click="agendamento" class="figure-image" style="display: none">
      <div class="">
        <img src="/imgs/icons/calendar-date-white.svg" class="img" />
        <figcaption>Agendamento</figcaption>
      </div>
    </figure>

    <div hidden>{{ hue }}</div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
// import clientData from '../../store/modules/newConnectLine';
import clientData from "../../store/modules/transduson";
const parsedTelefone = process.env.MIX_TELEFONE === "true";
const connection = parsedTelefone
  ? io.connect(`${location.protocol}//${location.hostname}:3006/`)
  : { on: function () {}, emit: function () {} };

export default {
  data() {
    return {
      interval_pause: "",
      time_pause: "",
      time_pause_format: "00:00:00",
      debugDisc: false,
      total_in_queue: "",
      queuesEsquerdo: false,
      queuePause: false,
      telefone: parsedTelefone,
      listOptTab: [],
    };
  },
  computed: {
    ...mapGetters([
      "getDeslog",
      "getModalPause",
      "getMessages",
      "getOccurrences",
      "getShowHistory",
      "getCnpj",
      "getPause",
      "getMute",
      "getQueues",
      "getExtension",
      "getId",
      "getChannel",
      "getChamada",
      "allClients",
      "getShowSpearhead",
      "getProtocol",
      "getCallTime",
      "getPauseTime",
      "oneClient",
      "getShowBarRight",
      "getTotalMyQueue",
      "getTotalInQueue",
      "getContacts",
      "getCalendar",
    ]),
    hue: function () {
      this.setExtension(user.getId());
      this.setQueues(user.getQueue());
      this.setId(user.getId());
    },
  },
  watch: {
    getPauseTime: function () {
      this.validateTimePause();
    },
    time_pause: function () {
      this.time_pause_format = moment
        .utc(this.time_pause * 1000)
        .format("HH:mm:ss");
    },
    getDeslog(value) {
      if (!value) {
        if (!this.getMessages) {
          this.setMessage();
        }
      } else {
        if (this.getMessages) {
          this.setMessage();
        }
      }
    },
  },
  created() {
    if (!this.getDeslog) this.setMessage();
    if (this.telefone) return;

    this.setDeslog();
    this.startOrOut();
  },
  methods: {
    ...mapActions([
      "selectPause",
      "sendPauseApi",
      "setOccurrences",
      "setShowHistory",
      "setModalPause",
      "setModalSchedule",
      "settModalCall",
      "setMessage",
      "fetchOcurrences",
      "fetchPauses",
      "comeBack",
      "setDeslog",
      "startOrOut",
      "setMute",
      "setExtension",
      "setQueues",
      "setId",
      "getRealTime",
      "getHasTab",
      "sendUnpause",
      "setChannel",
      "fetchClients",
      "fetchProtocol",
      "setShowSpearhead",
      "chooseClient",
      "chooseClientCnpj",
      "clearAllClients",
      "setCallTime",
      "setPauseTime",
      "saveProduct",
      "clearClient",
      "saveClient",
      "setShowBarRight",
      "checkTotalQUeues",
      "totalMyQueue",
      "setContacts",
      "chooseClientPhone",
    ]),
    ...mapMutations(["setClient", "delDataConnectLine"]),
    validateTimePause() {
      this.time_pause = this.getPauseTime != "null" ? this.getPauseTime : 0;
      if (this.getPauseTime != "null") {
        this.interval_pause = setInterval(() => {
          this.time_pause++;
        }, 1000);
      } else {
        clearInterval(this.interval_pause);
      }
    },
    historicol(event) {
      if (!this.getDeslog && !this.getShowHistory) {
        this.setShowHistory();
        this.fetchOcurrences(this.getCnpj);
      }
    },
    messages() {
      if (!this.getDeslog && !this.getMessages) this.setMessage();
    },
    discar(event) {
      this.settModalCall();
    },
    getTime() {
      var d = new Date();
      return d.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
    },
    pause() {
      this.queuePause = false;
      if (this.getPause == true) {
        connection.emit("QueueUnPause", {
          extension: user.getExtension(),
        });
      } else {
        if (this.getDeslog == false) {
          this.setModalPause();
          this.fetchPauses();
        }
      }
    },
    agendamento() {
      this.setModalSchedule();
    },
    deslogar() {
      if (!this.telefone) return;
      var data = {
        queues: [user.getQueue()],
        extension: user.getExtension(),
      };

      if (this.getDeslog) {
        connection.emit("QueueAdd", data);
        if (!this.getMessages) this.setMessage();
      } else {
        if (this.getMessages) this.setMessage();
        if (this.getContacts) this.setContacts();
        if (this.getShowHistory) this.setShowHistory();

        connection.emit("QueueRemove", data);
      }
    },
    mute() {
      //HABILITANDO O MUTE APENAS SE ESTIVER EM LIGAÇÃO
      if (this.getChannel()) {
        this.setMute();

        //COLOCANDO NO LOCALSTORAGE PARA PEGAR INFORMAÇÃO DO MUTE QUANDO ATUALIZAR A PÁGINA
        this.getMute == true
          ? localStorage.setItem("mute", "true")
          : localStorage.removeItem("mute");

        //CONTROLANDO SE É PARA COLOCAR O MUTE OU PARA TIRAR E ENVIANDO EMIT NECESSÁRIO
        var stateMute = this.getMute == true ? "on" : "off";
        connection.emit("MuteAudio", {
          channel: this.getChannel(),
          extension: user.getExtension(),
          state: stateMute,
        });
      }
    },
    async hangup() {
      connection.emit("hangup", {
        channel: this.getChannel(),
      });
      //Limpa o canal
      await this.setChannel("");

      //Limpa o ponta de lanca
      await clientData.mutations.delDataTransduson();
    },
    submitLogin(sub) {
      axios.post("/dialer/logs", { type: sub }).then(
        (response) => {
          console.info(response);
        },
        function (response) {
          console.error(response);
        }
      );
    },
    contacts() {
      this.setContacts();
    },
    getTabOptions() {
      axios.get("/listTabOptions").then(({ data }) => {
        this.listOptTab = data;
      });
    },
    saveTab() {
      const obj = {
        exten: this.hangupObj.dados[0],
        client_num: this.hangupObj.dados[1],
        status_id: this.selectedOption,
        uniqueid: this.hangupObj.uniqueid,
        call_time: this.hangupObj.dados[2],
      };
      console.log("OBJETO:", obj);
      return axios.post("/saveTab", obj).then(({ data }) => {
        console.log("SALVANDO:", data);
        return !!data;
      });
    },
    deleteTab() {
      const obj = {
        extension_line: this.ramal,
      };
      return axios.delete("/deleteTab", obj).then(({ data }) => {
        console.log("EXCLUIDO:", data);
        return !!data;
      });
    },
    updateTab() {
      const obj = {
        uniqueid: this.hangupObj.uniqueid,
        status_id: this.selectedOption,
      };
      console.log("OBJETO:", obj);
      return axios.put("/updateTab", obj).then(({ data }) => {
        console.log("SALVANDO:", data);
        return !!data;
      });
    },
  },
  mounted: function () {
    this.checkTotalQUeues();
    this.totalMyQueue();
    this.getTabOptions();

    // CAPTURANDO INFORMALÇÕES (SE ESTÁ EM LIGAÇÃO E SE ESTÁ LOGADO NA FILA)
    connection.emit("CoreShowChannels", {
      ramal: user.getExtension(),
    });

    // EVENTO PARA CAPTURAR DADOS DE LIGAÇÃO EM CURSO DO OPERADOR LOGADO
    connection.on(`CoreShowChannel-${user.getExtension()}`, async (data) => {
      //HABILITANDO CONTADOR E PASSANDO PRO CONTADOR QUANTO TEMPO ESTA EM LIGAÇÃO
      this.setCallTime(moment.duration(data.Duration).asSeconds());

      //BUSCANDO CLIENTE E PROTOCOLO DE ATENDIMENTO
      var self = this;

      this.fetchClients(data.ConnectedLineNum).then(async function () {
        //Recupera os dados tratado
        let phoneSplit = data.ConnectedLineName.split("||");
        let dataPhone = phoneSplit[1];

        if (self.allClients && self.allClients.length > 0) {
          self.chooseClient(self.allClients[0]);
          self.chooseClientCnpj(self.allClients[0]["cpf_cnpj"]);
          self.setShowSpearhead();
          // self.clearAllClients();
        } else {
          //SETANDO OS VALORES VAZIOS SE NÃO TIVER CLIENTE
          self.chooseClient({
            at_user_id: "",
            birthday: "",
            cep: "",
            city: "",
            complement: "",
            cpf: "",
            created_at: "",
            email: "",
            id: "",
            name: "",
            neighborhood: "",
            number: "",
            phone: dataPhone,
            phone_2: "",
            reference: "",
            rg: "",
            street: "",
            updated_at: "",
          });
          self.setShowSpearhead();
        }
      });
      this.fetchProtocol();
      //SETANDO O CANAL DA LIGAÇÃO ATUAL
      this.setChannel(data.Channel);

      if (data.ConnectedLineName.includes("||ramal")) {
        await clientData.mutations.setDataTransduson(
          `${data.ConnectedLineNum}||redirecionamento`
        );
      } else if (data.ConnectedLineName.includes("<unknown>")) {
        await clientData.mutations.setDataTransduson(`${data.Exten}||externo`);
      } else {
        await clientData.mutations.setDataTransduson(data.ConnectedLineName);
      }

      //HABILITANDO O MUTE NOVAMENTE CASO SEJA ATUALIZADO A PÁGINA
      localStorage.getItem("mute") && this.getChannel() && this.getMute == false
        ? this.setMute()
        : false;
    });

    // EVENT NECESSÁRIO PARA HABILITAR OS BOTÕES (LOGADO E PAUSA) QUANDO APESSOA ENTRA NA PAGINA
    connection.emit("QueueStatus", {
      ramal: user.getExtension(),
    });

    // HABILITANDO OS BOTÕES NOVAMENTE CASO ESTEJA LOGADO... EM PAUSA...
    var validate = 0;
    connection.on(`QueueMember-${user.getExtension()}`, (data) => {
      if (validate == 0) {
        if (data.Paused == 1) {
          //INICIANDO CONTADOR DE PAUSA CASO ESTEJA EM PAUSA
          this.setPauseTime(data.LastPause);
          this.comeBack();
        }
        this.setDeslog();
        validate = 1;
        this.queuesEsquerdo = true;
      }
    });

    // VALIDANDO QUANDO USUÁRIO É INSERIDO NA FILA
    connection.on(`QueueMemberAdded-${user.getExtension()}`, (data) => {
      if (!this.queuesEsquerdo) {
        this.setDeslog();
        this.startOrOut();
        this.submitLogin("LOGIN");
      }
      this.queuesEsquerdo = true;
    });

    // VALIDANDO QUANDO USUÁRIO É EXPULSO DA FILA
    connection.on(`QueueMemberRemoved-${user.getExtension()}`, (data) => {
      if (this.getPause == true) {
        var time = this.getTime();
        var x = {
          pauseId: id,
          tempo: `${time}`,
          userId: this.getId,
        };
        this.comeBack(`${time}`);
        this.sendUnpause(x);
      }

      if (this.queuesEsquerdo) {
        this.setDeslog();
        this.startOrOut();
        this.submitLogin("LOGOUT");
      }
      this.queuesEsquerdo = false;
    });

    // PEGANDO CANAL DO USUÁRIO EM LIGAÇÃO
    connection.on(`NewConnectedLine-${user.getExtension()}`, async (data) => {
      this.chooseClientPhone("");

      if (data.ConnectedLineName.includes("RAMAL||")) {
        await clientData.mutations.setDataTransduson(
          `${data.ConnectedLineNum}||interna`
        );
      } else {
        await clientData.mutations.setDataTransduson(data.ConnectedLineName);
      }

      //Recupera os dados tratado
      let phoneSplit = data.ConnectedLineName.split("||");
      let dataPhone = phoneSplit[1];

      //INICIANDO O CONTADOR COM ZERO
      this.setCallTime(1);

      //BUSCANDO CLIENTE E PROTOCOLO DE ATENDIMENTO
      var self = this;
      this.fetchClients(dataPhone).then(function () {
        if (self.allClients && self.allClients.length > 0) {
          self.chooseClient(self.allClients[0]);
          self.chooseClientCnpj(self.allClients[0]["cpf_cnpj"]);
          self.setShowSpearhead();
          self.clearAllClients();
        } else {
          //SETANDO OS VALORES VAZIOS SE NÃO TIVER CLIENTE
          self.chooseClient({
            at_user_id: "",
            birthday: "",
            cep: "",
            city: "",
            complement: "",
            cpf: "",
            created_at: "",
            email: "",
            id: "",
            name: "",
            neighborhood: "",
            number: "",
            phone: dataPhone,
            phone_2: "",
            reference: "",
            rg: "",
            street: "",
            updated_at: "",
          });
          self.setShowSpearhead();
        }
      });
      this.fetchProtocol();

      //SETANDO CANAL NO STATE
      this.setChannel(data.Channel);
    });

    // DESABILITANDO BOTÃO DE MUTE QUANDO FINALIZADO A LIGAÇÃO
    connection.on(`Hangup-${user.getExtension()}`, async (data) => {
      this.delDataConnectLine();
      this.setShowSpearhead();
      if (localStorage.getItem("mute")) {
        this.setMute();
        localStorage.removeItem("mute");
      }

      if (user.getHasTab() && data.ConnectedLineName != "<unknown>") {
        console.log('Hangup Tab', data);
        let splitClientNum = data.ConnectedLineName.split("||");
        let clientNum = splitClientNum[1];
        this.hangupObj["dados"] = splitClientNum;
        let splitTimeCall = data.ConnectedLineName.split("||");
        let timeCall = moment.utc(splitTimeCall[2] * 1000).format("HH:mm:ss");

        const { value: option } = await Swal.fire({
          type: "question",
          title: "Como foi esta chamada?",
          allowOutsideClick: false,
          allowEscapeKey: false,
          html:
            "<b>Numero do Cliente: </b>" +
            clientNum +
            "<br>" +
            "<b>Tempo de ligação: </b>" +
            timeCall,
          input: "select",
          inputPlaceholder: "Selecione o status",
          inputOptions: this.statusList,
          onOpen: () => {
            const statusVal = Swal.getInput();
            statusVal.addEventListener("change", () => {
              if (statusVal.value == 1) {
                Swal.update({
                  html:
                    "<b>Numero do Cliente: </b>" +
                    clientNum +
                    "<br>" +
                    "<b>Tempo de ligação: </b>" +
                    timeCall +
                    "<br>" +
                    "<div style='padding-top: 20px; font-size: 15px'>✅CAIXA POSTAL/NÃO ATENDIDO = <b>QUANDO NÃO É POSSIVEL FALAR COM NINGUÉM</b></div>",
                });
              }

              if (statusVal.value == 2) {
                Swal.update({
                  html:
                    "<b>Numero do Cliente: </b>" +
                    clientNum +
                    "<br>" +
                    "<b>Tempo de ligação: </b>" +
                    timeCall +
                    "<br>" +
                    "<div style='padding-top: 20px; font-size: 15px'>✅AGENDADA = <b>AGENDOU  UMA REUNIÃO</b></div>",
                });
              }

              if (statusVal.value == 3) {
                Swal.update({
                  html:
                    "<b>Numero do Cliente: </b>" +
                    clientNum +
                    "<br>" +
                    "<b>Tempo de ligação: </b>" +
                    timeCall +
                    "<br>" +
                    "<div style='padding-top: 20px; font-size: 15px'>✅PERDIDA = <b>SEM INTERESSE, OU DEU PERDIDO NO LEAD , NÃO ENTRARÁ MAIS EM CONTATO</b></div>",
                });
              }

              if (statusVal.value == 4) {
                Swal.update({
                  html:
                    "<b>Numero do Cliente: </b>" +
                    clientNum +
                    "<br>" +
                    "<b>Tempo de ligação: </b>" +
                    timeCall +
                    "<br>" +
                    "<div style='padding-top: 20px; font-size: 15px'>✅FOLLOW UP = <b>TEVE UM CONTATO COM ALGUEM(SECRETARIA OU DONO) E VAI RETORNAR EM OUTRO MOMENTO</b></div>",
                });
              }
            });
          },
          inputValidator: (value) => {
            if (!value) {
              return "Por favor, selecione uma das opções acima!";
            }
          },
        });

        if (option) {
          this.selectedOption = option;
          if (this.saveTab() && this.updateTab() && this.deleteTab()) {
            Swal.fire({
              position: "top-end",
              type: "success",
              title: "Tabulação realizada com Sucesso\n",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "top-end",
              type: "error",
              title: "Não foi possivel salvar a tabulação\n",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      }

      //CADASTRANDO A LIGAÇÃO REALIZADA DO CLIENTE
      var structClient = {
        at_user_id: this.getId,
        protocol: this.getProtocol.protocol,
        uniqueid: data.Linkedid,
        type: "telefonia",
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      };

      structClient.cpf_cnpj = this.oneClient ? this.oneClient.cpf_cnpj : null;
      this.saveClient(structClient);
      this.setCallTime("null");
      this.clearClient();
      this.clearAllClients();
      this.setChannel("");

      //LIMPANDO O CAMPO DE PRODUTO
      $(".select2-multi").val("-1").trigger("change");

      //Limpa o ponta de lanca
      await clientData.mutations.delDataTransduson();
    });

    connection.on(`QueueMemberPause-${user.getExtension()}`, (data) => {
      if (this.queuePause) return;
      this.queuePause = true;

      // Entrou em pausa
      if (data.Paused == 1) {
        this.setPauseTime(0);
        var time = this.getTime();
        var x = {
          pauseId: data.PausedReason,
          tempo: `${time}`,
          userId: this.getId,
        };

        this.sendPauseApi(x);
        this.selectPause(x);
      }

      // Saiu da pausa
      if (data.Paused == 0) {
        var time = this.getTime();
        var x = {
          pauseId: data.PausedReason,
          tempo: `${time}`,
          userId: this.getId,
        };

        this.comeBack(`${time}`);
        this.sendUnpause(x);
        this.setPauseTime("null");
      }
    });
  },
};
</script>

<style scoped>
.num {
  width: 25px;
  height: 25px;
  text-align: center;
  text-combine-upright: all;
  left: 4em;
  border-radius: 50%;
  line-height: 25px;
  color: white;
  background-color: red;
  top: 5.5em;
  position: absolute;
}

.barra-lateral-esquerdo {
  background-color: #cccccc;
  width: 100%;
  max-width: 99px;
  text-align: center;
  border-right: solid 1px #1c869a;
  min-height: 100%;
  height: calc(100vh - 4.5em);
  overflow: auto;
}

::-webkit-scrollbar {
  width: 0.18rem;
}

::-webkit-scrollbar-track {
  background: #f38630;
}

::-webkit-scrollbar-thumb {
  background: #1c869a;
}

.figure-image {
  margin: 0px;
  display: block;
}

.figure-image div {
  margin: 0px;
  padding: 9px 0 9px 0;
}

.figure-image figcaption {
  color: white;
}

.backGroundOrangeRed {
  background-color: orangered;
}

.backGroundOrange {
  background-color: #fea33b;
}

.bg-adv {
  background: #1c869a;
}

.backgroundGreen {
  background: #27ae60;
}

.count-msg {
  padding: 0px;
  color: white;
  font-size: 1.5em;
}

strong .count-msg {
  font-size: 1.5em;
}

.remove-padding {
  padding: 0 !important;
}
</style>



