<template>
  <b-toast :id="id_" :variant="variant" solid>
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <strong><b-icon :icon="icon" class="mr-2" :style="{color}" style="width: 18px; height: 18px;"></b-icon></strong>
        <strong class="mr-auto mt-2" style="font-size: 16px">{{ title }}</strong>
      </div>
    </template>
    {{ mensagem }}
  </b-toast>
</template>

<script>
export default {
  name: "AlertToast",

  props: {
    id_: {
      type: String,
    },
    variant: {
      type: String,
    },
    color: {
      type: String,
    },
    title: {
      type: String,
    },
    mensagem: {
      type: String,
    },
    icon: {
      type: String,
    }
  },

  methods: {
    showAlertToast() {
      this.$bvToast.show(this.id_);
    },
  }
}
</script>

<style scoped>

</style>