<template>
  <div class="row">
    <div class="col-12">
      <label :for="`input${id}`">{{title}}</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i :class="`${icon} fa-fw`"></i>
          </span>
        </div>
        <select
          class="custom-select"
          :id="`input${id}`"
          :name="name"
          ref="select"
          :required="required"
          :readonly="readonly"
          :disabled="disabled"
        >
          <option value disabled>Selecione</option>
          <option v-if="enableAll" value="*">Todos</option>
          <option :key="x.id" v-for="x in listData" v-bind:value="x.id">{{x.name}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    name: String,
    id: String,
    icon: String,
    dataType: String,
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    values: {
      type: Object,
      default: null
    },
    enableAll: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      listData: []
    };
  },
  mounted() {
    this.popData();
  },
  methods: {
    popData: function() {
      var self = this;
      switch (this.dataType) {
        case "usersList":
          axios
            .get("release/userList")
            .then(function(response) {
              self.listData = response.data;
            })
            .catch(function(error) {
              console.error(error);
            });
          break;

        case "occurrenceStatus":
          axios
            .get("occurrence/status")
            .then(function(response) {
              self.listData = response.data;
            })
            .catch(function(error) {
              console.error(error);
            });
          break;

        default:
          self.listData = this.values;
          break;
      }
    }
  }
};
</script>
