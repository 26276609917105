<template>
  <div class="custom-historico" v-show="getShowHistory">
    <div class="container-fluid">
      <div class="row mt-2 mb-2">
        <div class="col-md-12">
          <h3 class="text-center border-bottom-hist">Histórico</h3>
        </div>
      </div>
    </div>
    <tableHistory></tableHistory>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    checked: false,
    domCheck: false,
  }),
  computed: {
    ...mapGetters(["getShowHistory", "getChannel", "getCurrentConversaIndex"]),
  },
  methods: {
    checkdom() {
      this.domCheck =
        this.getChannel() != "" || this.getCurrentConversaIndex !== "";
      if (this.domCheck) {
        this.checked = false;
      }
    },
  },
  watch: {
    getShowHistory: function () {
      this.checkdom();
    },
  },
};
</script>

<style>
.border-bottom-hist {
  border-bottom: solid 2px #1c869a;
}
.check {
  margin-top: 2.6em;
  margin-left: 1em;
}
.custom-historico {
  display: flex;
  flex-direction: column;
  padding: 0.4rem 2rem 2rem;
}
.show-plus {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tables th,
.tables td {
  border: none;
}
.tables tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.tables th {
  background-color: #1c869a;
  color: white;
  padding: 8px;
  border: groove 1px lightgray;
}
</style>
