import { templateSettings, startCase } from "lodash"

const state = {
    status:false,

    standby:true,
    
    atendimento:false,
    chat:false,
    chamada:false,

    
    qualAtendimento:'',
    
}
const getters = { 
    getStatus:state =>state.status,
    getStandby:state =>state.standby,
    getAtendimento: state => state.atendimento,
    getQualAtendimento: state => state.qualAtendimento,
    getChamada:state=>state.chamada,
    getChat:state =>state.chat
}
const mutations = { 
    setStatus: (state,status)=>(state.status = !state.status),
    setStandby:(state,standby)=>(state.status = !state.standby),
    
    setAtendimento: (state,atendimento) =>(state.atendimento = !state.atendimento),
    
    /* separar para cada set  
    aptoChamada(state,chat,chamado,standby,pause,qualAtendimento){
        if(chat != false && pause != false){
            state.chamado = true;
            state.qualAtendimento = 'chamado';    
            state.standby = false;
        }
    },
    aptoChat(state,chat,chamado,standby,pause,qualAtendimento){
        if(chamado != false && pause != false){
            state.chat = true;
            state.qualAtendimento = 'chat';    
            state.standby = false;
        }
    },
    sairChamado(state,chamado,chat,standby){
        state.chat = false; 
        state.chamado = false,
        state.standby = true;
    }
    */
}
const actions = {
    async selectAtendimentoTelefone({commit}){
        commit('setAptoChamada'); 
    },
    async selectAtendimentoChat({commit}){
        commit('aptoChat'); 
    },
    async sairAtendimento({commit}){
        commit('sairChamado')
    },

    async startOrOut({commit}){
        commit('setStandby')
    },


    
    
    
}
export default { 
    getters,
    mutations,
    actions,
    state
}