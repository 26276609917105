<template>
  <div>
    <div class="row">

      
      <div class="input-group col-4">

        <div class="input-group-prepend">
          <span class="input-group-text" id="selectType"><i class="fas fa-chart-bar"></i></span>
        </div>

        <select class="form-control" v-model="rel" id="inputSelectType" aria-label="Tipo" aria-describedby="selectType">
          <option disabled value="">Selecione</option>
          <option value="rel-analitico">Visao Geral Campanha</option>
          <option value="rel-log">Log da Campanha </option>
          <!-- <option value='rel-auster' v-if="auster"> Campanha Covid-19 </option> -->
          <option value='rel-pape-santander' v-if="auster"> PAPE Santander </option>
        </select>

      </div>


      <div class="input-group col">

        <div class="input-group-prepend">
          <span class="input-group-text" id="SelectCampanha"><i class="fas fa-chart-bar"></i></span>
        </div>

        <select class="form-control" v-model="optionCampanha" id="inputSelectCampanha" aria-label="Tipo" aria-describedby="selectType">
          <option disabled value="">Selecione</option>
          <option value="todos">Todos</option>
          <option v-for="campanha in campanhas" :key="campanha" :value="campanha"> {{ campanha }}</option>
        </select>
      
      </div>
    </div>

    <component :is="rel" :filter="optionCampanha"/>
  </div>
</template>

<script>
import axios from 'axios';
import RelAnalitico from './RelAnalitico.vue';
import RelLog from './RelLog.vue';
import RelAuster from './RelAuster.vue';
import RelPapeSantander from './RelPapeSantander.vue';

export default {

  components: {RelAnalitico, RelLog, RelAuster, RelPapeSantander},
  data(){
    return{
      campanhas: [],
      rel: '',
      optionCampanha: '',
      auster: process.env.MIX_Auster
    }
  },

  created(){
    axios.get('/marketing/distinctCampanhas').then(data => this.campanhas = data.data);
  }
}
</script>

<style>

</style>