<template>

    <div>
        
        <div class="row">

            <div class="col-4">
                <select-clients v-on:removeAllClients="removeAllClients" v-on:removeClient="removeClient" v-on:addClient="addClient" :wallets="wallets" :cities="cities"/>
            </div>

            <div class="col-4">
                
                <message-text v-for="index in controlMsgs" :key="index" :idText="index.toString()" v-on:addImage="addImage" v-on:updateText="addText"/>
            </div>

            <div class="col">
                <preview-whatsapp :text="text" :image="image" />
            </div>

        </div>

        <div class="d-flex">

            <div class="mr-auto p-2">
                <b-button @click="submitCampaign" class="submit-button" >Enviar</b-button>
            </div>

        </div>
    </div>
  
  
</template>

<script>
import PreviewWhatsapp from './PreviewWhatsapp.vue';
import SelectClients from './SelectClientes.vue';
import MessageText from './MessageText.vue'

import io from 'socket.io-client'

// const socket = io(`${location.protocol}//${location.hostname}:4001`)
const socket = {'on': function(){}, 'emit': function(){}};

export default {
    components: {PreviewWhatsapp, SelectClients, MessageText},
    props: {
        wallets: {
            type: Array,
            default: []
        },
        cities: {
            type: Array,
            default: []
        }
    },
    data(){
        return{
            text: ['',  `Somos do PAPE Santander e gostaríamos de saber se você está com covid.

1 - Sim
2 - Não` ],
            image: [],
            mediaSrc: [],
            clients: [],
            controlMsgs: 1
        }
    },
    methods:{
        addText(index, data){
            this.text.splice(index -1, 1, data);
        },
        addImage(index, data, file){

            for(let i=0; i < index; i++){
                
                if(this.image[i] == null){
                    this.image[i] = null;
                }
            }
            this.image.splice(index -1 , 1, data);
            this.mediaSrc.splice(index-1, 1, {file: file, srcImage: data, type:file.type});
        },
        increaseMsg(){
            if(this.controlMsgs > 2) return;
            this.controlMsgs++;
        },
        decreaseMsg(){
            if(this.controlMsgs == 1) return;
            this.controlMsgs--;
        },
        addClient(client){
            this.clients.push(client);
        },
        removeClient(client){
            this.clients = this.clients.filter(el => {
                if(el.name != client.name && el.phone != client.phone) return el;
            });
            if(this.clients == undefined) this.clients = [];
        },
        removeAllClients(){
            this.clients = [];
        },
        getArrLength(){
            return (this.text.length > this.image.length) ? this.text.length : this.image.length;
        },
        submitCampaign(){
            const id = Date.now();
            this.clients.forEach(el => el.id = id);
            
            const index = this.getArrLength();
            const mensagem = [];
            for(let i = 0; i < index; i++){
                if(this.mediaSrc[i]){

  
                    mensagem[i] = {
                        'text': this.text[i],
                        'media': {
                            type:  this.mediaSrc[i] ? this.mediaSrc[i].type : null,
                            filename: this.mediaSrc[i] ? this.mediaSrc[i].file.name : null,
                            file: this.mediaSrc[i].srcImage ? this.mediaSrc[i].srcImage : null
                        }
                    }

                    try{

                        if(mensagem[i]['media'].file.match(/,(.+)$/)){
                            mensagem[i]['media'].file = mensagem[i].media.file.match(/,(.+)$/)[1];
                        }
                    }catch{
                        delete mensagem[i].media;
                    }

                }else{
                     mensagem[i] = {
                        'text': this.text[i]
                     }
                }
                
  
            }

            let obj = {
                telefones: this.clients,
                mensagem: mensagem
            }

            axios.post('/marketing/addCampaign', obj.telefones).then(response =>{

                socket.emit('startCampaing', obj, (data) => {
                    console.info('Callback:', data);
                });

                Swal.fire(
                    'Campanha Iniciada',
                    'Os clientes selecionados vão receber a mensagem nos próximos minutos',
                    'success'
                ).then((confirm) => {
                    location.reload();
                });
                
            });

        }
    }
}
</script>

<style scoped>
.col{
    padding-right: 0;
    padding-left:0;
}

  .submit-form-container {
    margin-top: 12px;
  }
  .submit-button {
    margin-top: 12px;
    color: #fff;
    background: #25d366;
    width: 180px;
    border: none;
  }

</style>