const _allMensagens = [
	{
		val: 'AGENDA',
		text: 'Agenda',
		departamento: '1',
		mensagens: [
			`*Como criar um agendamento?*

Para criar um agendamento, basta clicar na tela em branco, na faixa de horário e na data em que deseja criar esse agendamento. Quando você fizer isso, uma nova tela vai ser aberta. Nela, você verá essas informações:`,
			`*Criar como compromisso?*

Você não precisa usar a agenda somente para os agendamentos que você tem naquele dia, você pode também criar um compromisso pessoal ou de algum outro tema, desse modo você está mostrando que não está disponível para agendamentos nesse horário.`,
			`*Selecione um consultório*

Aqui você vai escolher um dos consultórios já cadastrados e selecionar onde você quer que o agendamento aconteça.`,
			`*Selecione um profissional*

Aqui você vai escolher um dos profissionais já cadastrados e selecionar qual você quer que atenda esse agendamento.`,
			`*Selecione o paciente*

Aqui você vai escolher um dos pacientes já cadastrados ou selecionar a opção de cadastro rápido.`,
			`*Notificar paciente por SMS?*

Aqui você escolhe se vai enviar um SMS para o telefone cadastrado do paciente.`,
			`*Selecione o tipo de consulta*

Aqui você vai escolher o tipo de consulta desse agendamento. Esses tipos de consulta são cadastrados no menu *CADASTROS - TIPOS DE CONSULTA*.`,
		]
	},

	{
		val: 'CADASTROS > ÁREAS',
		text: 'Cadastros > Áreas',
		departamento: '2',
		mensagens: [
			`*Como cadastrar uma nova área e um novo procedimento?*

Em áreas ficam centralizadas todas as áreas de atuação e procedimentos da sua clínica. É nessa tela que você inclui ou até mesmo deleta tratamentos que você não realiza. É necessário que esteja completo e de acordo com a sua realidade, pois reflete nas demais configurações da plataforma.`,
			`*Para cadastrar um novo procedimento:*

O sistema já traz algumas especialidades e procedimentos pré configurados. Mas também é possível acrescentar novos da seguinte forma:
			
01 - Selecione o menu *CADASTRO* e depois clique em *ÁREAS*.
02 - Para cadastrar uma nova área de atuação clique no botão *“+Novo”*.
03 - Preencha com o título e clique em salvar.
04 - Clique no olhinho (procedimentos) para visualizar os tratamentos dessa especialidade ou cadastrar novos dentro dessa categoria.`,
			`*Para cadastrar um novo procedimento é necessário preencher as seguintes informações:*

01 - *Título:* Nome do procedimento.	
02 - *Código:* É opcional de cadastro e controle interno da clínica.	
03 - *Duração:* Quantas vezes esse procedimento pode ser realizado em 1 ano.
04 - *Tipo de imagem:* Se for um procedimento que necessite de exames, selecione. Caso não, deixe sem imagem.
05 - *Quantidade de faces:* Determine a quantidade que você precisa.
06 - *Orçamento por elemento:* Tratamento por dente
07 - *Repetir manutenção:* Quantidade de vezes que esse tratamento será repetido.`,
		]
	},
	{
		val: 'CADASTROS > CARGOS',
		text: 'Cadastros > Cargos',
		departamento: '2',
		mensagens: [
			`*Qual a função do menu Cargos?*

Nesse menu você vai cadastrar a profissão exercida pelas pessoas que trabalham internamente na sua clínica. Exemplo:
			
- ASB
- Recepcionista
- TSB
- Estagiário
- Gerente
- Vendedor
- Dentista`,
			`*Para cadastrar um novo cargo:*

01 - Menu *CADASTRO*
02 - Clique em CARGOS
03 - Selecione  *“+Novo”* e em seguida salvar.`,
		]
	},
	{
		val: 'CADASTROS > CONSULTÓRIOS',
		text: 'Cadastros > Consultórios',
		departamento: '2',
		mensagens: [
			`*Como cadastrar um novo consultório?*

É aqui onde você faz a configuração da quantidade de salas da sua clínica. Esse menu reflete diretamente no menu *Agendas*.
		
*para cadastrar um novo consultório:*
			
01 - Clique no Menu *CADASTRO* e depois em *CONSULTÓRIOS*.
02 - Selecione *“+Add Consultório”*.
03 - Preencha o título e em seguida clique em *SALVAR.*`
		]
	},
	{
		val: 'CADASTROS > ESPECIALIDADES',
		text: 'Cadastros > Especialidades',
		departamento: '2',
		mensagens: [
			`*Como cadastrar uma nova especialidade?*

É aqui onde você faz a configuração das especialidades que os dentistas da sua clínica atendem.
		
*Para cadastrar uma nova:*
		
01 - Clique no meu *CADASTRO* e em seguida  *ESPECIALIDADES*		
02 - Selecione *“+add Especialidade”*.
03 - Preencha o campo título e em seguida *SALVAR*.`
		]
	},
	{
		val: 'CADASTROS > FORNECEDORES',
		text: 'Cadastros > Fornecedores',
		departamento: '2',
		mensagens: [
			`*Como cadastrar um novo fornecedor?*

É aqui onde você vai cadastrar os fornecedores da sua clínica, desde a empresa onde você compra as próteses, as máscaras, os aventais e até a empresa que fornece luz na sua cidade. É opcional, mas caso você tenha o costume de utilizar o seu dinheiro pessoal na clínica, você cadastrar “Pessoal” nesse menu, assim você contabiliza esse valor de forma mais organizada.
			
*Para cadastrar um novo fornecedor:*
			
01 - Clique no menu *CADASTROS* e em seguida selecione *FORNECEDORES*.		
02 - Selecione *“+add novo”*.		
03 - Preencha o campo *título* e em seguida clique em *SALVAR.*`
		]
	},
	{
		val: 'CADASTROS > GRUPO DE DESPESAS',
		text: 'Cadastros > Grupo de Despesas',
		departamento: '2',
		mensagens: [
			`*Como cadastrar um novo grupo de despesas?*

Nesse menu você vai cadastrar os grupos e dentro deles você consegue colocar as despesas que pertencem a esse grupo. Por exemplo:
		
Caso você queira registar as despesas fixas da sua clínica, você pode criar um grupo chamado "despesas fixas" e dentro dele colocar aquilo que é um custo fixo na sua clínica, como o aluguel por exemplo.
		
*Para cadastrar um novo grupo e uma nova despesa:*
		
01 - Clique no menu *CADASTROS* e em seguida selecione *GRUPO DE DESPESAS*.
02 - Selecione *“+novo”*.
03 - Preencha o campo *título* e em seguida clique em *salvar*.
04 - Agora você vai colocar as despesas no grupo que acabou de criar. Clique em *“+adicionar despesa”*.
05 - Preencha o campo *nome da despesa* e depois  clique em *ADICIONAR*.`
		]
	},
	{
		val: 'CADASTROS > METAS DE FATURAMENTO',
		text: 'Cadastros > Metas de Faturamento',
		departamento: '2',
		mensagens: [
			`*Como cadastrar uma nova meta de faturamento?*

É aqui onde você vai cadastrar as metas de faturamento que você tem para a sua clínica. Existem três métricas que utilizamos nesse menu para definir essas metas, que são: Faturamento, despesas e lucro. Nesse menu você vai definir o valor desses três para cada mês, no ano de sua escolha..
		
*Para cadastrar uma nova meta de faturamento:*
		
01 - Clique em *CADASTROS* e selecione *GRUPO DE DESPESAS*.
02 - Selecione o botão *“+add novo”*.
03 - Agora você vai escolher o ano no qual essa meta vai ser aplicada lá no campo *Escolha o ano*. Depois disso você vai preencher os dados mês a mês de *faturamento, despesas e lucro*.`
		]
	},
	{
		val: 'CADASTROS > METAS DE VENDAS',
		text: 'Cadastros > Metas de Vendas',
		departamento: '2',
		mensagens: [
			`*Como cadastrar uma nova meta de vendas?*

É aqui onde você vai  cadastrar e configurar as metas de vendas que você tem para a sua clínica. Você vai conseguir escolher em qual ano essas metas vão funcionar e definir o valor delas mês a mês.
		
*Para cadastrar uma nova meta de vendas:*
		
01 - Clique em *CADASTROS* e selecione *METAS DE VENDAS*.	
02 - Selecione *“+add novo”*.	
03 - Agora você vai escolher o ano no qual essa meta vai ser aplicada lá no campo *escolha o ano*. Depois disso você vai preencher os dados mês a mês.`,
			`*Qual a diferença entre vendas e faturamento?*

Vendas é o valor bruto fechado, enquanto faturamento é o valor líquido em conta.
		
Exemplo: Você fechou um tratamento de implante no valor de 3 mil reais, porém parcelado em 3x de 1 mil.
		
O valor da venda foi: 3 mil reais.
O valor do faturamento foi: 1 mil reais`
		]
	},
	{
		val: 'CADASTROS > OBSERVAÇÕES DA GUIA DE SERVIÇOS',
		text: 'Cadastros > Observações da Guia de Serviços',
		departamento: '2',
		mensagens: [
			`*Como cadastrar uma nova observação da guia de serviço?*

É aqui onde você vai conseguir adicionar as observações que você deseja que apareçam nas suas guias de serviço.
		
*Para cadastrar uma nova:*
		
01 - Clique em *CADASTROS* e selecione *OBSERVAÇÕES DA GUIA DE SERVIÇO*.
02 - Selecione *“+add observação”*.
03 - Preencha o campo *observação* e coloque o título que deseja aparecer na sua guia de serviço.`
		]
	},
	{
		val: 'CADASTROS > ORIGEM DO PACIENTE',
		text: 'Cadastros > Origem do Paciente',
		departamento: '2',
		mensagens: [
			`*Como cadastrar a origem do paciente?*

É  aqui onde você vai conseguir cadastrar  a origem do paciente e usar essa informação mais a frente, quando você for fazer o cadastro dele no seu sistema.
		
*Para cadastrar uma nova origem de paciente:*
		
01 - Clique em *CADASTROS* e selecione *ORIGEM DO PACIENTE*.
02 - Selecione *“+add novo”*.	
03 - Preencha o campo título com a origem desse paciente, exemplo: Instagram, google, indicação, etc…`
		]
	},
	{
		val: 'CADASTROS > PACIENTES',
		text: 'Cadastros > Pacientes',
		departamento: '2',
		mensagens: [
			`*Como cadastrar um novo paciente?*

Aqui é onde você vai cadastrar os pacientes da sua clínica com informações pertinentes que vão refletir em outros menus do Sistema, como por exemplo: Nome completo, CPF, RG, e-mail, data de nascimento e origem do paciente.
		
Além disso, é através desse menu que você consegue localizar e acessar o perfil dos pacientes que você já tem cadastrado no sistema.
		
*Para cadastrar um novo paciente:*
		
01 - Clique em *CADASTROS* e selecione *PACIENTES*.
02 - Selecione *“+cadastrar novo paciente”*.
03 - Agora você vai preencher cada campo com as informações coletadas desse paciente.`
		]
	},
	{
		val: 'CADASTROS > PERFIL DO PACIENTE',
		text: 'Cadastros > Perfil do Paciente',
		departamento: '2',
		mensagens: [
			`*O que encontramos no perfil do paciente?*

*Agendamentos*
Nesse botão você consegue ver os agendamentos que esse paciente teve, tem ou vai ter na sua clínica. Dentro dele nós temos outros dois botões menores, o de *"Agendamentos"* e *"Agendamentos livres"*. Basta clicar em um desses dois para ver os agendamentos que esse paciente teve, tem ou vai ter através da agenda programada  ou da agenda livre.
	   
*Orçamentos*
Nesse botão você vai visualizar os orçamentos deste paciente. Se o orçamento já foi aprovado por você, ele aparecerá com o status *"aprovado"*, você pode clicar na setinha  pra baixo e abrir mais informações desse orçamento.
	   
A única ação que você pode tomar neste botão é a de estornar o orçamento ao paciente. Para fazer isso, clique no símbolo de *bloqueio*. Muito cuidado ao tomar essa ação! Tenha certeza absoluta de que estornar o orçamento é a única opção para resolver o possível problema.
	   
*Recebimentos*
Alguns tipos de pagamento vão exigir que você dê a baixa manual para que esse valor seja contabilizado no seu caixa, ou seja, você precisa confirmar o recebimento desse pagamento para que ele seja contabilizado. Formas de pagamento que exigem isso: PIX, dinheiro, transferência bancária, boleto e cheque.
	   
*Ocorrências*
Nesse botão você vai conseguir visualizar as ocorrências que esse paciente tem, ou então cadastrar uma nova. As ocorrências servem basicamente como  anotações específicas para deixar no perfil do paciente. Você pode cadastrar os tipos de ocorrências em outro menu lá em "Cadastros" - "Tipos de ocorrências".
	   
*Cartas*
Nesse botão você pode visualizar as cartas que foram anexadas para esse paciente lá no momento da criação do orçamento. As cartas basicamente são documentos como por exemplo, atestado, recibos de pagamento, contrato de procedimento, etc.. que você pode criar lá em *"Cartas" - "Cadastro de cartas"*.`
		]
	},
	{
		val: 'CADASTROS > BAIXA MANUAL E PARCIAL',
		text: 'Cadastros > Baixa Manual e Parcial',
		departamento: '2',
		mensagens: [
			`*Para fazer a baixa manual:*

01 -  Em "recebimentos", escolha o orçamento que deseja fazer a baixa manual e clique na setinha para baixo que fica no canto esquerdo. Agora, na caixinha que também  fica do lado esquerdo, você seleciona o pagamento no qual você deseja fazer a baixa..
02 - Agora, mas abaixo, você verá uma série de opções que você pode preencher e selecionar. Escolha a forma de pagamento e  escreva o valor que você quer dar a baixa. Depois é só clicar em "Baixar as parcelas selecionadas".	
03 - Você pode dar a baixa de duas formas, a primeira é simplesmente seguir o passo 2, a outra é a baixa parcial. A baixa parcial serve para quando o paciente não vai pagar o valor total acordado, seja da parcela ou do valor cheio, exemplo: O paciente fechou um orçamento de 300 reais para pagar a vista, porém ele só vai conseguir pagar 200 agora. Nesse caso o sistema te oferece a opção da baixa parcial, onde você pode dar a baixa somente no valor de 200 reais.`
		]
	},
	{
		val: 'CADASTROS > PROFISSIONAIS',
		text: 'Cadastros > Profissionais',
		departamento: '2',
		mensagens: [
			`*Como eu faço o cadastro de um profissional no meu sistema?*

É aqui onde você vai cadastrar um profissional na sua clínica, ou seja, os próprios dentistas, ASB, TSB, recepcionistas, gerentes, etc… Todos eles devem ser cadastrados no Sistema ideal e é aqui onde você consegue fazer isso.
		
*Para cadastrar um novo profissional:*
		
01 - Clique em *CADASTROS* e selecione *PROFISSIONAIS*.
02 - Selecione *“+add profissional”*.
03 - Agora você vai preencher os campos com os dados que estão sendo solicitados…OBS: Os campos com um “*” do lado, são de preenchimento obrigatório.`,
			`*Acessos e permissões:*

Você vai encontrar esses dois campos quando for cadastrar um novo profissional, aqui vai uma breve explicação da função deles:
	
*01 - Acessos:* Aqui você vai cadastrar o e-mail desse profissional e criar uma senha. Desse modo, quando ele terá o próprio acesso ao sistema, sem ter que ficar utilizando o seu.
*02 - Permissões:* Aqui é onde você vai definir quais partes do sistema esse novo usuário vai poder acessar.
	
Cuidado com o que você libera ou deixa de liberar, isso pode prejudicar o funcionamento do sistema. É de extrema importância que as *três últimas opções* estejam liberadas para o usuário.`
		]
	},
	{
		val: 'CADASTROS > RECEITAS',
		text: 'Cadastros > Receitas',
		departamento: '2',
		mensagens: [
			`*Como adicionar as receitas da minha clínica?*

*O que é a receita:* É o valor que entra na sua clínica, desde um aporte, valor de aluguel,convênio, etc…
		
Nesse menu você vai cadastrar o título dessa receita, ou seja, os nomes de onde essa receita é proveniente.
		
*Para cadastrar uma nova receita:*
		
01 - Clique em *CADASTROS* e selecione *RECEITAS*.
02 - Selecione *“+add receitas”*.
03 - Preencha o nome da receita que deseja adicionar (aporte, aluguel, convênio, etc…) e clique em *salvar*.`
		]
	},
	{
		val: 'CADASTROS > TIPOS DE CONSULTAS',
		text: 'Cadastros > Tipos de Consultas',
		departamento: '2',
		mensagens: [
			`*Como adicionar os tipos de consulta da sua clínica?*

Aqui é onde você vai cadastrar os tipos de consultas que você tem na sua clínica. Essas informações refletem na Agenda, pois quando você for fazer um agendamento, essa é uma das informações solicitadas.
		
*Para adicionar um tipo de consulta:*
		
01 - Clique em *CADASTROS* e selecione *TIPOS DE CONSULTAS*.
02 - Selecione *“+add novo”*.
03 - Agora preencha o campo *título* com o nome desse tipo de consulta e o campo *abreviação* com uma breve descrição sobre.`
		]
	},
	{
		val: 'CADASTROS > TIPOS DE OCORRÊNCIAS',
		text: 'Cadastros > Tipos de Ocorrências',
		departamento: '2',
		mensagens: [
			`*Como adicionar um tipo de ocorrência?*

As ocorrências são como anotações que você adiciona ao perfil do paciente, já os tipos de ocorrências são as categorias onde elas vão estar.
		
Exemplo: Se você quiser fazer anotações sobre a evolução do tratamento daquele paciente, você vai adicionar uma ocorrência no perfil dele, mas antes disso, você vai em *TIPOS DE OCORRÊNCIAS* e adiciona uma categoria chamada “Evolução do tratamento”.
		
*Para adicionar um novo tipo de ocorrência:*
		
01 - Clique em *CADASTROS* e selecione *TIPOS DE OCORRÊNCIAS*.
02 - Selecione *“+add novo”*.
03 - Agora você vai preencher o campo *título* com o nome desse tipo de ocorrência e clicar em *SALVAR*.`
		]
	},
	{
		val: 'CADASTROS > USUÁRIOS',
		text: 'Cadastros > Usuários',
		departamento: '2',
		mensagens: [
			`*Como editar um usuário no sistema?*

Aqui nesse menu você vai conseguir visualizar os usuários que estão cadastrados no seu sistema, assim como editar as informações deles.
		
*Informações que você consegue editar através desse menu:*
		
01 - Nome.
02 - Sobrenome.
03 - Email.
04 - Senha.
		
*Para editar um novo usuário:*
		
01 - Clique em *CADASTROS* e selecione *USUÁRIOS*.
02 - Escolha o usuário que deseja editar, através da barra de pesquisa ou então rolando a tela até encontrar.
03 - Agora clique no símbolo de lápis para editar as informações do usuário (nome, e-mail e senha). Você pode também editar as permissões, assim como ativar ou desativar esse usuário no seu sistema. Após definir o que deseja editar, clique em *SALVAR*.`
		]
	},
	{
		val: 'CADASTROS > VENDEDORES',
		text: 'Cadastros > Vendedores',
		departamento: '2',
		mensagens: [
			`*Como cadastrar um vendedor no meu sistema?*

É aqui onde você vai conseguir cadastrar novos vendedores ou editar informações sobre eles no seu sistema.
		
*Para cadastrar um novo vendedor:*

01 - Clique em *CADASTROS* e selecione *VENDEDORES*.
02 - Selecione *“+add vendedor”*.
03 - Agora você vai preencher o nome, a comissão e o CPF deste vendedor. Feito isso, basta clicar em *Salvar*.`
		]
	},
	{
		val: 'CAIXAS DIÁRIOS',
		text: 'Caixas Diários',
		departamento: '3',
		mensagens: [
			`*Como funciona o Caixa diário?*
		
Aqui é onde você vai abrir e fechar um caixa. O que isso significa? Quando valores entram no seu sistema, eles são contabilizados no seu caixa.
		
Isso pode acontecer por conta dos orçamentos que tiveram valores entrando, ou então por meio dos valores de CONTAS A RECEBER que ficam como “receitas”.
		
*Para abrir um caixa:*
		
01 - Clique em *CAIXAS* e selecione *CAIXAS DIÁRIOS*.
02- Agora você vai preencher o campo *“Digite o valor em que o caixa está sendo aberto”*. Nesse campo, você obrigatoriamente tem que preencher algo, então caso você não queira abrir um caixa com valor, basta digitar  0.00.
03 - Agora basta clicar em *Abrir caixa*.`,
			`*Como funciona o histórico de caixas?*

Aqui é onde você vai ter acesso a todos os caixas que já foram abertos na sua clínica. Você pode filtrar por datas e também acessar os detalhes, para assim conseguir ver detalhadamente quais os valores que entraram através de *orçamentos* ou *receitas*.`
		]
	},
	{
		val: 'CARTAS',
		text: 'Cartas',
		departamento: '4',
		mensagens: [
			`*Como faço para cadastrar uma nova carta?*

As cartas são modelos de documentos que você pode adicionar no perfil do paciente, ou imprimir e entregar para o mesmo.
		
*Para cadastrar uma nova carta:*
		
01 - Na parte superior, clique em *CARTAS* e selecione a opção *CADASTRO DE CARTAS*.
02 - Selecione *“+add novo”*.
03 - Em título você vai inserir o nome desse documento (cirurgia, prontuário, atestado, etc..). No quadro em branco, repare que funciona basicamente como o Word. Aqui é onde você vai criar um modelo, da maneira que quiser.
04 - Depois de criar, basta selecionar o *tema* e o *tipo* de documento (os *temas* são cadastrados à parte, seguindo o caminho (*cartas - temas*).`,
			`*Como faço para gerar documentos?*

Nessa parte do menu, é onde você vai vincular um documento ao perfil do paciente. Então em CARTAS você está criando os documentos, aqui você está vinculando algum ao paciente.
		
*Para gerar um documento:*
		
01 - Clique em *CARTAS* e selecione *GERAR DOCUMENTOS*.
02 - Agora você vai preencher o campo Documento. Aqui você vai escolher um dos documentos que você criou lá no menu *CARTAS*.
03 - Agora você vai preencher o campo Paciente. Aqui você vai escolher qual o paciente que você deseja vincular o documento. Feito isso, basta clicar em *Gerar*.`,
			`*Como faço para criar um Tema?*

Para criar um tema:
01 - Clique em *CARTAS* e selecione *TEMAS*.
02 - Agora você vai preencher os campos Título e Tema.
03 - Agora basta clicar em *Salvar*.`
		]
	},
	{
		val: 'DENTISTA > AGENDA DO PROFISSIONAL',
		text: 'Dentista > Agenda do Profissional',
		departamento: '5',
		mensagens: [
			`*O que é a agenda do profissional?*

Aqui é onde você consegue visualizar a sua agenda de forma separada, exemplo:
Se você tem mais de um colaborador na sua clínica, você pode fazer com que ele visualize somente a agenda dele, liberando acesso somente ao menu *DENTISTA*.

A diferença entre o menu *DENTISTA* e o menu *AGENDA:*  No menu *AGENDA*, nós temos acesso a agenda da clínica como um todo. No menu *DENTISTA*, nós temos acesso somente a agenda do profissional que está utilizando o sistema naquele momento.`
		]
	},
	{
		val: 'FINANCEIRO > CONFERIR CAIXA',
		text: 'Financeiro > Conferir Caixa',
		departamento: '6',
		mensagens: [
			`*Como conferir o meu caixa?*

Aqui você pode fazer a conferência do seu caixa. É importante para manter o controle da sua clínica, exemplo: Se você é o gerente da clínica, é importante sempre conferir se os caixas foram fechados com os dados corretos, nesse menu você tem uma visualização clara e objetiva sobre esses dados.
		
*Para conferir o caixa:*
		
01 - Clique em *FINANCEIRO* e selecione *CONFERIR CAIXAS*.
02 - Escolha qual o caixa que deseja conferir, você pode fazer isso manualmente ou então clicar em filtro de data, assim você deixa na sua tela somente os caixas de uma data ou período específicos.
03 - Clique em *conferir*. Agora você tem a visualização completa dos valores desse caixa, desça a  tela e clique em *confirmar caixa*.`
		]
	},
	{
		val: 'FINANCEIRO > CONTAS A PAGAR',
		text: 'Financeiro > Contas a Pagar',
		departamento: '6',
		mensagens: [
			`*Como adicionar minhas despesas?*

Neste menu é onde você vai conseguir adicionar todas as despesas da sua clínica. Para esse menu funcionar de forma completa, é importante que você tenha adicionado informações lá no menu *CADASTROS - FORNECEDORES - GRUPO DE DESPESAS - RECEITAS*.
		
*Para adicionar suas despesas:*
		
01 - Clique em *FINANCEIRO* e selecione *CONTAS A PAGAR*.
02 - Clique em *“+ adicionar despesa”*.
03 - Preencha os campos com as informações sobre essa despesa e clique em *salvar pagamento*.
		
*Campos a serem preenchidos:*
*Fornecedor:* Aqui você vai selecionar um fornecedor (que foi cadastrado anteriormente no menu *CADASTROS*).
*Descrição:* Aqui você vai colocar o nome dessa despesa.
*Grupo de despesa:* Aqui você vai selecionar um grupo de despesa que já cadastrou lá no menu *CADASTROS - GRUPO DE DESPESAS*.
*Itens:* Aqui é onde você vai escolher os itens, que foram cadastrados dentro dos grupos de despesas.
*Data de competência:* É a data em que você está adicionando essa despesa.
*Data de vencimento:* É a data em que esse pagamento deve ser feito. Ou seja, a data de vencimento.
*Valor:* Aqui você escreve o valor dessa despesa.
*Repetir:* Aqui você pode escolher se quer que essa despesa se repita de alguma forma. Caso você queira, basta selecionar a caixinha e selecionar uma das opções de repetição.
*Pago e tipos de pagamento:* Aqui você vai utilizar, caso a despesa que você está adicionando, já tenha sido paga. Se esse for o caso, basta selecionar a caixinha e selecionar as formas de pagamento.
*Data do pagamento:* Aqui você coloca a data em que essa despesa foi paga.`
		]
	},
	{
		val: 'FINANCEIRO > CONTAS A RECEBER',
		text: 'Financeiro > Contas a Receber',
		departamento: '6',
		mensagens: [
			`*Como faço para adicionar contas a receber na minha clínica?*

Aqui nesse menu você consegue cadastrar todas as contas que você tem pra receber na sua clínica. Não estamos falando dos valores referentes a orçamentos, mas sim de valores que entram na sua clínica de outra maneira (aluguel de cadeira, aporte, convênio, etc…).
		
*Para adicionar uma conta a receber:*
		
01 - Clique em FINANCEIRO e selecione CONTAS A RECEBER.
02 - Selecione “+adicionar receita”.
03 - Agora basta preencher todos os campos com as informações corretas e clicar em Salvar receita.
		
*Campos a serem preenchidos:*	
*Descrição:* Aqui você vai colocar o nome dessa conta a receber.
*Receitas:* Aqui você vai selecionar o tipo de receita, cadastrados lá em *CADASTROS - RECEITAS*.
*Data de competência:* Data em que você está criando esse cadastro.
*Data de vencimento:* A data limite em que você deve receber o pagamento dessa conta.`
		]
	},
	{
		val: 'FINANCEIRO > DEMONSTRATIVO FINANCEIRO',
		text: 'Financeiro > Demonstrativo Financeiro',
		departamento: '6',
		mensagens: [
			`*O que é o demonstrativo financeiro?*

O demonstrativo financeiro, ou DRE, é onde você consegue visualizar como está a saúde financeira da sua clínica, através de relatórios baseados nas informações que você alimentou no sistema. Essas informações são disponibilizadas por semestre, por ano e mês a mês também.
		
*Quais pontos levantamos no demonstrativo financeiro:*
		
*Receita:* O valor total que entrou na sua clínica.
*Despesa:* O valor que saiu da sua clínica.
*Lucro:* O valor da *receita* é subtraído pelo valor de *despesa*, ou seja, aquilo que sobrou da sua receita, após você ter pagado as *despesas*.
*Meta:* O valor que você definiu como meta da sua clínica.
*Planeado:* Os valores que estão previstos para entrar, como orçamentos aprovados que foram feitos, porém ainda não foram pagos.
*Realizado:* Os valores que de fato já entraram na sua clínica.`
		]
	},
	{
		val: 'FINANCEIRO > EXTRATOS',
		text: 'Financeiro > Extratos',
		departamento: '6',
		mensagens: [
			`*O que é o menu Extratos?*

Aqui é onde você consegue visualizar todos os valores que entraram e saíram da sua clínica. O menu Extrato oferece pra você, um filtro que permite buscar as entradas e saídas de um período específico.
		
O Extrato é uma visão mais aprofundada em relação ao *DEMONSTRATIVO FINANCEIRO*, mostrando as entradas e saídas do caixa ao longo do mês que estivermos interessados em analisar. Ou seja, o *DEMONSTRATIVO FINANCEIRO* traz a visão mais “de fora” da operação, enquanto o *EXTRATO* dá um “zoom” mês a mês.`
		]
	},
	{
		val: 'FINANCEIRO > LANÇAMENTOS REALIZADOS',
		text: 'Financeiro > Lançamentos Realizados',
		departamento: '6',
		mensagens: [
			`*O que é o menu Lançamentos realizados?*

É o relatório que traz uma visão separada entre receitas e despesas em relação ao EXTRATO. Ele também mostra a comparação entre as metas do mês. Além disso, ele mostra a meta do final do mês, mostrando também a previsão do resultado esperado ao longo do mês em relação a meta,
		
*Metas:* Nas duas tabelas de Receitas e Despesas, as metas são aquelas cadastradas em metas de faturamento (cadastro > metas de faturamento).
		
*Previsto:* O valor como *Previsto* (abaixo do valor da meta) é uma conta baseada no total de dias de trabalho no mês e também  baseada no total de dias já trabalhados no mês. Ou seja, a *meta* ao final do mês é o valor cadastrado, mas a cada dia de trabalho há uma meta para a receita e para a despesa - esse valor é a meta completa dividida pelo total de dias de trabalho no mês. Assim, o valor em *Previsto* é essa “meta diária” multiplicada pelo total de dias já trabalhados no mês e o Previsto tem como objetivo trazer um farol de como estamos caminhando de acordo com a nossa meta do mês.
		
*Previsão de recebimento:* É aquele valor baseado nas contas que estão previstas para serem creditadas naquele mês. Isso indica todos os valores a receber  que têm data de vencimento no mês em questão.
		
*Em atraso:* É aquele valor que está atrasado, que tem vencimento para o mês em questão, porém ainda não foi creditado.
			
*Realizado:* Esse é o valor que de fato foi creditado, ele independe da data de vencimento, o que importa aqui é que ele foi creditado no mês em questão.`
		]
	},
	{
		val: 'FINANCEIRO > ORÇAMENTOS',
		text: 'Financeiro > Orçamentos',
		departamento: '6',
		mensagens: [
			`*Como visualizar os meus orçamentos?*

Aqui é onde você vai visualizar, gerenciar e criar novos orçamentos. Este menu está conectado diretamente com uma parte bem importante do sistema, o odontograma.`,
			`*Como visualizar os seus orçamentos:*

01 - Clique em *FINANCEIRO* e selecione *ORÇAMENTOS*.
02 - No campo de busca, procure pelo orçamento que deseja visualizar.
03 - Após encontrar, basta clicar no símbolo de olho.`,
			`*Depois de seguir esse passo a passo, algumas opções serão abertas para você:*

*Aprovar orçamento:* Aqui é onde você aprova um orçamento que foi criado, para fazer isso, basta clicar no botão aprovar.
		
*Guia de serviço:* Aqui você vai abrir a guia de serviço do seu paciente, que funciona como uma espécie de nota fiscal, mostrando os valores daquele orçamento. Para visualizar a guia de serviço, basta clicar no botão de ”página“, ao passar o mouse por cima, você consegue ver o nome daquele botão. Você também consegue imprimir a guia de serviço, depois de aberta, basta clicar no símbolo de impressora que fica localizado no canto superior direito.
		
*Vendedores:* Nesse botão, você consegue ver o vendedor responsável por este orçamento. Basta clicar no botão *veja*.`
		]
	},
	{
		val: 'ODONTOGRAMA',
		text: 'Odontograma',
		departamento: '7',
		mensagens: [
			`Aqui é onde você vai criar os orçamentos. Existem duas formas de acessar o odontograma de um paciente, pelo perfil do paciente (encontrado no menu *CADASTROS > PACIENTES*) e pelos orçamentos (encontrado no menu *FINANCEIRO > ORÇAMENTOS*). Busque sempre pelo símbolo de dente, tanto no perfil do paciente como no menu de orçamentos.

*Como faço para criar os orçamentos?*
			
*Para criar os seus orçamentos, siga esses passos:*
		
*Procedimentos:* Aqui você vai selecionar o nome do procedimento que vai ser feito para esse orçamento. Depois disso, você vai clicar no botão de +, depois clique em salvar.  Você pode adicionar mais de procedimento.
		
01 - Tabela de preço: Aqui você vai selecionar a tabela de preço ( editadas em *FINANCEIRO > TABELAS DE VALORES DOS PROCEDIMENTOS e TABELAS DE VALORES*).
02 -  Clique em salvar procedimentos. Agora lá no canto direito, clique em aprovar.
03 -  Preencha os seguintes campos de acordo com o que deseja:
		
*Aplicar desconto* - caso deseje, coloque o desconto em porcentagem ou em Reais.
		
*Condições de pagamento* - Aqui você escolhe se o orçamento é parcelado ou à vista.
		
*Caso seja parcelado* - Escolha se quer aplicar um valor de entrada, a quantidade de parcelas e de quanto em quanto tempo essas parcelas serão cobradas. Você pode também definir se esse parcelamento terá juros e multa por atraso.
		
*Selecione o vendedor* -  Aqui você pode definir se esse orçamento está ou não ligado a um vendedor.
		
*Validade do orçamento em dias* -  Aqui você define a quantidade de dias que esse orçamento será válido.
		
Agora uma tela nova foi aberta, é a última. Você vai agora definir a forma de pagamento no qual o paciente está pagando o orçamento em questão. Selecione a forma de pagamento, a data do pagamento e clique em *confirmar*. Pronto! O seu orçamento foi criado.`
		]
	},
	{
		val: 'FINANCEIRO > PAGAMENTO DE DENTISTAS',
		text: 'Financeiro > Pagamento de Dentistas',
		departamento: '8',
		mensagens: [
			`*O que é o menu pagamento de dentistas?*

É nesse menu onde você vai realizar o pagamento da comissão para os dentistas.
		
*Para pagar o seu dentista:*
		
01 - Clique em *FINANCEIRO* e selecione *PAGAMENTO DE DENTISTAS*.
02 - Agora no campo *Dentistas*, selecione o profissional que você deseja pagar. Agora selecione o filtro. Feito isso, você terá acesso aos dentistas que têm valores a receber na data selecionada.
03 - Agora vá no profissional que deseja e clique no símbolo verde de cifrão.
04 - Agora basta clicar em *Pagar doutor*.`
		]
	},
	{
		val: 'FINANCEIRO > PAGAMENTO DE VENDEDORES',
		text: 'Financeiro > Pagamento de Vendedores',
		departamento: '8',
		mensagens: [
			`*O que é o menu pagamento de vendedores?*

É nesse menu onde você vai realizar o pagamento da comissão para os dentistas.
		
*Para pagar o seu vendedor:*
		
01 - Clique em *FINANCEIRO* e selecione *PAGAMENTO DE VENDEDORES*.
02- Agora no campo *Vendedor*, selecione o profissional que você deseja pagar. Agora selecione o filtro. Feito isso, você terá acesso aos vendedores que têm valores a receber na data selecionada.
03 - Agora vá no profissional que deseja e clique no símbolo verde de cifrão.
04 - Agora basta clicar em *Pagar doutor*.`
		]
	},
	{
		val: 'FINANCEIRO > TABELA DE VALORES DOS PROCEDIMENTOS',
		text: 'Financeiro > Tabela de Valores dos Procedimentos',
		departamento: '8',
		mensagens: [
			`*O que é tabela de valores dos procedimentos?*

Aqui é onde você vai cadastrar os valores que cada procedimento da sua clínica tem. Este menu possui ligação direta na criação de orçamentos, pois se as tabelas de valores estiverem zeradas, os seus orçamentos também serão.
		
As tabelas de valores são criadas em *FINANCEIRO > TABELAS DE VALORES*. Nosso sistema já vem com alguns procedimentos criados, mas você pode criar um novo em *CADASTROS > ÁREAS* > clica no símbolo de olho dentro de uma área e lá cadastra o procedimento desejado.

Quando você criar uma tabela e um procedimento, é hora de registrar os valores. Para fazer isso:
		
01 - Clique em *FINANCEIRO* e selecione *TABELA DE VALORES DOS PROCEDIMENTOS*.
02 - Agora, na parte de cima você vai ver as tabelas de valores. Em negrito do lado esquerdo, estão as áreas e abaixo delas estão os procedimentos.
03 - Basta digitar o valor deste procedimento em cada tabela. Obs…nós recomendamos deixar o campo *“US - Pagamento dos profissionais”* zerado.
04 - Vá até o final da tela e clique em *Salvar*.`
		]
	},
	{
		val: 'FINANCEIRO > TABELA DE VALORES',
		text: 'Financeiro > Tabela de Valores',
		departamento: '8',
		mensagens: [
			`*Como cadastrar uma tabela de valores?*

Aqui você pode cadastrar o nome das tabelas de valores que você utiliza na sua clínica.
		
*Para cadastrar uma tabela de valores:*
		
01 - Clique em *FINANCEIRO* e selecione *TABELA DE VALORES*.
02 - Selecione *“+add tabela”*.
03 - Preencha o campo “título” com o nome que deseja para essa tabela.`
		]
	},
	{
		val: 'RELATÓRIOS > CANCELAMENTOS',
		text: 'Relatórios > Cancelamentos',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório de cancelamentos?*

Aqui é onde você consegue visualizar todos os orçamentos que foram cancelados. Você tem acesso ao paciente que foi cancelado, ao dentista que cancelou e o valor que foi estornado.
		
*Você pode utilizar o nosso filtro para encontrar um cancelamento específico. Para fazer isso:*
		
01 - .Preencha o campo *“Cancelado por”* com o nome do dentista que cancelou o orçamento que você deseja encontrar.
02 - Preencha o campo *“nome do paciente”*, com o nome do paciente que foi cancelado.
03 - Selecione a data no campo *“período”*, fazendo isso, o filtro será aplicado e você vai conseguir visualizar os cancelamentos que deseja.`
		]
	},
	{
		val: 'RELATÓRIOS > COMISSÕES DE DENTISTAS',
		text: 'Relatórios > Comissões de Dentistas',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório de comissões de dentistas?*

Este é o relatório que traz a visão das comissões que deverão ser pagas aos dentistas, de acordo com os procedimentos realizados, ou seja, procedimentos que estão com status “tratado”.
		
*Você pode utilizar o nosso filtro para encontrar uma comissão específica. Para fazer isso:*
		
01 - Preencha o campo *“Dentistas”* selecionando o dentista que você deseja pagar.
02 - Selecione o filtro com a data que deseja e pronto! Você agora está vendo as comissões de dentistas ou de períodos específicos.`
		]
	},
	{
		val: 'RELATÓRIOS > COMISSÕES DE VENDEDORES',
		text: 'Relatórios > Comissões de Vendedores',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório de comissões de vendedores?*

Relatório que traz a visão das comissões que deverão ser pagas aos vendedores, de acordo com os orçamentos aprovados (excluindo os estornados).
		
*Você pode utilizar o nosso filtro para encontrar uma comissão específica. Para fazer isso:*
		
01 - Preencha o campo *“Vendedores”* selecionando o vendedor que você deseja pagar.		
02 - Selecione o filtro com a data que deseja e pronto! Você agora está vendo as comissões de dentistas ou de períodos específicos.`
		]
	},
	{
		val: 'RELATÓRIOS > DASHBOARD GERAL',
		text: 'Relatórios > Dashboard Geral',
		departamento: '9',
		mensagens: [
			`*Qual a função do Dashboard geral?*

Este relatório tem como objetivo trazer uma visão gerencial em relação à performance da clínica com *agendamento, comparecimento e fechamento*.
		
O que levamos em consideração com esse relatório é a data de agendamento do paciente e, a partir deste agendamento, temos as conversões de confirmação de consulta, de liberação e de orçamentos aprovados.
		
Todos vão de acordo com a data em que a consulta estava agendada e não de acordo com a data de aprovação do orçamento, por exemplo.
		
*Você pode utilizar o nosso filtro para encontrar algumas informações específicas. Para fazer isso:*
		
01 - Preencha o campo *“consultórios”*, selecionando o consultório que deseja.
02 - Preencha o campo *"Dentistas"*, selecionando o profissional que deseja.
03 - Preencha o campo *“Tipos”*, selecionando os tipos de consulta.
04 - Preencha o campo *“Origem”*, selecionando a origem de pacientes.
05 - Preencha o campo *“Mês - Ano do relatório”*, selecionando o período que deseja.`
		]
	},
	{
		val: 'RELATÓRIOS > INADIMPLÊNCIA',
		text: 'Relatórios > Inadimplência',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório de inadimplência?*

Este relatório tem como objetivo trazer de forma detalhada quem são os pacientes que estão com parcelas em aberto com a clínica, para facilitar o gestor a encontrar quem está devendo, facilitar o contato com devedores e poder analisar a sua eficiência na cobrança.
		
*Você pode utilizar o nosso filtro para encontrar algumas informações específicas. Para fazer isso:*
		
01 - Preencha o campo “Nome do paciente” com o nome do paciente que deseja visualizar.
02 - Selecione a data para obter dados de uma data específica.
		
*Neste relatório você consegue ver:*
		
01- O orçamento.
02 - O nome do paciente.
03 - A data de vencimento deste orçamento.
04 - O valor que o paciente deve.
05 - E o número da parcela, caso a dívida seja em uma parcela ou mais de uma.`
		]
	},
	{
		val: 'RELATÓRIOS > LIBERAÇÃO DE CONSULTA',
		text: 'Relatórios > Liberação de Consulta',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório de liberação de consulta?*

Este relatório tem como objetivo mostrar em detalhes as liberações de consultas, para que o gestor da clínica possa avaliar quais usuários não estão liberando os pacientes pelo sistema e quais observações são adicionadas durante esse processo.
		
*Você pode utilizar o nosso filtro para encontrar algumas informações específicas. Para fazer isso:*
		
01 - Preencha o campo *“Nome do paciente”* com o nome do paciente que deseja.
02 - Preencha o campo *“Usuário”* com o nome do profissional que deseja visualizar.
03 - Selecione a data com o período que deseja.
		
*Neste relatório você consegue ver:*
		
01 - O nome do paciente.
02 - O nome do profissional que fez a liberação dessa consulta.
03 - A data em que ele fez essa liberação.
04 - E as observações que foram escritas.`
		]
	},
	{
		val: 'RELATÓRIOS > ORÇAMENTOS REALIZADOS',
		text: 'Relatórios > Orçamentos Realizados',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório Orçamentos realizados?*

Este relatório traz a visão geral em relação aos orçamentos realizados na clínica durante um período de tempo. Neste relatório você tem acesso aos orçamentos realizados, orçamentos em aberto e orçamentos aprovados (excluindo sempre os estornados).
		
*Você pode utilizar o nosso filtro para encontrar algumas informações específicas. Para fazer isso:*
		
01 - Preencha o campo *“nome do paciente”*, com o nome do paciente que deseja filtrar.
02 - Preencha o campo *“Dentista”*, com o nome do profissional que deseja filtrar.
03 - Preencha o campo *“Vendedor”*, com o nome do profissional que deseja filtrar.
04 - Selecione a data para filtrar o período que deseja filtrar.
05 - Clique em pesquisar.
		
*Neste relatório você consegue ver:*
	
01 - A quantidade de pacientes que temos em cada tipo de orçamento (realizados, em aberto e aprovados), assim como a quantidade de orçamentos.
02 - O valor total em cada tipo de orçamento, assim como o ticket médio (valor que cada orçamento custou em média)
03 - O percentual de cada tipo de orçamento. Exemplo: Mostramos quantos orçamentos foram aprovados, ou então desses orçamentos realizados, quantos estão em aberto.
04 - Você também consegue visualizar de forma separada, os orçamentos aprovados e os orçamentos em aberto.`
		]
	},
	{
		val: 'RELATÓRIOS > PREVISÕES DE RECEBIMENTO',
		text: 'Relatórios > Previsões de Recebimento',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório previsão de recebimentos?*

Este relatório traz uma visão gerencial mais completa da receita em relação ao DEMONSTRATIVO FINANCEIRO, de acordo com o filtro do mês, aprofundando no que temos de previstos, recebidos, em atraso e a receber. Esse relatório não tem relação com Contas a Receber, mostrando apenas os orçamentos feitos no sistema.
		
*Você pode utilizar o nosso filtro para encontrar algumas informações específicas. Para fazer isso:*
		
01 - Preencha o campo *“Nome do paciente”* com o paciente que deseja filtrar.
02 - Preencha o campo *“Período”*, selecionando a data que você deseja filtrar.
		
*O que você pode ver neste relatório:*
		
*Total de previsão:* O Total da Previsão mostra todas as parcelas dos orçamentos que possuem *data de vencimento* no mês filtrado.
		
*Total recebido:* O Total Recebido mostra todas as parcelas dos orçamentos que possuem *data de pagamento* no mês filtrado.
		
*Total em atraso:* O Total em Atraso mostra todas as parcelas dos orçamentos que possuem *data de vencimento* dentro do mês filtrado, mas com o dia de vencimento menor do que hoje e sem o pagamento realizado.
		
*Total a receber:* O Total a Receber mostra todas as parcelas dos orçamentos que possuem *data de vencimento* dentro do mês filtrado, mas com o dia de vencimento maior do que hoje e sem o pagamento realizado.`
		]
	},
	{
		val: 'RELATÓRIOS > PROCEDIMENTOS EXECUTADOS',
		text: 'Relatórios > Procedimentos Executados',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório Procedimentos executados?*

Este relatório traz uma visão dos procedimentos que estão com o status “tratado”. Ele foi feito, para trazer uma visão gerencial ao dono da clínica, de modo que entenda quais os procedimentos que são mais realizados na clínica, podendo também ajudar na precificação de procedimentos e contratação de novos dentistas.
		
*Você pode utilizar o nosso filtro para encontrar algumas informações específicas. Para fazer isso:*
		
01 - Preencha o campo *“Profissional”*, selecionando o dentista que você deseja ver os procedimentos realizados por ele.
02 - Preencha o campo *“Status do pagamento”*, para filtrar através dos status do pagamento (a receber, estornado ou pago).
03 - Preencha o campo *“Procedimentos”*, selecionando os tipos de procedimentos para filtrar um em específico.
04 - Preencha o campo *“Período”*, selecionando a data ou período que deseja filtrar.`
		]
	},
	{
		val: 'RELATÓRIOS > VENDAS',
		text: 'Relatórios > Vendas',
		departamento: '9',
		mensagens: [
			`*Qual a função do relatório de vendas?*

Este relatório mostra a visão gerencial das vendas da clínica, que são diretamente relacionadas aos orçamentos realizados e não leva em consideração as Contas a Receber.
		
A meta no gráfico de vendas é a mesma cadastrada em Metas de Vendas (*CADASTROS > METAS DE VENDAS*) e o valor no gráfico mostra a evolução dia a dia das vendas (orçamentos aprovados a cada dia) comparado com a meta.`
		]
	},
	{
		val: 'ENCERRAMENTO',
		text: 'Encerramento',
		departamento: '10',
		mensagens: [
			`Imagina! Sempre que precisar de ajuda é só entrar em contato com o suporte nesse mesmo canal de atendimento. Tenha um ótimo dia!😄`,
			`Imagina! Sempre que precisar de ajuda é só entrar em contato com o  suporte nesse mesmo canal de atendimento. Tenha uma ótima tarde!😄`,
			`Imagina! Sempre que precisar de ajuda é só entrar em contato com o  suporte nesse mesmo canal de atendimento. Tenha uma ótima noite!😄`
		]
	},
	{
		val: 'ENCERRAMENTO > POR OCIOSIDADE',
		text: 'Encerramento > Por Ociosidade',
		departamento: '10',
		mensagens: [
			`Olha, por falta de interação estou encerrando esse atendimento. Mas não se preocupe, você pode entrar em contato conosco novamente através desse mesmo canal de atendimento. Lembrando que nosso horário de atendimento é de Segunda a Sexta das 09:00 às 18:00. Tenha um ótimo dia!😄`
		]
	},
	{
		val: 'CANCELAMENTO > FIRST CALL',
		text: 'Cancelamento > First Call',
		departamento: '10',
		mensagens: [
			`É muito triste que você queira romper a sua parceria conosco, mas precisamos dar seguimento ao fluxo de cancelamento por aqui, vou te explicar um pouco sobre ele:

Nós primeiro faremos uma ligação contigo de até 10 minutos, assim nós alinhamos a melhor data e horário para marcarmos uma reunião com a  Diretora do projeto Clínica Ideal. Nessa reunião nós vamos tentar entender melhor o motivo que te levou a tomar essa decisão, além de tratar de alguns assuntos financeiros e legais com você.
		
Agora, eu preciso saber qual o melhor dia e horário pra você receber uma ligação nossa, para assim conseguirmos marcar a sua reunião.`
		]
	},
	{
		val: 'CANCELAMENTO > AGENDAR REUNIÃO',
		text: 'Cancelamento > Agendar Reunião',
		departamento: '10',
		mensagens: [
			`É muito triste que você queira romper a sua parceria conosco, mas precisamos dar seguimento ao fluxo de cancelamento por aqui, vou te explicar um pouco sobre ele:

Nós precisamos marcar uma reunião com a diretora do projeto Clínica Ideal. Nessa reunião nós vamos tentar entender melhor o motivo que te levou a tomar essa decisão, além de tratar de alguns assuntos financeiros e legais com você.
				
Agora, eu preciso saber qual o melhor dia e horário pra você participar da sua reunião de cancelamento com a nossa diretora.`
		]
	},
	{
		val: 'REPORTE DO CLIENTE > BUG',
		text: 'Reporte do Cliente - BUG',
		departamento: '11',
		mensagens: [
			`Nosso sistema está passando por uma pequena instabilidade, que acaba gerando esse tipo de problema. Eu já informei o que houve com você para o nosso time de tecnologia, assim que resolvermos essa questão, eu mesmo vou entrar em contato contigo para avisar. Combinado?`
		]
	},
	{
		val: 'SOLICITAÇÃO DE TESTE - BUG',
		text: 'Solicitação de Teste - BUG',
		departamento: '12',
		mensagens: [
			`Olá, eu me chamo *%nome_contato%* e sou analista de suporte aqui do Clínica Ideal, tudo bem com você?`,
			`Lembra que você entrou em contato conosco reportando um erro no sistema? Eu preciso que você teste novamente e me diga se agora está tudo certo.`
		]
	},
	{
		val: 'FEEDBACK POSITIVO > BUG',
		text: 'Feedback Positivo > BUG',
		departamento: '12',
		mensagens: [
			`Olá, eu me chamo *%nome_contato%* e sou analista de suporte aqui do Clínica Ideal, tudo bem com você?

Lembra que você entrou em contato comigo reportando um erro no sistema? Então, felizmente esse erro foi resolvido e agora está tudo certo! 😄
		
Se o sistema voltar a apresentar erros, entre em contato conosco novamente. Tenha um ótimo dia!`
		]
	},
	{
		val: 'SOLICITANDO ACESSO REMOTO',
		text: 'Solicitando Acesso Remoto',
		departamento: '12',
		mensagens: [
			`Vamos resolver isso juntos! Existe uma forma bem simples de eu acessar o seu computador remotamente. Fazendo isso, eu consigo resolver o problema de forma rápida e prática, sem você precisar se preocupar com as questões técnicas.

*Para fazer isso, siga esse passo a passo:*
		
Passo 01: Clique no link https://anydesk.com/pt
Passo 02: Agora que a página foi aberta, clique em "Baixe agora".
Passo 03: Abra o arquivo que foi baixado. Agora você está vendo uma numeração em vermelho, selecione essa numeração, copie e cole, mande pra mim.
Passo 04: Aceite o meu acesso ao seu computador.`
		]
	}
];

const getMensagens = () => _allMensagens;

module.exports = {
	getMensagens
}
