<template>
    <b-row>
        <b-col>
            <b-row id="filters-row">
                <div class="name-filter">
                    <input type="text" class="form-control" placeholder="🔎   Pesquise por nome..." v-model="searchInput">
                </div>
                <div class="type-filter">
                    <v-select id="city-search" :options="groups" v-model="groupSelect">
                        <template #no-options="{ }">
                        Nenhum filtro encontrado.
                        </template>
                    </v-select>
                </div>
            </b-row>
            <b-row>
                <b-table 
                    class="contacts-table"
                    striped
                    hover
                    sticky-header
                    fixed
                    head-variant="dark"
                    :items="items" 
                    :fields="fields"
                >
                    <template v-slot:cell(action)="data">
                        <button v-show="!!data.item.check === true" class="btn btn-danger btn-table" @click="delItem(data)"><i class="fas fa-times"></i>      Remover</button>
                        <button v-show="!!data.item.check === false" class="btn btn-primary btn-table"  @click="addItem(data)"><i class="fas fa-plus"></i>     Adicionar</button>
                    </template>
                </b-table>
            </b-row>
            <b-row id="count-row">
                <div id="total-count">
                    Total: <b>{{totalChecked}}</b> contato<span v-show="totalChecked > 1">s</span> adicionado<span v-show="totalChecked > 1">s</span>.
                </div>
                <div class="add-or-remove-all">
                    <button class="btn btn-primary" @click="addAll()">Adicionar todos</button>
                    <button class="btn btn-danger" @click="delAll()">Remover todos</button>
                </div>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        wallets: {
            type: Array,
            default: []
        },
        groups: {
            type: Array,
            default: []
        }
    },
    data(){
        return {
            at_wallets: this.wallets,
            groupSelect: 'Todos os grupos',
            elementos: 1,
            items: [],
            fields: [
                {
                    key: 'action',
                    label: 'Ação',
                    class: 'text-center',
                    thStyle: {width: '120px'} 
                },
                {
                    key: 'name',
                    label: 'Nome',
                    class: 'text-center',
                    sortable: true,
                    thStyle: {width: '200px'} 
                },
                {
                    key: 'phone',
                    label: 'Telefone',
                    class: 'text-center',
                    sortable: true
                },
                {
                    key: 'msg_group',
                    label: 'Grupo',
                    class: 'text-center',
                    sortable: true
                },
            ],
            searchInput: ''
        }
    },
    methods:{
        addItem (data) {
            data.item.check = true
            const { name, phone } = data.item
            this.$emit('addClient', { name, phone });

        },
        delItem (data) {
            data.item.check = false
            const { name, phone } = data.item
            this.$emit('removeClient', { name, phone });
            
        },
        addAll () {
            this.items.forEach(item => {
                item.check = true
                const { name, phone } = item
                this.$emit('addClient', { name, phone });
            })
        },
        delAll () {
            this.items.forEach(item => {
                item.check = false
            });
            this.$emit('removeAllClients');
        },
    },
    computed: {

        totalChecked () {
            let total = 0
            this.wallets.forEach(el => {
                if(!!el.check) total++
            });
            return total
        }

    },
    watch:{
        groupSelect (value) {
            if(value != 'Todos os grupos') {
                let newItems = []
                this.at_wallets.forEach(item => {
                    if(item.msg_group === value) {
                        newItems.push(item)
                    }
                })
                this.items = newItems
            } else {
                this.items = this.at_wallets
            }
        },
        searchInput(){
            let keyword = this.searchInput?.toLowerCase();
            this.groupSelect = 'Todos os grupos';
            this.items = this.at_wallets.filter(row => {
                const name = row.name?.toLowerCase();
                const phone = row.phone;
                return (name?.includes(keyword) ||
                        phone?.includes(keyword)
                )
            })
        }
    },
    mounted () {
        this.items = this.at_wallets
    }

}
</script>

<style scoped>
    #city-search {
        width: 200px;
    }
    #filters-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px 0px 5px 0px;
    }

    #count-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px;
    }
    #total-count{
        align-self: center;
    }
    .contacts-table>thead>tr{
        background: #1C869A;
        color: white;
    }
    td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1px;
    }
    .b-table-sticky-header{
        max-height: 35vh;
    }
    .add-or-remove-all button{
        border-radius: 10px !important;
    }
    .btn-table{
        border-radius: 10px !important;
        font-size: 10px;
        margin: 0px;
    }
</style>