<template>
  <div class="container">
    <div>

      <h2>Upload de CSV</h2>
      <hr/>

      <div class="drag-area flex w-full h-screen items-center justify-center text-center" @dragover.prevent="dragOver" @dragleave="dragleave" @drop.prevent @drop="dragFile">
        <input style="display:none;" type="file" id='file-upload' accept=".csv" @change="handleFileUpload( $event )"/>

        <label for="file-upload" class="cursor-pointer text">
          Arraste o arquivo para área azul ou <span class="underline">clique aqui</span> para subir um novo arquivo
        </label>

        <div v-if="file">
          {{ file.name }}
        </div>

        <br>

        <div v-if="saving" class="pb-1">
          <p id="saving"><strong><i class="fas fa-spinner fa-pulse"></i> Atualizando base de contatos. Por favor, aguarde...</strong></p>
        </div>
        <button v-else v-on:click="submitFile()"><i class="fas fa-upload"></i> Enviar</button>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: "",
      number: "",
      duplicate: "",
      saving: false,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    dragFile(e) {
      document.getElementById("file-upload").files = e.dataTransfer.files;

      this.file = document.getElementById("file-upload").files[0];
      console.log("file", this.file);
      // Clean up
      e.currentTarget.classList.remove("gray");
      e.currentTarget.classList.add("salmon");
    },
    dragOver(event) {
      event.currentTarget.classList.add("gray");
      event.currentTarget.classList.remove("salmon");
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("gray");
      event.currentTarget.classList.add("salmon");
    },
    submitFile() {
      this.saving = true;
      let formData = new FormData();
      let file = document.getElementById("file-upload").files[0];

      if (!this.file) {
        Swal.fire({
          type: "warning",
          title: "Arquivo não anexado!",
          text: "Por favor, escolha um arquivo CSV para upload!",
        });
        this.saving = false;
        return;
      }

      formData.append("file", file);

      axios
        .post("/customer_base/store", formData)
        .then((data) => {
          console.log(">> . >>", data.data);
          Swal.fire("Upload realizado", "O arquivo foi adicionado", "success");
          this.saving = false;
          document.getElementById("file-upload").value = "";
          this.file = "";
          this.$emit("updateList");
        })
        .catch(function (error) {
          let message = error.response.data.message;
          const regexp = /(\'\s\d*\')|(\'\d*\')|(\'\d*\s\')/g;
          let duplicate = message.match(regexp);
          Swal.fire(
            "Erro",
            "Verifique o arquivo CSV selecionado, pode estar faltando alguma informação importante ou existem numeros duplicados!<br>" +
              "<b>Numero Duplicado:</b> " +
              duplicate,
            "error"
          );
          console.log("FAILURE!!");
        });
    },
  },
};
</script>

<style scoped>
.text {
  padding: 1em;
}

.drag-area {
  display: block;
  width: 100%;
  background-color: rgba(28, 133, 154, 0.05);
}

.gray {
  background-color: rgba(28, 133, 154, 1);
}

.salmon {
  background-color: rgba(28, 133, 154, 0.05);
}
button {
  border: none;
  background-color: #1c869a;
  width: 49%;
  margin: 1em auto;
  padding: 1em;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}
</style>