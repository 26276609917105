<template>
  <div class="row">
    <div class="col-12">
      <label :for="`input${name}`">{{title}}</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" :id="`${name}`">
            <i :class="`${icon} fa-fw`"></i>
          </span>
        </div>
        <textarea
          :id="`${id}_input`"
          :name="name"
          class="form-control"
          :aria-label="name"
          :aria-describedby="name"
          :placeholder="placeholder"
          :required="required"
          :disabled="disabled"
          :readonly="readonly"
          v-model="text"
          style="resize: vertical; min-height: 150px; height: 150px; max-height: 500px;"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    name: String,
    title: String,
    placeholder: String,
    icon: String,
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      text : this.value ? this.value : ''
    }
  }
};
</script>