<template>
  <section>
    <div class="row m-4">
      <button :class="['btn', 'btn-secondary',{'active': 'charts' == cl}]" @click="setRT('charts')"> <i class="fas fa-chart-pie"></i> </button>
      <button :class="['btn', 'btn-secondary',{'active': 'table' == cl}]" @click="setRT('table')"> <i class="fas fa-table"></i> </button>
      <button :class="['btn', 'btn-secondary',{'active': 'toogle' == cl}]" @click="setRT('toogle')"> <i class="fas fa-sync"></i> </button>
    </div>

    <div class="row" :class="[{'toogle-el': !toogleRealtime}]">

      <div class="col-md-12 tab-one-wrapper">
        <form id="filter-charts">
            <div class="row col-10">
              <label for="inputSelectQueue">Filtro de Filas para os Gráficos</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="selectType"><i class="fas fa-chart-bar"></i></span>
                </div>
                <select class="form-control" name="selectedQueue" id="inputSelectQueue" v-model="chartFilter" required aria-label="Tipo" aria-describedby="selectType">
                  <option value="*" selected>Todos</option>
                </select>
              </div>
          </div>
        </form>

        <div class="wrapper-charts">
          <PizzaChartAnswered :filter="chartFilter"></PizzaChartAnswered>
          <PizzaChartAbandoned :filter="chartFilter"></PizzaChartAbandoned>
        </div>
      </div>
      
      <div class="col-md-12 tab-two-wrapper">
        <div class="wrapper-group">
          <AverageTimeCalls type="serviceTime" :filter="chartFilter"></AverageTimeCalls>
          <AverageTimeCalls type="waitingTime" :filter="chartFilter"></AverageTimeCalls>
        </div>

        <div>
          <OperatorProductivityChart :filter="chartFilter"></OperatorProductivityChart>
          <MixedChart :filter="chartFilter"></MixedChart>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" :class="[{'toogle-el': toogleRealtime}]">
      <div id="table-dash-hour-hour" class="col-md-12">
        <form id="filter">
          <div class="row">
            <div class="col-8">
              <start-end-date-component id="dashboard" :required="true" autofill="onlyToday" type="date"></start-end-date-component>
            </div>
            <div class="col-4">
              <label for="inputSelectType">Filas</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="selectType"><i class="fas fa-chart-bar"></i></span>
                </div>
                <select class="form-control" name="selectedLog" id="inputSelectType" required aria-label="Tipo" aria-describedby="selectType">
                  <option value="*" selected>Todos</option>
                </select>
                <div class="input-group-prepend">
                  <button type="submit" id="btnSubmit" class="btn btn-primary"><i class="fa fa-search fa-fw"></i></button>
                </div>
                <div class="input-group-prepend">
                  <button type="reset" id="btnReset" class="btn btn-secondary"><i class="fas fa-times-circle fa-fw"></i></button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="alerts">
          <div id="alertSelectDashboard">
            <inline-alert-component type="warning" message="Use o filtro acima para gerar os gráficos." :dismiss="false"></inline-alert-component>
          </div>
          <div id="alertLoading" hidden>
            <inline-alert-component type="primary" message="Aguarde, os gráficos estão sendo carregados." :dismiss="false"></inline-alert-component>
          </div>
          <div id="alertInfo" hidden>
            <inline-alert-component type="warning" message="Não há informações para alguns gráficos." :dismiss="false"></inline-alert-component>
          </div>
        </div>

        <div id="table-container-tab" style="box-shadow: 3px 3px 3px #CCC; background-color: #FFF; padding: 5px; margin-bottom: 20px;" hidden>
          <div class="row">
            <div class="col-11 mt-2" style="text-align: center">
              <h5 class="font-weight-bold" id="title_hourly_calls">Detalhamento de Ligações por Operadores - Tabulação</h5>
            </div>
          </div>
          <hr style="margin-top: 0px;">
          <div class="row">
            <div class="col-12" id="dashboardTableContentTab">
              <table id="table_hourly_calls_tab" class="table display table-hover table-striped" style="width: 100%">
                <thead style="font-weight: bold">
                  <tr role="row">
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">Operador</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">Ligações Recebidas</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">Ligações Realizadas</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">Total de Ligações</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">Ligações Unicas</th>
                      <th class='text-center align-middle' rowspan="1" colspan="3" style="border: 1px solid #fff;">Tabulações</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">09h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">10h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">11h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">12h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">13h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">14h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">15h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">16h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">17h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">18h</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">01 min</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">02 min</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">05 min</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">10 min</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">10 min+</th>
                  </tr>
                  <tr role="row">
                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >AGE</th>
                      <th rowspan="1" colspan="1" >PER</th>
                      <th rowspan="1" colspan="1" >FUP</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>
                      
                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>
                  </tr>
              </thead>
              </table>
            </div>
          </div>
        </div>

        <div id="table-container" style="box-shadow: 3px 3px 3px #CCC; background-color: #FFF; padding: 5px; margin-bottom: 20px;" hidden>
          <div class="row">
            <div class="col-11 mt-2" style="text-align: center">
              <h5 class="font-weight-bold" id="title_hourly_calls">Detalhamento de Ligações por Operadores</h5>
            </div>
          </div>
          <hr style="margin-top: 0px;">
          <div class="row">
            <div class="col-12" id="dashboardTableContent">
        
              <table id="table_hourly_calls" class="table display table-hover table-striped" style="width: 100%">
                <thead style="font-weight: bold">
                  <tr role="row">
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">Operador</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">Ligações Recebidas</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">Ligações Realizadas</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">Total de Ligações</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">Ligações Unicas</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">09h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">10h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">11h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">12h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">13h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">14h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">15h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">16h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">17h</th>
                      <th class='text-center align-middle' rowspan="1" colspan="2" style="border: 1px solid #fff;">18h</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">01 min</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">02 min</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">05 min</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">10 min</th>
                      <th class='text-center align-middle' rowspan="2" colspan="1" style="border: 1px solid #fff;">10 min+</th>
                  </tr>
                  <tr role="row">
                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>
                      
                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>

                      <th rowspan="1" colspan="1" >ATE</th>
                      <th rowspan="1" colspan="1" >N ATE</th>
                  </tr>
              </thead>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import AverageTimeCalls from "../charts/AverageTimeCalls.vue";
import OperatorProductivityChart from "../charts/OperatorProductivityChart.vue";
import MixedChart from "../charts/MixedChart.vue";
import PizzaChartAnswered from "../charts/PizzaChartAnswered.vue";
import PizzaChartAbandoned from "../charts/PizzaChartAbandoned.vue";

export default {
  name: "DashboardApp",
  components: {
    AverageTimeCalls,
    OperatorProductivityChart,
    MixedChart,
    PizzaChartAnswered,
    PizzaChartAbandoned
  },
  data() {
    return {
      toogleRealtime: false,
      intervalId: "",
      cl: "toogle",
      chartFilter: "*"
    };
  },
  created() {
    this.intervalId = setInterval(() => {
      this.toogleRealtime = !this.toogleRealtime;
    }, 15000);
  },
  methods: {
    setRT(style) {
      this.cl = style;
      clearInterval(this.intervalId);

      if (style == "charts") {
        return (this.toogleRealtime = true);
      }

      if (style == "table") {
        return (this.toogleRealtime = false);
      }

      if (style == "toogle") {
        this.intervalId = setInterval(() => {
          this.toogleRealtime = !this.toogleRealtime;
        }, 15000);
      }
    },
    updateCharts() {
      this.chartFilter
    }
  },
};
</script>

<style scoped>
.tab-one-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.wrapper-charts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#filter-charts {
  margin-left: 2%;
}

.tab-two-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sub-group {
  width: 45%;
}

.wrapper-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.toogle-el {
  display: none;
}
</style>