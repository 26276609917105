<template>
  <b-modal id="bv-modal-create" :title="'Cadastrar '+ tipo" centered>
    <b-row class="justify-content-md-center">
      <b-col cols="12" md="7">
        <b-form-group
            label="Descrição:"
            label-for="input-desc"
            class="mb-0"
        >
          <b-form-input
              id="input-desc"
              v-model="descricao"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-row class="mt-3 pad">
        <b-col></b-col>
        <b-col cols="12" md="auto">
          <b-button @click="$bvModal.hide('bv-modal-create')">CANCELAR</b-button>
          <b-button variant="primary" @click="save">SALVAR</b-button>
        </b-col>
      </b-row>
    </template>

    <AlertToast
        id_="alert-toast"
        variant="warning"
        icon="exclamation-triangle"
        color="#91560a"
        title="Atenção"
        mensagem="Preencha a Descrição"
        ref="alert-toast"/>
  </b-modal>
</template>

<script>
import AlertToast from "./AlertToast";
export default {
  name: "CreateModal",
  components: {AlertToast},
  data: () => ({
    id: null,
    descricao: null,
    tipo: null,
  }),

  methods: {
    showModal(obj) {
      this.tipo = obj.type;
      this.id = obj.id;
      this.descricao = obj.name;
      this.$bvModal.show('bv-modal-create');
    },

    hideModal() {
      this.$bvModal.hide('bv-modal-create');
    },

    save() {
      if(!this.descricao){
        this.$refs["alert-toast"].showAlertToast();
        return;
      }

      if (this.id == null) {
        this.$emit('handleCreate', {
          categoria: this.tipo,
          descricao: this.descricao
        });
      } else {
        this.$emit('handleUpdate', {
          id: this.id,
          categoria: this.tipo,
          descricao: this.descricao,
        });
      }
    }
  },
}
</script>

<style scoped>

</style>