const io = require('socket.io-client')
window.moment = require('moment');
window.moment.locale('pt-br');

const state = {
    calltime: 'null',
    // realtime: io.connect("http://" + location.hostname + ":3001")
}
const getters = {
    getRealTime: state => state.realtime,
    getCallTime: state => state.calltime
}
const mutations = {
    setNewCallTime: (state, time) => (state.calltime = time)
}
const actions = {
    async setCallTime({ commit }, data) {
        commit('setNewCallTime', data)
    },
    async login(data) {

        socket.emit('queueLogin', {
            extension: data.getters.getExtension(),
            queue: data.getters.getQueues
        });
    },


    async ouvir(data) {
        socket.on(`QueueMemberAdded-${data.getters.getExtension()}`, () => {
            // ...
        })
    }

}

export default {
    getters,
    mutations,
    actions,
    state
}