<template>
    <div class='barra-lateral-direita' v-if='getShowBarRight'>
 
             <form class='share-client direita' @submit.prevent>
                 <div class="search-client">
                     <div class="d-flex" style="align-items: center">
                         <input  @click.prevent v-model="cnpj" style="flex:2" class='esquerda input-text form-control round-input' type='text' placeholder="Procure um contato...">
                         <button @click.prevent="searchClients" class="form-control" id="search-button"><i class="fas fa-search" />  Buscar</button>                    
                     </div>
                 </div>
                 <h5 class="mt-2" v-if="allClients && allClients.length > 0">Selecione um cliente:</h5>
                 <div v-if="allClients && allClients.length > 0" id="search-results">
                     <div  @click.prevent="chooseAndInsertClient(client.id)" class='show-share' v-for="client in allClients" :key='client.id'>
                         <div v-if="client" class='conteudo-share'>
                             <p class='formatName'><b>Nome:</b> {{client.name}}</p>
                             <p class='formatPhone'><b>Telefone:</b> {{formTelefone(client.phone)}}</p>
                             <p class='formatPhone'><b>CPF/CNPJ:</b> {{formatCnpjCpf(client.unique_key)}}</p>
                         </div>
                     </div>
                 </div>
             </form>
 
             <div class='menu-lateral-direito' v-if="(preSelectShow && preSelectShow.phone != null && selected && !msgError)">
                 <div id="contact-container">
                     <div class="text-center">
                         <div>
                             <p class="sublinhado-embaixo mb-3" style="align-self: center; font-size: 18px">{{ (preSelectShow && preSelectShow.name != null)? preSelectShow.name : 'Nome' }}</p>
                         </div>
                         <div v-if="preSelectShow.unique_key">
                             <p class="mb-0"><b>CPF/CNPJ: </b></p>
                             <p class="mb-0">{{ formatCnpjCpf(preSelectShow.unique_key) }}</p>
                         </div>
                         <div class='second-client m-3' v-if="preSelectShow.phone">
                             <div class='mb-0'>
                                 <p class="mb-0"><b>TELEFONE: </b></p>
                                 <p class="mb-0">{{ formTelefone(preSelectShow.phone) }}</p>
                             </div>
                         </div>
                         <div class="d-flex">
                            <div class='wpp-call' @click="call_client">
                                <div class="direita d-flex justify-content-center">
                                    <p class='esquerda' style="align-self: center"><i class="fas fa-phone"></i>  <b>Ligar</b></p>
                                </div>
                            </div>
                         </div>
                     </div>
                     <slot></slot>
                 </div>
             </div>
 
             <div class='menu-lateral-direito' v-if="!allClients && !selected && msgError">
                 <div id="contact-container">
                     <div class="text-center">
                         <div class='second-client m-3'>
                             <div class='mb-0'>
                                 <p class="mb-0">A pesquisa deve conter pelo menos três dígitos...</p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
 
             <div class='menu-lateral-direito' v-if="loading && !allClients && !selected && !msgError">
                 <div id="contact-container">
                     <div class="text-center">
                         <div class='second-client m-3'>
                             <div class='mb-0'>
                                 <b-spinner variant="dark" small></b-spinner>
                                 <p class="mb-0">Processando, por favor, aguarde...</p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>

             <div class='menu-lateral-direito' v-if="allClients && allClients.length == 0 && loading && !selected && !msgError">
                 <div id="contact-container">
                     <div class="text-center">
                         <div class='second-client m-3'>
                             <div class='mb-0'>
                                 <p class="mb-0">Nenhum contato encontrado...</p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>

             <div class='menu-lateral-direito' v-if="!loading && !selected && !msgError">
                 <div id="contact-container">
                     <div class="text-center">
                         <div class='second-client m-3'>
                             <div class='mb-0'>
                                 <p class="mb-0"><strong>Campos válidos para pesquisa:</strong> Telefone, Nome e CPF/CNPJ.</p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
     </div>
 
 </template>
 <script>
 import { mapGetters,mapActions } from 'vuex';
 import { formata_cpf_cnpj } from '../../validaCPFeCNPJ'
 import { BSpinner } from 'bootstrap-vue'
 Vue.component('b-spinner', BSpinner)

 const parsedTelefone = process.env.MIX_TELEFONE === "true";
 const connection = parsedTelefone ? io.connect(`${location.protocol}//${location.hostname}:3006/`) : { on: function () {}, emit: function () {} };
 
 export default {
 
     data() {
         return{
             formata_cpf_cnpj: formata_cpf_cnpj,
             cnpj:'',
             cnpj_real:'',
             selected: false,
             msgError: false,
             loading: false
         }
     },
     watch: {
         cnpj: function(value) {
             if(!value ) {
                if(value.length == 0) {
                    this.selected = false;
                    this.msgError = false;
                    this.loading = false;
                } else if(value.length < 3) {
                    this.selected = false;
                    this.msgError = true;
                    this.loading = false;
                }

                this.clearAllClients();
                return;
             }
         }
     },
     computed: {
         ...mapGetters([
             'allClients',
             'getShowBarRight',
             'preSelectShow',
             'getCnpj',
             'getShowSpearhead',
             'oneClient'
 
         ])
     },
     methods: {
         ...mapActions([ 
             'fetchClients',
             'chooseClient',
             'fetchProtocol',
             'clearAllClients',
             'chooseClientCnpj',
             'setShowSpearhead',
             'setShowBarRight',
             'preselectClient',
             'startConversation'
         ]),
         chooseAndInsertClient(id) {
             this.allClients.forEach(x => {
                 if(x.id == id){
                     this.preselectClient(x);
                     this.fetchProtocol();
                 }
             })
             this.clearAllClients();
             this.selected = true;
         },
         open_client() {
             if(this.preSelectShow.phone){
                 this.setShowBarRight();
                 this.startConversation(this.preSelectShow);
             }
         },
         call_client() {
             if(this.preSelectShow.phone) {
                this.setShowBarRight();
                const phone = this.formatPhoneToCall(this.preSelectShow.phone);
				connection.emit('Originate', {
					calltype: 'outgoing',
					extension: user.getExtension(),
					phone: `${phone}`,
				});
             }
         },
         formatCnpjCpf(value){
  			const cnpjCpf = value?.replace(/\D/g, '');

  			if (cnpjCpf?.length === 11) {
    			return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
  			} 
  
  			return cnpjCpf?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
		 },
         formTelefone(tel) {
			if(tel.substr(0,2) != '55'){
                return '+' + tel;
            }
            
            if( (tel.length == 10) || (tel.length == 11) ) {
                const v1 = tel.replace(/\D/g,"");
                const v2 = v1.replace(/^(\d{2})(\d)/g,"($1) $2");
                const v3 = v2.replace(/(\d)(\d{4})$/,"$1-$2");
                
                return v3;
            }
            tel=tel.replace(/\D/g,"")
            tel=tel.replace(/^(\d)/,"+$1")
            tel=tel.replace(/(.{3})(\d)/,"$1($2")
            tel=tel.replace(/(.{6})(\d)/,"$1)$2")
            if(tel.length == 12) {
                tel=tel.replace(/(.{1})$/,"-$1")
            }
            else if (tel.length == 13) {
                tel=tel.replace(/(.{2})$/,"-$1")
            }
            else if (tel.length == 14) {
                tel=tel.replace(/(.{3})$/,"-$1")
            }
            else if (tel.length == 15) {
                tel=tel.replace(/(.{4})$/,"-$1")
            }
            else if (tel.length > 15) {
                tel=tel.replace(/(.{4})$/,"-$1")
            }
            tel=tel.replace("("," (");
            tel=tel.replace(")",") ");

            return tel;
         },
         formatPhoneToCall(phone) {
            let newPhone = phone.replace(/\D/g, "");
            newPhone = newPhone.slice(0, 2) == "55" ? newPhone.slice(2) : newPhone;
            return newPhone.slice(0, 2) == "11" ? newPhone.slice(2) : newPhone;
         },
         searchClients() {
             if(this.cnpj.length == 0) { 
                 this.selected = false;
                 this.msgError = false;
                 this.loading = false;
             } else if(this.cnpj.length < 3) { 
                 this.selected = false;
                 this.msgError = true;
                 this.loading = false;
 
                 this.clearAllClients();
             } else {
                 this.clearAllClients();
                 this.selected = false;
                 this.msgError = false;
                 this.loading = true;
 
                 this.fetchClients(this.cnpj);
             }

             return;
         }
     }
 
 }
 
 </script>
 
 <style>
 .barra-lateral-direita{
     width: 350px;
     padding:15px 5px 15px 5px;
     text-align: center;
 }
 .menu-lateral-direito{
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
 }
 .share-client input[type='submit']{
     background-image: url('/imgs/icons/search-white.svg');
     background-repeat: no-repeat;
     margin: 0;
     background-position: center;
     background-size: 20px 20px;
     display: flex;
     
 }
 .area-share{
     position: absolute;
     width: 95%;
     top: 6.7em;
     max-height: 18em;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: #6babb8;
 }
 .show-share{
     z-index: 1;
     display: contents;
     color: black;
 }
 .conteudo-share{
     border-top: solid 1px lightgray;
     width: 91%;
     display: inline-block;
     overflow-x: hidden;
     text-align: start;
 }
 .formatName{
     font-weight: italic;
 }
 .formatPhone{
     font-style: italic;
 }       
 .conteudo-share:hover{
     background-color: gray;
 }
 .espaco-cima-baixo p, .espaco-cima-baixo{
     margin: 16px 0 10px 0;
 }
 .sublinhado-embaixo{
     border-bottom:solid 1px white;
     padding: 10px 0 10px 0;
 }
 </style>
 
 
 
 <style scoped>
 #search-button {
     flex: 1 1 0%;
     padding: 1px;
     margin: 5px;
     border: 2px solid lightgray;
     border-radius: 10px !important;
     background-color: #1c869a;
     color: white;
     transition: 0.3s;
 }
 #search-button:hover{
     background-color: #104b57;
 }
 #search-results {
    max-height: 72vh;
     overflow-y: auto;
     overflow-x: hidden;
     background-color: #84a9b1;
 }
 #contact-container {
     display: flex;
     justify-content: center;
     border: 2px solid rgba(211, 211, 211, 0.416);
     border-radius: 20px !important;
     padding: 10px;
     width: 21em;
     margin: 10px;
 }
 .round-input{
     border-radius: 0.25em !important;
 }
 .wpp-call{
     border: solid 2px green;
     border-radius: 10px;
     padding: 0px 10px;
     cursor: pointer;
     background-color: #27ae60;
     width: 100%;
     transition: 0.2s;
 }

 .wpp-call:hover{
     background-color: green;
 }
 
 .search-client{
     display: flex;
     flex-direction: column;
     align-items: center;
 }
 </style>