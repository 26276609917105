import { render, staticRenderFns } from "./RealtimePhone.vue?vue&type=template&id=784e31e4&scoped=true&"
import script from "./RealtimePhone.vue?vue&type=script&lang=js&"
export * from "./RealtimePhone.vue?vue&type=script&lang=js&"
import style0 from "./RealtimePhone.vue?vue&type=style&index=0&id=784e31e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784e31e4",
  null
  
)

export default component.exports