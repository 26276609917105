<template>
  <div class="card my-3 px-3 py-3">
    <h5 style="color: #000; align-self: center">{{ title }}</h5>
    <apexchart type="bar" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  props: ['title', 'values', 'categories', 'catramal'],
  data: function() {
    return {
      chartOptions: {
        chart: {
          id: "barchart",
            toolbar: {
              show: false,
            },
        },
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: {
              position: 'top'
            }
          },
        },
        theme: {
          palette: "palette7",
        },
        stroke: {
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.categories,
          overwriteCategories: this.catramal
        },
        tooltip: {
          theme: 'dark',
        },
        yaxis: {
          show: false
        },
      },

      series: this.values
    };
  }
};
</script>