<template>
	<div>
		<div class='add-company' @click="button_client">
			<div class="direita hover-change-color-pointer pad-5">
				<img src="/imgs/icons/person-plus-white.svg" class="esquerda icon-user">
				<p class='esquerda'>Usar cliente</p>
			</div>
		</div>
		<!-- <select class="custom-select mt-2">
			<option class='options' value="0">Lorem Ipsum</option>
		</select> -->
	</div>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from 'vuex';

export default {
	name: 'SelectProduct',
	methods: {
		...mapActions([
			'setShowSpearhead',
			'setShowBarRight',
			'selectClient',
			'changeclientname'
		]),
		button_client() {
			this.setShowSpearhead();
			this.setShowBarRight();
			this.selectClient();
			this.changeclientname();
		},
	},
}
</script>

<style>
	.pad-5 {
		padding: 5px;
	}
	.icon-user {
		border: 1px solid #afabab;
		background: #afabab;
		padding: 5px;
		border-radius: 4px;
		margin-right: 7px;
		width: 38px;
	}
</style>
