<template>
  <div class="col-md-12" style="background-color: #ecf0f1;" :id="`user_${data.extension}`">
    <div class="row pt-2">
      <div class="col-3 d-none d-md-block d-lg-block">
        <img
          v-if="data.photo != 'null'"
          :src="`/storage/${data.photo}`"
          alt="Imagem de perfil"
          class="rounded-circle"
               style="height: 57px;"
        />
        <img
          v-else
          src="/imgs/default-profile.png"
          alt="Imagem de perfil"
          class="rounded-circle"
          style="height: 57px;"
        />
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-12">
            <strong>{{data.name}}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
      <div :id="`rtInfo_${data.extension}`" style="font-size: 15px" class="row flex-nowrap realtimeItemOptions pt-1 pb-1">
        <div class="col-1">
          <i style="font-size: 20px" v-on:click="showOptions()" class="fas fa-chevron-circle-right cursor-pointer"></i>
        </div>
        <div class="col-7">
          <i class="fas fa-phone-alt pl-1"></i><span class="queuePhone"> Livre</span>
        </div>
        <div class="col-4">
          <i class="fas fa-clock pl-1"></i><span class="queueTime"> 00:00:00</span>
        </div>
      </div>
        <div :id="`rtOptions_${data.extension}`" class="row flex-nowrap realtimeItemOptions pt-1 pb-1" style="font-size: 15px" hidden>
            <div :id="`callOperator_${data.extension}`" class="col-1 ml-1 callOperator" title="Ligar">
          <i class="fas fa-phone fa-fw cursor-pointer"></i>
        </div>
          <div :id="`hangupOperator_${data.extension}`" class="col-1 ml-1 hangupOperator" title="Desligar">
          <i class="fas fa-phone-slash fa-fw cursor-pointer"></i>
        </div>
          <div :id="`catchCallOperator_${data.extension}`" class="col-1 ml-1 catchCallOperator" title="Puxar">
          <i class="fas fa-hand-rock fa-fw cursor-pointer"></i>
        </div>
          <div :id="`listenOperator_${data.extension}`" class="col-1 ml-1 listenOperator" title="Escutar">
          <i class="fas fa-headphones fa-fw cursor-pointer"></i>
        </div>
          <div :id="`spyOperator_${data.extension}`" class="col-1 ml-1 spyOperator" title="Sussurrar">
          <i class="fas fa-phone-volume fa-fw cursor-pointer"></i>
        </div>
          <div :id="`pauseOperator_${data.extension}`" class="col-1 ml-1 pauseOperator" title="Pausar">
          <i class="fas fa-pause fa-fw cursor-pointer"></i>
        </div>
          <div :id="`removeOperator_${data.extension}`" :data-queue='`${JSON.stringify(data.queue)}`' class="col-1 ml-1 removeOperator" title="Deslogar">
          <i class="fas fa-power-off cursor-pointer"></i>         
        </div>
           <div class="col-1 offset-2">
          <i style="font-size: 20px" v-on:click="showInfo()" class="fas fa-chevron-circle-left cursor-pointer"></i>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    },
  },
  mounted() {},
  methods:{
    showOptions: function(){
      $(`#rtInfo_${this.$props.data.extension}`).prop("hidden", true);
      $(`#rtOptions_${this.$props.data.extension}`).prop("hidden", false);
     
    },

      showInfo: function(){
      $(`#rtOptions_${this.$props.data.extension}`).prop("hidden", true);
      $(`#rtInfo_${this.$props.data.extension}`).prop("hidden", false);
     
    }
  }
};
</script>
