<template>
  <div class="background-modal align-modal" id="outModal" onkeypress="onClick">

      
      <div class="content">

        <div class="header"> 
          <div class="row header-row">
            <h4> Selecione o arquivo que deseja enviar</h4>
            <i class="fas fa-times" @click="dismissModal"></i>

          </div>

        </div>

        <hr/>

        <div class="body">

          <div class="card" v-for="arquivo in arquivos" :key="arquivo.id">
            <h2> {{ limitText(arquivo.name) }}</h2>
            <hr/>
            <p>
              O arquivo {{ arquivo.name }} foi adicionado {{ arquivo.created_at}}
            </p>
            <div class="row">
              <button class="green" @click.prevent="selectItem(arquivo.name, arquivo.path)">Selecionar</button>
            </div>
          </div>

        </div>

        <div class="footer">
          <!-- este é o footer -->
        </div>
      </div>
  </div>

</template>

<script>
export default {
  data(){
    return {
      show: true,
      arquivos: []
    }
  },
  created(){
     axios.get('/new-screen/show').then(data => this.arquivos = data.data)
  },
  mounted(){
    this.$el.addEventListener('click', this.onClick);
    document.addEventListener('keyup', this.onClick);

  },
  destroyed() {
    this.$el.removeEventListener('click', this.onClick);
    document.removeEventListener('keyup', this.onClick);
  },
  methods:{
    onClick(e){
      if(e.target.id == 'outModal'|| e.key == 'Escape') this.$emit('dismissModal');
    },
    selectItem(name, path){
      const url = `/new-screen//download/${path}`
      axios.get(url).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        this.$emit('sendSelectedFile', blob);
        this.$emit('dismissModal');
      }).catch((e)=> console.error(e));
    },
    dismissModal(){
      this.$emit('dismissModal');
    },
    limitText(text){
      return text.slice(0, 30) + (text.length > 29 ? '…' : '');
    }
  }

}
</script>

<style scoped>

.background-modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color:rgba(125,125,125, 0.5);
}

.align-modal{
  display:flex;
  align-items: center;
  justify-content: center;
}

.header-row{
  display: flex;
  justify-content: space-around;
}

.header-row i{
  cursor:pointer;

}

.content{
  background-color: #fff;
  height: 40em;
  width: 50em;
  border-radius: 0.25em;
  padding:2em;
  overflow: auto;
}

.header{
  text-transform: uppercase;
  line-height: 5;
}

.body{
  display:flex;
  flex-flow: row wrap;
  overflow: auto;
}
.card {
  background-color: white;
  min-width: 250px;
  max-width: 250px;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
  margin: 5px;
  border-radius: 10px;

}
.card h1, .card h2, .card h3, .card h4, .card h5 {
  margin: 0px;
  padding: 0px 0px 15px 0px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 30px;
  color: #282828;
}
.card hr {
  display: block;
  border: none;
  height: 3px;
  background-color: #f38630;
  margin: 0px;
  -webkit-animation-name: line-show;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.3s;
  /* Safari 4.0 - 8.0 */
  animation-name: line-show;
  animation-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  -o-transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  /* custom */
}
.card p {
  margin: 15px 0px 0px 0px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 100;
  letter-spacing: -0.25px;
  line-height: 1.25;
  font-size: 16px;
  word-break: break-all;
  word-wrap: pre-wrap;
  color: #282828;
  -webkit-animation-name: p-show;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s;
  /* Safari 4.0 - 8.0 */
  animation-name: p-show;
  animation-duration: 1.5s;
}
.card button {
  border: none;
  background-color: salmon;
  width: 55%;
  margin: 1em auto;
  padding: 1em;
  color: white;
  font-family: 'Noto Sans KR', sans-serif;
  text-transform: uppercase;
}
.green{
  background-color: green !important;
}
</style>