<template>
	<section>
		<div class='dropdown' >

			<div id='groupClient' class='mt-1'>

                <input id="myclients-input-dropdown" type="checkbox" checked>
                <label for="myclients-input-dropdown" class="animate">Clientes Receptivos <span class="attCount">{{arrayOperator.length}}</span></label>
                <ul id='myclients'>
					<section v-for="item in arrayOperator" :key="item.protocol" >
						<div class="myclientscreated">
							<li  class="animate" :class="item.key == selected ? 'selected' : ''" >
								<item-todas-mensagens
									class="custom-message p-2"
									@click.native="setCurrentConversa(item.key), selectConversa(item.key)"
									:avatar_url="item.avatar"
									:time="item.time.slice(-5)"
									:nome="item.nome"
									:notify="item.notify"
									:fila="item.fila"
									tipo="receptivo"
									:operador="item.operador"
									:prev_text="item.text_prev">
								</item-todas-mensagens>
							</li>
						</div>
					</section>
                </ul>
            </div>


			<div id='groupAtivos' class='mt-1'>

                <input id="myclientsativo-input-dropdown" type="checkbox" checked>
                <label for="myclientsativo-input-dropdown" class="animate">Clientes Ativos <span class="attCount">{{arrayActive.length}}</span></label>
                <ul id='myclientsativos'>
					<section v-for="item in arrayActive" :key="item.protocol" >
						<div class="myclientscreated">
							<li  class=" animate " :class="item.key == selected ? 'selected' : ''">
								<item-todas-mensagens
									class="custom-message p-2"
									@click.native="setCurrentConversa(item.key), selectConversa(item.key)"
									:avatar_url="item.avatar"
									:time="item.time.slice(-5)"
									:nome="item.nome"
									:notify="item.notify"
									fila="Ativo"
									tipo="ativo"
									:operador="item.operador"
									:prev_text="item.text_prev">
								</item-todas-mensagens>
							</li>
						</div>
					</section>
                </ul>
            </div>

			<div id="groupQueue" class='mt-1'>
                <input id="queueclients-input-dropdown" type="checkbox" checked>
                <label for="queueclients-input-dropdown" class="animate">Fila de Atendimento <span class="attCount">{{arrayQueue.length}}</span></label>
                <ul id='queueClients'>
					<section v-for="item in arrayQueue" :key="item.protocol" >

						<li  class=" animate ">
							<div v-if="item.operador == ''">
								<!-- <section v-if="index  == 0"> -->
								<section>
									<item-todas-mensagens
										class="custom-message p-2"
										@click.native="setCurrentConversa(item.key), selectConversa(item.key)"
										:avatar_url="item.avatar"
										:time="item.time.slice(-5)"
										:nome="item.nome"
										:notify="item.notify"
										:fila="item.fila"
										tipo="receptivo"
										:operador="item.operador"
										:prev_text="item.text_prev">
									</item-todas-mensagens>
								</section>
							</div>
						</li>
					</section>
                </ul>
            </div>

		</div>
	</section>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import ItemTodasMensagens from "./ItemTodasMensagens";
import notificationService from "../../../modules/notification"
const audio = new Audio('/sounds/button-09a.mp3');

export default {
	name: "TodasMenssagensChat",
	components: {ItemTodasMensagens},
	data: function() {
		return {
			test: 0,
			jabipado: {},
			mensagemFoiLida: {},
			selected: '',
			tempArrayOperator: [],
			tempArrayActive: [],
			tempArrayQueue: []
		}
	},
	computed: {
		...mapGetters(['getCurrentMensagens', 'getExtension', 'getConversasChat', 'getMessagesNotRead']),
		arrayOperator() {
			return this.tempArrayOperator.sort((a, b) => {
				if(a.time < b.time) {
					return 1;
				} else if(a.time > b.time) {
					return -1;
				} else {
					return 0;
				}
			});
		},
		arrayActive() {
			return this.tempArrayActive.sort((a, b) => {
				if(a.time < b.time) {
					return 1;
				} else if(a.time > b.time) {
					return -1;
				} else {
					return 0;
				}
			});
		},
		arrayQueue() {
			return this.tempArrayQueue.sort((a, b) => {
				if(a.created_at < b.created_at) {
					return -1;
				} else if(a.created_at > b.created_at) {
					return 1;
				} else {
					return 0;
				}
			});
		}
	},
	methods: {
		...mapMutations(['setCurrentConversa']),
		...mapActions(['joinQueue','newEntry','removeClient', 'operatorInUse','disconnect']),
		totalNewMessages(val) {
			if (val > 0) {
				window.document.title = '(' + val + ') Tela do Operador - Advance Management';
			} else {
				window.document.title = 'Tela do Operador - Advance Management';
			}
		},
		selectConversa(key) {
			this.selected = key;
		},	
		notifyNewMessages(val) {
			let total = val.length;
			let mandarAviso = false;
			let msgAindaAqui = {...this.mensagemFoiLida};
			val.forEach(chat => {
				if ((this.mensagemFoiLida[chat.client_id]) !== chat.msgs.length) {
					mandarAviso = true;
					this.mensagemFoiLida[chat.client_id] = chat.msgs.length;
				}
				if (this.mensagemFoiLida[chat.client_id] !== undefined) {
					delete msgAindaAqui[chat.client_id];
				}
			});

			// Object.keys(msgAindaAqui).forEach(clienteId => delete this.mensagemFoiLida[clienteId]);
			if (mandarAviso) {
				let title = ' Nova Conversa';
				if (total > 1) {
					title = ' Novas Conversas';
				}
				notificationService.notification(total + title, 'Você tem uma nova mensagem.');
			}
		},
		getChat(val) {
			this.tempArrayOperator = [];
			this.tempArrayActive = [];
			this.tempArrayQueue = [];
			if (val.length > 0) {
				val.forEach((el) => {
					if(el.operador != '' && el.fila != 'entrante') {
						this.tempArrayOperator.push(el);
					} else if(el.fila == 'entrante') {
						this.tempArrayActive.push(el);
					} else {
						this.tempArrayQueue.push(el);
					}
				});
			}
		}
	},
	watch: {
		getConversasChat: {
			handler(val) {
				if (val.length > 0) {
					val.forEach((el) => {
						const [key, value] = Object.entries(el)[0];
						if (value.operador === '' && this.jabipado[key] !== true) {
							audio.play();
							this.jabipado[key] = true
						} else if(value.operador !== '') {
							this.jabipado[key] = false;
						}
					});
				}
			},
			deep: true,
		},
		getCurrentMensagens: {
			handler(val) {
				this.getChat(val);
			}
		},
		getMessagesNotRead: {
			handler(val) {
				this.totalNewMessages(val.length);
				this.notifyNewMessages(val);
			},
			deep: true,
		},
	},
	mounted() {
		const x = this.getExtension() // o valor de x não importa, o que importa é o state do VUE
		this.joinQueue(x)
		this.newEntry();
		this.removeClient();
		this.disconnect(x);
		this.operatorInUse();
		this.getChat(this.getCurrentMensagens);
    }
}
</script>

<style scoped>

/* border-right: solid 2px #1C869A;
border-radius: 0% 2% 2% 0%; */
	.custom-message {
		cursor: pointer;
		padding: 0px !important;
	}

	*{
		padding: 0;
		margin: 0;
		font-family: 'Lato', sans-serif;
		box-sizing: border-box;
	}
	.float-right{
		float: right;
	}
	.fa{
		font-size: .8em;
		line-height: 22px !important;
	}
	.attCount{
		background-color: #599ca8;
    	padding: 3px 6px;
    	border-radius: 5px;
    	font-weight: bold;
		margin-left: 10px;
	}
	.dropdown{max-height: 450px}
	.dropdown label, .dropdown ul li{
		display: block;
		/* width: 200px; */
		background: #ECF0F1;
		padding: 8px 10px;
		margin: 2px;
		border: 1px solid lightgray
	}

	.dropdown label:hover, .dropdown ul li:hover{
		background: #1c869a;
		color: white;
		cursor: pointer;
	}

	.dropdown label{
		color: #1c869a;
		border-left: 4px solid #1c869a;
		border-radius: 0 5px 0 0;
		position: relative;
		z-index: 2;
	}

	.dropdown #myclients-input-dropdown{
		display: none;
	}
	.dropdown #queueclients-input-dropdown{
		display: none;
	}
	.dropdown #myclientsativo-input-dropdown{
		display: none;
	}

	input:checked + label{
		background: #1c869a;
		color: white;
	}

	#groupClient input ~ ul{
		position: relative;
		visibility: hidden;
		display:none;
		opacity: 0;
		top: -20px;
		z-index: 1;
	}

	#groupClient input:checked ~ ul{
		visibility: visible;
		display: block;
		opacity: 1;
		top: 0;
	}


	#groupQueue input ~ ul{
		position: relative;
		visibility: hidden;
		display:none;
		opacity: 0;
		top: -20px;
		z-index: 1;
	}

	#groupQueue input:checked ~ ul{
		visibility: visible;
		display: block;
		opacity: 1;
		top: 0;
	}

	#groupAtivos input ~ ul{
		position: relative;
		visibility: hidden;
		display:none;
		opacity: 0;
		top: -20px;
		z-index: 1;
	}

	#groupAtivos input:checked ~ ul{
		visibility: visible;
		display: block;
		opacity: 1;
		top: 0;
	}

	.animate{
		font-weight: bold;
		-webkit-transition: all .3s;
		-moz-transition: all .3s;
		-ms-transition: all .3s;
		-ms-transition: all .3s;
		transition: all .3s;
		backface-visibility:hidden;
		-webkit-backface-visibility:hidden; /* Chrome and Safari */
		-moz-backface-visibility:hidden; /* Firefox */
		-ms-backface-visibility:hidden; /* Internet Explorer */
	}
	.selected {
        background-color: #c6d6d9 !important;
        box-shadow: inset 0px 0px 5px 2px #1c869a;
    }
</style>