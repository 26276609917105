<template>
  <div id="loader">
    <img id="spinner" src="/imgs/loading.gif" alt="Carregando Sistema" title="Carreganto Sistema" />
  </div>
</template>

<script>
export default {
  created() {
    console.log("created");
    // loading indicator
    $("body").css("overflow", "hidden");
    $("#loader").fadeIn(200);
  },
  mounted() {
      console.log("mounted");
      $("#loader").fadeOut(200);
      $("body").css("overflow", "auto");

      $(document).on('beforeUnload', function(){
        $("body").css("overflow", "hidden");
        $("#loader").fadeIn(200);
      })
  }
};
</script>