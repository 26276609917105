<template>
  <section class="custom-chat" style="background: #efe7dd url('imgs/bg_wtt.jpg') repeat;" v-if="getTemCurrentConversa">
    <div class="mensagens" ref="msgContainer">
      <item-mensagen
        v-for="(msgItem, index) in getCurrentConversa"
        :key="'item-mensagen-' + index"
        :modal="'modal-' + index"
        :avatar="msgItem.avatar"
        :isProprio="msgItem.isProprio"
        :exibirData="msgItem.exibirData"
        :hora="msgItem.hora"
        :data="msgItem.data"
        :mensagem="msgItem.mensagem"
        @addContact="saveContact($event)"
      ></item-mensagen>
      {{ scrollMsg() }}
    </div>
    <div class="text-area">
      <textarea
        ref='textArea'
        class="form-control"
        v-model="msg"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="addCurrMsg()"
        @paste="fileToClipboard"
        name="mensagemNew"
        placeholder="Digite sua Mensagem"
      >
      </textarea>
      <button class="btn" id="send-button" title="Enviar mensagem" @click.prevent="addCurrMsg()"><i class="fas fa-paper-plane"></i></button>
      <button
        class="btn btn-primary button-send-absolut"
        type="button"
        @click.prevent="changeFile()"
      >
        <i class="fas fa-paperclip"></i>
      </button>
    <voice-recorder></voice-recorder>
		<button type="button" class="btn-emoji" @click="showEmojis">
			<svg class="btn-emoji-open" data-svg='true' style="max-height:18px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 106.059 106.059" fill="gray">
			<path d="M90.544 90.542c20.687-20.684 20.685-54.341.002-75.024-20.688-20.689-54.347-20.689-75.031-.006-20.688 20.687-20.686 54.346.002 75.034 20.682 20.684 54.341 20.684 75.027-.004zM21.302 21.3c17.494-17.493 45.959-17.495 63.457.002 17.494 17.494 17.492 45.963-.002 63.455-17.494 17.494-45.96 17.496-63.455.003-17.498-17.498-17.496-45.966 0-63.46zM27 69.865s-2.958-11.438 6.705-8.874c0 0 17.144 9.295 38.651 0 9.662-2.563 6.705 8.874 6.705 8.874C73.539 86.824 53.03 85.444 53.03 85.444S32.521 86.824 27 69.865zm6.24-31.194a6.202 6.202 0 1 1 12.399.001 6.202 6.202 0 0 1-12.399-.001zm28.117 0a6.202 6.202 0 1 1 12.403.001 6.202 6.202 0 0 1-12.403-.001z"></path>
			</svg>

		<div class='emoji-frame' v-if="emojis"> 
			<VEmojiPicker @select="selectEmoji" :i18n="i18n" />
		</div>
	  </button>
    
      <input
        type="file"
        name="sendFile"
        id="sendFile"
        @change="confirmFile()"
        accept=".doc,.docx, application/msword, application/zip, text/plain, application/pdf, image/jpeg, image/png, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ref="sendFile"
        hidden
      />
      <button
        class="btn btn-danger chatMensagem-button"
        type="button"
        @click.prevent="endCurrChat()"
      >
        Finalizar
      </button>
      <button
        class="btn transferir chatMensagem-button"
        type="button"
        @click.prevent="openModalTransf()"
      >
        Transferir
      </button>
      
    </div>

    <div class="modal-2" v-if="modal">
      <div class="modal-content-my">
        <form class="form-modal">
          <div class="modal-head">
            <h3 class="title-text">Arquivo selecionado</h3>
            <span @click="btnClose()" class="closeBtn">&times;</span>
          </div>
          <div>
            <p class="opcoes-filename">{{ filename }}</p>
          </div>
          <div>
            <button
              class="btn btn-success chatMensagem-button widthButton"
              type="button"
              @click.prevent="sendFile()"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>

    <component :is="componentName" v-on:dismissModal="dismissModal" v-on:sendSelectedFile="sendSelectedFile" v-on:sendTotextArea="sendTotextArea"/>


    <ModalTransferencia ref="modalTrans"/>
  </section>
  <div v-else>

    <div id="home" class="_3qlW9">
      <div class="_3BqNZ">
        <div data-asset-intro-image="true" class="_2Uo0Z " style="transform: scale(1); opacity: 1; background-image: url('imgs/advance-logo.png');"></div>
          <div class="_2MnNk" style="opacity: 1; transform: translateY(0px);">
            <h1 class="iHhHL">Advance Management</h1>
                <div class="_1Y6o1">
                  <div class="_3zFlu"></div>
                    <div class="_3cK8a">
                      <span data-icon="laptop" class="">
                        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 18" width="21" height="18">
                          <path fill="#9DA3A5" d="M10.426 14.235a.767.767 0 0 1-.765-.765c0-.421.344-.765.765-.765s.765.344.765.765-.344.765-.765.765zM4.309 3.529h12.235v8.412H4.309V3.529zm12.235 9.942c.841 0 1.522-.688 1.522-1.529l.008-8.412c0-.842-.689-1.53-1.53-1.53H4.309c-.841 0-1.53.688-1.53 1.529v8.412c0 .841.688 1.529 1.529 1.529H1.25c0 .842.688 1.53 1.529 1.53h15.294c.841 0 1.529-.688 1.529-1.529h-3.058z"></path>
                        </svg>
                      </span>
                      <div class="_1lrYJ">Adicione um contato para iniciar uma conversa.</div>
                    </div>
                  </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ItemMensagen from "./ItemMensagen";
import swal from "sweetalert";
import $ from "jquery";
import voiceRecorder from './VoiceRecorder.vue';
import ModalCatalogo from './ModalCatalogo.vue';

import ModalTransferencia from "./ModalTransferencia";

import { VEmojiPicker } from "v-emoji-picker";

window.$eventMessage = new Vue();

const dateTimeFormat = new Intl.DateTimeFormat("pt-br", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
});

const toBase64 = (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};

export default {
  name: "ChatMensagem",
  data: () => ({
    msg: "",
    options: "",
    componentName: '',
    modal: false,
	  emojis: false,
	  i18n: {
		  search: 'Pesquisar...',
		  categories: {
        Activity: "Atividades",
        Flags: "Bandeiras",
        Foods: "Comida",
        Frequently: "Frequentes",
        Objects: "Objetos",
        Nature: "Natureza",
        Peoples: "Pessoas",
        Symbols: "Símbolos",
        Places: "Locais"
		  }
	  }
  }),
  components: {
    ModalTransferencia,
    ModalCatalogo,
    voiceRecorder,
    VEmojiPicker,
    ItemMensagen,
    ...mapGetters(["getCurrentConversa", "getOptions", "getTokenChatClient"])
  },
  methods: {
    saveContact(data){
      this.$emit('addContact', data)
    },
    sendTotextArea(data){
      this.msg = data;
      this.$refs.textArea.focus();
    },
    setMsgRapida(){
      this.componentName = 'mensagem-rapida';
    },
    ...mapMutations([
      "addMensagemCurrentConversa",
      "endCurrentConversa",
      "transfCurrentConversa",
      "transfProcess",
    ]),
    ...mapActions(["transferOptions"]),
    selectEmoji(emoji) {
      this.emojis = false;
      this.msg += emoji.data;
      this.$refs.textArea.focus();
    },
    dismissModal(){
      this.componentName = '';
      
    },
    setMsgRapida(){
      this.componentName = 'mensagem-rapida';
    },
	  showEmojis(e){

      if(e.target.tagName == 'INPUT' || e.target.tagName == 'DIV') return;
      if(e.target.className == 'container-search' ) return;
      if(e.target.className.animVal == '') return;

      this.emojis = !this.emojis;

	  },
    async addMsg(txt = null, file = null, filename = null, typeFile = null) {
      this.emojis = false;

      const obj = {
        isProprio: true,
        data: dateTimeFormat.format(new Date())
      };
      if (file != null && filename != null && typeFile != null) {
        const reader = await toBase64(file);
        obj.file = reader;
        obj.filename = filename;
        obj.type = typeFile;
        this.addMensagemCurrentConversa(obj);
      } 
      else {
        obj.mensagem = `*${user.getName()}:*

${txt}`;

        this.addMensagemCurrentConversa(obj);
      }

      this.clearMsg();
      this.scrollMsg();
      
    },
    clearMsg() {
      this.msg = "";
    },
    scrollMsg() {
      try {
        requestAnimationFrame(() => {
          if (this.$refs.msgContainer) {
            return (this.$refs.msgContainer.scrollTop =
              this.$refs.msgContainer.scrollHeight);
          }
        });
      } catch {
        return;
      }
    },
    addCurrMsg() {
      this.addMsg(this.msg);
    },
    changeFile() {

      if(process.env.MIX_CATALOGO){
        this.componentName = 'modal-catalogo';
        return;
      }

      this.$refs.sendFile.click();
    },
    confirmFile() {
      try {
        this.filename = this.$refs.sendFile.files[0].name;
        var type = this.$refs.sendFile.files[0].type;
      } catch (err) {
        return;
      }
      var filesize = (this.$refs.sendFile.files[0].size / 1024 / 1024).toFixed(
        4
      ); // MB

      if (filesize > 10) {
        swal("Arquivo maior que quantidade permitida (10MB)", {
          icon: "warning",
        });

        return;
      }

      const formatos_aceitaveis = [
        "application/zip",
        "text/plain",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/webp",
        "image/jpeg",
        "image/png",
        "text/csv",
        "application/msword",
        'audio/wav',
        'audio/mpeg'
      ];
      
      if (!formatos_aceitaveis.includes(type)) {
        swal("Formato inválido", {
          icon: "warning",
        });
        return;
      }
      this.modal = true;
    },
    sendFile() {
      var file = this.$refs.sendFile.files[0];
      this.modal = false;
      this.addMsg(null, file, file.name, file.type);
    },
    sendSelectedFile(blob){
      const file =  new File([blob], "test.pdf",{
        type:  'application/pdf'
      });
      this.addMsg(null, file, file.name, file.type);
    },
    btnClose() {
      this.modal = false;
    },
    endCurrChat() {
        this.alertEndChat();
    },
    alertEndChat() {
      Swal.fire({
        title: "Finalizar atendimento",
        text: "Tem certeza que deseja finalizar o atendimento? Por favor certifique-se que o contato do cliente está salvo na base antes de encerrar este atendimento!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Finalizar",
        cancelButtonText: "Cancelar",
        onOpen: function () {
          $(".asd").select2({
            width: "100%",
          });
        },
      }).then((willDelete) => {
        if (willDelete.value) {
          var produto = $(".asd :selected")
            .map(function () {
              return $(this).val();
            })
            .get();
          $(".asd").val("-1").trigger("change");
          this.endCurrentConversa(produto);
          swal("Atendimento finalizado com sucesso", {
            icon: "success",
          });
        }
      });
    },
    transferir() {
      this.transfProcess();
    },

    openModalTransf() {
      this.$refs.modalTrans.openModal();
    },

    async fileToClipboard(e){
      const file = e.clipboardData.files;
      this.$refs.sendFile.files = file;
      this.confirmFile();
    },
  },
  computed: {
    ...mapGetters([
      "getTemCurrentConversa",
      "getCurrentConversa",
      "getCurrentConversaIndex",
    ]),
  },
  watch: {
    getCurrentConversaIndex() {
      if(typeof this.getCurrentConversaIndex === 'number'){
        this.scrollMsg();
        if(typeof this.getCurrentConversa === 'object' && this.getCurrentConversa.length > 0){
          if(this.getCurrentConversa[this.getCurrentConversa.length - 1].mensagem.includes('Para iniciar o atendimento, clique no botão abaixo')){
            this.first = true;
          } else {
            this.first = false;
          }
        }
      }
    },
    getTemCurrentConversa(){
      if(this.getTemCurrentConversa){
        this.clientName = Object.values(this.getTemCurrentConversa)[0].client
      }
    }
  },
  mounted() {
    this.transferOptions();

    $eventMessage.$on('sendMensagem', ( msg ) => {
      this.msg = msg;
    });
  }
};

</script>

<style>
._3qlW9 {
    align-items:center;
    -webkit-flex-grow:1;
    flex-grow:1;
    -webkit-justify-content:center;
    justify-content:center;
    /* z-index:1; */
    display:flex;
}

._3qlW9:after {
    bottom:0;
    height:0
}

._3qlW9 a {
    color:#07bc4c
}

._2Uo0Z {
    width:256px;
    height:255px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: fixed;
    background-position: 50% 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.iHhHL {
    color:#4b5961;
    font-size:36px;
    font-weight:300;
    text-align: center;
}

._1ClcF {
    font-size:14px;
    line-height:20px;
    text-align: center;
}

._3zFlu {
    height:1px;
    margin:20px 0px 20px 0px;
    background-color:#E1E9EB
}

._3cK8a {
    font-size:14px;
    line-height:20px;
    display:-webkit-inline-flex;
    display:inline-flex;
    -webkit-align-items:center;
    align-items:center
}

._1Y6o1 {
    display: table;
    margin: 0 auto;
}

._1lrYJ {
    margin-left:5px;
    margin-right:5px;
}

::-webkit-scrollbar {
    width:6px!important;
    height:6px!important
}

::-webkit-scrollbar-thumb {
    background-color:rgba(0,0,0,.2)
}
::-webkit-scrollbar-track {
    background:rgba(255,255,255,.08)
}

.select2-dropdown {
  z-index: 9001;
}

.transferir {
  background-color: #fcaf26;
}
.custom-chat {
  display: flex;
  flex-direction: column;
}

.text-area {
  height: 3em;
  display: flex;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 2em;
  margin-bottom: 0.5em;
}

.mensagens {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

</style>
<style scoped>
.form-control {
  border-radius: 0.5em 0.5em 0.5em 0.5em !important;
  margin-right: 1em;
  resize: none;
}

img {
  width: 130%;
}

.button-send-absolut {
  /* position: absolute; */
  right: 18.6em;
  bottom: 1em;
  border-radius: 50% !important;
  background-color: rgba(0, 0, 0, 0);
  color: #1c869a !important;
  border: none;
}

.chatMensagem-button {
  border-radius: 0.25em !important;
  margin-right: 0.25em;
}

.widthButton {
  width: 100%;
}

.btn-emoji {
  background: #ffff;
  border-color: rgba(127,127,127, 0.3);
	margin-right: 1em;
	border-radius: 0.25em !important; /* odeio esse importante */
	border-width:1px;
}

.btn-emoji:hover {
	background-color: rgba(127,127,127,0.2);;
	color: white;
}

.emoji-frame {
	  position: absolute;
	  z-index:1;
    margin-top: -420px;
    margin-left: -350px;
    background-color: #f6f6f6;
}

#msg-rapida-botao {
  font-size: 12px;
  width: 200px;
  margin: 0;
  border: 1px solid lightblue;
  font-weight: bold;
  color: #33839d;
  border-radius: 9px !important;
  margin-right: 10px;
  background-color: white;
}

#msg-rapida-botao:hover {
  background-color: rgb(91 154 175);
  color: white;
  transition: 0.2s;
}

#send-button {
  color: white;
  background-color: #1c869a;
  border-radius: 20px !important;
  padding: 0px 10px;
  height: 40px;
  align-self: center;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
}

#send-button:hover {
  color: #1c869a;
  background-color: #1c859a39;
  transition: 0.3s;
}
</style>
