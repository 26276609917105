<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="vlOperador">Selecione o Operador</label>
                    </div>
                    <select type="text" class="custom-select" name="at_user_id" id="vlOperador" disabled>
                        <option v-for="(account, key) in sel_usuarios" :key="key" :value="account.id">{{ account.name }} - {{ account.extension_line }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UsersComponent",
        data () {
            return {
                sel_usuarios: []
            }
        },
        methods: {
            //MONTAR O SELECT DE OPERADORES DO MODAL DE AGENDAMENTO
            mountOperators() {
                axios.get(`${window.location.origin}/user/list`, {})
                    .then(response => {
                        this.sel_usuarios = response.data;
                    }).catch(error => {
                    console.error(error)
                })
            },
        },
        mounted() {
            this.mountOperators();
        }
    }
</script>
