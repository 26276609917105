<template>
    <div id="tabs">
        <ul>
            <li><a href="#tabs-upload">Upload</a></li>
            <li><a href="#tabs-arquivos">Arquivos</a></li>
        </ul>


        <div class="row">

            <div class="col-md-12" id="tabs-upload">
                <catalogo-produtos  v-on:updateList="inc" />

            </div>

            <div class="col-md-12" id="tabs-arquivos">
                <catalogo-arquivos :upList="upList" />
            </div>
            
        </div>
    </div>

</template>

<script>
export default {
    data(){
        return{
            upList: 0
        }
    },
    methods:{
        inc(){
            this.upList++;
        }
    }

}
</script>

<style>

</style>