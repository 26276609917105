<template>
    <section>
        <wysiwyg v-model="message" placeholder="Digite aqui o texto"/>
        <textarea name="input_message" :value="message" :required="required" style="display: none"></textarea>
    </section>
</template>
<script>
export default {
    props: ['required'],
    data: function(){
        return {
            'message': ''
        }
    }
}
</script>