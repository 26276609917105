import axios from 'axios'
const state = {
    pauses: [],
    motivoPause: '',
    tempoFinal: '',
    tempoInicio: '',
    pause: false,
    pauseTimer: 'null'


}
const getters = {
    getPauses: state => state.pauses,
    getPause: state => state.pause,
    getTempoInicio: state => state.tempoInicio,
    getTempoFinal: state => state.tempoFinal,
    getMotivoPause: state => state.motivoPause,
    getPauseTime: state => state.pauseTimer,
}

const mutations = {
    allPauses: (state, pauses) => (state.pauses = pauses),
    setTempoInicio: (state, tempoInicio) => (state.tempoInicio = tempoInicio),
    setTempoFinal: (state, tempoFinal) => (state.tempoFinal = tempoFinal),

    setMotivoPause: (state, motivoPause) => (state.motivoPause = motivoPause),
    setQualAtemdimento: (state, qualAtendimento) => (state.qualAtendimento = qualAtendimento),
    setPause: (state, pause) => (state.pause = !state.pause),
    setPauseTimer: (state, pause) => (state.pauseTimer = pause),
}

const actions = {
    async setPauseTime({ commit }, data) {
        commit('setPauseTimer', data);
    },
    async fetchPauses({ commit }) {
        const response = await axios('/pauses-list');
        commit('allPauses', response.data);
    },
    async selectPause({ commit }, data) {
        commit('setPause');
        commit('setMotivoPause', data.pauseId);
        commit('setTempoInicio', data.tempo)
    },
    async comeBack({ commit }, data) {
        commit('setPause');
        commit('setMotivoPause', '');
        commit('setTempoFinal', data);
    },
    async sendPauseApi({ commit }, data) {
        await axios.post(`${location.origin}/api/setPause`, { userId: data.userId, pauseId: data.pauseId });
    },
    async sendUnpause({ commit }, data) {
        await axios.post(`${location.origin}/api/setPause`, { userId: data.userId, pauseId: 1 });
    }
}
export default {
    getters,
    mutations,
    actions,
    state
}
