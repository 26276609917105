<template>
    <div>
        <multiselect v-model="value" :options="options"></multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'


export default {
    components: { Multiselect },
    data(){
        return {
            value: null,
            options: ['um', 'dois', 'tres']
        }
    }
}
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>

</style>