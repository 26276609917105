<template>
  <div class="col-md-12 mb-3" style="background-color: #ecf0f1;" :id="`user_${data.id}`">
    <div class="row p-3">
      <div class="col-2 d-none d-md-block d-lg-block">
        <img
          v-if="data.photo != null"
          :src="`/storage/${data.photo}`"
          alt="Imagem de perfil"
          class="rounded-circle"
               style="width: 110%;"
        />
        <img
          v-else
          src="/imgs/default-profile.png"
          alt="Imagem de perfil"
          class="rounded-circle"
          style="width: 110%;"
        />
      </div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-12">
            <strong>{{ data.name }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    }
  },
  mounted() {}
};
</script>