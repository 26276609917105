/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./datatables');

// imported js
window.io = require('socket.io-client');
// populate.js
window.populate = require('populate.js');
// moment js
window.moment = require('moment');
window.moment.locale('pt-br');
import VueTelInput from 'vue-tel-input';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/widgets/autocomplete';
import 'vue-tel-input/dist/vue-tel-input.css';

window.Vue = require('vue');

// vue-wysiwyg
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
    hideModules: { 'image': true, 'code': true },
    image: {},
    maxHeight: "500px",
});

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTelInput);

// apex charts
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Vue Select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import vueWysiwyg from 'vue-wysiwyg';
// CommonJS
window.Swal = require('sweetalert2')

/**
 * import and use vuex
 */
import store from './store/index'

Vue.component('contacts', require('./components/newScreen/contacts/contacts.vue').default);


Vue.component('loading-indicator-component', require('./components/LoadingIndicatorComponent.vue').default);
Vue.component('home-component', require('./components/HomeComponent.vue').default);
// alerts
Vue.component('inline-alert-component', require('./components/alerts/InLine.vue').default);
// users box
Vue.component('default-user-box-component', require('./components/boxes/DefaultUserBox.vue').default);
Vue.component('user-realtime-box-component', require('./components/boxes/UserRealtimeBox.vue').default);
Vue.component('users-box-component', require('./components/boxes/Users.vue').default);
Vue.component('users2-box-component', require('./components/boxes/Users2.vue').default);
// inputs
Vue.component('start-end-date-component', require('./components/filters/StartEndDate.vue').default);
Vue.component('input-component', require('./components/filters/DefaultInput.vue').default);
Vue.component('multiselect-component', require('./components/filters/MultiSelect.vue').default);
Vue.component('group-extension-component', require('./components/filters/GroupExtension.vue').default);
Vue.component('select-component', require('./components/filters/DefaultSelect.vue').default);
Vue.component('texteditor-component', require('./components/Wysiwyg.vue').default);
Vue.component('textarea-component', require('./components/filters/Textarea.vue').default);
Vue.component('costumers-select-component', require('./components/filters/CostumerSelect.vue').default);
Vue.component('users-component', require('./components/users/UsersComponent').default);
Vue.component('multiselect-chat', require('./components/filters/MultiselectChat').default);
// charts
Vue.component('chart-bar-component', require('./components/charts/BarComponent.vue').default);
Vue.component('chart-multibar-component', require('./components/charts/MultiBarComponent.vue').default);
Vue.component('chart-donut-component', require('./components/charts/DonutComponent.vue').default);
Vue.component('chart-donut-satisfaction-component', require('./components/charts/DonutSatisfactionComponent.vue').default);
Vue.component('service-level-cards', require('./components/charts/ServiceLevelCards.vue').default);

// modals
Vue.component('modal-component', require('./components/modals/ModalComponent.vue').default);
//new screen menus
Vue.component('menu-esquerdo',require('./components/newScreen/MenuEsquerdo.vue').default);
Vue.component('barra-direito',require('./components/newScreen/BarraDireito.vue').default)
//escrever aguma coisa depois
Vue.component('ponta-de-lanca',require('./components/newScreen/PontaDeLanca.vue').default);
Vue.component('select-product',require('./components/newScreen/SelectProduct.vue').default)
Vue.component('button-push',require('./components/newScreen/ButtonPush.vue').default)
//redes sociais
Vue.component('redes-sociais',require('./components/newScreen/redesSociais/TodasRedesSociais.vue').default);
//modals new screen
Vue.component('modal-discar', require('./components/newScreen/modals/ModalDiscar.vue').default)
Vue.component('modal-agendamento', require('./components/newScreen/modals/ModalAgendamento.vue').default)
Vue.component('modal-pause', require('./components/newScreen/modals/ModalPause.vue').default)
//ocorrencias
Vue.component('todas-ocorrencias', require('./components/newScreen/ocorrencias/TodasOcorrencias.vue').default)
Vue.component('nova-ocorrencia', require('./components/newScreen/ocorrencias/NovaOcorrencia.vue').default)
Vue.component('historicos',require('./components/newScreen/ocorrencias/Historico.vue').default);
Vue.component('tableHistory',require('./components/newScreen/ocorrencias/tableHistory.vue').default);
Vue.component('callback',require('./components/newScreen/callback/IntroCallback.vue').default);

Vue.component('logModal',require('./components/newScreen/ocorrencias/logModal.vue').default);
Vue.component('infoModal',require('./components/newScreen/ocorrencias/infoModal.vue').default);
// components Realtime
Vue.component('realtime-app', require('./components/realtime/RealtimeApp').default);

Vue.component('marketing-messages-component', require('./components/marketing/ViewMessages.vue').default);
Vue.component('marketing-index', require('./components/marketing/MarketingIndex.vue').default);
Vue.component('marketing-auster', require('./components/marketing/MarketingAuster.vue').default);

Vue.component('status-campanhas', require('./components/marketing/StatusCampanhas.vue').default);
Vue.component('catalogo-produtos', require('./components/catalogo/CatalogoProdutos.vue').default);
Vue.component('catalogo-arquivos', require('./components/catalogo/CatalogoArquivos.vue').default);
Vue.component('catalogo-index', require('./components/catalogo/CatalogoIndex.vue').default);

Vue.component('costumers-index', require('./components/costumers/CostumersIndex.vue').default)
Vue.component('settings-index', require('./components/settings/SettingsIndex').default);
Vue.component('history', require('./components/history/History.vue').default);

Vue.component('dashboard-app', require('./components/dashboard/DashboardApp.vue').default);

Vue.component('dtModalUsers',require('./components/realtime/components/modalUsers.vue').default);
Vue.component('dtModalVisualizar',require('./components/realtime/components/modalVisualizar.vue').default);
Vue.component('dtModalVisualizarUra',require('./components/realtime/components/modalVisualizarUra.vue').default);
Vue.component('dtModalVisualizarFila',require('./components/realtime/components/modalVisualizarFila.vue').default);

new Vue({
    el: '#app',
    store,
});
