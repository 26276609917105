<template>
  <b-container>
    <b-row>
        <b-col cols="8">
        <div class="filters">
          <label for="city-search">Cidade:</label>
          <v-select id="city-search" :options="cities" v-model="citySelected">
            <!-- <template #no-options="{ search, searching, loading }"> -->
            <template #no-options="{ }">
              Nenhuma cidade encontrada.
            </template>
          </v-select>
        </div>

        <b-table striped hover sticky-header head-variant="dark" :items="items" :fields="fields">
          <template v-slot:cell(action)="data">
            <b-button v-show="!!data.item.check === true" variant="danger" @click="delItem(data)">Remover</b-button>
            <b-button v-show="!!data.item.check === false" variant="primary" @click="addItem(data)">Adicionar</b-button>
          </template>
        </b-table>

        <div class="total-count">
          Total: <b>{{totalChecked}}</b> contato<span v-show="totalChecked > 1">s</span> adicionado<span v-show="totalChecked > 1">s</span>.
        </div>

        <div class="add-or-remove-all">
          <b-button variant="primary" @click="addAll()">Adicionar todos</b-button>
          <b-button variant="danger" @click="delAll()">Remover todos</b-button>
        </div>

        <div class="submit-form-container">
          <b-button class="submit-button" v-show="totalChecked > 0 && mensagem" @click="submitForm">Enviar Mensagem</b-button>
        </div>

      </b-col>
      <b-col cols="4">

        <label for="input-message">Mensagem:</label>

        <div id='container-campanha mt-2' v-for="index in elementos" :key="index">

          <b-form-textarea
            :id="`input-message_${index}`"
            size="lg"
            placeholder="Digite a mensagem aqui."
            rows="5"
            v-model="mensagem[index]"
            @keyup="keyup"
          ></b-form-textarea>

          <b-form-file
            class="c-file"
            v-model="file"
            accept=".jpg, .jfif, .jpeg, .png, .gif, .pdf"
            :state="Boolean(file)"
            placeholder="Escolha a Imagem ou PDF..."
            drop-placeholder="Arraste para aqui..."
            browse-text="Procurar"
          ></b-form-file>
          <div class="mt-1">{{ file ? 'Arquivo selecionado: ' + file.name : '' }}</div>
        </div>

        <!-- <div class="clearfix" cols="3">
          <b-img v-show="srcImage" left :src="srcImage" fluid alt="Fluid image"></b-img>
        </div> -->

        <b-row class="float-right">
          <b-col >
            <b-button @click="decreaseMsg"> - </b-button>
            <b-button @click="increaseMsg"> + </b-button>
          </b-col>
        </b-row>

      </b-col>
    
    </b-row>
  </b-container>

</template>


<script>
const { io } = require("socket.io-client");
// const socket = io(`${location.protocol}//${location.hostname}:4000`);
const socket = { 'on': function(){}, 'emit': function(){} };


export default {

  props: {
    wallets: {
      type: Array,
      default: []
    },
    cities: {
      type: Array,
      default: []
    }
  },
  data() {
    return  {
      at_wallets: this.wallets,
      citySelected: null,
      elementos: 1,
      items: [],
      fields: [
          {
            key: 'action',
            label: 'Ação'
          },
          {
            key: 'name',
            label: 'Nome',
            sortable: true
          },
          {
            key: 'phone',
            label: 'Telefone',
            sortable: true
          },
          {
            key: 'city',
            label: 'Cidade',
            sortable: true
          },
        ],
      mensagem: [],
      srcImage: null,
      file: null
    }
  },
  methods: {
    decreaseMsg(){
      if(this.elementos == 1) return;
      this.elementos--;
    },
    increaseMsg(){
      if(this.elementos > 3) return;
      this.elementos++
    },
    keyup(e){
      const id = e.target.id.split('_')[1] -1 ;
      this.$emit('updateText', id, e.target.value);
    },
    addItem (data) {
      data.item.check = true
    },
    delItem (data) {
      data.item.check = false
    },
    addAll () {
      this.items.forEach(item => {
        item.check = true
      })
    },
    delAll () {
      this.items.forEach(item => {
        item.check = false
      })
    },
    submitForm () {
	    let obj = {
        mensagem: this.mensagem,
        media: {
          type:  this.file ? this.file.type : null,
          filename: this.file ? this.file.name : null,
          file: this.srcImage ? this.srcImage : null
        },
        telefones: []
      }
      const id = Date.now();

      this.at_wallets.forEach(item => {
        if(item.check) {
          const { name, phone } = item
          let user = {name, phone, id}
          obj.telefones.push(user)
        }
      });

	  try{

		  if(obj.media.file.match(/,(.+)$/)){
			  obj.media.file = obj.media.file.match(/,(.+)$/)[1];
		  }
	  }catch{
		  delete obj.media;
	  }
    // FAZER POST:
    socket.emit('startCampaing', obj, (data) => {
		  console.info('Callback:', data);
    })

	  Swal.fire(
    	'Campanha Iniciada',
    	'Os clientes selecionados vão receber a mensagem nos próximos minutos',
		  'success'
    ).then((confirm) => {
		  location.reload();
	  })


    }
  },
  computed: {
    totalChecked () {
      let total = 0
      this.wallets.forEach(el => {
        if(!!el.check) total++
      });
      return total
    }
  },
  watch: {
    citySelected (value) {
      if(!!value) {
        let newItems = []
        this.at_wallets.forEach(item => {
          if(item.city === value) {
            newItems.push(item)
          }
        })
        this.items = newItems
      } else {
        this.items = this.at_wallets
      }
    },
    file () {
      if(!!this.file && this.file.type == 'application/pdf' || this.file.type == 'image/jpeg' || this.file.type == 'image/png' || this.file.type == 'image/gif') {
        let reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onloadend = () => {
          this.srcImage = reader.result
          this.$emit('addImage', this.srcImage);
        }
      } else {
        this.file = null
        this.srcImage = null
        Swal.fire({
            type: 'error',
            text: 'O arquivo selecionado não é válido. Escolha uma Imagem (jpeg, jpg, png e gif) ou PDF.'
        })
      }
    }
  },
  mounted () {
    this.items = this.at_wallets
  }
}

</script>

<style scoped>
  .total-count {
    margin-bottom: 12px;
  }
  .filters {
    margin-bottom: 12px;
  }
  .c-file {
    margin-top: 12px;
  }
  .submit-form-container {
    margin-top: 12px;
  }
  .submit-button {
    margin-top: 12px;
    color: #fff;
    background: #25d366;
    width: 180px;
    border: none;
  }
</style>
