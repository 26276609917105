<template>
  <div>
      
        <div class="col">
            <h3>Campanha Pape Santander</h3>
            <table id="relAuster" ref='tabela' class="size-tabela table display table-hover table-striped">
                <thead></thead>
                <tbody></tbody>
                <tfoot></tfoot>
            </table>
        </div>
        
  </div>
</template>

<script>
export default {
    props:{
        filter: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            dt: null,
            val: this.filter
        }
    },
    watch:{
        filter: {
            handler: function(val){
                this.createTable()
            },
            deep: true
        }
    },
    methods:{
        createTable(){

            if (this.dt){
                $('#relAuster').DataTable().destroy();
            }

            console.log('filter', this.filter);

            this.dt = $('#relAuster').DataTable({
                ajax:{
                    url: '/marketing/covidAuster',
                    dataSrc: ''
                },
                columns: [{
                    data: 'cliente',
                    title: 'Cliente',
                    class: 'text-center',
                },
                {
                    data: 'covid',
                    title: 'Você teve Covid?',
                    class: 'text-center',
                    render: data => (data == 1) ? 'Sim' : 'Não'
                },
                {
                    data: 'sintomasFortes',
                    class: 'text-center',
                    title: 'Você teve sintomas?',
                    render: data => {
                        
                        if(data == null) return '-';
                        
                        const translate = {
                            '1': 'Fracos',
                            '2': 'Fortes',
                            '3': 'Sem Sintomas'
                        }
                        
                        return translate[data];
                    }
                },  
                {
                    data: "primeiraVez",
                    class: 'text-center',
                    title: 'Você esteve internado por covid?',
                    render: data => (data == 1) ? 'Sim' : 'Não'
                },
                {
                    data: "quandoTeve",
                    class: 'text-center',
                    title: 'Você tem alguma dificuldade em relação à Covid? (escreva em uma mensagem única)',
                    render: (data) => {
                        return data;
                    }
                }]
            })
        }
    },
    mounted(){
        this.createTable();

    }

}
</script>

<style scoped>
.size-tabela{
    width: 700px;
}

</style>