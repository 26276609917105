const state = {
    data: {},
    keys: {
        '0': 'Protocolo',
        '1': 'Data1',
        '2': 'Registro',
        '3': 'Nome',
        '4': 'Data',
        '5': 'Idade',
        '6': 'CPF',
        '7': 'Convenio',
        '8': 'CEP',
        '9': 'Endereco',
        '10': 'EnderecoNum',
        '11': 'Cidade'
    }
}

const getters = {
    getDataTransduson(state) {
        return state.data;
    }
}

const actions = {}

const mutations = {
    async setDataTransduson(data) {
        const dataEvent = data.split('||');
        const dataLength = dataEvent.length;
        let dtToState = {
            protocol: "",
            queue: "",
            name: "",
            phone: "",
            cpf: "",
            type: 'phone'
        }

        switch (dataLength) {
            case 2:
                dtToState = {
                    protocol: "",
                    queue: dataEvent[1],
                    name: dataEvent[0],
                    phone: dataEvent[0],
                    cpf: "",
                    type: 'phone'
                }
            break;

            case 3:
                dtToState = {
                    protocol: "",
                    queue: dataEvent[1],
                    name: dataEvent[0],
                    phone: dataEvent[0],
                    cpf: "",
                    type: 'phone'
                }
            break;

            case 4:
                dtToState = {
                    protocol: dataEvent[0],
                    queue: dataEvent[2],
                    name: dataEvent[1],
                    phone: dataEvent[1],
                    cpf: "",
                    type: 'phone'
                }
            break;

            case 5:
                dtToState = {
                    protocol: dataEvent[2],
                    queue: dataEvent[0],
                    name: dataEvent[1],
                    phone: dataEvent[1],
                    cpf: dataEvent[4],
                    type: 'phone'
                }
            break;
        }

        state.data = dtToState;
        return state.data;
    },

    async delDataTransduson() {
        state.data = {}
    },

    async setChatData(data) {
        let obj = {
            Protocolo: data.protocol,
            phone: data.phone,
            type: 'chat',
            name: data.name,
            queue: data.queue
        };
        
        state.data = obj;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
