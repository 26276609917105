<template>
    <b-modal id="bv-modal-users" size="lg" title="Detalhes do Operador" scrollable centered hide-footer class="p-1">
      <div class="modal-log" v-if="getData()">
  
      <fieldset class="made-up">
          <legend> {{ getData().user }}</legend>

          <div class="row">
              <div class="col-md-6" v-if="getData().queueChat">
                  <label for="queueChat"><strong>Fila</strong></label>
                  <div class="form-control mb-2" v-for="queue in getData().queueChat">
                        {{ queue }}
                  </div>
              </div>

              <div class="col-md-6" v-if="getData().opcoes.fila">
                  <label for="fila"><strong>Status</strong></label>
                  <div class="form-control mb-2" v-for="fila in getData().opcoes.fila">
                        <b-badge :variant="getStatus(fila).variant">{{ getStatus(fila).txt }}</b-badge>
                  </div>
              </div>
          </div>

      </fieldset>
  
      </div>
    </b-modal>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  
  export default {
    data:() => ({
        dataModal: []
    }),
    computed:{
      ...mapGetters(['getlogchat','getlogInfo'])
    },
    methods:{
      openModal() {                
          this.$bvModal.show('bv-modal-users');
      },
      getStatus(stt) {
        if (stt === 'c_deslogado') {
            return {txt: 'Deslogado', variant: 'danger'};
        }
        if (stt === 'a_em_atendimento') {
            return {txt: 'Em Atendimento', variant: 'warning'};
        }
        if (stt === 'b_ativo') {
            return {txt: 'Disponível', variant: 'primary'};
        }
      },
      getData(){
        return this.getlogchat.find(o => o.opcoes.ramal == this.getlogInfo.ramal);        
      }
    }
  }
  </script>
  
  <style scoped>
      .modal-log{
          overflow-y: auto;
          overflow-x: hidden;
      }
  
      .modal-log .espcForm{
          margin: 5px 0px 0px 0px;
      }
  
      .modal-log label, input, textarea{
          font-size: 12px;
      }
      .made-up{
          font-family: arial;
          margin: 10px;
          border-radius: 10px;
          background: #fff;
          border: solid 1px #9797A6;
          border-radius: 2px;
          padding: 10px;
      }
  
      .made-up fieldset{
          margin: 5px 30px;
      }
  
      .made-up legend{
          background: #1C869A; 
          color: #FEFEFE;
          margin: 0px;
          padding: 5px 20px;
          width: auto;
          font-size: 14px;
          border-radius: 2px;
      }
  
      .avoid-page-break {
        page-break-inside: avoid;
      }
  </style>