<template>
  <div class="card my-3 px-3 py-3">
    <h5 style="color: #000; align-self: center">{{ title }}</h5>
    <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  props: ['title', 'values', 'categories'],
  data: function() {
    return {
      chartOptions: {
        chart: {
          id: "donutchart"
        },
        dataLabels: {
          dropShadow: {
            enabled: false
          }
        },
        colors: ['#f26143', '#F2A687', '#daa520', '#90ee90', '#27ae60'],
        labels: this.categories,
        noData: {
            text: 'Sem dados para exibir no momento...'
        },
      },
      series: this.values
    };
  }
};
</script>