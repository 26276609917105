<template>
  <b-modal id="bv-modal-msgs" size="lg" title="Conversa" scrollable centered>
    <itemMessage
      v-for="(msgItem, index) in fulllog.msgs"
      :key="'item-mensagen-' + index"
      :avatar="fulllog.meuAvatar"
      :is-proprio="msgItem.type"
      :exibirData="msgItem.exibirData"
      :data="msgItem.data"
      :hora="msgItem.hora"
      :mensagem="msgItem.mensagem"
    ></itemMessage>

    <template #modal-footer>
      <div class="w-100">
        <b-button variant="danger" class="float-right" @click="downloadPdf()">
          Baixar PDF
        </b-button>
      </div>
    </template>

    <VueHtml2pdf
      class="d-none"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :filename="mensagens"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <itemMessage
          class="avoid-page-break"
          v-for="(msgItem, index) in fulllog.msgs"
          :key="'item-mensagen-' + index"
          :avatar="fulllog.meuAvatar"
          :is-proprio="msgItem.type"
          :exibirData="msgItem.exibirData"
          :data="msgItem.data"
          :hora="msgItem.hora"
          :mensagem="msgItem.mensagem"
        ></itemMessage>
      </section>
    </VueHtml2pdf>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import itemMessage from "../mensagem/ItemMensagen";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    itemMessage,
    VueHtml2pdf,
  },

  data: () => ({
    fulllog: [],
    mensagens:
      "mensagens_" + window.moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
  }),

  computed: {
    ...mapGetters(["getlogchat"]),
  },

  methods: {
    openModal() {
      this.$bvModal.show("bv-modal-msgs");
      this.fulllog = this.getlogchat;
    },

    downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.modal-log {
  position: relative;
  margin: 10% auto;
  max-height: 50em;
  padding: 30px;
  max-width: 60em;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  overflow-y: auto;
}

.avoid-page-break {
  page-break-inside: avoid;
}
</style>