<template>
	<section class="custom-item-mensagem">
		<div class="exibirData" v-if="exibirData">
			<div class="exibirDataText">{{ exibirData }}</div>
		</div>

		<div class="myMessage" :class="isProprio ? isProprio : 'sistema'">
			<div class="avatar">
				<img :src="getProperavatar(isProprio)" alt="">
			</div>

			<div class="formatImg" style="cursor:pointer;" v-if="mensagem.substring(0,4) == '<img'">
				<div class="texto" v-html="mensagem" v-on:click="verImagem(mensagem)"></div>
				<div class="imgShadow"></div>
				<div class="date">{{ hora }}</div>
			</div>

			<div class="formatDoc" style="cursor:pointer;" v-else-if="mensagem.substring(0,4) == '<a h'">
				<div class="icon-doc-doc" v-if="verExtension(mensagem, 'doc') == true"></div>
				<div class="icon-doc-pdf" v-else-if="verExtension(mensagem, 'pdf') == true"></div>
				<div class="icon-doc-xls" v-else-if="verExtension(mensagem, 'xls') == true"></div>
				<div class="icon-doc-txt" v-else-if="verExtension(mensagem, 'txt') == true"></div>
				<div class="icon-doc-ppt" v-else-if="verExtension(mensagem, 'ppt') == true"></div>
				<div class="icon-doc-generic" v-else></div>
				<div class="texto" v-html="mensagem"></div>
				<div class="date">{{ hora }}</div>
			</div>

			<div v-else-if="mensagem.includes('WhatsappContacts: ')">
				<div v-for="contact in getContacts(mensagem)" :key="contact.phone" class="whatsappContacts">
					<span class="contactName">👤  {{contact.name}}</span>
					<span class="contactPhone">📞  {{formTelefone(contact.phone)}}</span>
					<button class="btn btn-primary form-control contactAdd" @click.prevent="saveContact(contact)">➕  Adicionar contato</button>
					<div class="date">{{ hora }}</div>
				</div>
			</div>

			<div class="mensagem" v-else>
				<div class="texto" v-html="formatMessage(mensagem)"></div>
				<div class="date">{{ hora }}</div>
			</div>

		</div>
		<modal v-if="mensagem.substring(0,4) == '<img'" height="auto" width="800px" style="max-height: 600px; vertical-align: middle; text-align: center;" :name="imagemModal">
			<div class="imagemPreview" v-html="imagemModal"></div>
		</modal>

	</section>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import axios from "axios";
const instance = axios.create();
instance.defaults.timeout = 30000;

import VModal from "vue-js-modal";
Vue.use(VModal);

export default {
  name: "ItemMensagen",
  props: [
    "modal",
    "avatar",
    "mensagem",
    "exibirData",
    "data",
    "hora",
    "isProprio",
    "idOperador",
  ],
  data() {
    return {
      imagemModal: "",
    };
  },
  async created() {
    await this.getOp(this.idOperador);
  },
  computed: {
    ...mapGetters(["getUserPhotoOperator"]),
  },
  methods: {
    ...mapMutations(["setPhotoOperator"]),
    async imgOperator(id) {
      return new Promise(async (resolve) => {
        await instance
          .get(`/user/${id}`)
          .then((response) => {
            if (response.data.photo) {
              return resolve(`storage/${response.data.photo}`);
            }
            resolve(false);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    getContacts(contacts) {
      let contactsString = contacts.replace("WhatsappContacts: ", "");
      return new Function("return [" + contactsString + "];")();
    },
    saveContact(contact) {
      this.$emit("addContact", contact);
    },
    formatMessage(msg) {
      const result_asterisco = this.formatTextWpp(msg);

      return result_asterisco.replaceAll("\n", "<br/>");
    },
    formatTextWpp(text) {
      return text
        .replace(
          /(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g,
          '<span style="font-weight: bold;">$1</span>'
        )
        .replace(
          /(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,
          '<span style="font-style: italic;">$1</span>'
        )
        .replace(
          /(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,
          '<span style="text-decoration: line-through;">$1</span>'
        )
        .replace(
          /(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,
          '<span style="text-decoration: underline;">$1</span>'
        )
        .replace(
          /(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,
          "<span style=\"font-family:'Lucida Console', monospace\">$1</span>"
        );
    },
    formTelefone(tel, typePhone = false) {
      if (typePhone) return tel;
      if (tel.substr(0, 2) != "55") return `+${tel}`;

      if (tel.length == 10 || tel.length == 11) {
        const v1 = tel.replace(/\D/g, "");
        const v2 = v1.replace(/^(\d{2})(\d)/g, "($1) $2");
        const v3 = v2.replace(/(\d)(\d{4})$/, "$1-$2");

        return v3;
      }
      tel = tel.replace(/\D/g, "");
      tel = tel.replace(/^(\d)/, "+$1");
      tel = tel.replace(/(.{3})(\d)/, "$1($2");
      tel = tel.replace(/(.{6})(\d)/, "$1)$2");
      if (tel.length == 12) {
        tel = tel.replace(/(.{1})$/, "-$1");
      } else if (tel.length == 13) {
        tel = tel.replace(/(.{2})$/, "-$1");
      } else if (tel.length == 14) {
        tel = tel.replace(/(.{3})$/, "-$1");
      } else if (tel.length == 15) {
        tel = tel.replace(/(.{4})$/, "-$1");
      } else if (tel.length > 15) {
        tel = tel.replace(/(.{4})$/, "-$1");
      }
      tel = tel.replace("(", " (");
      tel = tel.replace(")", ") ");

      return tel;
    },
    async getOp(idOperador) {
      if (!this.getUserPhotoOperator[idOperador]) {
        let obj = {};

        //Seta a Imagem default
        obj[`${idOperador}`] = "/imgs/default-profile.png";
        this.setPhotoOperator(obj);

        //Seta a Imagem do Operador
        const imgOp = await this.imgOperator(idOperador);
        obj[`${idOperador}`] = imgOp ? imgOp : "/imgs/default-profile.png";
        this.setPhotoOperator(obj);
      }
    },
    getProperavatar(type) {
      this.getOp(this.idOperador);

      return type == "client"
        ? this.avatar
        : this.getUserPhotoOperator[this.idOperador];
    },
    verImagem(img) {
      const self = this;

      let regex = /<img.*?src='(.*?)'/;
      let iModal = regex.exec(img)[1];

      this.imagemModal =
        '<img style="padding: 5px; width: auto; height: auto; max-width: 800px; max-height: 600px;" src="' +
        iModal +
        '">';
      this.$nextTick(function () {
        self.$modal.show(this.imagemModal);
      });
    },
    verExtension(img, ext) {
      let reg = new RegExp(/>(.*)<\/a>/gi);
      const extension = reg.exec(img);

      const ret = extension[1].split(ext).length;
      if (ret > 1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>


<style scoped>
.custom-item-mensagem {
  width: 100%;
  display: inline-block;
}
.exibirData {
  margin: 20px 0px;
  text-align: center;
  align-items: center;
  font-weight: bold;
  display: block;
}

.exibirData .exibirDataText {
  background-color: #ffffff;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.2);
  padding: 2px 40px;
  width: 200px;
  text-align: center;
  align-items: center;
  display: inline-block;
}

.currentColor {
  background-color: #ffffff;
}

.myMessage {
  display: flex;
  max-width: 540px;
  min-width: 120px;
  margin: 5px 20px;
}
.avatar img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: solid 1px lightgray;
}
.avatar {
  margin: 10px;
}
.mensagem {
  flex: 1;
  padding: 8px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.2);
  margin: 0px;
  position: relative;
}

.formatDoc {
  padding: 8px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.2);
  margin: 0px;
  position: relative;
}

.formatDoc .date {
  color: rgb(40, 40, 40);
  font-size: 11px;
  text-align: right;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.client .formatDoc {
  background-color: #ffffff;
  text-align: left;
  border-radius: 0px 8px 8px 8px;
}

.sistema .formatDoc {
  background-color: #d1f4cc;
  text-align: left;
  border-radius: 8px 0px 8px 8px;
}

.operator .formatDoc {
  background-color: #d1f4cc;
  text-align: left;
  border-radius: 8px 0px 8px 8px;
}

.formatDoc .date {
  color: rgb(0, 0, 0);
  font-size: 11px;
  text-align: right;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.formatImg {
  padding: 8px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.2);
  margin: 0px;
  position: relative;
}

.formatImg .date {
  color: rgb(40, 40, 40);
  font-size: 11px;
  text-align: right;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.mensagem .date {
  color: rgb(40, 40, 40);
  font-size: 11px;
  text-align: right;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.mensagem .texto {
  padding-bottom: 20px;
}

.formatImg .texto {
  padding-bottom: 20px;
}
.formatDoc .texto {
  padding-bottom: 20px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 10px;
  margin-left: 10px;
  overflow-x: hidden;
  min-width: 150px;
  max-width: 250px;
}
.client {
  margin-right: auto;
  margin-left: 0;
  float: left;
}
.client .mensagem {
  background-color: #ffffff;
  text-align: left;
  border-radius: 0px 8px 8px 8px;
}

.operator {
  margin-left: auto;
  margin-right: 0;
  float: right;
}

.operator .mensagem {
  background-color: #d1f4cc;
  text-align: right;
  border-radius: 8px 0px 8px 8px;
}
.operator .avatar {
  order: 2;
}

.sistema {
  margin-left: auto;
  margin-right: 0;
  float: right;
}
.sistema .mensagem {
  background-color: #d1f4cc;
  text-align: right;
  border-radius: 8px 0px 8px 8px;
}
.sistema .avatar {
  order: 2;
}

@media screen and (max-width: 1024px) {
  .custom-item-mensagem {
    display: flex;
    width: 340px;
    padding: 20px 20px 5px;
  }
}

.client .formatImg {
  background-color: #ffffff;
  text-align: left;
  border-radius: 0px 8px 8px 8px;
}

.client .formatImg .date {
  color: rgb(255, 255, 255);
  font-size: 11px;
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.client .formatImg .imgShadow {
  height: 30px;
  background-image: linear-gradient(
    to top,
    rgba(11, 20, 26, 0.5),
    rgba(11, 20, 26, 0)
  );
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}

.client .formatImg .texto {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: 0px 8px 8px 8px;
}

.sistema .formatImg {
  background-color: #d1f4cc;
  text-align: left;
  border-radius: 8px 0px 8px 8px;
}

.sistema .formatImg .date {
  color: rgb(255, 255, 255);
  font-size: 11px;
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.sistema .formatImg .imgShadow {
  height: 30px;
  background-image: linear-gradient(
    to top,
    rgba(11, 20, 26, 0.5),
    rgba(11, 20, 26, 0)
  );
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}

.sistema .formatImg .texto {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: 8px 0px 8px 8px;
}

.operator .formatImg {
  background-color: #d1f4cc;
  text-align: left;
  border-radius: 8px 0px 8px 8px;
}

.operator .formatImg .date {
  color: rgb(255, 255, 255);
  font-size: 11px;
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.operator .formatImg .imgShadow {
  height: 30px;
  background-image: linear-gradient(
    to top,
    rgba(11, 20, 26, 0.5),
    rgba(11, 20, 26, 0)
  );
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}

.operator .formatImg .texto {
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: 8px 0px 8px 8px;
}

.icon-doc-generic {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAYAAADL94L/AAAByElEQVR4Ae3axdJTQRAFYFyegA3u8ALseCDcicsGhxt3x+G32BXc3X3NBnfXYTqp3sZlhuqpOlXZRL46He9ReJyJxGSTEreaPfEHZiX+1uSJvelVNu+Jvjd7Yk9zI8aSUe0eDpjCIYfNSuw5v/zF5In/6mU27478tXriLJvXjdSwPq1lCDTCmxjiCNav8GZYBVMwWKagX8kWjk9vCcMhYWhEFEw1+oV0wZjdPKY6Vn9EwmBDTYPwBoXCYPLGDQTJjkHQNQRJj0FQtmgs+C8wOHIIkh2DoDu5vD5Xfkz9hsTBWDyxhjDYUDqvLRYSY1JilSQGyyxXOt4QKJPX70NDQmI27gyxHcn9bH/5RFMNAUgoDI4afOAMHBiCdiDNj5woGAhgsCEYudSI1lBCRwoPL957slAoDDYEoPXb/ZVs3FE/y9072fDxsx4BMPVfGOpl1VY/y5++4EWM1Fm9LcCKpy8RpnchDGEIQxjCEIYwhCEMYQhDGMIQhjCEIQxhCEMYwhCGMIQhDGEIQxhYNlXiP+XHXLRDM5thQVpyzIfS2YtLceVEkRmzalsgMArPhp258bA6b/LEb8LqPM930VNdvY/fhMmCxw+Of+4BTcPInBo2AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 26px;
  float: left;
}
.icon-doc-doc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAulBMVEUAAACPw/ePw/lgqvVfqvaUx/mQxPhfqfWjz/lfqfVfqvZgp/9cp/ZfqvZgqvaayPpTpPVfqvVjpvNgqfaQw/mjzvq42PdfqvZgqvZiq/ZwsveRxflkqe9rsPdcqPZeqPRorvfN5fzw9/5bp/Z2tvdZoOqTxvqCuvPj8P7+//+QxPmBvfl3su+Pw/lWpfWx1fuJwPlUpPWVxvnp8/7////4+/9ure3C3/uq0fvb7P1usffn8v15tfFMmOceGILeAAAAF3RSTlMAIdHm7ijJ+JH8eQeTzaw68foX1Ll9kGZdllAAAAEQSURBVHgB7dZVcsMwFIVhhTkp5krmMJcZ9r+sMl7PEczopfC/f7ZFtoUQreZuWVepJ3g73VKtoSvaKzDS6kp9abSsMtQumQ1HtYbZcCSl2TBkZRiyNU+o4m6WxYqzeUIFd/P0eO7m6fGsDUNmQ+lsypA0ll6E4XdkYSbrTTj96AlJczSJ1rOP7vrQsFt9dl/HBnX8y00Cg0YFqBtkKJuDbgdEwMSLM9Cp9GnUCWoD54AOYH7XZwRDhtQRagVNfA5azKXX9RmirpGRaowKfM6bt/NDekPZYa6rIAEGz/VwNfJvskGuy3FimAOVzzhvlA+Z//f1vyltu5p90Sw5mq2eEG1H03759e+Ua7aVO82WeASxdDP0M8Z9fAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 26px;
  float: left;
}
.icon-doc-pdf {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAvVBMVEUAAAD3d3fxRkbyRkbyRkb3fn74g4PyRUX3lZXzRUXyRUX/RkbyQED3fn7zRUX2iYn/Rkb4lpb0cHDvQEDzRETzRkbzQEDzQkLzOzv////939/yODj2enr4kJD0UVHzPT38xMT92tr5lZX1ZGT+6+v4g4P1bW35m5v7yMj91dX3fn76sLD1XFz6qan//f36trb+5OT0VVX6rKz7vb3yNDT4iIj/9vb0TEz5o6PtTEzlRUXsYWHiNzfyRETsSEiKDgROAAAAFHRSTlMAH+Z5+80n+JHu0AWTv6w6CX3V/BbNCjoAAAHfSURBVHgB7daDsisxGMDxHNtftLZqu+//WDebOu1p08H1f7D8zRoIoferx4djXb4htYvXy7vrI32NXi4U8v6KjzcbFTcKer48bQplS3dYwyhbwhpGRTpGRZpGoNvzjUT6RkU6RkX6RiJNM7dUhE/2NYkVhDU2lCgIa6DxxNr0eathBPoab5re6xjJ1pkaRu2XmP8GiIzKCSrHAW9NiKhiwKnKsFkukRMOZaWCVnWZA7uGxEaZn6UDApBGYjz3ekDFZtrGoig1FRPwZXnoQoXL6p0qwSTjy9pdxdicG41K1ue8g6kwRiWLOPcHQDzO84aoE7N9M2SMWX1eD11hal0zNMQ8tzQ2c0UU75saBXB9zhMmTJthsyc2J7cTuIRSQQ5sxzRZK9oYN4x4VGXCDMtz14IDxiusntiM0SILQ6oG71umMLKcHTCL+rEL+qZe5iWUwta+hUwYz7btoCAHTCdJEgcTgKUxY84NfPwc1AihABhLM+x2N+c6oICXqWa5RJo8bTQ59x04YgJhYMvIMnnvfGtivxmsTdr0RbmNCca0ZjR7FCtGNnCcAd6aEIELyhLFABXb35qg62k59v9d9d/8MnN5fa75QFeXZ5qvN4SezzTPCKEn8et/p9vD49UT+gEdNgrQ89qG8QAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 26px;
  float: left;
}
.icon-doc-xls {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAulBMVEUAAACK4K5X0otX14eI362F3aqG4q1X0Yqe5LxX0YpW0otT0YhS0YdW0otW0otX0otY0Iug5Lqg5r2e5byf3rr///9O0IVR0IdU0YlX0otV0opV0YpKz4L+//5S0YjJ8dqK4K7p+fCQ4rKI36x426KL4K5t15p12qDT9OFy2Z5Lz4JXyIdZzotb046r6MVY0oz9/v2D3qpo15e47M593KXc9uj4/fqh5r5PxYHL8dvP8t687dCd5bue5bzVEOpnAAAAFXRSTlMAwekIyxwk+JHcffGW/KzN0Ss+eo5vCipPAAABZklEQVR4Ae3WhfaqMACA8dn1T3VBd9jd+v6PZY/L2YExbtdnKz8aAQBQar43eH18ArbKW7HGbVioMKT0JvFThh6LXorZxmRQ7VXAMIgPqDHLldyGTimPuaFqfmOWq/kNXSZxQ6ckaByTQQJm6TFIyoz4thlHQmbg2p4XR5II8mdOVPkzMny0HURtu9RkpUSpdWrE6//VBqnpoUQjS87mmNbGUZIMkfYwvZ0uJxjla83aurWG0Iq35pow6F0zIJz5vagg5BpDQRjjBYTzPqYhxeCbEbm+PUDN1yUaGf0Io8uyfDcyTc8yE0wIuZo5IjQ84RtrfGsF4WIcy/ru2xROb2nPVxrfhMvAdV0DQjNwacEy/A7r4BdtU6wi1L/tbyqiqZhvVvatEMKTZ39pxTVa4rrWvvc2naYFDcbQXGeWlhNQwhiM0sN/zv/1f1N8zWs6oFnMaVptAF5ympf7pf9HqyZa671ZAhesTDDT4wdnEAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 26px;
  float: left;
}

.icon-doc-txt {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAvVBMVEUAAACfsLihsrl5kJp4kJultrygsrl5j5uzv8Z4kJp4kJt1jZmhsrh4j5t0i6J4kJusucB5kJp5kJt5kJt/n59/mZlviJSzv8Wvu8H///91jZh4j5pzi5d3jpl5kJv6+vuhsrmuvMJ+lJ5yipaktLvAys/FztN7kZyaq7OJnaaNoKn+/v/7/P21wcfr7vDx8/Tt8fKHm6WVp6+RpK3f5OequL+DmKLP1tra4ON7kJtviJSesLduh5N1ipNrgYvfIttIAAAAGXRSTlMA0crm/Cgh+JHueZO/rAXNOvQX1AgK8X2QgEGk0gAAAcVJREFUeAHt1IWS2zAUBVAvM5T7BM/MYeb//6zeLIRBmh0o3ZBgjsiOHcf5dvvl5lDOnp3NfH44uzo/ED28v9wgXx/4cPTQO91Ad2fHjbcx06dzA7MxE7OJAbI3QNYG6MLeeCcX1gbo0t5geaaGFwbLMzNSZhvIwIg82kB8NFq0NhAfjxDNbLwIEBtEq+kys58wRpGLTK5hLDP6oPlv5DLz8qJtreOAQUUzol/KG2hhRJHE76nm1SSJWLKuxkk3X3bEhV41YUSL1FsJvgotMpfIb9MirheuGY9ct1JSWXHLRptrRIniAdGA2/MOotJ1y/pYLQ2SBgG36lQrUEhVF4PmXkluFqIuMWGNA3RsnUHYhmkK7FSnfaIOpukLOe9owYTb54aoF6MZCTEyUm+Kl465USgcMkAJIVXFFmbkYd+VXFsYHWAz8+1YmNDHLC5VvNDYiLxBFGNLnVQYmxhnFqCKU5BmRmc4AF+EL7eQOGIaL0ZyB6sKtOxhhQkfNLoX+VEgWfa6vl+8zOf7fopfNER6p2EplHj5VUppRgQKbw3i/7Pqv/mjzNmVrfnh3J5Zmu/PjnNnae4cx3m8fbq5Ms3N0+2j8ws7X1cWmnQE/gAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 26px;
  float: left;
}
.icon-doc-ppt {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAvVBMVEUAAAD72HT712j4yTf4yTj52nn72XX4yDj73475yTn/yjT3xzr4yTn5yDn5xzH63IL4yDn4yDj62Hb4xCr84I755Kj5yDT5yTj////5yDf5yTn823j5xzD5xi75xiz5xSr72XX60FD//vv6zET6yz796az/++/tvjL6zkr84I797bz6yjn+89LzxTzzxDb84pTyzFv96rT71mnuxUr+9dz10Wb834v72HH+9Nb+9drruCP73ID+9+H856b85Jt3fPirAAAAFnRSTlMA0Rx5/CbJ+JHtB+TNrJI6l9S58X2QIoLA1AAAAW5JREFUeAHt1oN6A0EUQOGJnXq0ZmzV7/9YvXXXe2udj4N/EW0IIc3SQSOt1h4JV+w0auW0JofFEGl2ZHpiYlZDaL+RbcxqJWBq5WwTRhLKNnB5eANnwhtAWAMVKngDCG/g8vAGzoQ3gHKajRJCMrPpCkwAZRsxnZvKc4BkDtRbzTdPzU9yGGjae+68ns+MXmSAwfYzDDWyo0FDrW1Wg61FA0a/sHlWtqK92ajL8enY59w/i+t2AdaXasAw13FGQ849tx/NWXA+HDmOywJGCkoNMFcGjWZ4YGBBRN4fpt0ancnbKJPPC+qt0WAm1QiLUazRZkNLZTijX/NLhapYw23P0pEGWitCIA00EAxp7EWfIs+zViiTOOP1YRvK+IpQce8PXVpwXSgDSCA+bwaNpqeYpO+Pu0g18HWMNvZTTULvaSTdzQZJzXZUxhhAanL07b/X/6ZVw5pjUmohzRH8Re8iTffur3+7Uctbo11qkhvyszc0CTNWCAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  width: 26px;
  float: left;
}
.whatsappContacts {
  box-shadow: 1px 1px 0px 0px rgb(0 0 0 / 20%);
  position: relative;
  background-color: #ffffff;
  text-align: left;
  border-radius: 0px 8px 8px 8px;
  margin-bottom: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.whatsappContacts .date {
  color: rgb(40, 40, 40);
  font-size: 11px;
  text-align: right;
  bottom: 5px;
  right: 5px;
}

.contactName {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 3px;
}

.contactPhone {
  font-size: 14px;
  font-style: italic;
  text-align: center;
  margin: 3px;
}

.contactAdd {
  font-weight: bold;
  border: 2px solid #1c869a;
  border-radius: 5px !important;
  background-color: #1c869a;
  color: white;
  margin: 3px;
  transition: ease 0.5s;
}

.contactAdd:hover {
  background-color: #1c869aa3;
}
</style>