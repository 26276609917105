import Vuex from 'vuex'
import Vue from 'vue'
import clients from './modules/clients'
import ocurrences from './modules/ocurrences'
import protocol from './modules/protocol'
import showHidden from './modules/showOrHidden'
import message from './modules/message'
import pauses from './modules/pauses'
import status from './modules/status.js'
import operator from './modules/operator'
import products from './modules/products'
import realtime from "./modules/realtime";
import transduson from './modules/transduson';

Vue.use(Vuex)

/**
 * gerenciamento de estados
 */
export default new Vuex.Store({
    modules: {
		clients,
		ocurrences,
		protocol,
		showHidden,
		message,
		pauses,
		status,
		operator,
		products,
		realtime,
		transduson
    }
})