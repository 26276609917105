<template>
    <section>
        <div class="d-flex justify-content-center" >
            <div class="cards ligacao" @click="ligacao"> 
                <h4>Ligação</h4> 
                <hr class="style-two">
                <h5>{{call}}</h5>
            </div>

            <div class="cards pausa" @click="pausa"> 
                <h4> Pausa </h4> 
                <hr class="style-two">
                <h5>{{pause}}</h5>
            </div>

            <div class="cards ativo" @click="ativo"> 
               <h4> Ativo </h4> 
               <hr class="style-two">
               <h5>{{active}}</h5>
            </div>

            <div class="cards deslogado" @click="deslogado">
                <h4> Deslogado </h4>
                <hr class="style-two">
                <h5>{{logoff}}</h5>
            </div>

            <div class="cards todos" @click="tudo">
                <h4> Todos </h4>
                <hr class="style-two">
                <h5>{{ todos }}</h5>
            </div>
        </div>
    </section>
</template>

<script>
import { getStatus } from "./status";

const hideall = function () {
  let divs = document.querySelectorAll('.card-realtime:not([type="none"])');

  [].forEach.call(divs, function (div) {
    div.closest("section").style.display = "none";
  });
};

const showall = function (e) {
  let divs = document.querySelectorAll(".card-realtime");
  [].forEach.call(divs, function (div) {
    div.closest("section").style.display = "";
  });
};

const showCall = function (type) {
  hideall();
  let divs = document.querySelectorAll(`div.linha-status.${type}`);
  [].forEach.call(divs, function (div) {
    div.closest("section").style.display = "";
  });

  return setInterval(() => {
    hideall();
    let divs = document.querySelectorAll(`div.linha-status.${type}`);
    [].forEach.call(divs, function (div) {
      div.closest("section").style.display = "";
    });
  }, 1000);
};

export default {
  data: function () {
    return {
      call: 0,
      pause: 0,
      active: 0,
      logoff: 0,
      todos: 0,
      intervalor: {},
    };
  },
  methods: {
    ligacao() {
      document.getElementById("searchramal");
      clearInterval(this.intervalor);
      this.intervalor = showCall("ligacao");
    },
    pausa() {
      document.getElementById("searchramal");
      clearInterval(this.intervalor);
      this.intervalor = showCall("pausa");
    },
    ativo() {
      document.getElementById("searchramal");
      clearInterval(this.intervalor);
      this.intervalor = showCall("ativo");
    },
    deslogado() {
      document.getElementById("searchramal");
      clearInterval(this.intervalor);
      this.intervalor = showCall("deslogado");
    },
    tudo() {
      document.getElementById("searchramal");
      clearInterval(this.intervalor);
      showall();
    },
  },
  mounted() {
    setInterval(() => {
      this.call = getStatus().ligacao;
      this.pause = getStatus().pausa;
      this.active = getStatus().ativo;
      this.logoff = getStatus().deslogado;
      this.todos = this.call + this.pause + this.active + this.logoff;
    }, 100);

    const self = this;
    if (document.getElementById("searchramal") != null) {
      document
        .getElementById("searchramal")
        .addEventListener("keydown", function (e) {
          clearInterval(self.intervalor);
        });
    }
  },
};
</script>

<style scoped>
    .cards{
        border-radius: 4px;
        align-content: center;
        color:white;
        height:7em;
        width: 11em;
        text-align: center;
        margin-right: 1em;
        padding: 0.5em;
        margin-bottom: 2em;
        cursor:pointer; 
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
    .cards:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    .ligacao {
        background-color: #16a085;
    }
    .pausa{
        background-color: #f39c12;
    }
    .ativo{
        background-color: #17a2b8;
      
    }
    .deslogado{
        background-color: grey;
    }
    .todos{
        background-color: #a1a1a1;
    }
    hr.style-two{
        margin-top:0px;
        border: 0;
        height: 1px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
    }

</style>