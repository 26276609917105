<template>
  <main>
    <div class="border-divisor-up"></div>

    <div class="row">
      <inline-alert-component
        v-if="seenAlert"
        type="primary"
        message="Carregando informações dos cards"
      />
    </div>

    <div class="row state-overview">
      <div class="col-lg-4 col-sm-7">
        <section class="card" style="margin-bottom: 0 !important">
          <div class="icons gray">
            <i class="fas fa-phone-alt" />
          </div>
          <div class="titles">
            <h1 class="count">{{ totalEntry }}</h1>
            <p>Total de Ligações Recebidas</p>
          </div>
        </section>
      </div>
      <div class="col-lg-4 col-sm-7">
        <section class="card" style="margin-bottom: 0 !important">
          <div class="icons gray">
            <i class="fas fa-clock" style="font-size: 20px" />
          </div>
          <div class="titles">
            <h1 class="count3">{{ avgWait }}</h1>
            <p>TME</p>
          </div>
        </section>
      </div>
      <div class="col-lg-4 col-sm-7">
        <section class="card" style="margin-bottom: 0 !important">
          <div class="icons gray">
            <i class="fas fa-clock" style="font-size: 20px" />
          </div>
          <div class="titles">
            <h1 class="count3">{{ avgAttend }}</h1>
            <p>TMA</p>
          </div>
        </section>
      </div>
      <div class="col-lg-4 col-sm-7">
        <section class="card" style="margin-bottom: 0 !important">
          <div class="icons gray">
            <i class="fas fa-percentage" style="font-size: 20px" />
          </div>
          <div class="titles">
            <h1 class="count3">{{ efficiency }}%</h1>
            <p>% Eficiência</p>
          </div>
        </section>
      </div>
      <div class="col-lg-4 col-sm-7">
        <section class="card" style="margin-bottom: 0 !important">
          <div class="icons gray">
            <i class="fas fa-percentage" style="font-size: 20px" />
          </div>
          <div class="titles">
            <h1 class="count3">{{ abandonPercent }}%</h1>
            <p>% Abandonadas</p>
          </div>
        </section>
      </div>
      <div class="col-lg-4 col-sm-7">
        <section class="card" style="margin-bottom: 0 !important">
          <div class="icons gray">
            <i class="fas fa-percentage" style="font-size: 20px" />
          </div>
          <div class="titles">
            <h1 class="count3">{{ abandonPercent60sPlus }}%</h1>
            <p>% Abandonadas acima de 60s</p>
          </div>
        </section>
      </div>
      <div class="col-lg-4 col-sm-7">
        <section class="card" style="margin-bottom: 0 !important">
          <div class="icons gray">
            <i class="fas fa-percentage" style="font-size: 20px" />
          </div>
          <div class="titles">
            <h1 class="count3">{{ avg_answereds_60s }}%</h1>
            <p>Nivel de Serviço</p>
          </div>
        </section>
      </div>
    </div>

    <div class="row state-overview">
      <div class="col-lg-3 col-sm-6">
        <section class="card">
          <div class="symbol terques">
            <i class="fas fa-smile" />
          </div>
          <div class="value">
            <h1 class="count">{{ answeredsCalls }}</h1>
            <p>Atendidas</p>
          </div>
        </section>
      </div>
      <div class="col-lg-3 col-sm-6">
        <section class="card">
          <div class="symbol terques">
            <i class="fas fa-phone"></i>
          </div>
          <div class="value">
            <h1 class="count3">{{ answereds60s }}</h1>
            <p>Atendidas até 60s</p>
          </div>
        </section>
      </div>
      <div class="col-lg-3 col-sm-6">
        <section class="card">
          <div class="symbol terques">
            <i class="fas fa-phone"></i>
          </div>
          <div class="value">
            <h1 class="count3">{{ answereds60sPlus }}</h1>
            <p>Atendidas maior 60s</p>
          </div>
        </section>
      </div>
      <div class="col-lg-3 col-sm-6">
        <section class="card">
          <div class="symbol red">
            <i class="fas fa-frown"></i>
          </div>
          <div class="value">
            <h1 class="count2">{{ abandon }}</h1>
            <p>Abandonadas</p>
          </div>
        </section>
      </div>
      <div class="col-lg-4 col-sm-6">
        <section class="card">
          <div class="symbol red">
            <i class="fas fa-phone-slash"></i>
          </div>
          <div class="value">
            <h1 class="count4">{{ abandon60s }}</h1>
            <p>Abandonadas até 60s</p>
          </div>
        </section>
      </div>
      <div class="col-lg-4 col-sm-6">
        <section class="card">
          <div class="symbol red">
            <i class="fas fa-phone-slash"></i>
          </div>
          <div class="value">
            <h1 class="count4">{{ abandon60sPlus }}</h1>
            <p>Abandonadas maior 60s</p>
          </div>
        </section>
      </div>
    </div>

    <div class="border-divisor-down"></div>
  </main>
</template>

<script>
export default {
  name: "ServiceLevelCards",
  data() {
    return {
      efficiency: 0,
      abandonPercent: 0,
      abandonPercent60sPlus: 0,
      serviceLevel: 0,
      totalEntry: 0,
      answeredsCalls: 0,
      answereds60s: 0,
      answereds60sPlus: 0,
      abandon: 0,
      abandon60s: 0,
      abandon60sPlus: 0,
      avg_answereds_60s: 0,
      seenAlert: true,
      avgWait: "00:00:00",
      avgAttend: "00:00:00",
    };
  },
  methods: {
    totalizerServiceLevel() {
      this.seenAlert = true;
      this.totalEntry = 0;
      this.answeredsCalls = 0;
      this.answereds60s = 0;
      this.answereds60sPlus = 0;
      this.abandon = 0;
      this.abandonPercent = 0;
      this.abandonPercent60sPlus = 0;
      this.efficiency = 0;
      this.abandon60s = 0;
      this.abandon60sPlus = 0;
      this.avg_answereds_60s = 0;
      this.avgWait = "00:00:00";
      this.avgAttend = "00:00:00";
      const startDate = $("#inputDataInicio").val();
      const endDate = $("#inputDataFim").val();

      if (startDate && endDate) {
        axios
          .get(`reports/totalizerServiceLevel?startDate=${startDate}&endDate=${endDate}`)
          .then(({ data }) => {
            this.totalEntry = data.totalEntry || 0;
            this.answeredsCalls = data.answeredsCalls || 0;
            this.answereds60s = data.answereds60s || 0;
            this.answereds60sPlus = data.answereds60sPlus || 0;
            this.abandon = data.abandon || 0;
            this.abandonPercent = data.abandonPercent || 0;
            this.abandonPercent60sPlus = data.abandonPercent60sPlus || 0;
            this.efficiency = data.efficiency || 0;
            this.abandon60s = data.abandon60s || 0;
            this.abandon60sPlus = data.abandon60sPlus || 0;
            this.avg_answereds_60s = data.avg_answereds_60s || 0;
            this.avgWait = data.avgWait || 0;
            this.avgAttend = data.avgAttend || 0;
            this.seenAlert = false;
          })
          .catch((error) => {
            console.error(error);
            this.seenAlert = false;
          });
      }
    },
  },
  mounted() {
    this.totalizerServiceLevel();
  },
};
</script>

<style scoped>
/*cards*/
.border-divisor-up {
  margin-top: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.border-divisor-down {
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.row.state-overview {
  display: flex;
  justify-content: center;
}

.state-overview .card {
  display: block;
  margin-bottom: 2rem;
  margin-top: 2rem;
  border: none;
}

.state-overview .gray {
  background: #999999;
}

.state-overview .terques {
  background: #4cd137;
}

.state-overview .red {
  background: #c23616;
}

.state-overview .blue {
  background: #1c869a;
}

.state-overview .orange {
  background: #ffa502;
}

.state-overview .symbol {
  /*width: 60px;*/
  height: 60px;
  border-radius: 4px 0 0 4px;
  padding: 17px 17px;
}

.state-overview .symbol i {
  color: #fff;
  font-size: 25px;
}

.state-overview .symbol,
.state-overview .value {
  display: inline-block;
}

.state-overview .value {
  padding-top: 8px;
  text-align: right;
  padding-right: 8px;
}

.state-overview .value {
  float: right;
}

.state-overview .value h1 {
  font-size: 1.1rem;
  font-weight: 300;
}

.state-overview .value h1,
.state-overview .value p {
  margin: 0;
  padding: 0;
  color: #7b7c80;
}

.state-overview .icons {
  width: 43px;
  height: 43px;
  border-radius: 4px 0 0 4px;
  padding: 12px 13px;
}

.state-overview .icons i {
  color: #fff;
  font-size: 17px;
}

.state-overview .icons,
.state-overview .titles {
  display: inline-block;
}

.state-overview .titles {
  padding-top: 3px;
  text-align: right;
  padding-right: 4px;
}

.state-overview .titles {
  float: right;
}

.state-overview .titles h1,
.state-overview .titles p {
  margin: 0;
  padding: 0;
  color: #7b7c80;
  font-size: 0.9rem;
  font-weight: 300;
}

/*legenda*/
.legend {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  width: 52%;
  border-radius: 5px;
}

.legend .container h4,
p {
  color: #000000;
}

.legend:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.header {
  border-radius: 5px 5px 0 0;
  padding: 0.35rem 1.25rem;
  margin-bottom: 0;
  background-color: #1c869a;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}
.container {
  padding: 2px 16px;
  color: #000000;
}

.seprator_class {
  position: relative;
}

.sep {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  display: table;
  height: 100%;
}
</style>
