<template>
  <div class="row">
    <div class="col-6">
      <label for="inputSelectQueue">Grupo</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="selectQueue">
            <i class="fas fa-users fa-fw"></i>
          </span>
        </div>
        <select
          class="form-control"
          id="inputSelectQueue"
          aria-label="Queue"
          aria-describedby="selectQueue"
          required
          v-model="selectedQueue"
          name="queue"
          ref="queue"
        >
          <option value="*">Todas</option>
          <option :key="key" v-for="(value, key) in queues" :value="value.name">{{ value.name }}</option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <label for="inputSelectUser">Operador</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="selectUser">
            <i class="fas fa-phone-square-alt fa-fw"></i>
          </span>
        </div>
        <select
          class="form-control"
          id="inputSelectUser"
          aria-label="User"
          aria-describedby="selectUser"
          name="user"
          v-model="selectedUser"
        >
          <option value="*" selected>Todos</option>
          <option :key="key" v-for="(value, key) in users" :value="value.slice(0, 4)">{{ formatName(value) }}</option>
        </select>
        <div class="input-group-prepend">
          <button type="submit" id="btnSubmit" class="btn btn-primary">
            <i class="fa fa-search fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      queues: [],
      users: [],
      selectedQueue: '*',
      selectedUser: '*'
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get("/records/list").then(response => {
          this.queues = response.data;
          this.getAllUsers();
        }).catch(function(error) {
          console.error(error);
        })
    },

    getUsers() {
      this.users = [];
      this.users = this.queues[this.selectedQueue].users;
      this.users = this.users.sort()
    },

    getAllUsers() {
      this.users = [];
      Object.keys(this.queues).forEach(el => {
        this.queues[el].users.forEach(u => {
          if(!this.users.includes(u)){
            this.users.push(u)
          }
        })
      });

      this.users = this.users.sort();
    },

    formatName(name) {
      if(name.length > 22) {
        name = name.slice(0, 21) + '...';
      }
      return name;
    },
  },
  watch: {
    selectedQueue() {
      this.selectedUser = '*';
      if(this.selectedQueue == '*') {
        this.getAllUsers();
      } else {
        this.getUsers();
      }
    },
  }
};
</script>