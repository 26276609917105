<template>
    <section v-if='getAllOccurrence' class="custom-all-occurrences">
		<div  class="row">
			<div class="col-md-3">
				<label for="de">De</label>
				<input type='date' class="form-control" id="de" name="de" v-model="de">
			</div>
			<div class="col-md-3">
				<label for="ate">Até</label>
				<input type='date' class="form-control" id="ate" name="ate" v-model="ate">
			</div>
			<div class="col-md-3">
				<label for="status">Status</label>
				<select v-model='status' id="status" name="status" class="form-control">
					<option value="" selected>Selecione</option>
					<option  value='2'>Aberto</option>
					<option  value='1'>Fechado</option>
					<option  value='3'>Aguardando</option>
				</select>
			</div>
			<div class="col-md-3">
				<button class='btn btn-secondary' @click.prevent="shareHistory()" type="button" id="pesquisar" name="pesquisar" style="margin-top: 30px;">Pesquisar</button>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-md-12">
				<table class="tables w-100">
					<tr>
						<th>Código</th>
						<th>Assunto</th>
						<th>Cliente</th>
						<th>Data de Abertura</th>
						<th>Status</th>
						<th>Ver Mais</th>
					</tr>

					<!-- <tr v-for="occurrence in getOccurrencesByDateAndStatus" :key='occurrence.id' v-if="this.getOccurrencesByDateAndStatus.length !== 0">
						<td>{{occurrence.protocol}}</td>
						<td>{{occurrence.description}}</td>
						<td>{{occurrence.name}}</td>
						<div v-if='occurrence.id == 2'>
							<td>Aberto</td>
						</div>
						<div v-else-if='occurrence.id == 1'>
							<td>Fechado</td>
						</div>
						<div v-else-if='occurrence.id == 3'>
							<td>Aguardando</td>
						</div>
						<div v-else>
							<td>--------</td>
						</div>
						<td>{{occurrence.created_at}}</td>

						<td class="show-plus">
							<img class='img img-table' src='/imgs/icons/eye.svg'>
						</td>
					</tr> -->

					<tr>
						<td colspan="6" class="no-results">Nenhuma Informacão Encontrada</td>
					</tr>
				</table>
			</div>
		</div>
	</section>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
	name: 'TodasOcorrencias',
    computed:{
        ...mapGetters(['getOccurrences','getAllOccurrence','getOccurrencesByDateAndStatus','getCnpj'])
    },
    methods:{
        ...mapActions(['fetchOcurrencesByDateAndStatus']),
        shareHistory(){
            this.fetchOcurrencesByDateAndStatus({
				cnpj:this.getCnpj,
				de:this.de,
				ate:this.ate,
				status:this.status
			});
        }
    },
	data(){
        return{
            de:'',
            ate:'',
            status:''
        }
    },
}
</script>

<style scoped>
	.custom-all-occurrences {
		display: flex;
		flex-direction: column;
		padding: 2rem;
	}
	.show-plus{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.tables th,.tables td  {
		border: none;
	}
	.tables tr:nth-child(even) {
		background-color: rgba(0, 0, 0, 0.05);
	}
	.tables th {
		background-color: #1c869a;
		color:white;
		padding: 8px;
		border:groove 1px lightgray
	}
	.no-results {
		padding: 8px;
		text-align: center;
	}
</style>
