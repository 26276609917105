<template>
    <div class="discar modal-2" v-if='getModalCall'>
        <div class="modal-content-my">
            <form class='form-modal' v-on:submit.prevent="sendCall">
				<div class="modal-head">
					<h4 class="title-text">Discar</h4>
					<span @click='btnClose' class='closeBtn'>&times;</span>
				</div>
				<div class="row">
					<div class="col-md-9 form-group">
						<input id="phoneCall" v-model="phoneNumber" class='esquerda form-control' type="text" placeholder="Telefone">
					</div>
					<div class="col-md-2 form-group">
						<button @click="sendCall" class="btn btn-success" type="button">Discar</button>
					</div>
				</div>
			</form>
		</div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
const connection = io.connect(`${location.protocol}//${location.hostname}:3006/`);

export default {
	data() {
		return {
			phoneNumber: ""
		}
	},
    computed:{
        ...mapGetters([
			'getModalCall',
		])
    },
    methods:{
        ...mapActions([
			'settModalCall',
		]),
		btnClose() {
			this.settModalCall();
		},
		formatPhoneToCall(phone) {
            let newPhone = phone.replace(/\D/g, "");
            newPhone = newPhone.slice(0, 2) == "55" ? newPhone.slice(2) : newPhone;
            return newPhone.slice(0, 2) == "11" ? newPhone.slice(2) : newPhone;
        },
		sendCall() {
			const phone = this.formatPhoneToCall(this.phoneNumber);
			connection.emit('Originate', {
				calltype: 'outgoing',
				extension: user.getExtension(),
				phone: phone
			});
			
			this.settModalCall();
		}
    }
}
</script>
