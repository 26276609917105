export default function notification(msgTitle, msgBody) {
	if (!window.Notification) {
		alert('Esse browser não suporta notificações desktop');
		return;
	}

	if (Notification.permission !== 'granted') {
		Notification.requestPermission();
		return null;
	} else {
		let notification = new Notification(msgTitle, {
			icon: '/imgs/advance-logo.png',
			body: msgBody,
		});

		notification.onclick = function () {
			window.focus();
		};
		return notification;
	}

}
