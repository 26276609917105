<template>
    <div class="mic-container" @click="recording">
        <div class="circle" :class="{'active': active}">
            <i 
            class="fas fa-microphone"
            ></i>
        </div>

        <div class="holder">
          <!-- <div data-role="controls">
            <button>Record</button>
          </div> -->
          <div data-role="recordings"></div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data(){
      return {
        active: false,
        myRecorder: {
          objects: {
            context: null,
            stream: null,
            recorder: null
          }
        } 
      }    
    },
    methods:{
        ...mapActions(["sendAudio"]),
        recording(){
            this.active = !this.active;

            this.init();

            var listObject = $('[data-role="recordings"]');
            var buttonState = !!$(this).attr('data-recording');

            if (!buttonState) {
              $(this).attr('data-recording', 'true');
              this.start();
            } else {
              $(this).attr('data-recording', '');
              this.stop(listObject);
            } 
        },
        init: function () {
          if (null === this.myRecorder.objects.context) {
            this.myRecorder.objects.context = new (
              window.AudioContext || window.webkitAudioContext
            );
          }
        },
        start(){
              const self = this;
              var options = {audio: true, video: false};

              navigator.mediaDevices.getUserMedia(options).then(function (stream) {
                  self.myRecorder.objects.stream = stream;
                  self.myRecorder.objects.recorder = new Recorder(
                          self.myRecorder.objects.context.createMediaStreamSource(stream),
                          {numChannels: 1}
                  );
                  self.myRecorder.objects.recorder.record();

              }).catch(function (err) {
                console.error(err)
              });
        },
        stop(listObject) {
              const self = this;
              if (null !== this.myRecorder.objects.stream) {
                  this.myRecorder.objects.stream.getAudioTracks()[0].stop();
              }
              if (null !== this.myRecorder.objects.recorder) {
            
                  this.myRecorder.objects.recorder.stop();

                  // Validate object
                  if (null !== listObject
                          && 'object' === typeof listObject
                          && listObject.length > 0) {

                      // Export the WAV into blob
                    

                      this.myRecorder.objects.recorder.exportWAV(async function (blob) {
                          self.sendAudio(blob);
                      }, 'audio/mpeg');
                  }
              }

        }
    }

}
</script>

<style scoped>
body {
  overflow: hidden;
}
.mic-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right:1em;
}
.mic-container .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: 0.5s;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.mic-container .circle i {
  color: #b2b1b1;
  font-size: 23px;
  transition: 0.9s;
}
.mic-container .circle:before {
  content: '';
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.2;
  z-index: -1;
  position: absolute;
}
.mic-container .circle.active {
  background: #ff0000;
}
.mic-container .circle.active:before {
  background: gray;
  animation: bounce 0.8s ease-in-out infinite 0.5s;
}
.mic-container .circle.active i {
  color: #ffffff;
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.4);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}


</style>