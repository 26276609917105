import axios from 'axios';
import clients from './clients';
import show from './showOrHidden';
import operator from './operator';
import protocol from './protocol';
import occurence from './ocurrences';
import clientData from './transduson';
import Swal from 'sweetalert2';
const { io } = require("socket.io-client");
const meuAvatar = '/imgs/icons/person.svg';
const wppAvatar = '/imgs/default-profile.png';
// var socket = io(`${location.protocol}//${location.hostname}:4000`);
var socket = { 'on': function(){}, 'emit': function(){} };
const dateTimeFormat = new Intl.DateTimeFormat('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' });

const getMenssagensChat = (inputConversa) => {
	let key = Object.keys(inputConversa)[0];
	inputConversa = inputConversa[key];

	const ultimaMsg = inputConversa.msgs[inputConversa.msgs.length - 1];
	const resp = {
		operador: inputConversa.operador_id,
		avatar: inputConversa.avatar,
		nome: inputConversa.nome,
		notify: inputConversa.notify,
		fila: inputConversa.queue,
		time: '',
		text_prev: '',
		key: key,
		created_at: inputConversa.created_at,
		protocol: inputConversa.protocol,
		phone: inputConversa.phone
	};

	if (ultimaMsg) {
		if (/^\d+$/.test(ultimaMsg.data)) {
			ultimaMsg.data = dateTimeFormat.format(ultimaMsg.data);
		}
		resp.time = ultimaMsg.data;
		resp.text_prev = ultimaMsg.mensagem.slice(0, 40) + (ultimaMsg.mensagem.length > 39 ? '…' : '');

		//Mensagem de notificacao
		if (resp.text_prev.substring(0, 4) == '<img') {
			resp.text_prev = '<span title="Foto"><div style="float: left; padding: 0px 5px 0px 0px;"><span data-icon="status"><svg viewBox="0 0 16 20" width="16" height="20" class=""><path fill="currentColor" d="M13.822 4.668H7.14l-1.068-1.09a1.068 1.068 0 0 0-.663-.278H3.531c-.214 0-.51.128-.656.285L1.276 5.296c-.146.157-.266.46-.266.675v1.06l-.001.003v6.983c0 .646.524 1.17 1.17 1.17h11.643a1.17 1.17 0 0 0 1.17-1.17v-8.18a1.17 1.17 0 0 0-1.17-1.169zm-5.982 8.63a3.395 3.395 0 1 1 0-6.79 3.395 3.395 0 0 1 0 6.79zm0-5.787a2.392 2.392 0 1 0 0 4.784 2.392 2.392 0 0 0 0-4.784z"></path></svg></span></div><span style="float: left;">Foto</span></span>';
		}
		else if (resp.text_prev.substring(0, 4) == '<aud') {
			resp.text_prev = '<span title="Áudio"><div style="float: left; padding: 0px 5px 0px 0px;"><span data-icon="status"><svg viewBox="0 0 12 20" width="12" height="20" class=""><path fill="currentColor" d="M6 11.745a2 2 0 0 0 2-2V4.941a2 2 0 0 0-4 0v4.803a2 2 0 0 0 2 2.001zm3.495-2.001c0 1.927-1.568 3.495-3.495 3.495s-3.495-1.568-3.495-3.495H1.11c0 2.458 1.828 4.477 4.192 4.819v2.495h1.395v-2.495c2.364-.342 4.193-2.362 4.193-4.82H9.495v.001z"></path></svg></span></div><span style="float: left;">Áudio</span></span>';
		}
		else if (resp.text_prev.substring(0, 4) == '<vid') {
			resp.text_prev = '<span title="Vídeo"><div style="float: left; padding: 0px 5px 0px 0px;"><span data-icon="status"><svg viewBox="0 0 16 20" width="16" height="20" class=""><path fill="currentColor" d="m15.243 5.868-3.48 3.091v-2.27c0-.657-.532-1.189-1.189-1.189H1.945c-.657 0-1.189.532-1.189 1.189v7.138c0 .657.532 1.189 1.189 1.189h8.629c.657 0 1.189-.532 1.189-1.189v-2.299l3.48 3.09v-8.75z"></path></svg></span></div><span style="float: left;">Vídeo</span></span>';
		}
		else if (resp.text_prev.substring(0, 4) == '<a h') {
			resp.text_prev = '<span title="Anexo"><div style="float: left; padding: 0px 5px 0px 0px;"><span data-icon="status"><svg viewBox="0 0 13 20" width="13" height="20" class=""><path fill="currentColor" d="M10.2 3H2.5C1.7 3 1 3.7 1 4.5v10.1c0 .7.7 1.4 1.5 1.4h7.7c.8 0 1.5-.7 1.5-1.5v-10C11.6 3.7 11 3 10.2 3zm-2.6 9.7H3.5v-1.3h4.1v1.3zM9.3 10H3.5V8.7h5.8V10zm0-2.7H3.5V6h5.8v1.3z"></path></svg></span></div><span style="float: left;">Anexo</span></span>';
		}
	}

	return resp;
}

const sumTotalNaoLido = (conversas) => {
	return conversas.reduce((t, inputConversa) => {
		let key = Object.keys(inputConversa)[0];
		inputConversa = inputConversa[key]
		return t + inputConversa.notify
	}, 0)
}

const state = {
	conversasChat: [],
	conversasWhatsapp: [],
	conversasFacebook: [],
	conversasInstagram: [],
	currentConversa: 'conversasChat',
	currentConversaIndex: '',
	token: '',
	protocol: '',
	curConversaCNPJ: '',
	logchat: [],
	userRealtime: '',
	userRealtimeData: '',
	userPhotoOperator: '',
	logInfo: [],
	totalMyQueue: 0,
	options: [],
	transfData: []
}

const formatData = (data) => {
	let dt = data.trim().split(" ");
	return {
		'data': dt[0],
		'hora': dt[1]
	}
}

const getters = {
	gettransfData: state => state.transfData,
	getOptions: state => state.options,
	getCurrentMensagens: (state) => {
		if (!state.currentConversa) {
			return [];
		}
		const currentChat = state[state.currentConversa];
		return currentChat.map(getMenssagensChat);

	},
	getCurrentConversa: (state) => {
		if (!state.currentConversa || state.currentConversaIndex === null) {
			return [];
		}

		let index = state.conversasChat.findIndex(el => Object.keys(el) == state.token);
		let currentConversa = state[state.currentConversa][index];

		let key = Object.keys(currentConversa)[0];
		currentConversa = currentConversa[key];

		const objReturn = [];
		const dataCheck = [];

		currentConversa.msgs.forEach(function (msg) {
			let sepData = formatData(msg.data);

			let exibirData = '';
			if (!dataCheck.includes(sepData.data)) {
				dataCheck.push(sepData.data);
				exibirData = sepData.data;
			}

			const obj = {
				isProprio: msg.type,
				exibirData: exibirData,
				data: sepData.data,
				hora: sepData.hora,
				mensagem: msg.mensagem,
				avatar: msg.isProprio ? meuAvatar : currentConversa.avatar,
				idOperador: currentConversa.operador,
				phone: currentConversa.phone

			}
			objReturn.push(obj);
		});

		return objReturn;
	},
	//getTemCurrentConversa: (state) => state.currentConversa && state.currentConversaIndex !== null && state[state.currentConversa][state.currentConversaIndex],
	getTemCurrentConversa: (state) => {
		if (!state.token) return null;
		let index = state.conversasChat.findIndex(el => Object.keys(el) == state.token);
		if (state.currentConversa && state.currentConversaIndex !== null && state[state.currentConversa][index]) {
			return state[state.currentConversa][index];
		}
	},
	getTotalChatQueue: (state) => state.conversasChat.length,
	getTotalNaoLidoWhatsapp: (state) => sumTotalNaoLido(state.conversasWhatsapp),
	getTotalNaoLidoFacebook: (state) => sumTotalNaoLido(state.conversasFacebook),
	getTotalNaoLidoInstagram: (state) => sumTotalNaoLido(state.conversasInstagram),
	getCurrentConversaIndex: state => state.currentConversaIndex,
	getTokenChatClient: state => state.token,
	getlogchat: state => state.logchat,
	getUserRealtime: state => state.userRealtime,
	getUserRealtimeData: state => state.userRealtimeData,
	getUserPhotoOperator: state => state.userPhotoOperator,
	getlogInfo: state => state.logInfo,
	getTotalMyQueue: state => state.totalMyQueue,
	getTotalInQueue: state => state.conversasChat.length,
	getConversasChat: state => state.conversasChat,
	getMessagesNotRead: state => {
		if (!state.currentConversa) {
			return [];
		}
		const currentChat = state[state.currentConversa];
		let newMessages = [];
		currentChat.forEach(elemento => {
			const [key, value] = Object.entries(elemento)[0];
			if ((value.notify > 0)) {
				newMessages.push(value);
			}
		});
		return newMessages;
	}
}

const mutations = {
	addMensagemCurrentConversa(state, { isProprio, data, mensagem, file, filename, type }) {

		if (!state.currentConversa || state.currentConversaIndex === null) {
			return;
		}

		// state[state.currentConversa][state.currentConversaIndex][state.token].msgs.push({
		// 	isProprio,
		// 	data,
		// 	mensagem,
		// });

		const obj = {
			token: state.token,
			msg: mensagem,
			exten: user.getId(),
		}

		if (file && filename && type) {
			obj.file = file;
			obj.filename = filename;
			obj.type = type;
		}

		socket.emit('sendMessageOthers', obj);
	},

	endCurrentConversa(state) {
		occurence.actions.fetchOcurrences(this, []);
		var uniqueid = state.token;
		let test = state.token.match(/^WPP_(.+)/);
		if (test) {
			var currTime = Date.now();
			uniqueid = `${state.token}-${currTime}`;
		}

		let obj = {
			at_user_id: operator.state.id,
			ticket: state.ticket,
			protocol: state.protocol,
			cpf_cnpj: state.curConversaCNPJ,
			uniqueid: uniqueid,
			type: state.currentConversa,
			created_at: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
			updated_at: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
		}

		socket.emit('disconnectClient', {
			exten: user.getId(),
			token: state.token,
			atendimento: obj
		});

		if (operator.state.myChannel == '') {
			protocol.actions.fetchProtocol(this);
			clients.state.client = [];
		}

		if (this.getters.getProtocol.protocol == state.protocol) {
			state.protocol = '';
		}

		let objInfo = {
			protocol: '',
			phone: '',
			name: '',
			queue: ''
		}

		clientData.mutations.setChatData(objInfo);
	},

	async setCurrentConversa(state, index) {
		let raw = index.replace(/\D/g, '');
		let num = raw.replace(/^55/g, '');
		clients.actions.chooseClientPhone(this, num);
		clients.actions.fetchClientsOP(this, num);

		if (!index) index = state.token;
		if (!state.currentConversa) return null;
		let arrIndex = state[state.currentConversa].findIndex(el => Object.keys(el)[0] == index);
		if (!state[state.currentConversa][arrIndex]) {
			return null;
		}

		state.token = index
		state.currentConversaIndex = arrIndex;
		state[state.currentConversa][arrIndex][index].notify = 0;
		const capture = state.conversasChat[arrIndex][index]['capture'];
		socket.emit('setClientToMe', {
			token: state.token,
			exten: user.getId(),
			name: user.getName()
		});

		if (!capture) {
			Swal.fire({
				text: "Carregando mensagens. Por favor, aguarde...",
				showConfirmButton: false,
				html: `<div><i class="fas fa-spinner fa-pulse"></i><br><span><b>Carregando mensagens</b> <br>Por favor, aguarde...</span></div>`
			});

			const chatMessages = await axios.get('/getMsgsFromRedis', { params: { key: index } })
			.then(({ data }) => {
				Swal.close();
				if (data) return Object.values(data);
			});

			state.conversasChat[arrIndex][index]['msgs'] = chatMessages;
			state.conversasChat[arrIndex][index]['capture'] = true;
		}

		const clientInfo = state[state.currentConversa][arrIndex][index];
		const objInfo = {
			protocol: clientInfo.protocol,
			phone: clientInfo.client_id,
			name: clientInfo.client,
			queue: clientInfo.queue
		}

		clientData.mutations.setChatData(objInfo);
		if (operator.state.myChannel == '') {
			let cnpj = state[state.currentConversa][arrIndex][index].client;
			state.curConversaCNPJ = cnpj;
			axios(`/share-client-cnpj?share=${cnpj}`).then(data => {
				protocol.actions.fetchProtocol(this, true);
				show.state.showSpearhead = true
				try {
					if (state.curConversaCNPJ == data.data[0].cpf_cnpj) return;
					state.curConversaCNPJ = (data.data[0]) ? data.data[0].cpf_cnpj : '';
				} catch (err) {
					return;
				}

				socket.emit('changeClientName', {
					cnpj: state.curConversaCNPJ,
					token: state.token,
					exten: user.getId()
				});
			});
		} else {
			axios(`/dialer/protocol`).then(data => {
				state.protocol = data.data.protocol;
				state.curConversaCNPJ = (data.data[0]) ? data.data[0].cpf_cnpj : '';
			});
		}
	},

	transfCurrentConversa(state, newQueue) {
		socket.emit('transfClient', {
			client: state.token,
			origin: newQueue.origin,
			destination: newQueue.destination,
			type: newQueue.type,
		});

		let objInfo = {
			protocol: '',
			phone: '',
			name: '',
			queue: ''
		}

		clientData.mutations.setChatData(objInfo);
	},

	transfProcess(state) {
		socket.emit('showmeQueueOptionsOperator', {});
	},

	setLogChat: (state, logs) => (state.logchat = logs),
	setLogInfo: (state, logs) => (state.logInfo = logs),

	setUserRealtime: (state, logs) => (state.userRealtime = logs),
	setUserRealtimeData: (state, logs) => (state.userRealtimeData = logs),

	setPhotoOperator: (state, img) => (state.userPhotoOperator = img),
}

const actions = {

	async joinQueue(operator, ramal) {

		socket.on('connect', () => {
			show.actions.setisReconnect(false);
		});

		var rec = (ramal == 'reconnect') ? true : false;

		var exten = user.getId()
		var queues = user.getChatQueues()
		var id = operator.rootState.operator.id;

		socket.emit('join', { exten, id, queues, rec });
	},

	async newEntry() {

		socket.on('feedbackStartChat', msg => {

			Swal.fire({
				title: "Não é possível abrir conversa",
				html: msg,
				type: 'info',
				confirmButtonText: "Ok"
			});
		});

		socket.on('newEntry', (data) => {
			let key = Object.keys(data)[0];
			let found = state.conversasChat.findIndex(el => Object.keys(el) == key);
			if (found == -1) {
				data[key]['nome'] = data[key]['client'];
				data[key]['avatar'] = data[key]['avatar'] ?? wppAvatar;
				data[key]['notify'] = data[key].msgs.length;
				data[key]['capture'] = false;
				state.conversasChat.push(data);
				return;
			}

			if (data[key].operador == '' && data[key].operador_id == '') {
				data[key]['capture'] = false;
			}

			let msgs = state.conversasChat[found][key].msgs

			//Push da última mensagem, entrando pelo evento newEntry
			if (msgs[msgs.length - 1].mensagem != data[key].msgs[0].mensagem && data[key].msgs.length == 1) {
				msgs.push(data[key].msgs[0]);
			}

			//QUANDO FOR ALTERAR, COMENTAR AS LINHAS 433 A 436
			// if(data[key].msgs.length == 1){
			// 	if(msgs[msgs.length - 1].mensagem != data[key].msgs[0].mensagem){
			// 		msgs.push(data[key].msgs[0])
			// 	} else {
			// 		if(msgs[msgs.length - 1].type != data[key].msgs[0].type){
			// 			msgs.push(data[key].msgs[0])
			// 		}
			// 	}
			// }

			//State sendo alterado e computado pra atribuição da conversa à tela do operador
			if (data[key].operador == user.getId()) {
				state.conversasChat[found][key] = data[key];
			}

			//Isso aqui eu não sei pra que serve, por isso não é bom mexer 🐴🤔
			data[key]['nome'] = data[key]['client'];
			data[key]['avatar'] = data[key]['avatar'] ?? wppAvatar
			data[key]['notify'] = msgs.length;
			state.conversasChat[found][key].notify = (state.token == key) ? 0 : msgs.length;

			//Substituição das mensagens do state pelo array com todas as mensagens, inclusive a que foi empurrada por último
			state.conversasChat[found][key].msgs = msgs;
		});
	},

	async removeClient(data) {

		socket.on('removeClient', (token) => {
			let index = state.conversasChat.findIndex(el => Object.keys(el) == token);
			if (index > -1) {
				try {
					if (operator.state.myChannel == '') {
						if (this.getters.getAllOcorrence.length && state.conversasChat[index][state.token]) {
							if (state.conversasChat[index][state.token].nome == this.getters.getAllOcorrence[0].cpf_cnpj) {
								clients.state.client = [];
							}
						}
					}
				} catch (err) {
					clients.state.client = [];
				}
				Vue.delete(state.conversasChat, index);
				if (state.currentConversaIndex === index) {
					state.currentConversaIndex = '';
					state.token = '';
					clients.actions.chooseClientPhone(this, '');

				}
			}
		});
	},

	async findLogById(id) {
		socket.emit('getlog', id);
	},

	async receivelog(state) {
		socket.on('sendlog', data => {
			state.state.logchat = data[0];
		});
	},

	async changeclientname(state) {
		if (operator.state.myChannel == '') {
			socket.emit('changeClientName', {
				cnpj: clients.state.client.cpf_cnpj,
				token: state.state.token,
				exten: user.getId()
			});
		}
	},

	async checkTotalQUeues(state) {
		var queues = user.getChatQueues()
		var exten = user.getId()

		socket.emit('checkTotalQUeues', {
			queue: queues,
			exten: exten
		});
	},

	async totalMyQueue(state) {

		socket.on('totalMyQueue', total => {

			this.totalMyQueue = total
		});

	},

	async transferOptions(state) {
		socket.on('transferOptions', (queues, operators) => {
			let options = {
				'operators': operators,
				'queues': queues
			}

			state.state.transfData = options;
		});
	},

	async operatorInUse(state) {
		socket.on('operatorInUse', () => {

			Swal.fire({
				title: "Ramal em uso!",
				text: 'Este ramal já foi esta sendo utilizado por outro usuário',
				type: 'info',
				confirmButtonText: "Ok"
			}).then(data => {
				window.location.href = '/';
			});
		});

		socket.on('clientExpirado', () => {
			Swal.fire({
				title: "Sessão do cliente expirou",
				text: 'O cliente já desconectou da sessão ',
				type: 'info',
				confirmButtonText: "Ok"
			});
		})
	},

	async startConversation(state, data) {
		socket.emit('startNewChat', { phone: data.phone, operador: user.getId(), operator_name: user.getName() });
	},

	async disconnect(state) {

		socket.on('disconnect', msg => {
			socket.removeAllListeners();

			// socket = io(`${location.protocol}//${location.hostname}:4000`);
			socket = { 'on': function(){}, 'emit': function(){} };
			show.actions.setisReconnect(true);
			actions.joinQueue(state, 'reconnect');
			actions.newEntry();
			actions.removeClient(state);
			actions.disconnect(state);
			actions.operatorInUse();

			// Log conversa
			actions.receivelog(state);

			// Action transferencia
			actions.totalMyQueue(state);
			actions.transferOptions(state);


		});
	},

	async sendAudio(state, blob) {
		socket.emit('blobAudio', { 'blob': blob, 'token': state.state.token });
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
