<template>
  <div>
    <b-card border-variant="dark">
      <div class="height-queue">
        <span class="font-weight-bold font-15">Clientes na URA</span>
        <b-table id="queue" small striped bordered fixed :per-page="perPage"
                 :fields="fields" :items="items" responsive="sm" class="mt-2"
                 :current-page="currentPage" show-empty>
          <template #empty="scope">
            <h6 class="text-center">Nenhum cliente na URA</h6>
          </template>
          <!-- A custom formatted column -->
          <template #cell(time)="data">
            <b-badge :variant="getTime(data.item.time).variant">{{ getTime(data.item.time).val }}</b-badge>
          </template>

          <!-- opcoes -->
          <template #cell(opcao)="data">
            <i title="Visualizar conversa" id="action-button-view" class="image-button fa fa-eye" @click.prevent="abrirVisualizar(data.item.opcao)"></i>
            <i title="Excluir atendimento" id="action-button-delete" class="image-button fas fa-trash"  @click.prevent="limparUra(data.item.opcao)"></i>
          </template>

        </b-table>
        <b-pagination v-model="currentPage" pills :total-rows="rows" :per-page="perPage" aria-controls="queue" size="sm" align="right"></b-pagination>
        </div>
    </b-card>

    <dtModalVisualizarUra ref="dtModalVisualizarUra"/>
  </div>
</template>
  
  <script>
  import { mapMutations, mapGetters } from 'vuex';
  const socket = require('./../../../modules/realtime/service/service');
  
  export default {
    name: "TbFilaURA",
  
    data:() => ({
      fields: [
        {key: 'nome', label: 'Cliente', class: 'text-center align-middle', thStyle: {'font-weight': 'bold'}},
        {key: 'phone', label: 'Telefone', class: 'text-center align-middle', thStyle: { width: "160px", 'font-weight': 'bold' }},
        {key: 'time', label: 'Tempo', class: 'text-center align-middle', thStyle: {'font-weight': 'bold'}},
        {key: 'opcao', label: 'Ações', class: 'text-center align-middle', thStyle: { width: "70px", 'font-weight': 'bold' }}
      ],
      currentPage: 1,
      perPage: 10,
      queues: []
    }),
  
   created() {
      socket.connection.on('queueUra', (obj) => {
        this.queues = obj.queues;
      });
    },
  
    computed: {
      ...mapGetters([
        "getUserRealtime"
      ]),
      items() {
        return this.mountTable();
      },
      rows() {
        return this.items.length;
      }
    },
  
    methods: {
      ...mapMutations(['setUserRealtime','setUserRealtimeData']),

      async abrirVisualizar(idUsers){
        //Seta o ID do Usuário
        await this.setUserRealtime(idUsers);

        if(this.getUserRealtime){
          await this.checkChatRealtime();

          //Solicita informacoes da conversa
          await socket.connection.emit('newEntryRealtimeUsers', {idUsers});

          //Abre o Modal
          this.$refs.dtModalVisualizarUra.openModal();
        }
      },

      getTime(time) {
        if(time == NaN) return;

        //Pega as Horas
        let horas = ("00" + parseInt(time / 3600)).slice(-2);

        //Remove a quantidade de horas do time
        time = time - (horas * 3600);

        //Pega os Minutos
        let minutos = ("00" + parseInt(time / 60)).slice(-2);

        //Remove a quantidade de minutos do time
        time = time - (minutos * 60);

        //Pega os segundos
        let segundos = ("00" + parseInt(time)).slice(-2);

        const format = `${horas}:${minutos}:${segundos}`;
        if (parseInt(horas) < 1 && parseInt(minutos) < 30) {
          return {val: format, variant: 'success'};
        } 
        else if (parseInt(horas) < 3) {
          return {val: format, variant: 'warning'};
        } 
        else if (parseInt(horas) > 2) {
          return {val: format, variant: 'danger'};
        }
      },

      formTelefone(tel) {
			  if(tel.substr(0,2) != '55'){
          return '+' + tel;
        }
        if( (tel.length == 10) || (tel.length == 11) ) {
            const v1 = tel.replace(/\D/g,"");
            const v2 = v1.replace(/^(\d{2})(\d)/g,"($1) $2");
            const v3 = v2.replace(/(\d)(\d{4})$/,"$1-$2");
            
            return v3;
        }
        tel=tel.replace(/\D/g,"")
        tel=tel.replace(/^(\d)/,"+$1")
        tel=tel.replace(/(.{3})(\d)/,"$1($2")
        tel=tel.replace(/(.{6})(\d)/,"$1)$2")
        if(tel.length == 12) {
            tel=tel.replace(/(.{1})$/,"-$1")
        }
        else if (tel.length == 13) {
            tel=tel.replace(/(.{2})$/,"-$1")
        }
        else if (tel.length == 14) {
            tel=tel.replace(/(.{3})$/,"-$1")
        }
        else if (tel.length == 15) {
            tel=tel.replace(/(.{4})$/,"-$1")
        }
        else if (tel.length > 15) {
            tel=tel.replace(/(.{4})$/,"-$1")
        }
        tel=tel.replace("("," (");
        tel=tel.replace(")",") ");

        return tel;
      },

      limparUra: function(client){
        Swal.fire({
            title: 'Remover da URA?',
            text: `Você tem certeza que deseja remover este cliente ${this.formTelefone(client)} da ura?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: 'rgb(28,134,154)',
            confirmButtonText: 'Sim, remover',
            cancelButtonText: 'Cancelar'
        }).then((isConfirm) => {
              if(isConfirm.value) socket.connection.emit('queueUraDelete', {client});
        });
      },
  
      mountTable() {
        const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        const queue = [];
        this.queues.map(q => {
          var ms = moment(now).diff(moment(q.created_at));
          queue.push({
            nome: q.getPermissionName ? q.getPermissionName : q.client,
            phone: this.formTelefone(q.client_id),
            time: moment.duration(ms).asSeconds(),
            opcao: q.client_id,
          })
        });

        queue.sort(function (x, y) {
            let a = new Date(x.time),
            b = new Date(y.time);
            return a - b;
        });

        return queue;
      },

      async checkChatRealtime() {
        if(this.getUserRealtime){
          socket.connection.on('newEntryRealtime', async (data)=> {
            let key = Object.keys(data)[0];
            if(this.getUserRealtime == key){
              const objReturn = [];
              const dataCheck = [];

              data[key].msgs.forEach(function(msg){
                let dt = msg.data.trim().split(" ");
                let sepData = {
                  'data': dt[0],
                  'hora': dt[1]
                };
                
                let exibirData = '';
                if(!dataCheck.includes(sepData.data)){
                  dataCheck.push(sepData.data);
                  exibirData = sepData.data;
                }

                const obj = {
                  isProprio: msg.type,
                  exibirData: exibirData,
                  data: sepData.data,
                  hora: sepData.hora,
                  mensagem: msg.mensagem,
                  avatar: msg.isProprio ? meuAvatar : data[key].avatar,
                  idOperador: data[key].operador
                }
                objReturn.push(obj);
              });

              //Salva os dados da conversa
              this.setUserRealtimeData(objReturn);
            }
          });
        }
      }
    },
  
    watch: {
      async queues() {
        this.mountTable();
        await this.checkChatRealtime();
      }
    }
  }
  </script>
  
  <style scoped>
    .card {
      color: black !important;
      border: 1px solid rgba(0, 0, 0, 0.125) !important;
      border-radius: 0.25rem !important;
    }
    .font-15 {
      font-size: 15px;
    }
    .height-queue {
      /* height: 205px; */
    }

    #action-button-view {
      cursor: pointer;
      font-size: 16px;
      color: #1c869a;
      transition: 0.25s;
      margin: 5px;
    }

    #action-button-view:hover {
      color: #177081;
    }

    #action-button-delete {
      cursor: pointer;
      font-size: 16px;
      color: #e3342f;
      transition: 0.25s;
      margin: 5px;
    }

    #action-button-delete:hover {
      color: #b42a26;
    }
  </style>