<template>
  <div>
        <div class="col">
            <h3>Status da Campanha</h3>
            <table id="relAnalitic" ref='tabela' class="table display table-hover table-striped" style="width:100%;">
                <thead></thead>
                <tbody></tbody>
                <tfoot></tfoot>
            </table>
        </div>
  </div>
</template>

<script>
export default {
    props: {
        filter: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dt: null,
            val: this.filter
        }
    },
    watch: {
        filter: {
            handler: function(val) {
                this.createTable()
            },
            deep: true
        }
    },
    methods: {
        createTable() {
            if (this.dt) {
                $('#relAnalitic').DataTable().destroy();
            }

            this.dt = $('#relAnalitic').DataTable({
                ajax:{
                    url: '/marketing/analise',
                    data: { param: this.filter },
                    dataSrc: ''
                },
                columns: [{
                    data: 'campanha',
                    title: 'Campanha',
                    class: 'text-center'
                },
                {
                    data: 'Pendente',
                    class: 'text-center',
                    title: 'Pendente'
                },
                {
                    data: 'Enviado',
                    class: 'text-center',
                    title: 'Enviado'
                },
                {
                    data: 'Nao-Entregue',
                    class: 'text-center',
                    title: 'Não possível entregar'
                },
                {
                    data: "total",
                    class: 'text-center',
                    title: 'Progresso Campanha',
                    render: (_, __, row) => {
                        const total = row['Pendente'] + row['Enviado'] + row['Nao-Entregue']
                        const progresso = Math.round(((total - row['Pendente'] )/ total)  * 100);
                        return Number.isNaN(progresso) ? "0%" : `${progresso}%`;
                    }
                }]
            })

            this.dt.buttons('.dtUpdateButton').remove();
        }
    },
    mounted() {
        this.createTable();

    }
}
</script>

<style scoped>
.bg-purple{
    background-color: purple;
}
.size-tabela{
    width: 700px;
}

</style>