<template>
  <div class="col-md-4" :id="`user_${data.id}`" style="margin: 15px 0px">
    <div class="col-md-12" style="background-color: #ecf0f1; padding: 15px">
      <div class="row">
        <div class="col-md-4 d-none d-md-block d-lg-block">
          <img
            v-if="data.photo != null"
            :src="`/storage/${data.photo}`"
            alt="Imagem de perfil"
            class="rounded-circle"
            style="width: 100%"
          />
          <img
            v-else
            src="/imgs/default-profile.png"
            alt="Imagem de perfil"
            class="rounded-circle"
            style="width: 100%"
          />
        </div>
        <div class="col-md-8" style="font-size: 12px">
          <strong>Nome:</strong>
          {{ data.name }}
          <br />
          <strong>Ramal:</strong>
          {{ data.extension_line }}
          <br />
          <strong>Login:</strong>
          {{ data.login }}
          <br />

          <div class="row mt-1">
            <div class="col-12">
              <button
                :id="`editUser_${data.id}`"
                v-on:click="editUser()"
                class="btn btn-outline-primary hiddenButton"
              >
                <i class="fas fa-edit"></i> Editar
              </button>
              <button
                :id="`deleteUser_${data.id}`"
                v-on:click="deleteUser()"
                class="btn btn-outline-danger hiddenButton"
              >
                <i class="fas fa-times"></i> Excluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {},
  methods: {
    editUser: function () {
      $("#password, #confirmPassword").prop("required", false);
      var key = Object.keys;
      var value = Object.values;

      for (key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          $("input[name=" + key + "]").val(this.data[key]);
          $("select[name=" + key + "]").val(this.data[key]);
          if (this.data.queue) {
            $("#queue_input").val(this.data.queue);
          }

          if (this.data.queuechat) {
            $("#queuechat_input").val(this.data.queuechat);
          }

          if (this.data.photo == null) {
            $("#imagePreview").css(
              "background-image",
              "url(/imgs/default-profile.png)"
            );
          } else {
            $("input[name=photo]").val(this.data.photo);
            $("#imagePreview").css(
              "background-image",
              `url(/storage/${this.data.photo})`
            );
          }
        }
      }

      // $("input[id=has_tab]").prop("checked", !!this.data.has_tab);

      $(this.data.permission).each(function (index, item) {
        $("input[id=" + item + "]").prop("checked", true);
      });

      $("#usersModal").modal("show");
    },

    deleteUser: function () {
      var key = Object.keys;
      $("#deleteUserName").text(`${this.data.name}`);
      $("#deleteUserModal").modal("show");
      for (key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          $("input[name=" + key + "]").val(this.data[key]);
        }
      }
    },
  },
};
</script>