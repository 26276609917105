import axios from 'axios'

const state = {
    product: '',
}
const getters = {
    getProduct: state => state.product,
}
const actions = {
    async saveProduct({ commit }, data) {
        const response = await axios.get(`/dialer/product_register`, {
            params: {
                products: data.products,
                call: data.data,
            }
        });
        // commit('setNameClient', response.data)
    }
}
const mutations = {
    setNameClient: (state, nameClient) => (state.nameClient = nameClient),
}
export default {
    state,
    getters,
    actions,
    mutations
}