const state = {
    barRight:false,
    messages:false,
    occurrences:false,
    modalPause:false,
    modalSchedule:false,
    modalCall:false,
    showHistory:false,
    showCallback: false,
    allOccurrence:true,
    newOccurrence:false,
    deslog:true,
    getButtonClient:false,
    showSpearhead:false,
    mute:false,
    yesOrNot:false,
    yes:false,
    showMessageAutomatic:false,
	isReconnect: false,
    contacts: false,
    calendar: false
};

const getters = {
    getYes:state=>state.yes,
    getYesOrNot:state=>state.yesOrNot,
    getShowBarRight:state => state.barRight,
    getOccurrences:state => state.occurrences,
    getAllOccurrence:state => state.allOccurrence,
    getNewOccurrence:state => state.newOccurrence,
    getShowHistory:state => state.showHistory,
    getShowCallback:state => state.showCallback,
    getMessages:state => state.messages,
    getModalCall: state => state.modalCall,
    getModalSchedule: state => state.modalSchedule,
    getModalPause: state=>state.modalPause,
    getDeslog: state =>state.deslog,
    getButtonClient:state => state.clickButtonClien,
    getShowSpearhead:state => state.showSpearhead,
    getMute:state => state.mute,
    getShowMessageAutomatic:state => state.showMessageAutomatic,
	isReconnect: state => state.isReconnect,
    getContacts: state => state.contacts,
    getCalendar: state => state.calendar
}

const actions = {
    setShowMessageAutomatic({commit}){
        commit('toogleShowMessageAutomatic')
    },
    async setYesOrNot({commit}){
        commit('tootleYesOrNot')
    },
    async setMute({commit}){
        commit('toogleMute');
    },
    async setShowBarRight({commit}){
        commit('toggleBarRight')
    },
    async setOccurrences({commit}){
        commit('toggleOccurrences')
    },
    async setNewOccurrences({commit}){
        commit('toogleNewOccurrence')
    },
    async setAllOccurrences({commit}){
        commit('toogleAllOccurrence')
    },
    async setShowHistory({commit}){
        state.messages = false;
        state.contacts = false;
        state.showCallback = false;
        state.calendar = false;

        //if(state.showHistory == true) return;
        commit('toogleShowHistory')
    },
    async setShowCallback({commit}){
        state.messages = false;
        state.contacts = false;
        state.showHistory = false
        if(state.showCallback == true) return;
        commit('toogleShowCallback')
    },
    async setMessage({commit}){
        state.showHistory = false;
        state.contacts = false;
        state.calendar = false;
        state.showCallback = false;
        //if(state.messages == true) return;
        commit('toggleMessages')
    },
    async settModalCall({commit}){
        commit('toogleModalCall')
    },
    async setModalSchedule({commit}){
        commit('toogleModalSchedule')
    },
    async setModalPause({commit}){
        commit('toogleModalPause')
    },
    async setDeslog({commit}){
        commit('toogleDeslog')
    },
    /*async setButtonClient({commit}){
        commit('toogleDeslog')
    },*/
    async setShowSpearhead({commit}){
        commit('toogleShowSpearhead')
    },

	setisReconnect(value){
		state.isReconnect = value;
	},
    async setContacts({ commit }) {
		state.showHistory = false;
		state.messages = false;
        state.campanha = false;
        state.showCallback = false;
        state.calendar = false;
        //if(state.contacts == true) return;
        commit('toogleContacts');
    },
    async setCalendar({ commit }) {
        state.showHistory = false;
        state.messages = false;
        state.contacts = false;

	    commit('toggleCalendar')
    },
}

const mutations = {
    toogleYes:(state,yes)=>(state.yes = !state.yes),
    tootleYesOrNot:(state,yesOrNot)=>(state.yesOrNot = !state.yesOrNot),
    toggleBarRight: (state,barRight)=>(state.barRight = !state.barRight),
    toggleMessages:(state,messages)=> (state.messages = !state.messages),
    toggleOccurrences: (state, occurrences)=>(state.occurrences = !state.occurrences),
    toogleModalPause:(state,modalPause)=>(state.modalPause = !state.modalPause),
    toogleModalSchedule:(state,modalSchedule)=>(state.modalSchedule =!state.modalSchedule),
    toogleModalCall:(state,modalCall)=>(state.modalCall = !state.modalCall),
    toogleShowHistory:(state,showHistory)=>(state.showHistory = !state.showHistory),
    toogleShowCallback:(state,showCallback)=>(state.showCallback = !state.showCallback),
    toogleAllOccurrence:(state,allOccurrence)=>(state.allOccurrence = !state.allOccurrence),
    toogleNewOccurrence:(state,newOccurrence)=>(state.newOccurrence = !state.newOccurrence),
    toogleDeslog:(state,deslog)=>(state.deslog = !state.deslog),
    toogleShowSpearhead:(state,showSpearhead)=>(state.showSpearhead = true),
    toogleMute:(state,mute)=>(state.mute = !state.mute),
    toogleShowMessageAutomatic:(state,showMessageAutomatic) =>(state.showMessageAutomatic = !state.showMessageAutomatic),
    toogleContacts: (state, contacts) => (state.contacts = !state.contacts),
    toggleCalendar: (state, calendar) => (state.calendar = !state.calendar)
}

export default {
    state,
    getters,
    actions,
    mutations
}
