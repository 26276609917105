<template>
  <section>
    <div class="row mt-4 container-table">
      <div class="col-md-12">
        <table class="tables w-100 mb-4">
          <tr>
            <th class="text-center">Fila de atendimento</th>
            <th class="text-center">Telefone</th>
            <th class="text-center">Tempo de espera</th>
            <th class="text-center">Capturar</th>
          </tr>

          <tr v-for="el in sortedResults" :key="el.id">
            <td class="text-center">{{ el.fila }}</td>
            <td class="text-center">{{ el.telefone }}</td>
            <td class="text-center">{{ el.tempo.toString().toHHMMSS() }}</td>
            <td class="text-center" @click="takeCall(el.canal)">
              <button id="take-call">📞</button>
            </td>
          </tr>

          <tr v-if="obj.length == 0">
            <td colspan="5" class="text-center">Nenhum atendimento</td>
          </tr>
        </table>
        <button class="button-page btn btn-light" @click="nextPage">
          Proximo
        </button>
        <button class="button-page btn btn-light" @click="prevPage">
          Anterior
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

String.prototype.toSeconds = function () {
  if (!this.match(/\d{2}:\d{2}:\d{2}/g)) return "Bad format";
  let hhmmss = this.split(":");

  let hour = Math.floor(parseInt(hhmmss[0]) * 3600);
  let minutes = Math.floor(parseInt(hhmmss[1]) * 60);
  let seconds = parseInt(hhmmss[2]);

  return hour + minutes + seconds;
};

export default {
  props: ["connection"],
  data() {
    return {
      obj: [],
      currentSort: "created_at",
      currentSortDir: "asc",
      pageSize: 10,
      currentPage: 1,
      queues: [],
    };
  },
  computed: {
    sortedResults: function () {
      return this.obj
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        })
        .filter((row, index) => {
          let start = (this.currentPage - 1) * this.pageSize;
          let end = this.currentPage * this.pageSize;
          if (index >= start && index < end) return true;
        });
    },
  },
  methods: {
    nextPage() {
      if (this.currentPage * this.pageSize < this.obj.length)
        this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    async queueEntry() {
      this.connection.on("QueueEntry", async (dataQueue) => {
        if (this.queues.includes(dataQueue.Queue)) {

          let newClient = {
            telefone: dataQueue.CallerIDNum,
            fila: dataQueue.Queue,
            tempo: dataQueue.Wait,
            canal: dataQueue.Channel,
          };

          const index = this.obj.findIndex(
            (el) => el.telefone == dataQueue.CallerIDNum
          );
          if (index != -1) return;
          this.obj.push(newClient);
        }
      });
    },
    async queuecallerjoin() {
      this.connection.on("QueueCallerJoin", async (dataQueue) => {
        if (this.queues.includes(dataQueue.Queue)) {
          const index = this.obj.findIndex(
            (el) => el.telefone == dataQueue.CallerIDNum
          );

          if (index != -1) return;
          this.obj.push({
            telefone: dataQueue.CallerIDNum,
            fila: dataQueue.Queue,
            tempo: "0",
            canal: dataQueue.Channel,
          });
        }
      });
    },
    async queuecallerleave() {
      this.connection.on("QueueCallerLeave", (data) => {
        const index = this.obj.findIndex(
          (el) => el.telefone == data.CallerIDNum
        );
        if (index == -1) return;
        this.obj.splice(index, 1);
      });
    },
    takeCall(channel) {
      const extension = user.getExtension();
      const dataObj = {
        ramal: extension,
        channel: channel,
        extension: extension,
      };
      
      this.connection.emit("Redirect", dataObj);
      return;
    },
  },
  created() {
    setInterval(() => {
      this.obj.map((el) => {
        el.tempo = el.tempo.toString().toHHMMSS();
        el.tempo = el.tempo.toSeconds();
        el.tempo = parseInt(el.tempo);
        el.tempo++;
      });
    }, 1000);

    axios.get("/allQueuesArray").then(({ data }) => {
      this.queues = data;
      this.queueEntry();
      this.queuecallerjoin();
      this.queuecallerleave();
    });
  },
};
</script>

<style scoped>
.container-table {
  margin-bottom: 20px;
}

.tables th {
  border: none;
}

.button-page {
  float: right;
  margin-right: 0.5em;
}

.no-border {
  border: hidden;
  background: none;
}

th {
  padding: 1em;
}

td {
  padding: 1em;
}

#take-call {
  border: 1px solid #1c869a;
  border-radius: 10px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.2s;
}

#take-call:hover {
  background-color: #1c869a5c;
}
</style>
