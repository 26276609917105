import axios from 'axios'
import message from './message'
const state ={
    protocolDiler:'',
    protocolOccurrence:''
}
const getters ={
    getProtocol: state => state.protocolDiler,
    getProtocolOcurrence:state=>state.protocolOccurrence
}
const actions={
    async fetchProtocol({commit}, chat){
        let self = this;
        axios(`/dialer/protocol`).then(data => {
            if(chat)
                message.state.protocol = data.data.protocol;
                commit('setProtocol',data.data)
                
        })
        
    },
    async fetchProtocolOccurrence({commit}){
        const response = await axios(`/dialer/protocol`)
        commit('setProtocol',response.data)
        
    }
}
const mutations={
    setProtocol:(state,protocolDiler)=>state.protocolDiler = protocolDiler,
    setProtocolOcurrence:(state,protocolOccurrence)=>(state.protocolOccurrence = protocolOccurrence)
}
export default {
    state,
    getters,
    actions,
    mutations
}

