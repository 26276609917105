<template>
    <div class="row">
        <div class="col-6">
            <label for="inputDataInicio">Data Inicial</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="dataInicio"><i class="fas fa-calendar-alt fa-fw"></i></span>
                </div>
                <input type="date" :id="`${id}_inputDataInicio`" name="startDate" v-model="startDate" class="form-control" aria-label="DataInicio" aria-describedby="dataInicio" :required="required">
            </div>
        </div>
        <div class="col-6">
            <label for="inputDataFim">Data Final</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="dataFim"><i class="fas fa-calendar-alt fa-fw"></i></span>
                </div>
                <input type="date" :id="`${id}_inputDataFim`" name="endDate" v-model="endDate" class="form-control" aria-label="DataFim" aria-describedby="dataFim" :required="required">
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
        'id': String,
        'autofill': {
            type: String,
            default: 'monthly'
        },
        'required': {
            type: Boolean,
            default: true
        }},
        data () {
            return {
                startDate: '',
                endDate: ''
            }
        },
        mounted() {
            switch(this.autofill){
                case 'monthly':
                    var firstOfTheMonth = moment().format('YYYY-MM-01')
					var now = moment().format('YYYY-MM-DD')
                    this.startDate = firstOfTheMonth;
                    this.endDate = now;
                break;
                case 'initialToday':
                    var now = moment().format('YYYY-MM-DDTHH:mm');
                    var lastOfTheMonth = moment().endOf('month').format('YYYY-MM-DDT00:00');

                    $('input[name=startDate]').attr('min', now).val(now);
                    $('input[name=endDate]').attr('min', now).val(lastOfTheMonth);

                    this.startDate = '';
                    this.endDate = '';
                break;
            }
        }
    }
</script>
