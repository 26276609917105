<template>
  <div>
    <div class="col-md-12">
        <h3>Clientes da base</h3>
        <table id="relLog" ref='tabela' class="table display table-hover table-striped" style="width: 100%">
            <thead></thead>
            <tbody></tbody>
            <tfoot></tfoot>
        </table>
    </div>

    <component :is="comp" :data="compData" v-on:dismissModal="dismissModal" />
  </div>
</template>

<script>
import modalCostumers from "./ModalCostumers.vue";

export default {
  components: { modalCostumers },
  props: {
    filter: {
      type: String,
      default: "",
    },
    upList: 0,
  },
  data() {
    return {
      dt: null,
      val: this.filter,
      comp: "",
      compData: "",
    };
  },
  watch: {
    upList: function () {
      this.createTable();
    },
    filter: {
      handler: function (val) {
        this.createTable();
      },
      deep: true,
    },
  },
  methods: {
    dismissModal() {
      this.comp = "";
      this.createTable();
    },
    createTable() {
      if (this.dt) {
        $("#relLog").DataTable().destroy();
      }

      this.dt = $("#relLog").DataTable({
        responsive: true,
        processing: true,
        destroy: true,
        serverSide: true,
        ajax: {
          type: "GET",
          url: "/customer_base/getclients",
        },
        columns: [
          { data: "name", title: "Nome" },
          { data: "phone", className: "text-center", title: "Telefone" },
          { data: "company_name", className: "text-center", title: "Empresa" },
          {
            data: "unique_key",
            className: "text-center",
            title: "CPF/CNPJ",
          },
          {
            class: "details-control find-data text-center",
            data: "id",
            title: "Detalhes",
            render: () =>
              `<button type='button' class='find-data invisible-button detail-button'><i class="find-data far fa-eye"></i></button>`,
          },
        ],
        order: [[0, "asc"]],
      });
    },
  },
  mounted() {
    this.createTable();

    document.querySelector("#relLog tbody").addEventListener("click", (e) => {
      if (e.target.classList.contains("find-data")) {
        const tr = e.target.closest("tr");
        const row = this.dt.row(tr).data();
        this.compData = row;
        this.comp = "modal-costumers";
      }
    });
  },
};
</script>

<style>
.detail-button {
  font-size: 18px !important;
  color: #1c869a;
}

.detail-button:hover {
  color: #0c4955;
  transition: 0.5s;
}
</style>