<template>
  <div  v-show='getShowCallback'>

      <h1>aaa</h1>
      <table-callback></table-callback>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import tableCallback from './tableCallback.vue';
export default {
  components:{tableCallback},
  computed:{
      ...mapGetters(['getShowCallback'])
	},

}
</script>

<style>

</style>