<template>
	<div id="container-main">
		<div id="container-cards">
			<div class="queue-calls-today">
				<h3>Ligações de Hoje</h3>
				<div>
					<div class="calls-today border-incoming">
						<div>
							<h5 class="">Ligações Recebidas</h5>
							<span class="value">{{ callsToday.incoming }}</span>
						</div>
						<div>
							<i class="fas fa-phone incoming"></i>
						</div>
					</div>

					<div class="calls-today border-answered">
						<div>
							<h5>Ligações Atendidas</h5>
							<span class="value">{{ callsToday.answered }}</span>
						</div>
						<div>
							<i
								class="fas fa-phone-volume answered"
								style="padding: 10px 12px; font-size: 1.4em"
							></i>
						</div>
					</div>

					<div class="calls-today border-missed">
						<div>
							<h5>Ligações Perdidas</h5>
							<span class="value">{{ callsToday.abandon }}</span>
						</div>
						<div>
							<i class="fas fa-phone-slash missed"></i>
						</div>
					</div>
				</div>

				<apexchart
					type="radialBar"
					width="400"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</div>

			<div class="queue-calls-today op-prod">
				<h3>Produtividade Individual dos Operadores</h3>

				<div class="fixTableHead">
					<table class="special-table w-100 mb-4">
						<thead>
							<tr>
								<th class="text-center" style="background-color: #323031" title="Nome do Operador">
									<i class="fas fa-user" />
									Operador
								</th>
								<th class="text-center" style="background-color: #023e8a" title="Ligações Recebidas">
									<i class="fas fa-phone" />
									Recebidas
								</th>
								<th class="text-center" style="background-color: #0ead69" title="Ligações Atendidas">
									<i class="fas fa-phone-volume" />
									Atendidas
								</th>
								<th class="text-center" style="background-color: #7b2cbf" title="Ligações Atendidas">
									<i class="fas fa-phone-alt" />
									Realizadas
								</th>
								<th class="text-center" style="background-color: #ef233c" title="Ligações Perdidas">
									<i class="fas fa-phone-slash" />
									Perdidas
								</th>
								<th class="text-center" style="background-color: #ffb600" title="Tempo das Pausas">
									<i class="fas fa-clock" />
									Tempo em Atendimento
								</th>
							</tr>
						</thead>
						<tr v-for="operator in operators" :key="operator.id">
							<td class="text-center">{{ `${operator.name} (${operator.extension})` }}</td>
							<td class="text-center">{{ operator.incoming_calls }}</td>
							<td class="text-center">{{ operator.answered_calls }}</td>
							<td class="text-center">{{ operator.outgoing_calls }}</td>
							<td class="text-center">{{ operator.missed_calls }}</td>
							<td class="text-center">{{ formatDuration(operator.attendance_time) }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import moment from 'moment'
import momentDuration from "moment-duration-format";
momentDuration(moment);

export default {
	props: ["filter"],
	data: function() {
		return {
			loading: false,
			startDate: moment().format('YYYY-MM-DD 00:00:00'),
			endDate: moment().format('YYYY-MM-DD 23:59:59'),
			operators: [],
			callsToday: {
				incoming: 0,
				answered: 0,
				abandon: 0
			},
			series: [0],
			chartOptions: {
				tooltip: {
					enabled: true,
					theme: 'dark',
					custom: () => '<div style="padding: 5px"><span style="font-weight: 600;">Aproveitamento de ligações recebidas sobre atendidas</span></div>'
				},
				chart: {
					type: "radialBar",
					offsetY: -20,
					sparkline: {
						enabled: true
					}
				},
				plotOptions: {
					radialBar: {
						startAngle: -90,
						endAngle: 90,
						track: {
							background: "#e7e7e7",
							strokeWidth: "97%",
							margin: 5,
							dropShadow: {
								enabled: true,
								top: 2,
								left: 0,
								color: "#999",
								opacity: 1,
								blur: 2
							}
						},
						dataLabels: {
							name: {
								show: true
							},
							value: {
								offsetY: -50,
								fontSize: "22px"
							}
						}
					}
				},
				grid: {
					padding: {
						top: -10
					}
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						shadeIntensity: 0.4,
						inverseColors: false,
						opacityFrom: 1,
						opacityTo: 1,
						stops: [0, 50, 53, 91]
					}
				},
				labels: ["Aproveitamento"],
				noData: {
                    text: 'Sem dados para exibir...'
                }
			}
		};
	},
	methods: {
		formatDuration(date) {
			return date >= 86400 ?
			 moment.duration(date, "seconds").format('hh:mm:ss').replace(',', '.') :
			 moment.utc(date * 1000).format('HH:mm:ss')
		},
		getCallsToday() {
			axios.get(`${window.location.href}/todayCallsPerQueue?queue=${this.filter}`)
				.then(({ data }) => {
					const percent = (data.answered * 100) / data.incoming;
					this.callsToday = data;
					this.series = percent > 0 ? [percent.toFixed(1)] : [0];
				});
		},
		searchOperators(event) {
			event && event.preventDefault();
			this.loading = true;
			axios.get(`${window.location.href}/operatorProductivityToDashboard?startDate=${this.startDate}&endDate=${this.endDate}&queue=${this.filter}`)
				.then(({ data }) => {
					this.operators = data.sort((a, b) => {
						if(a.incoming_calls < b.incoming_calls) return 1;
						if(a.incoming_calls > b.incoming_calls) return -1;
						return 0;
					});
					this.loading = false;
				});
		}
	},
	beforeMount() {
		this.getCallsToday();
		this.searchOperators();
		setInterval(() => {
			this.getCallsToday();
			this.searchOperators();
    	}, 30000);
	},
	watch: {
		filter() {
			this.getCallsToday();
			this.searchOperators();
		}
	}
};
</script>

<style scoped>

#container-main {
	display: flex;
}

#container-cards {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}

#container-cards form {
	width: 50%;
}

.btn-small-form {
	margin-top: 33px;
}

.queue-calls-today h2 {
	margin-bottom: 20px;
}

.value {
	font-size: 30px;
}

.incoming {
	background-color: blue;
}

.border-incoming {
	border-bottom: solid 2px blue;
}

.answered {
	background-color: green;
}

.border-answered {
	border-bottom: solid 2px green;
}

.missed {
	background-color: red;
}

.border-missed {
	border-bottom: solid 2px red;
}

.queue-calls-today {
	display: flex;
	flex-direction: column;
	padding: 20px;
	margin-bottom: 20px;
	background-color: white;
	width: 100%;
	border-radius: 12px;
	/* box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.5); */
	box-shadow: -5px -5px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1),
    5px 5px 8px rgba(0, 0, 0, 0.15);
}

.op-prod {
	height: 820px;
}

.queue-calls-today > div {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.calls-today {
	display: flex;
	padding: 10px;
	justify-content: space-between;
	width: 30%;
}

.calls-today i {
	color: white;
	font-size: 1.2em;
	border-radius: 100%;
	padding: 10px;
}

.special-table tr:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.05);
}

.special-table tr > th:first-child {
	border-radius: 15px 0px 0px 0px;
}

.special-table tr > th:last-child {
	border-radius: 0px 15px 0px 0px;
}

.special-table {
	width: 100%;
	margin-top: 20px;
	font-size: 1em;
	font-family: "Sulphur Point", sans-serif;
	border-bottom: solid 2px black;
}

.special-table th {
	background-color: #dec9e9;
	color: white;
	padding: 5px 20px;
	font-weight: bold;
}

.special-table tfoot {
	background-color: black;
}

.special-table > tr:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.operator-card {
	display: flex;
	background-color: white;
	flex-direction: column;
	margin-bottom: 10px;
	border-radius: 5px;
	box-shadow: 2px 2px 5px black;
}

.personal-data {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: deepskyblue;
	color: white;
}

.operator-card > .productivity {
	padding: 0px 20px;
}

.fixTableHead {
	overflow-y: auto;
}

.fixTableHead thead th {
	position: sticky;
	top: 0;
}
</style>
