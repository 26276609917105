<template>
  <div>
    <div class="row" style="height: 55vh">
      <div class="col-8">
        <select-clients
          v-on:removeAllClients="removeAllClients"
          v-on:removeClient="removeClient"
          v-on:addClient="addClient"
          :wallets="wallets"
          :groups="groups"
        />
      </div>
      <div class="col">
        <preview-whatsapp :text="text" :image="image" />
        <b-row class="m-0">
          <b-col id="msg-text-slot" cols="10" class="p-0">
            <message-text
              v-for="index in controlMsgs"
              :key="index"
              :idText="index.toString()"
              v-on:addImage="addImage"
              v-on:updateText="addText"
            />
          </b-col>
          <b-col cols="2" class="p-0">
            <div class="action-buttons">
              <b-button id="add-button" @click="increaseMsg"> ➕ </b-button>
            </div>
            <div class="action-buttons">
              <b-button id="del-button" @click="decreaseMsg"> ❌ </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="d-flex name-filter">
      <div>
        <input
          type="text"
          class="form-control"
          placeholder="Nome da Campanha"
          v-model="campanha"
          autofocus
        />
      </div>
      <b-button @click="submitCampaign" class="submit-button">
        Enviar <i class="fas fa-paper-plane"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
import PreviewWhatsapp from "./PreviewWhatsapp.vue";
import SelectClients from "./SelectClientes.vue";
import MessageText from "./MessageText.vue";
import io from "socket.io-client";
// const socket = io(`${location.protocol}//${location.hostname}:4001`);
const socket = { 'on': function(){}, 'emit': function(){} };

export default {
  components: { PreviewWhatsapp, SelectClients, MessageText },
  props: {
    wallets: {
      type: Array,
      default: [],
    },
    groups: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      text: ["Digite sua mensagem aqui"],
      image: [],
      mediaSrc: [],
      clients: [],
      controlMsgs: 1,
      campanha: "",
    };
  },
  methods: {
    addText(index, data) {
      this.text.splice(index - 1, 1, data);
    },
    addImage(index, data, file) {
      for (let i = 0; i < index; i++) {
        if (this.image[i] == null) {
          this.image[i] = null;
        }

        if (this.mediaSrc[i] == null) {
          this.mediaSrc[i] = null;
        }
      }

      this.image.splice(index - 1, 1, data);
      this.mediaSrc.splice(index - 1, 1, {
        file: file,
        srcImage: data,
        type: file.type,
      });
    },
    increaseMsg() {
      if (this.controlMsgs > 2) return;
      this.controlMsgs++;
    },
    decreaseMsg() {
      if (this.controlMsgs == 1) return;
      this.controlMsgs--;
    },
    addClient(client) {
      this.clients.push(client);
    },
    removeClient(client) {
      this.clients = this.clients.filter((el) => {
        if (el.name != client.name && el.phone != client.phone) return el;
      });

      if (this.clients == undefined) this.clients = [];
    },
    removeAllClients() {
      this.clients = [];
    },
    getArrLength() {
      return this.text.length > this.image.length
        ? this.text.length
        : this.image.length;
    },
    submitCampaign() {
      if (this.clients == "") {
        Swal.fire({
          type: "error",
          title: "Erro!",
          text: "Informe os contatos da campanha!",
        });

        return;
      }

      if (
        (this.text == "" || this.text == "Digite sua mensagem aqui") &&
        this.mediaSrc == ""
      ) {
        Swal.fire({
          type: "error",
          title: "Erro!",
          text: "Digite a mensagem da campanha!",
        });

        return;
      }

      if (this.campanha == "") {
        Swal.fire({
          type: "error",
          title: "Erro!",
          text: "Digite o nome da campanha!",
        });

        return;
      }

      if (
        this.clients !== "" &&
        (this.text !== "" || this.mediaSrc !== "") &&
        this.campanha !== ""
      ) {

        const id = this.campanha || Date.now();
        this.clients.forEach((el) => (el.id = id));
        const index = this.getArrLength();
        const mensagem = [];

        for (let i = 0; i < index; i++) {
          if (this.mediaSrc[i]) {
            mensagem[i] = {
              text: this.text[i],
              media: {
                type: this.mediaSrc[i] ? this.mediaSrc[i].type : null,
                filename: this.mediaSrc[i] ? this.mediaSrc[i].file.name : null,
                file: this.mediaSrc[i].srcImage
                  ? this.mediaSrc[i].srcImage
                  : null,
              },
            };

            try {
              if (mensagem[i]["media"].file.match(/,(.+)$/)) {
                mensagem[i]["media"].file =
                  mensagem[i].media.file.match(/,(.+)$/)[1];
              }
            } catch {
              delete mensagem[i].media;
            }
          }

          if (!this.mediaSrc[i]) {
            mensagem[i] = {
              text: this.text[i],
            };
          }
        }

        this.clients.forEach((el) => {
          el["campanha"] = this.campanha;
        });

        let obj = {
          telefones: this.clients,
          mensagem: mensagem,
        };

        axios.post("/marketing/addCampaign", obj.telefones).then(() => {
          socket.emit("startCampaing", obj);

          Swal.fire(
            "Campanha Iniciada",
            "Os clientes selecionados vão receber a mensagem nos próximos minutos",
            "success"
          ).then(() => {
            location.reload();
          });

          this.campanha = "";
        });
      }
    },
  },
};
</script>

<style scoped>
#msg-text-slot {
  max-height: 25vh;
  overflow-y: auto;
}

.col {
  padding-right: 0;
  padding-left: 0;
}

.name-filter button {
  margin-left: 40px;
}

.submit-form-container {
  margin-top: 12px;
}

.submit-button {
  color: #fff;
  background: #25d366;
  width: 180px;
  border: none;
  font-weight: bold;
  border-radius: 10px !important;
  z-index: 10;
}

.submit-button:hover {
  background: #157237;
}

.action-buttons {
  margin: 10px 0px;
  text-align: center;
}

#add-button {
  color: #635994;
  font-weight: bold;
  font-size: 14px;
  background-color: white;
  border-radius: 10px !important;
  border: 3px solid #635994;
  padding-left: 2px;
  padding-right: 2px;
}

#add-button:hover {
  color: white;
  border: 3px solid #635994;
  background-color: #8f81d3b7;
}

#del-button {
  color: #f92f60;
  font-weight: bold;
  font-size: 14px;
  background-color: white;
  border-radius: 10px !important;
  border: 3px solid #f92f60;
  padding-left: 2px;
  padding-right: 2px;
}

#del-button:hover {
  color: white;
  border: 3px solid #f92f60;
  background-color: #f92f617e;
}
</style>
