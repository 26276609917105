const state = {
	id: '',
	queues: '',
	hasTab: '',
	extension: '',
	myChannel: '',
}

const getters = {
	getExtension: (state) => () => state.extension,
	getChannel: (state) => () => state.myChannel,
	getQueues: state => state.queues,
	getId: state => state.id,
	// getHasTab: state => state.has_tab,
}

const mutations = {
	insertExtension: (state, extension) => (state.extension = extension),
	insertQueues: (state, queues) => (state.queues = queues),
	insertId: (state, id) => (state.id = id),
	insertChannel: (state, myChannel) => (state.myChannel = myChannel),
	insertTab: (state, hasTab) => (state.hasTab = hasTab),
}

const actions = {
	async setExtension({ commit }, data) {
		commit('insertExtension', data)
	},
	async setQueues({ commit }, data) {
		commit('insertQueues', data)
	},
	async setId({ commit }, data) {
		commit('insertId', data)
	},
	async setChannel({ commit }, data) {
		commit('insertChannel', data)
	},
	async setHasTab({ commit }, data) {
		commit('insertTab', data)
	}
}

export default {
	getters,
	mutations,
	actions,
	state
}