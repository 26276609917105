<template>
  <section>
    <div id="modalRealtime" :class="['modalPause', modal.show]">
      <div class="conteudo-modal d-flex flex-column align-items-center">
        <h4>Selecione a Pausa</h4>
        <hr class="hr-modal">
        <button class="pausa-btn" v-for="el in modal.pausas" :key="el" @click="setPause(el)">{{ el }}</button>
      </div>
    </div>
    <div class="card-realtime col" :class="{'dndBorder': dndStatus}" :name="extension">
      <div style="position: absolute">
        <div v-show="dndStatus" class="dndTag">🚫 DND</div>
      </div>
      <div class="row">
        <div class="col-4">
          <img src="/imgs/default-profile.png" class="rounded-circle">
        </div>
        <div class="col-8">
          <div class="row nome" >
            <p> {{ convertName(nome) }} </p>
          </div>
          <div class="row">
            <div class="col-6" style="text-align: center">
              <p> {{ extension }} </p>
            </div>
            <!-- <div class="col-6">
              <p class="statusLabel" v-if="userStatus == 'ativo'"> <i class="fas fa-circle text-success fa-beat-fade" style="--fa-animation-duration: 1.5s;"></i> Online </p>
              <p class="statusLabel" v-else-if="userStatus == 'deslogado'"> <i class="fas fa-circle text-danger"></i> Offline </p>
              <p class="statusLabel" v-else-if="userStatus == 'ligacao'"> <i class="fas fa-phone text-primary"></i> Em ligação </p>
              <p class="statusLabel" v-else-if="userStatus == 'pausa'"> <i class="fas fa-pause text-warning"></i> Em pausa </p>
              <p class="statusLabel" v-else> {{userStatus}} </p>
            </div> -->
            <div v-if="extensionStatus" class="d-flex">
                <i class="fas fa-circle text-success fa-beat-fade icon-extension-status" style="--fa-animation-duration: 1.5s;"></i>
                <p class="statusLabel" v-if="extensionStatus">Ramal<br>Online</p>
            </div>
            <div v-else class="d-flex">
                <i class="fas fa-circle text-danger icon-extension-status"></i>
                <p class="statusLabel">Ramal<br>Offline</p>
            </div>
          </div>
        </div>
      </div>

      <div :class="['row', 'linha-status', userStatus, {'open': open}]">
        <i :class="['fas', 'fa-greater-than', userStatus]" @click="open = !open"></i>
        <div class="functions-btn row">

          <i class="fas fa-phone fa-fw cursor-pointer" title="Ligar" @click="ligar"></i>
          <i class="fas fa-phone-slash fa-fw cursor-pointer" title="desligar" @click="desligar"></i>
          <i class="fas fa-hand-rock fa-fw cursor-pointer" title="Puxar" @click="puxar"></i>
          <i class="fas fa-headphones fa-fw cursor-pointer" title="Escutar" @click="escutar"></i>
          <i class="fas fa-phone-volume fa-fw cursor-pointer" title="Sussurar" @click="sussurar"></i>
          <i class="fas fa-pause fa-fw cursor-pointer" title="Pausar" @click="pausar"></i>
          <i class="fas fa-power-off cursor-pointer" title="Deslogar" @click="logoff"></i>

          <div :class="[{'hide': open}, 'row', 'show-info']">
                        <span v-if="call.num != ''" class="statusLabel">
                            <i v-if="call.type == 'sainte'" class="fas fa-arrow-up text-danger font-weight-bold"></i>
                            <i v-else-if="call.type == 'entrante'" class="fas fa-arrow-down text-success font-weight-bold"></i>
                            <i v-else-if="call.type == 'transfer'" class="fas fa-arrows-h icone-tocando"></i>
                            <i v-else-if="call.num.includes('Tocando')" class="fas fa-volume-control-phone icone-tocando fa-beat-fade"  style="--fa-animation-duration: 0.5s;"></i>
                              {{call.num}}
                        </span>
            <span v-else>
                            <p class="statusLabel" v-if="userStatus == 'ativo'">Disponível</p>
                            <p class="statusLabel" v-else-if="userStatus == 'deslogado'">Indisponível</p>
                        </span>

            <span class="timer"> <i class="fas fa-clock fa-fw"> </i> {{ call.time }}</span>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from './status'

String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds;
}

String.prototype.toSeconds = function(){
  if(!this.match(/\d{2}:\d{2}:\d{2}/g)) return 'Bad foramt';
  let hhmmss = this.split(':');

  let hour = Math.floor(parseInt(hhmmss[0]) * 3600);
  let minutes = Math.floor(parseInt(hhmmss[1]) * 60);
  let seconds = parseInt(hhmmss[2]);

  return hour + minutes + seconds;
}

export default {
  props: ['nome', 'extension', 'status', 'connection', 'departments', 'id'],
  data: function(){
    return {
      modal: {pausas: [], show: ''},
      intervalTimer: {},
      ramal: user.getExtension(),
      userStatus: this.status,
      open: false,
      oldStatus: '',
      oldTimer: '00:00:00',
      call: {
        time: '00:00:00',
        channel: '',
        num: '',
        oldNum: '',
        type: '',
      },
      queues: [],
      dndStatus: false,
      extensionStatus: false
    }
  },
  watch:{
    userStatus: function() {
      store.setStatus(this.extension, this.userStatus);
    },
    dndStatus: function(value) {
      if(value){
        setTimeout(() => {
          this.dndStatus = false
        }, 2500)
      }
    }
  },
  computed:{
    classeRealtime(){
      return [
        `card-realtime col`
      ]
    },
  },
  methods: {
    convertName(name){
      let n = name.trim().split(" ");
      if(n.length > 2){
        let nReturn = '';
        for(let i = 0; i < 2; i++){
          if(n[i]){
            nReturn += n[i].trim()+" ";
          }
        }
        return nReturn.trim();
      }
      else{
        return name;
      }
    },
    ligar(){
      this.connection.emit('Originate', {
        calltype: 'outgoing',
        extension: this.ramal,
        phone: this.extension
      });
      this.call.type = 'sainte';
    },
    desligar(){
      this.connection.emit('hangup', {
        channel: this.call.channel
      });
      this.call.type = '';
    },
    puxar(){
      const data = {
        channel: this.ramal,
        exten: `701${this.extension}`
      };
      this.call.type = '';

      this.connection.emit('pickupCall', data);
    },
    escutar(){
      const data = {
        channel: this.ramal,
        exten: `700${this.extension}`
      };
      this.call.type = '';

      this.connection.emit('listenOperator', data);
    },
    sussurar(){
      const data = {
        channel: this.ramal,
        exten: `702${this.extension}`
      };
      this.call.type = '';

      this.connection.emit('suportOperator', data);
    },
    pausar(){
      if(this.userStatus == 'pausa'){
        this.connection.emit('QueueUnPause', {
          extension: this.extension,
          id: this.id
        });
        this.call.type = '';
        return;
      }
      this.ModalPause();
    },
    logoff(){
      if(this.oldStatus.toLowerCase() == 'deslogado'){
        this.connection.emit('QueueAdd', {
          queues: [user.getQueue()],
          extension: this.extension
        });
      }
      else{
        this.connection.emit('QueueRemove', {
          queues: [user.getQueue()],
          extension: this.extension
        });
      }
      this.call.type = '';
    },
    setPause(reason){
      this.connection.emit('QueuePause', {
        extension: this.extension,
        reason: reason,
        id: this.id
      });

      this.userStatus = 'pausa';
      this.oldStatus = 'ativo';
    },
    ModalPause() {
      axios.get("dialer/listBreaks")
          .then(response => {
            var pausas = [];
            response.data.forEach(el => {
              if (el.name != "Ativar") {
                pausas.push(el.name)
              }
            });
            this.modal.pausas = pausas;
            this.modal.show = 'mostrar';
          });
    },
    calcTimer(time){
      clearInterval(this.intervalTimer);
      let tempo  = time.toSeconds();
      this.intervalTimer =  setInterval(() => {
        this.call.time = tempo.toString().toHHMMSS();
        tempo++;
      }, 1000);
    },
    stopTimer(){
      clearInterval(this.intervalTimer);
      if (this.oldStatus == "pausa") {
        this.call.num = this.call.oldNum;
        this.userStatus = this.oldStatus;
        this.oldStatus = "ativo";
        this.calcTimer(this.oldTimer);
        return;
      }

      this.userStatus = this.oldStatus;
      this.call.time = "00:00:00";
      this.call.num = '';
      this.call.type = '';
    },
    queuemember: async function(){
      this.connection.on(`QueueMember-${this.extension}`, (data) => {
        if(this.userStatus  == 'pausa') return;
        if(this.userStatus  == 'ligacao') return;

        this.extensionStatus = (data.Status == '4' || data.Status == '5') ? false : true;
        this.oldStatus = 'ativo';

        if(data.Paused == '1'){
          this.call.num = data.PausedReason;
          this.call.oldNum = this.call.num;
          this.userStatus = 'pausa';
          this.oldStatus = 'ativo';
          this.oldTimer = data.LastPause.toHHMMSS();
          this.calcTimer(data.LastPause.toHHMMSS());
          return;
        }

        this.userStatus = 'ativo';
        this.oldStatus = this.userStatus;
      });
    },
    coreshowchannel: async function(){
      this.connection.on(`CoreShowChannel-${this.extension}`, (data) => {
        if(this.userStatus == 'pausa') {
          this.call.oldNum = this.call.num;
        }

        this.userStatus = 'ligacao'; //Alteração do status do card para Ligação

        if(data.type.includes('entrante')){
          this.call.type = 'entrante';
        } else if(data.type.includes('sainte')){
          this.call.type = 'sainte';
        } else if(data.type.includes('transfer')){
          this.call.type = 'transfer';
        } else {
          this.call.type = '';
        }

        if(data.status == 'ringing') {
          this.call.num = `Tocando ${data.phone}`;
        } else {
          this.call.num = data.phone;
        }

        this.call.channel = data.channel;
        this.calcTimer(data.duration);
        return;
      });
    },
    queuememberadded: async function(){
      this.connection.on(`QueueMemberAdded-${this.extension}`, data => {
        this.userStatus = 'ativo';
        this.oldStatus = this.userStatus;
        this.call.type = '';
      });
    },
    queuememberremoved: async function(){
      this.connection.on(`QueueMemberRemoved-${this.extension}`, data => {
        this.userStatus = 'deslogado';
        this.oldStatus = this.userStatus;
        this.call.type = '';
      });
    },
    dnd: async function() {
      this.connection.on(`DoNotDisturb-${this.extension}`, data => {
        this.dndStatus = !this.dndStatus;
        // this.dndStatus = false;
      })
    },
    queuememberpause: async function(){
      this.connection.on(`QueueMemberPause-${this.extension}`, data => {
        if(this.userStatus == 'ligacao') {
          if(this.oldStatus == 'pausa') {
            this.oldStatus = 'ativo';
          } else {
            this.oldStatus = 'pausa';
            this.oldTimer = '00:00:00';
          }
          return;
        }

        //Pausa
        if(data.Paused == 1) {
          this.oldStatus = 'pausa';
          this.oldTimer = '00:00:00';
          this.call.oldNum = data.PausedReason ? data.PausedReason : data.Reason;
          this.stopTimer();
          return;
        }

        //Ativo
        this.oldStatus = 'pausa';
        this.userStatus = 'ativo';
        this.call.num = '';

        clearInterval(this.intervalTimer);
        this.call.time = '00:00:00';
        this.call.type = '';

        return;
      });
    },
    newconnectedline: async function(){
      this.connection.on(`NewConnectedLine-${this.extension}`, data => {
        if(this.userStatus == 'pausa') {
          this.call.oldNum = this.call.num;
        }

        this.userStatus = 'ligacao'; //Alteração do status do card para Ligação

        if(data.type.includes('entrante')){
          this.call.type = 'entrante';
        } else if(data.type.includes('sainte')){
          this.call.type = 'sainte';
        } else if(data.type.includes('transfer')){
          this.call.type = 'transfer';
        } else {
          this.call.type = '';
        }

        if(data.status == 'ringing') {
          this.call.num = `Tocando ${data.phone}`;
        } else {
          this.call.num = data.phone;
        }

        this.call.channel = data.channel;
        this.calcTimer('00:00:00');

        return;
      });
    },
    peerStatus: async function(){
        this.connection.on(`PeerStatus-${this.extension}`, data => {
            this.extensionStatus = (data == 'Registered') ? true : false;
            if (data != 'Registered' && this.userStatus == "ligacao") this.userStatus = this.oldStatus;
        });
    },
    hangup: async function(){
      this.connection.on(`Hangup-${this.extension}`, data => {
        this.stopTimer();
      });
    }
  },
  mounted(){
    document.querySelector("body").addEventListener("click", e => {
      if(!e.target.classList.contains("conteudo-modal")) {
        this.modal.show = '';
      }
    });

    this.userStatus = !this.userStatus ? "ativo" : "deslogado";
    this.oldStatus = this.userStatus;

    this.queuemember();
    this.coreshowchannel();

    this.queuememberadded();
    this.queuememberremoved();
    this.dnd();

    this.queuememberpause();
    this.newconnectedline();

    this.peerStatus();

    this.hangup();
  },
}
</script>

<style scoped>
.dndBorder {
  box-shadow: 0px 0px 5px 3px #f00 !important;
}

.dndTag {
  color: white;
  background-color: red;
  font-size: smaller;
  position: relative;
  border-radius: 5px;
  padding: 0px 4px;
  top: -10px;
  left: 9em;
}
.pausa-btn {
  width: 80%;
  border: 0;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 0.374em !important;
  background-color: #f5f5f5;
}

hr.hr-modal {
  margin-top: 0px;
  border: 0;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.mostrar {
  display: block !important;
}

.conteudo-modal{
  background-color: #fefefe;
  margin: auto;
  padding: 40px;
  border: 1px solid #888;
  width: 25%;
  border-radius: 0.5em;
}

.modalPause{
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

section{
  margin-right: 1em;
  margin-top: 1em;
}
.nome{
  margin-left: 0.1em;
  margin-top: 0.5em;
  font-weight: bold;
}
img{
  height: 5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: 10px solid transparent;
}


.hide{
  display: none
}
.linha-status{
  background-color: grey;
  padding: 0.4em;
  height:30px;
  border-radius: 0px 0px 6px 7px;

}
.linha-status i{
  cursor:pointer;
}
.linha-status .fa-greater-than{
  color: #f2e5e4;
  border-radius: 50%;
  background-color: grey;
  padding: 10px;
  position: absolute;
  margin-top: -8px;
  transition: 1s;
}
.open .fa-greater-than{
  margin-left: 90%;

  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.linha-status .Deslogado{
  /* display:none; */
}
.functions-btn{
  margin-left: 1em;
  color:white;
}
.functions-btn i{
  display:none;
  transition: 1s;
}
.open .functions-btn i {
  display: block;
  margin-right: 1.5em;
}
.ativo{
  background-color: #17a2b8;
}
.ligacao{
  background-color: #16a085;
}
.pausa{
  background-color: #f39c12;
}
.card-realtime{
  background-color: #ecf0f1;
  /* max-width: 20%; */
  min-width: 20em;
  margin-right: 1em;
  border: 2px solid ;
  border-radius: 9px;
  border-color: #ecf0f1;

}
.show-info{
  margin-left: 1.9em;
}
.show-info span[text*='Deslogado']{
  margin-left: 1em;
}
.show-info i{
  display: inline; /* SOBRESCREVE .functions-btn i */
}
.show-info .timer{
  position: absolute;
  margin-left: 11.8em;
  margin-top: -0.2em;
}

.statusLabel {
  white-space: nowrap;
  font-size: x-small;
  font-weight: bold;
  margin: 0px;
}

.icone-tocando {
  color: darkblue;
}

.icon-extension-status {
  align-self: center;
  margin-right: 5px;
}

</style>