<template>
  <div class="background-modal align-modal" id="outModal" onkeypress="onClick">
    <div class="content">
      <div class="header"> 
        <div id="header-row">
          <p id="header-title">Detalhes do contato</p>
          <i class="fas fa-times" id="close-button" @click="dismissModal"></i>
        </div>
      </div>
      <div class="body">
        <div id="img-container">
          <img src="/imgs/default-profile.png">
        </div>
        <div id="details-container">
          <div class="custom-input-group">
            <label for="name">Nome</label>
            <input type="text" class="form-control" name="name" id="name" v-model="updateData.name" required :disabled="disable">
          </div>
          <div class="custom-input-group">
            <label for="phone">Telefone</label>
            <input type="text" class="form-control campoTel" pattern="\d+" name="phone" id="vlTel1" v-model="updateData.phone"  maxlength="15" required :disabled="disable">
          </div>
          <div class="custom-input-group">
            <label for="empresa">Empresa</label>
            <input type="text" class="form-control" name="empresa" id="empresa" v-model="updateData.company_name"  :disabled="disable">
          </div>
          <div class="custom-input-group">
            <label for="chave-unica">CPF/CNPJ</label>
            <input type="text" class="form-control" name="chave-unica" id="chave-unica" v-model="updateData.unique_key" :disabled="disable">
          </div>
        </div>
      </div>
      <div>
        <div id="button-group">
          <button type="button" :hidden="disable"  @click="updateCl" class="btn btn-success cursor-pointer" id="btn-save"><i class="fa fa-save"></i>    Salvar</button>
          <button type="button" :hidden="disable"  @click="deleteCl" class="btn btn-danger cursor-pointer" id="btn-delete"><i class="fa fa-trash"></i>    Excluir</button>
          <button type="button" :hidden="!disable" @click="enableEdit" class="btn btn-info cursor-pointer" id="btn-edit"><i class="fa fa-edit"></i>    Editar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      show: true,
      arquivos: [],
      disable: true,
      updateData: {
        name: this.data.name,
        phone: this.data.phone,
        unique_key: this.data.unique_key,
        company_name: this.data.company_name,
      },
    };
  },
  mounted() {
    this.$el.addEventListener("click", this.onClick);
    document.addEventListener("keyup", this.onClick);
  },
  destroyed() {
    this.$el.removeEventListener("click", this.onClick);
    document.removeEventListener("keyup", this.onClick);
  },
  methods: {
    enableEdit() {
      this.disable = !this.disable;
    },
    onClick(e) {
      if (e.target.id == "outModal" || e.key == "Escape")
        this.$emit("dismissModal");
    },
    dismissModal() {
      this.$emit("dismissModal");
    },
    deleteCl() {
      axios
        .post("/customer_base/destroy", { id: this.data.id })
        .then(() => {
          Swal.fire(
            "Registro Excluído",
            "Este cliente não faz mais parte de sua base",
            "success"
          ).then(() => {
            this.dismissModal();
          });
        });
    },
    updateCl() {
      this.updateData.phone = this.updateData.phone
        ? this.updateData.phone.replace(/\D/g, "")
        : null;

      if (this.checkForm) {
        axios.post("/customer_base/save", {
            id: this.data.id,
            name: this.updateData.name,
            phone: this.updateData.phone,
            unique_key: this.updateData.unique_key,
            company_name: this.updateData.company_name,
          }).then(() => {
            Swal.fire(
              "Atualização realizada",
              "Os dados deste cliente foram atualizados",
              "success"
            ).then(() => {
              this.dismissModal();
            });
          });
      }
    },

    checkForm: function () {
      if (this.updateData.name == "") {
        Swal.fire({
          html: 'O campo "Nome" não foi preenchido!',
          type: "warning",
          showCloseButton: true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i>',
        });
        return false;
      }
      if (this.updateData.phone == "") {
        Swal.fire({
          html: 'O campo "Telefone" não foi preenchido!',
          type: "warning",
          showCloseButton: true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i>',
        });
        return false;
      }
      if (
        this.updateData.phone.length != 10 &&
        this.updateData.phone.length != 11
      ) {
        Swal.fire({
          html: "Número de telefone inválido!",
          type: "warning",
          showCloseButton: true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i>',
        });
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.background-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(125, 125, 125, 0.5);
}

.align-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

#header-row {
  display: flex;
  justify-content: space-between;
  background-color: #1c869a;
  border-top: 3px solid #f38630;
}

#header-title {
  color: white;
  font-weight: bold;
  margin: 5px 20px;
  align-self: center;
}

#close-button {
  font-weight: bolder;
  background-color: white;
  color: #aa1a1a;
  cursor: pointer;
  border: 1px solid #aa1a1a;
  border-radius: 10px !important;
  padding: 8px;
  align-self: center;
  margin: 5px;
}

#close-button:hover {
  background-color: #aa1a1a;
  color: white;
  transition: 0.5s;
  border: 1px solid white;
}

.content {
  background-color: #fff;
  width: 35em;
  border-radius: 15px;
  overflow: auto;
}

.body {
  display: flex;
  flex-flow: row wrap;
  overflow: auto;
  justify-content: center;
}
.row {
  width: 100%;
}

.body img {
  border-radius: 50%;
  height: 7em;
  border: 5px solid #1c869a66;
}

#img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

#details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.custom-input-group {
  padding: 10px;
}

.custom-input-group label {
  align-self: center;
  font-weight: bold;
}

#btn-save {
  color: white;
  background-color: #034103;
  border: 3px solid white;
  border-radius: 10px !important;
}

#btn-save:hover {
  color: #034103;
  background-color: white;
  border: 3px solid #034103;
  border-radius: 10px !important;
  transition: 0.5s;
}

#btn-delete {
  color: white;
  background-color: #aa1a1a;
  border: 3px solid white;
  border-radius: 10px !important;
}

#btn-delete:hover {
  color: #aa1a1a;
  background-color: white;
  border: 3px solid #aa1a1a;
  border-radius: 10px !important;
  transition: 0.5s;
}

#btn-edit {
  color: white;
  background-color: #0e0e69;
  border: 3px solid white;
  border-radius: 10px !important;
}

#btn-edit:hover {
  color: #0e0e69;
  background-color: white;
  border: 3px solid #0e0e69;
  border-radius: 10px !important;
  transition: 0.5s;
}

#button-group {
  display: flex;
  justify-content: flex-end;
  margin: 10px 20px;
}
</style>