<template>
  <div :class="[`rankingItem col-md-4 ${progressGoalPercentageCalls >= 100 ? 'concluidaMeta' : (progressGoalPercentageCalls < 40 ? 'abaixoMeta' : 'mediaMeta')}`]" style="margin: 15px 0px;">
    <div class="col-md-12" style="background-color: #ecf0f1; padding: 15px;">
      <div class="row">
        <div class="col-md-4 d-none d-md-block d-lg-block">
          <img v-if="image != 'null'"
            :src="`/storage/${image}`"
            alt="Imagem de perfil"
            class="rounded-circle"
            style="width: 110%"
          />
           <img v-else
            src="/imgs/default-profile.png"
            alt="Imagem de perfil"
            class="rounded-circle"
            style="width: 110%"
          />
        </div>
        <div class="col-md-8" style="font-size: 12px;">
          <strong>Nome:</strong>
          {{name}}
          <br />
          <strong>Ramal:</strong>
          {{extension}}
          <br />
          <div class="progress">
            <div
              :class="[`progress-bar ${progressGoalPercentageCalls >= 100 ? 'bg-success' : (progressGoalPercentageCalls < 40 ? 'bg-danger' : 'bg-primary')} progress-bar-striped progress-bar-animated`]"
              role="progressbar"
              :style="`width:${progressGoalPercentageCalls}%`"
              :aria-valuenow="progressGoalPercentageCalls"
              aria-valuemin="0"
              aria-valuemax="100"
            >{{progressGoalPercentageCalls}}%</div>
          </div>
          <strong>Meta ligações:</strong>
           {{goalCalls}}
          <span class="money"></span>
          <br />
          <strong>Concluído:</strong>
          {{progressGoalCalls}}
          <span class="money"></span>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["image", "name", "extension", "progressGoalCalls", "progressGoalPercentageCalls", "goalCalls"],
  mounted() {

  }
};
</script>