<template>
  <div class="col-12">
    <div class="alert alert-primary" v-if="type === 'primary'" role="alert">
      <i class="fas fa-spinner fa-pulse"></i>
      <strong>Carregando</strong>
      - {{message}}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span v-if="dismiss" aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="alert alert-secondary" v-if="type === 'secondary'" role="alert">
      <i class="fas fa-spinner fa-pulse"></i>
      <strong>Carregando</strong>
      - {{message}}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span v-if="dismiss" aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="alert alert-success" v-if="type === 'success'" role="alert">
      <i class="fas fa-check-circle fa-fw"></i>
      <strong>Sucesso!</strong>
      - {{message}}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span v-if="dismiss" aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="alert alert-danger" v-if="type === 'danger'" role="alert">
      <i class="fas fa-times-circle fa-fw"></i>
      <strong>Erro!</strong>
      - {{message}}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span v-if="dismiss" aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="alert alert-warning" v-if="type === 'warning'" role="alert">
      <i class="fas fa-exclamation-triangle fa-fw"></i>
      <strong>Atenção!</strong>
      - {{message}}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span v-if="dismiss" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="alert alert-info" v-if="type === 'info'" role="alert">
      <i class="fas fa-info-circle"></i>
      <strong>Observação:</strong>
      {{message}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span v-if="dismiss" aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- <div class="alert alert-light" role="alert"></div>
    <div class="alert alert-dark" role="alert"></div>-->
  </div>
</template>
<script>
export default {
  props: {
    message: String,
    type: String,
    dismiss: { type: Boolean, default: true }
  },
  mounted() {}
};
</script>