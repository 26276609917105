<template>
	<div class="nova-ocorrencia" v-if='getOccurrences && getNewOccurrence'>
		<div class="row">
			<div class="col-md-4">
				<label for="protocolo">Número da Ocorrência</label>
				<input class="form-control" type="text" id="protocolo" name="protocolo" v-model='protocolo'>
			</div>
			<div class="col-md-4">
				<label for="assunto">Assunto</label>
				<input class='form-control' type="text" id="assunto" name="assunto" v-model='assunto'>
			</div>
			<div class="col-md-4">
				<label for="status">Status</label>
				<select class="form-control" id="status" name="status" v-model='status'>
					<option value="" selected>Selecione</option>
					<option value="2">Aberto</option>
					<option value="1">Fechado</option>
					<option value="3">Aguardando</option>
				</select>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-md-12">
				<label for="descricao">Descrição</label>
				<textarea class="form-control" rows="4" id="descricao" name="descricao" cols="50" v-model='desc'>
				</textarea>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-md-3">
				<button @click.prevent="create()" class="btn btn-primary">Salvar</button>
			</div>
		</div>
	</div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    computed:{
        ...mapGetters(['getOccurrences','getNewOccurrence'])
    },
    methods:{
        ...mapActions(['createOccurrence']),
        create(){
            this.createOccurrence({
				"subject": this.assunto,
				"description": this.desc,
				"protocol": this.protocolo,
				//esses dois sao teste, preciso pensar como fazer isso edepois tirar as constantes
				"at_status_occurrences_id": 1,
				"set_client_occurrences_id": 34,
				"at_user_id": 1
			});
        }
    },
    data(){
        return{
            desc:'',
            assunto:'',
            protocolo:'',
            status:''
        }
    }
}
</script>

<style>
	.nova-ocorrencia {
		display: flex;
		flex-direction: column;
		padding: 2rem;
	}
</style>
