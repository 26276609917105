<template>
    <section id="contact-popup">
        <div id="container-contacts-form" class="container d-flex">
            <div class="text-align-start formHeader">
                <img src="/imgs/icons/adv-logo-sm.png" alt="Advance Management Logo" style="max-width: 2em; margin-right: .5em">
                <!-- <i class="fa fa-address-book fa-2x" aria-hidden="true" style="color: #fff"></i> -->
                <span style="vertical-align: middle; font-weight: 600"> Cadastro de Contatos</span>
            </div>
            <!-- <div id="div-title">
                <i class="fa fa-address-book fa-2x" aria-hidden="true" style="color: rgb(28,134,154)"></i>
                <h2 id="container-title">   Cadastro de Contatos</h2>
            </div> -->
            <form action="" id="contacts-form">
                <div class="row custom-row">
                    <div class="input-group">
                        <label for="contactName">Nome</label>
                        <input 
                            class="form-control" 
                            name="contactName" 
                            type="text" 
                            v-model="contact.name" 
                            placeholder="Insira o nome do contato" 
                            required>
                    </div>
                </div>
                <div class="row custom-row">
                    <div class="input-group">
                        <label for="contactPhone">Telefone</label>
                        <vue-tel-input 
                            id="phone-input" 
                            :inputOptions="optionsTelInput" 
                            mode="international" 
                            v-model="contact.phone">
                        </vue-tel-input>
                    </div>
                </div>
                <div class="row custom-row">
                    <div class="col">
                        <div class="input-group">
                	        <label for="contactCorporation">Empresa</label>
                	        <input 
                	          class="form-control" 
                	          name="contactCorporation" 
                	          type="text" 
                	          v-model="contact.company_name"
                	          placeholder="Insira a empresa do contato"
                	        >
                        </div>
                    </div>
                    <div class="input-group mt-2">
                      <select class="form-control mr-3 ml-3" name="id-cpf" id="id-cpf" v-model="idCpf" style="flex: 1">
                          <option value="cpf">CPF</option>
                          <option value="cnpj">CNPJ</option>
                      </select>
                      <input v-if="idCpf == 'cpf'"
                          class="form-control" 
                          name="contactKey" 
                          type="text" 
                          v-model="contact.unique_key"
                          v-mask="'###.###.###-##'"
                          placeholder="XXX.XXX.XXX-XX"
                          style="flex: 4"
                      >
                      <input v-if="idCpf == 'cnpj'"
                          class="form-control"
                          name="contactKey"
                          type="text"
                          v-model="contact.unique_key"
                          v-mask="'##.###.###/####-##'"
                          placeholder="XX.XXX.XXX/XXXX-XX"
                          style="flex: 4"
                      >
                  </div>
                </div>
                <div v-if="saving">
                    <p id="saving"><strong><i class="fas fa-spinner fa-pulse"></i> Salvando contato...</strong></p>
                </div>
                <div v-else >
                    <button    
                        type="submit"
                        class="btn btn-success"
                        id="btn-save"
                        form="chamadoForm"
                        @click.prevent="saveContact()"
                    ><i class="fas fa-check"></i>
                        Salvar
                    </button>
                    <button 
                        type="submit"
                        class="btn btn-danger"
                        id="btn-cancel"
                        form="chamadoForm"
                        @click.prevent="resetContact()"
                    ><i class="fas fa-window-close fa-fw"></i>
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

export default {
  name: "Contacts",
  props: ['addWppContact'],
  data() {
    return {
      contact: {
        name: "",
        phone: "",
        company_name: "",
        filter: "",
        unique_key: "",
      },
      saving: false,
      idCpf: "cpf",
      optionsTelInput: {
        placeholder: 'Insira o telefone',
        required: true
      },
    };
  },
  components: {
    ...mapGetters(["getContacts"]),
  },
  methods: {
    checkForm: function () {
      if (this.contact.name == '') {
        Swal.fire({
          html: 'O campo "Nome" não foi preenchido!',
          type: "warning",
          showCloseButton: true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i>'
        });

        return false
      }

      if (this.contact.phone == '') {
        Swal.fire({
          html: 'O campo "Telefone" não foi preenchido!',
          type: "warning",
          showCloseButton: true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i>'
        });

        return false
      }

      if (this.contact.unique_key == '') {
        Swal.fire({
          html: 'O campo "CPF/CNPJ" não foi preenchido!',
          type: "warning",
          showCloseButton: true,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i>'
        });

        return false
      }

      return true;
    },
    saveContact: function () {
      if (this.checkForm()) {
        this.saving = true;
        axios.post("dialer/saveContact", this.contact).then(({ data }) => {
          if (data.passed == false) {
            let str = "";
            Object.keys(data.errors).forEach((key) => {
              str += data.errors[key];
            });

            Swal.fire({
              type: "warning",
              title: "Não foi possível inserir o cliente a base",
              text: str,
            }).then(() => {
              this.saving = false;
            });

            return;
          }

          Swal.fire({
            type: "success",
            title: "Feito!",
            text: "Contato salvo com sucesso!",
          }).then(() => {
            this.saving = false;
            this.contact.name = "";
            this.contact.phone = "";
            this.contact.company_name = "";
            this.contact.idCpf = "cpf";
            this.contact.unique_key = "";
            this.$emit("closeContacts");
          });
        });
      }
    },
    resetContact: function () {
      Swal.fire({
        title: "Deseja descartar as informações?",
        text: "Você perderá as alterações não salvas!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "rgb(28,134,154)",
        confirmButtonText: "Sim, descartar",
        cancelButtonText: "Voltar ao formulário",
      }).then((result) => {
        if (result.value) {
          this.saving = false;
          this.contact.name = "";
          this.contact.phone = "";
          this.contact.company_name = "";
          this.contact.idCpf = "cpf";
          this.contact.unique_key = "";
          this.$emit("closeContacts");
        }
      });
    },
    async setClientData(client) {
      if ((client.phone || client.name) && client.type == "chat") {
        let phoneArr = client.phone.split("@");
        let phoneEdited = phoneArr[0].substr(2);

        this.contact = {
          name: client.name,
          phone: phoneEdited,
        };
      }
    },
  },
  computed: {
    ...mapGetters(["getContacts"]),
    ...mapActions(["setMessage"]),
  },
  watch: {
    addWppContact(data) {
        if(data) {
          const crtPhone = data.phone?.slice(0, 2) == "55" ? `+${data.phone}` : `+55${data.phone}`;
            this.contact = {
              'name': data.name,
              'phone': crtPhone,
            }
        }
    },
  },
  mounted() {
    if (localStorage.getItem("clienteAtual")) {
      const clienteAtual = JSON.parse(localStorage.getItem("clienteAtual"));
      if (Object.keys(clienteAtual).length) {
        this.setClientData(clienteAtual);
      }
    }
  },
};
</script>

<style scoped>
#contact-popup {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 18%;
    right: 20vw;
    z-index: 10;
}

.formHeader {
    background-color: rgb(28, 134, 154);
    border-top: 3px solid #f38630;
    color: white;
    padding: 0.2em;
    display: flex;
    justify-content: center;
    border-radius: 20px 20px 0px 0px;
}

.input-group {
    width: 90%;
    align-self: center;
    line-height: 2.55;
}

.custom-row {
    display: flex;
    justify-content: center;
}

#container-contacts-form {
    background-color: white;
    margin: 3em;
    width: 40vw;
    border: solid 2px lightgray;
    border-radius: 25px;
    text-align: center;
    flex-direction: column;
    margin: 10px;
    padding: 0;
}

#saving {
    color: rgb(28, 134, 154);
    font-size: 16px;
    text-align: center;
}

#div-title {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

#contacts-form {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#buttonGroup {
    display: flex;
    justify-content: center;
}

#btn-save {
    border: solid 2px #008000;
    border-radius: 20px;
    background-color: #008000;
    color: white;
    transition: 0.5s;
}

#btn-save:hover {
    color: #008000;
    background-color: white;
}

#btn-cancel {
    border: solid 2px #b30000;
    border-radius: 20px;
    background-color: #b30000;
    color: white;
    transition: 0.5s;
}

#btn-cancel:hover {
    color: #b30000;
    background-color: white;
}

section {
    display: flex;
    justify-content: center;
}

h2 {
    color: rgb(28, 134, 154);
}

label {
    margin: 10px;
    font-size: 16px;
    font-weight: 500;
}

select {
    align-self: center;
}

input {
    width: 40px;
    align-self: center;
}

button {
    width: 120px;
    margin: 20px;
    border-radius: 10px !important;
}
</style>
