<template>
  <div class="container">
    <div>

      <h2>Upload de Arquivos</h2>
      <hr/>

      <div class="drag-area flex w-full h-screen items-center justify-center text-center" @dragover.prevent="dragOver" @dragleave="dragleave" @drop.prevent @drop="dragFile">
        <input style="display:none;" type="file" id='file-upload' accept=".pdf,.jpg,.jpeg,.png" @change="handleFileUpload( $event )"/>

        <label for="file-upload" class="cursor-pointer text">
          Arraste o arquivo para área azul ou <span class="underline">clique aqui</span> para subir um novo arquivo
        </label>

        <div v-if="file">
          {{ file.name }}

        </div>

        <br>
        <button v-on:click="submitFile()">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            file: ''
        }
    },
    methods: {
        handleFileUpload( event ){
            this.file = event.target.files[0];
        },
        
        dragFile(e){
          document.getElementById('file-upload').files = e.dataTransfer.files;

          this.file = document.getElementById('file-upload').files[0];
          console.log('file', this.file);
          // Clean up
          e.currentTarget.classList.remove('gray');
          e.currentTarget.classList.add('salmon');

        },
        dragOver(event){
          event.currentTarget.classList.add('gray');
          event.currentTarget.classList.remove('salmon');
        },
        dragleave(event){
          // Clean up
          event.currentTarget.classList.remove('gray');
          event.currentTarget.classList.add('salmon');
        },
        submitFile(){

            let formData = new FormData();
            let file = document.getElementById('file-upload').files[0];

            formData.append('file', file);

            axios.post( '/catalogo/store',
               formData,{
                 headers:{
                   'Content-Type': 'multipart/form-data'
                 }
               }
            ).then((data) => {
              Swal.fire(
                'Upload realizado',
                'O arquivo foi adicionado',
                'success'
              )
              document.getElementById('file-upload').value = "";
              this.file = '';
              this.$emit('updateList');
            })
            .catch(function(){
                console.log('FAILURE!!');
            });
      },
    }
}
</script>

<style scoped>
.text{
  padding:1em;
}

.drag-area{

  display: block;
  width: 100%;
  background-color:rgba(28, 133, 154, .05);

}

.gray{
   background-color:rgba(28, 133, 154, 1);
}

.salmon{
  background-color:rgba(28, 133, 154, .05);
}
button{
  border: none;
  background-color: #1c869a;
  width: 49%;
  margin: 1em auto;
  padding: 1em;
  color: white;
  font-family: 'Noto Sans KR', sans-serif;
  text-transform: uppercase;
}

.underline{
  text-decoration: underline;
}
</style>