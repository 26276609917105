<template>
  <b-card border-variant="dark" style="height: 330px;">
    <h6>Ocupação de Agentes</h6>
    <div id="chart2">
      <apexchart type="bar" height="280" :options="chartOptions" :series="series"></apexchart>
    </div>
  </b-card>
</template>

<script>

export default {
  name: "GraficoOcupacaoAgent",

  data:() => ({
    chartOptions: {
      chart: {
        type: 'bar',
        height: 280,
      },
      colors: ['#188ae2', '#fe0000', '#ffed4a'],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'center',
          },
          columnWidth: '60%',
          distributed: true,
        }
      },
      grid: {
        show: true,
        borderColor: '#ffffff',
      },
      tooltip: {
        enabled: false
      },
      yaxis: {
        show: false,
      },
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways: true,
          style: {
            colors: ['#188ae2', '#fe0000', '#ecd202'],
            fontSize: '12px',
            cssClass: 'font-weight-bold',
          },
        },
        categories: ['Disponível', 'Deslogado', 'Atendimento'],
      },
      legend: {
        show: false,
      },
    },
    items: [],
  }),
  computed: {
    series() {
      return [{
        data: this.mountGrafic()
      }];
    },
  },
  created() {
    this.items = this.$parent.$refs.tbUsers.items;
  },
  methods: {
    mountGrafic() {
      let disponivel = 0, atendimento = 0, deslogado = 0;
      this.$parent.$refs.tbUsers.items.map((el) => {
        if (el.status.includes('em_atendimento')) {
          atendimento++;
        }
        if (el.status.includes('ativo') && !el.status.includes('em_atendimento')) {
          disponivel++;
        }
        if (el.status.includes('deslogado')) {
          deslogado++;
        }
      });
      return [disponivel, deslogado, atendimento];
    }
  },
  watch: {
    items() {
      this.mountGrafic();
    }
  }
}
</script>

<style scoped>
  .card {
    color: black !important;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 0.25rem !important;
  }
  .apexcharts-title-text {
    font-weight: bold !important;
  }
</style>