<template>
  <div>
    <h1 class="mb-4">Arquivos disponíveis no catálogo </h1>
    <div class="container center">
      <div class="card" v-for="arquivo in arquivos" :key="arquivo.id">
        <h2> {{ limitText(arquivo.name) }}</h2>
        <hr/>
        <p>
          O arquivo {{ arquivo.name }} foi adicionado {{ arquivo.created_at}}
        </p>
        <div class="row">
          <button class="green"  @click.prevent="downloadItem(arquivo.name, arquivo.path)">Download</button>
          <button @click="deleteItem(arquivo.path)">Excluir</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props:{
      upList:{
      type: Number
    }
    },
    data(){
        return {
            arquivos: []
        }
    },
    mounted(){
        axios.get('/catalogo/show').then(data => this.arquivos = data.data)
    },
    watch:{
      upList(){
        axios.get('/catalogo/show').then(data => this.arquivos = data.data)
      }
    },
    methods:{

      downloadItem(name, path){
        const url = `/catalogo/download/${path}`;
        axios.get(url, { responseType: 'blob' })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = name
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
      },

      deleteItem(path){
        Swal.fire({
          title: 'Tem certeza que deseja excluir?',
          text: "Não será possível recuperar o arquivo após excluir",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Sim, delete!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.value) {
              const url = `/catalogo/delete/${path}`;
              axios.post(url).then(data => {
                Swal.fire(
                  'Deletado',
                  'Seu arquivo foi deletado',
                  'success'
                )
                axios.get('/catalogo/show').then(data => this.arquivos = data.data)

              });
     
          }
        })
      },

      limitText(text){
        return text.slice(0, 30) + (text.length > 29 ? '…' : '');
      }
    }

}
</script>

<style scoped>

.center{
  display:flex;
  flex-flow: row wrap;
}

.card {
  background-color: white;
  min-width: 250px;
  max-width: 250px;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
  margin: 5px;
  border-radius: 10px;

}
.card h1, .card h2, .card h3, .card h4, .card h5 {
  margin: 0px;
  padding: 0px 0px 15px 0px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 30px;
  color: #282828;
}
.card hr {
  display: block;
  border: none;
  height: 3px;
  background-color: #f38630;
  margin: 0px;
  -webkit-animation-name: line-show;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.3s;
  /* Safari 4.0 - 8.0 */
  animation-name: line-show;
  animation-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  -o-transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  /* custom */
}
.card p {
  margin: 15px 0px 0px 0px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 100;
  letter-spacing: -0.25px;
  line-height: 1.25;
  font-size: 16px;
  word-break: break-all;
  word-wrap: pre-wrap;
  color: #282828;
  -webkit-animation-name: p-show;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s;
  /* Safari 4.0 - 8.0 */
  animation-name: p-show;
  animation-duration: 1.5s;
}
.card button {
  border: none;
  background-color: salmon;
  width: 49%;
  margin: 1em auto;
  padding: 1em;
  color: white;
  font-family: 'Noto Sans KR', sans-serif;
  text-transform: uppercase;
}
.green{
  background-color: green !important;
}
</style>