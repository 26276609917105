<template>
  <div id="tabs">
    <ul>
      <li><a href="#tabs-contato">Contato</a></li>
    </ul>

    <div class="row">
      <div class="col-md-12" id="tabs-contato">
        <ContatoList/>
      </div>
    </div>
  </div>
</template>

<script>
import ContatoList from "./contato/ContatoList";
export default {
  name: "SettingsIndex",

  components: {ContatoList},

  data: () => ({
    ative: null,
    tabs: ['Contato', 'Usuário']
  }),

  created() {
    this.ative = 0;
  },

  methods: {
    showContent(index) {
      this.ative = index;
    }
  },

}
</script>

<style scoped>

</style>