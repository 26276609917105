import { render, staticRenderFns } from "./BarraDireito.vue?vue&type=template&id=f06794d8&scoped=true&"
import script from "./BarraDireito.vue?vue&type=script&lang=js&"
export * from "./BarraDireito.vue?vue&type=script&lang=js&"
import style0 from "./BarraDireito.vue?vue&type=style&index=0&id=f06794d8&prod&lang=css&"
import style1 from "./BarraDireito.vue?vue&type=style&index=1&id=f06794d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f06794d8",
  null
  
)

export default component.exports