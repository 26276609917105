<template>
    <div  class="agendamento modal-2" v-if='getModalSchedule'>
            <div class="modal-content-my ">
                <span @click='btnClose' class='closeBtn'>&times;</span>
                <form class=''>
                    <p>agendamento</p>
                    <div class='linha'></div>
                    <div class='direita'>
                        <div class='esquerda  label-date' >
                            <label for='' class='cima'>data e hora</label>
                            <input type="datetime-local" class="esquerda input-date">
                        </div>
                        
                    </div>
                    
                    <div class='input-content-area'>
                        <label class='cima'>Observação</label>
                        <textarea class='' rows='4' cols="50">
                        </textarea>
                    </div>
                    <div class='button-submit'>
                        <input class='button-modal' type="submit" value='salvar'>
                    </div>
                </form>
                
            </div>
        </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
export default {
    computed:{
        ...mapGetters(['getModalSchedule'])
    },
    methods:{
        ...mapActions(['setModalSchedule']),
        btnClose(event){
            this.setModalSchedule();
        }
    }
}
</script>