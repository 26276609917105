<template>
    <div>
        <div class="row">
            <div class="card-status-center">
                <card-status />
            </div>
            <div class="col-md-7">
                <div class="row">

                    <card-realtime
                     v-for="op in operators"
                     :key="op.id"
                     :nome="op.nome" 
                     :id="op.id" 
                     :extension="op.extension_line"
                     :status="userStatus"
                     :connection="connection"
                     :userQueues="op.queues"
                    ></card-realtime>

                </div>

            </div>

            <div class="col-md-5">
                <calls-queue :connection="connection"/>
                <total-calls :connection="connection"/>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import cardRealtime from './CardRealtime.vue';
import cardStatus from './CardStatus.vue';
import callsQueue from './CallsinQueue';
import totalCalls from './TotalCalls';
const parsedTelefone = (process.env.MIX_TELEFONE === 'true');
const connection = (parsedTelefone) ? io.connect(`${location.protocol}//${location.hostname}:3007/realtimeModule`) : {'on': function(){}, 'emit': function(){}};	

export default {
    components: {cardRealtime, cardStatus, callsQueue, totalCalls},
    data() {
        return {
            operators: [],
            connection: {},
            queueStatus: []
        }
    },
    methods: {
        groupQueues() {
            this.queueStatus = [];
        }
    },
    created() {
        this.connection = connection;
        axios.get('/realtime/listUsersPhone').then(({ data }) => {
            this.operators = data;
        });

        this.userStatus = 'Deslogado'
    },
    mounted() {
        this.connection.emit('QueueStatus');
    }
}
</script>

<style scoped>
.card-status-center{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>