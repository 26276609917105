const elemento = {};

const setStatus = (extension, status) => elemento[extension] = status
const getStatus = () => {
    return {
        'ativo': Object.entries(elemento).filter( (el) =>  el[1] == 'ativo').length,
        'deslogado': Object.entries(elemento).filter( (el) =>  el[1] == 'deslogado').length,
        'ligacao': Object.entries(elemento).filter( (el) =>  el[1] == 'ligacao').length,
        'pausa': Object.entries(elemento).filter( (el) =>  el[1] == 'pausa').length,
    }
}
module.exports = {
    elemento,
    getStatus,
    setStatus
}