<template>
   <div style="margin-top: 50px">
	   <h3>Ligações Recebidas na Semana</h3>
     <apexchart ref="mixedChart" type="line" :options="chartOptions" :series="series"></apexchart>
   </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
	props: ["filter"],
    data: function() {
      return {
		series: [],
		chartOptions: {
			chart: {
				height: 350,
				type: 'line',
				stacked: false
			},
			stroke: {
				width: 2
			},
			plotOptions: {
              bar: {
                dataLabels: {
                  position: 'center',
                },
              }
            },
			dataLabels: {
				enabled: true,
			},
			markers: {
				size: 5,
				hover: {
					sizeOffset: 2
				}
			},
			xaxis: {
				categories: [],
			},
			tooltip: {
				x: { formatter: data => `Dia ${data}` },
			},
			legend: {
				horizontalAlign: 'center',
				offsetX: 80
			},
			noData: {
                text: 'Sem dados para exibir...'
            },
			colors:['#008FFB', '#FF4560', '#00E396', '#ffd60a', '#775DD0', '#7f4f24', '#00f5d4', '#4d194d', '#333533']
	  	},
      }
    },
	methods: {
		getAttendances() {
			axios.get(`${window.location.href}/weeklyCallsPerQueue?queue=${this.filter}`).then(({ data }) => {
				let totalMonth = [];
				let days = [];
				data.TotalMonth.forEach(element => {
					days.push(moment(element.date).format('DD/MM/YY'));
					totalMonth.push(element.total);
				});

				this.series.length = 0;
				data.PerQueue.forEach(element => {
					this.series.push({
						name: element.queuename.toUpperCase(),
						type: 'column',
						data: element.total
					})
				});

				this.series.push({
					name: "TOTAL DO DIA",
					type: 'line',
					data: totalMonth
				});

				this.$refs.mixedChart.updateOptions({
					xaxis: { categories: days }
				});
			});
		},
	},
	created() {
		this.getAttendances();
		setInterval(() => {
			this.getAttendances();
    	}, 30000);
	},
	watch: {
		filter() {
			this.getAttendances();
		}
	}
};
</script>
