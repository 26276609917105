<template>
  <div class="row">
    <div class="col-12">
      <label for="inputCostumersList">Cliente</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="fas fa-user-circle fa-fw"></i>
          </span>
        </div>
        <input
          type="hidden"
          autocomplete="off"
          :required="required"
          id="costumerId"
          name="costumer_id"
        />
        <input
          type="text"
          autocomplete="off"
          class="form-control autoComplete"
          id="inputCostumersList"
          name="costumer_name"
          ref="costumers"
          placeholder="Digite para buscar o cliente"
          :required="required"
          :readonly="readonly"
          :disabled="disabled"
        />
      </div>
      <div
        v-if="found"
        id="selectCostumers"
        style="background-color: #ffffff; border-radius: 3px; position: absolute; height: auto; width: 92%; z-index: 9999; box-shadow: 0px 3px 3px #ccc;"
      >
        <div class="list-group">
          <!-- list of founded costumers -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      found: []
    };
  },
  mounted: function() {
    var self = this;
    $(".autoComplete").on("input", function() {
      let name = $(this).val();
      axios
        .get("/customer_base/searchClients", { params: { search: name } })
        .then(response => {
          var costumers = response.data;

          self.found = [];
          if (name.length > 0) {
            $.each(costumers, function(index, item) {
              if (
                item.fantasy_name.toLowerCase().includes(name.toLowerCase()) ||
				item.cpf_cnpj.includes(name) ||
				item.cellphone.includes(name) ||
				item.telphone.includes(name) ||
                item.corporate_name.toLowerCase().includes(name.toLowerCase())
              ) {
                self.found.push(item);
              }
            });
            $("#selectCostumers div.list-group button").remove();
            $.each(self.found, function(index, item) {
              var listItem = `<button type="button" data-id="${item.id}" data-name="${item.fantasy_name}" class="list-group-item list-group-item-action costumerListItem">${item.fantasy_name}</button>`;
              $("#selectCostumers div.list-group").append(listItem);
            });
          } else {
            $("#selectCostumers div.list-group button").remove();
          }

          $(document).on("click", function(event) {
            event.stopPropagation();
            if (
              $(event.target).hasClass("costumerListItem") ||
              event.target.id == "inputCostumersList"
            ) {
              var elem = $(event.target);
              var id = elem.data("id");
              var name = elem.data("name");
              $("#inputCostumersList").val(name);
              $("#costumerId").val(id);

              self.found = 0;
            }
          });
        });
      return;
    });
  }
};
</script>