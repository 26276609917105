<template>
  <b-modal
    v-model="show"
    title="Mensagens Prontas"
    size="lg"
    centered
    scrollable
  >
    <b-container fluid>
      <b-row>
        <b-col cols="4" class="pl-0">
          <b-list-group class="h-100" v-if="!isBusca">
            <b-list-group-item
              @click="showContent(i)"
              style="cursor: pointer"
              class="d-flex justify-content-between align-items-center text-white"
              :class="[indexAtivo === i ? 'bg-active' : 'bg-adv']"
              v-for="(value, i) in tabs"
              :key="i"
            >
              {{ value }}
              <b-icon icon="caret-right-fill" class="text-white"></b-icon>
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center bg-adv text-white h-100"
            >
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="8" class="p-3">
          <b-alert show variant="info" v-if="allMensagens.length > 0"
            ><strong>Clique na mensagem para selecionar.</strong></b-alert
          >
          <b-alert show variant="info" v-else
            ><strong
              >Não possui mensagens para esse departamento</strong
            ></b-alert
          >
          <div class="balloon" v-for="(msg, index) in mensagens" :key="index">
            <p
              @click="() => selectMessage(msg)"
              v-html="formatMessage(msg)"
            ></p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <div class="w-100 justify-content-center">
        <b-input-group>
          <b-form-input
            type="search"
            v-model="search"
            placeholder="Busque por uma mensagem"
            class="rounder"
          />
          <b-input-group-append>
            <b-input-group-text class="justify-content-center">
              <b-icon icon="search" font-scale="1" variant="secondary" />
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
const msgPronta = require("./../../../modules/newScrean/mensagem/mensagensProntas");
window.$eventMessage = new Vue();

export default {
  name: "MensagenPronta",
  data: () => ({
    show: false,
    search: "",
    indexAtivo: null,
    allMensagens: [],
    isBusca: false,
    nameContact: "",
  }),
  created() {
    msgPronta.getMensagens().forEach((el) => {
      user
        .getChatQueues()
        .map((li) => li)
        .includes(el.departamento)
        ? this.allMensagens.push(el)
        : [];
    });
    this.indexAtivo = 0;
  },
  computed: {
    // ...mapGetters(["getDataConnectLine"]),
    tabs() {
      return this.allMensagens.map((i) => i.text);
    },
    mensagens() {
      if (this.search.length >= 4) {
        this.isBusca = true;
        return this.allMensagens
          .reduce((acc, valor) => [...acc, ...valor.mensagens], [])
          .filter((valor) => valor.search(new RegExp(this.search, "gi")) >= 0);
      }

      const msg = this.allMensagens[this.indexAtivo]
        ? this.allMensagens[this.indexAtivo].mensagens
        : false;

      if (msg) {
        this.isBusca = false;
        return msg;
      }

      return [];
    },
  },
  methods: {
    selectMessage(msg) {
      let result = msg.replace(/%nome_contato%/g, this.nameContact);
      $eventMessage.$emit("sendMensagem", result);
      this.show = false;
      this.search = "";
    },
    formatMessage(msg) {
      let result = msg.replace(/%nome_contato%/g, this.nameContact);
      result = result.replace(/\n/g, "<br/>");
      return result;
    },
    showContent(index) {
      this.indexAtivo = index;
    },
  },
  watch: {
    // getDataConnectLine(val) {
    //   this.nameContact = val.name;
    // },
  },
};
</script>

<style scoped lang="scss">
.rounder {
  border-radius: 0.5em 0 0 0.5em !important;
}
.input-group-text {
  border-radius: 0 0.5em 0.5em 0 !important;
  width: 37px !important;
  border: 1px solid #ccc !important;
  background-color: white !important;
}
.bg-adv {
  background-color: #0c5460;
}
.bg-active {
  background-color: #1b6f7d;
}
.list-group {
  border-radius: 0 !important;
}
.balloon::v-deep {
  p {
    position: relative;
    max-width: 82%;
    background-color: #ccc;
    padding: 1em;
    font-size: 1em;
    border-radius: 0.7rem 0 0.7rem 0.7rem;
    cursor: pointer;
    margin-left: 15%;
    overflow-wrap: break-word;

    &:hover {
      box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
        0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
    }

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: 15px solid #ccc;
      border-right: 15px solid transparent;
      border-top: 15px solid #ccc;
      border-bottom: 15px solid transparent;
      right: -16px;
      top: 0;
    }
  }
}
</style>

<style>
.modal-body {
  padding: 10;
}
</style>
