<template>
  <section>
    <div class="row mb-4" v-if="false">
      <button :class="['btn', 'btn-secondary',{'active': 'phone' == cl}]" @click="setRT('phone')">  <i class="fas fa-phone icon"></i> </button>
      <button :class="['btn', 'btn-secondary',{'active': 'chat' == cl}]" @click="setRT('chat')">  <i class="fas fa-comment icon"></i> </button>
      <button :class="['btn', 'btn-secondary',{'active': 'toogle' == cl}]" @click="setRT('toogle')">  <i class="fas fa-reply icon"></i> </button>
    </div>

    <div class="row m-4">
      <div class="col-md-8" :class="[{'toogle-el': toogleRealtime}]">
        <TbFilaURA/>
        <TbFilaAtendimento/>
        <TbFilaAndamento/>
      </div>
      <div class="col-md-4 p-0" :class="!toogleRealtime ? 'container-align' : 'toogle-el'">
        <TbFilaDetail class="m-2"/>
        <GraficoRespondids class="m-2"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8" :class="!toogleRealtime ? 'container-align' : 'toogle-el'">
        <TbUsers ref="tbUsers"/>
      </div>
      <div class="col-md-4" :class="[{'toogle-el': toogleRealtime}]">
        <GraficoOcupacaoAgent/>
      </div>

      <realtime-phone :class="[{'toogle-el': !toogleRealtime}]"/>
    </div>

  </section>
</template>

<script>
import TbUsers from "./components/TbUsers";
import GraficoRespondids from "./components/GraficoRespondids";
import GraficoOcupacaoAgent from "./components/GraficoOcupacaoAgent";
import TbFilaAtendimento from "./components/TbFilaAtendimento";
import RealtimePhone from "./RealtimePhone.vue";
import TbFilaURA from "./components/TbFilaURA";
import TbFilaAndamento from "./components/TbFilaAndamento";
import TbFilaDetail from "./components/TbFilaDetail";

export default {
  name: "RealtimeApp",
  components: {
    TbFilaAtendimento,
    TbFilaURA,
    TbFilaAndamento,
    TbFilaDetail,
    GraficoOcupacaoAgent,
    GraficoRespondids,
    TbUsers,
    RealtimePhone,
  },
  data() {
    return {
      toogleRealtime: true,
      intervalId: "",
      cl: "phone",
    };
  },
  created() {
      // this.intervalId = setInterval(() => {
      //   this.toogleRealtime = !this.toogleRealtime;
      // }, 15000);
  },
  methods: {
    setRT(style) {
      this.cl = style;
      clearInterval(this.intervalId);

      if (style == "phone") {
        return (this.toogleRealtime = true);
      }

      if (style == "chat") {
        return (this.toogleRealtime = false);
      }

      if (style == "toogle") {
        // this.intervalId = setInterval(() => {
        //   this.toogleRealtime = !this.toogleRealtime;
        // }, 15000);
      }
    },
  },
};
</script>

<style scoped>
.toogle-el {
  display: none;
}

.container-align {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>