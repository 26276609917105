<template>
  <b-card border-variant="dark">
    <div id="chart1">
      <apexchart type="donut" :options="chartOptions" :series="series" ref="realtimeResp"></apexchart>
    </div>
  </b-card>
</template>

<script>
const socket = require('./../../../modules/realtime/service/service');

export default {
  name: "GraficoRespondids",

  data:() => ({
    chartOptions: {
      chart: {
        type: 'donut',
      },
      labels: ['Em atendimento', 'Aguardando atendimento'],
      colors: ['#23ed87', '#fe0000'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                color: '#939595',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                }
              }
            }
          }
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
    },
  }),

  computed: {
    series() {
      return [0,0];
    }
  },

  created() {
    socket.connection.on('queueDetailAll', (obj) => {
        this.$refs.realtimeResp.updateSeries([obj.queues.atendimento, obj.queues.aguardando]);
    });
  },
}
</script>

<style scoped>
  .card {
    color: black !important;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 0.25rem !important;
  }
</style>